<template>
  <div class="accordion-card" header-tag="header" no-body role="tab"  >
      <div tabindex="0" block v-b-toggle="'accordion-accordiondata_' + this.id" class="accordion-header">
         <div class="accordion-heading">

           <div v-if="item.contentMap" class="desktopShow">
             <h2 class="h5" v-if="item.title">
               {{ item.title }}
             </h2>
           </div>

           <div v-else class="title">
             <h2 class="h5" v-if="item.title">
               {{ item.title }}
             </h2>
           </div>


           <div v-if="item.contentMap" class="desktopHide">
             <div  v-if="item.Icon" class="iconArea">
               <img :src="getImgUrl(item.Icon)" height="25" alt="information icon"/>
             </div>
           </div>

           <div v-else class="icon">
             <div  v-if="item.Icon" class="iconArea">
               <img :src="getImgUrl(item.Icon)" height="25" alt="information icon"/>
             </div>
           </div>

        <div class="accordionIcon">
          <div class="ShowClose">
            <i class="fas fa-chevron-down"></i>
          </div>
        </div>
         </div>
    </div>

     <b-collapse v-if="item.contentMap" :id="'accordion-accordiondata_' + this.id" :accordion="'accordiondata-accordion'"
                 class="accordion-body content map" tabindex="0">

      <div class="textArea">
        <div v-if="item.text && Array.isArray(item.text)" sm="12" md="10" lg="6"  class="answerText">
          <p
              v-for="(paragraph, index) in item.text"
              :key="`${$route.name}_paragraphs_${index}`"
              v-html="paragraph"
          ></p>
        </div>

        <div else v-else-if="item.text" sm="12" md="10" lg="6"  class="answerText">
          <h6>
            {{ item.text}}
          </h6>
        </div>
      </div>
     </b-collapse>

    <b-collapse v-else :id="'accordion-accordiondata_' + this.id" :accordion="'accordiondata-accordion'"
                class="accordion-body content" tabindex="0">

      <div class="textArea">
        <div v-if="item.text && Array.isArray(item.text)" sm="12" md="10" lg="6"  class="answerText">
          <p
              v-for="(paragraph, index) in item.text"
              :key="`${$route.name}_paragraphs_${index}`"
              v-html="paragraph"
          ></p>
        </div>

        <div else v-else-if="item.text" sm="12" md="10" lg="6"  class="answerText">
          <h6>
            {{ item.text}}
          </h6>
        </div>
      </div>
    </b-collapse>

  </div>
</template>

<script>

export default {
  name: "AccordionCard",
  props: {
    item: {
      type: Object,
      default() {
        return {
          title: "Question Here",
          text: "Answer here",
        };
      },
    },
    isExpanded:{
      type: Boolean,
      default: false,
    },
    id:
        {
      type: Number,
      default() {
        return 0;
      }
    }
  },
  methods:
      {
        getImgUrl(pet)
        {
          var images = require.context('../assets/images/Icons/', false, /\.png$/)
          return images('./' + pet + ".png")
        },
      }
}
</script>

<style lang="scss">
@import "../Variables";

.accordion-card, 
//.accordion-card:focus, 
.accordion-card:active
{
  padding: 0;
  margin: 1rem 0 0;
  background-color: transparent!important;
  box-shadow: none!important;
  border: none!important;
  border-radius: 0!important;

  .accordion-header,
  .accordion-header:active {
    margin: 0;
    background-color: $Gcc-Pale-BG;
    padding: 10px 0;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    box-shadow: none;
    cursor: pointer;

    &.not-collapsed {
      .ShowClose {
         -webkit-transform:rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }

      &:focus {
        outline: none;
      }
    }

    &.collapsed {
      &:focus {
        outline: 2px solid #0054A4;
      }
    }

    .accordion-heading
    {
      position: relative;
      padding: 10px 25px 4px;

      .desktopShow{

        display: block;

        @media screen and (max-width: 1024px) {
          display: none;
        }
      }

      .desktopHide{

        display: none;

        @media screen and (max-width: 1024px) {
          display: block;
        }
      }

      .textAreaHeader
      {
        flex: auto;
        padding: 0 10px;
        background-color: $Gcc-Pale-BG;

        @media screen and (min-width: 1025px) {
          padding: 5px 20px;
        }
      }
    }

    .accordionIcon
    {
      padding-right: 1vw;
      font-size: 16px;
      color: $Gcc-Blue;
      text-align-last: center;
      align-self: center;
      position: absolute;
      right: 5px;
      top: 12px;
    }
  }

  .collapsible {
     .textArea
      {
        flex: auto;
        padding: 0 10px;
        background-color: $Gcc-Pale-BG;

         @media screen and (min-width: 1025px) {
            padding: 5px 20px;
          }
      }
  }


  .content {
    padding: 0 18px;
    transition: max-height 0.2s ease-out;
    background-color: #f1f1f1;

    &.map{
      //overflow: scroll;
      //max-height: 12rem;

      &.collapse {
        &.show {
          &:focus {
            outline: 2px solid #0054A4;
          }
        }
      }
    }

    &.collapse {
      &.show {
        &:focus {
          outline: 2px solid #0054A4;
        }
      }
    }

    .textArea
    {
      flex: auto;
      padding: 10px;
      background-color: $Gcc-Pale-BG;

       @media screen and (min-width: 1025px) {
          padding: 5px 20px;
        }
    }
  }

  .iconArea
  {
    width: auto;
    padding-right: 100%;
    //padding-left: 1vw;
    text-align-last: right;
    margin: auto;
    position: relative;
    //transform: translate(0px, -30px);
  }

  .answerText {
    margin: 10px 4% 3% 2%;
  }

  p {
    color: $Gcc-Blue;
    font-size: 16px;
    line-height: 22px;
  }


}
</style>