var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"questionnaire"},[(!_vm.isThankYouPage)?_c('SectionMap',{attrs:{"currentIndex":_vm.section},on:{"update":_vm.sectionUpdate}}):_vm._e(),_c('div',{class:("inputSection " + (_vm.section === 0 && 'active')),attrs:{"id":"feedback-section-1"}},[_c('TextTitleFullWidth',{attrs:{"title":_vm.sectionTitleData.title,"text":_vm.sectionTitleData.text,"smallTitle":true}}),_c('div',{staticClass:"feedbackInput"},[_c('RadioColumn',{attrs:{"id":'agreeWithScheme',"titleText":_vm.section1QData.Q1.title,"questions":_vm.section1QData.Q1.questions,"commentBox":false,"currentInput":_vm.savedFeedbackData.section1.question1
            ? _vm.savedFeedbackData.section1.question1
            : []}})],1),_c('div',{staticClass:"feedbackInput"},[_c('CheckboxTable',{attrs:{"id":'schemeOptionOpinion',"titleText":_vm.section1QData.Q2.title,"list":_vm.section1QData.Q2.paragraphs,"answers":_vm.section1QData.Q2.answers,"questions":_vm.section1QData.Q2.questions,"commentBox":true,"commentBoxText":_vm.section1QData.Q2.commentBox,"commentBoxPreviewText":_vm.section1QData.Q2.commentBoxPreviewText,"currentInput":_vm.savedFeedbackData.section1.question2
            ? _vm.savedFeedbackData.section1.question2
            : [[], [], [], []]}})],1),_c('div',{staticClass:"feedbackInput"},[_c('RadioButtonTable',{attrs:{"id":'concerns',"titleText":_vm.section1QData.Q3.title,"list":_vm.section1QData.Q3.paragraphs,"answers":_vm.section1QData.Q3.answers,"questions":_vm.section1QData.Q3.questions,"commentBox":false,"commentBoxText":_vm.section1QData.Q3.commentBox,"currentInput":_vm.savedFeedbackData.section1.question3
            ? _vm.savedFeedbackData.section1.question3
            : [],"listOn":false}})],1),_c('div',{staticClass:"feedbackInput"},[_c('RadioButtonTable',{attrs:{"id":'concerns',"titleText":_vm.section1QData.Q4.title,"list":_vm.section1QData.Q4.paragraphs,"answers":_vm.section1QData.Q4.answers,"questions":_vm.section1QData.Q4.questions,"commentBox":false,"commentBoxText":_vm.section1QData.Q4.commentBox,"currentInput":_vm.savedFeedbackData.section1.question4
            ? _vm.savedFeedbackData.section1.question4
            : [],"listOn":false}})],1)],1),_c('div',{class:("inputSection " + (_vm.section === 1 && 'active')),attrs:{"id":"feedback-section-2"}},[_c('TextTitleFullWidth',{attrs:{"title":_vm.sectionTitleData.title,"text":_vm.sectionTitleData.text,"smallTitle":true}}),_c('div',{staticClass:"feedbackInput"},[(_vm.section2QData.subsection1)?_c('div',[_c('h3',[_vm._v(_vm._s(_vm.section2QData.subsection1.title))]),_c('RadioColumn',{attrs:{"id":'travel',"titleText":_vm.section2QData.subsection1.Q1.title,"questions":_vm.section2QData.subsection1.Q1.questions,"commentBox":false,"currentInput":_vm.savedFeedbackData.section2.question5
            ? _vm.savedFeedbackData.section2.question5
            : []}}),_c('OptionComments',{attrs:{"textTitle":_vm.section2QData.subsection1.Q2.title,"options":_vm.section2QData.subsection1.Q2.question,"commentBoxPreviewText":_vm.section2QData.subsection1.Q2.commentBoxPreviewText,"currentInput":_vm.savedFeedbackData.section2.question6
            ? _vm.savedFeedbackData.section2.question6
            : []}})],1):_vm._e()]),_c('div',{staticClass:"feedbackInput"},[(_vm.section2QData.subsection2)?_c('div',[_c('h3',[_vm._v(_vm._s(_vm.section2QData.subsection2.title))]),_c('RadioColumn',{attrs:{"id":'travel',"titleText":_vm.section2QData.subsection2.Q1.title,"questions":_vm.section2QData.subsection2.Q1.questions,"commentBox":false,"currentInput":_vm.savedFeedbackData.section2.question7
            ? _vm.savedFeedbackData.section2.question7
            : []}}),_c('OptionComments',{attrs:{"textTitle":_vm.section2QData.subsection2.Q2.title,"options":_vm.section2QData.subsection2.Q2.question,"commentBoxPreviewText":_vm.section2QData.subsection2.Q2.commentBoxPreviewText,"currentInput":_vm.savedFeedbackData.section2.question8
            ? _vm.savedFeedbackData.section2.question8
            : []}})],1):_vm._e()]),_c('div',{staticClass:"feedbackInput"},[(_vm.section2QData.subsection3)?_c('div',[_c('h3',[_vm._v(_vm._s(_vm.section2QData.subsection3.title))]),_c('RadioColumn',{attrs:{"id":'travel',"titleText":_vm.section2QData.subsection3.Q1.title,"questions":_vm.section2QData.subsection3.Q1.questions,"commentBox":true,"commentBoxText":_vm.section2QData.subsection3.Q1.commentBox,"commentBoxPreviewText":_vm.section2QData.subsection3.Q1.commentBoxPreviewText,"ariaLabelText":_vm.section2QData.subsection3.Q1.optionDetail,"currentInput":_vm.savedFeedbackData.section2.question9
            ? _vm.savedFeedbackData.section2.question9
            : []}})],1):_vm._e()]),_c('div',{staticClass:"feedbackInput"},[(_vm.section2QData.subsection4)?_c('div',[_c('h3',[_vm._v(_vm._s(_vm.section2QData.subsection4.title))]),_c('h4',{staticClass:"h5"},[_vm._v(_vm._s(_vm.section2QData.subsection4.extrasubsection1.title))]),_c('RadioColumn',{attrs:{"id":'travel',"titleText":_vm.section2QData.subsection4.extrasubsection1.Q1.title,"questions":_vm.section2QData.subsection4.extrasubsection1.Q1.questions,"commentBox":false,"currentInput":_vm.savedFeedbackData.section2.question10
            ? _vm.savedFeedbackData.section2.question10
            : []}}),_c('OptionComments',{attrs:{"textTitle":_vm.section2QData.subsection4.extrasubsection1.Q2.title,"options":_vm.section2QData.subsection4.extrasubsection1.Q2.question,"commentBoxPreviewText":_vm.section2QData.subsection4.extrasubsection1.Q2.commentBoxPreviewText,"currentInput":_vm.savedFeedbackData.section2.question11
            ? _vm.savedFeedbackData.section2.question11
            : []}}),_c('h4',{staticClass:"h5"},[_vm._v(_vm._s(_vm.section2QData.subsection4.extrasubsection2.title))]),_c('RadioColumn',{attrs:{"id":'travel',"titleText":_vm.section2QData.subsection4.extrasubsection2.Q1.title,"questions":_vm.section2QData.subsection4.extrasubsection2.Q1.questions,"commentBox":false,"currentInput":_vm.savedFeedbackData.section2.question12
            ? _vm.savedFeedbackData.section2.question12
            : []}}),_c('OptionComments',{attrs:{"textTitle":_vm.section2QData.subsection4.extrasubsection2.Q2.title,"options":_vm.section2QData.subsection4.extrasubsection2.Q2.question,"commentBoxPreviewText":_vm.section2QData.subsection4.extrasubsection2.Q2.commentBoxPreviewText,"currentInput":_vm.savedFeedbackData.section2.question13
            ? _vm.savedFeedbackData.section2.question13
            : []}})],1):_vm._e()]),_c('div',{staticClass:"feedbackInput"},[(_vm.section2QData.subsection5)?_c('div',[_c('h3',[_vm._v(_vm._s(_vm.section2QData.subsection5.title))]),_c('RadioColumn',{attrs:{"id":'travel',"titleText":_vm.section2QData.subsection5.Q1.title,"questions":_vm.section2QData.subsection5.Q1.questions,"commentBox":false,"currentInput":_vm.savedFeedbackData.section2.question14
            ? _vm.savedFeedbackData.section2.question14
            : []}}),_c('OptionComments',{attrs:{"textTitle":_vm.section2QData.subsection5.Q2.title,"options":_vm.section2QData.subsection5.Q2.question,"commentBoxPreviewText":_vm.section2QData.subsection5.Q2.commentBoxPreviewText,"currentInput":_vm.savedFeedbackData.section2.question15
            ? _vm.savedFeedbackData.section2.question15
            : []}})],1):_vm._e()])],1),_c('div',{class:("inputSection " + (_vm.section === 2 && 'active')),attrs:{"id":"feedback-section-3"}},[_c('TextTitleFullWidth',{attrs:{"title":_vm.sectionTitleData.title,"text":_vm.sectionTitleData.text,"smallTitle":true}}),_c('div',{staticClass:"feedbackInput"},[_c('RadioColumn',{attrs:{"id":'travel',"titleText":_vm.section3QData.Q1.title,"questions":_vm.section3QData.Q1.questions,"commentBox":false,"currentInput":_vm.savedFeedbackData.section3.question16
            ? _vm.savedFeedbackData.section3.question16
            : []}})],1),_c('div',{staticClass:"feedbackInput"},[_c('OptionComments',{attrs:{"textTitle":_vm.section3QData.Q2.title,"options":_vm.section3QData.Q2.question,"commentBoxPreviewText":_vm.section3QData.Q2.commentBoxPreviewText,"currentInput":_vm.savedFeedbackData.section3.question17
            ? _vm.savedFeedbackData.section3.question17
            : []}})],1),_c('div',{staticClass:"feedbackInput"},[_c('OptionComments',{attrs:{"textTitle":_vm.section3QData.Q3.title,"options":_vm.section3QData.Q3.question,"commentBoxPreviewText":_vm.section3QData.Q3.commentBoxPreviewText,"currentInput":_vm.savedFeedbackData.section3.question18
            ? _vm.savedFeedbackData.section3.question18
            : []}})],1),_c('h3',[_vm._v(_vm._s(_vm.section3QData.subsection0.title))]),_c('div',{staticClass:"feedbackInput"},[_c('OptionComments',{attrs:{"textTitle":_vm.section3QData.subsection0.Q4.title,"options":_vm.section3QData.subsection0.Q4.question,"commentBoxPreviewText":_vm.section3QData.subsection0.Q4.commentBoxPreviewText,"currentInput":_vm.savedFeedbackData.section3.question19
            ? _vm.savedFeedbackData.section3.question19
            : []}})],1),_c('h3',[_vm._v(_vm._s(_vm.section3QData.subsection1.title))]),_c('div',{staticClass:"feedbackInput"},[_c('CheckboxTable',{attrs:{"id":'travel',"titleText":_vm.section3QData.subsection1.Q5.title,"list":_vm.section3QData.subsection1.Q5.paragraphs,"answers":_vm.section3QData.subsection1.Q5.answers,"questions":_vm.section3QData.subsection1.Q5.questions,"areQuestions":false,"commentBox":true,"commentBoxText":_vm.section3QData.subsection1.Q5.commentBox,"commentBoxPreviewText":_vm.section3QData.subsection1.Q5.commentBoxPreviewText,"currentInput":_vm.savedFeedbackData.section3.question20
            ? _vm.savedFeedbackData.section3.question20
            : [[], [], [], []]}})],1),_c('div',{staticClass:"feedbackInput"},[_c('OptionComments',{attrs:{"textTitle":_vm.section3QData.subsection1.Q6.title,"options":_vm.section3QData.subsection1.Q6.question,"commentBoxPreviewText":_vm.section3QData.subsection1.Q6.commentBoxPreviewText,"currentInput":_vm.savedFeedbackData.section3.question21
            ? _vm.savedFeedbackData.section3.question21
            : []}})],1),_c('div',{staticClass:"feedbackInput"},[_c('RadioColumn',{attrs:{"id":'travel',"titleText":_vm.section3QData.subsection1.Q7.title,"questions":_vm.section3QData.subsection1.Q7.questions,"commentBox":true,"commentBoxText":_vm.section3QData.subsection1.Q7.commentBox,"commentBoxPreviewText":_vm.section3QData.subsection1.Q7.commentBoxPreviewText,"ariaLabelText":_vm.section3QData.subsection1.Q7.optionDetail,"currentInput":_vm.savedFeedbackData.section3.question22
            ? _vm.savedFeedbackData.section3.question22
            : []}})],1),_c('div',{staticClass:"feedbackInput"},[_c('RadioColumn',{attrs:{"id":'travel',"titleText":_vm.section3QData.subsection1.Q8.title,"questions":_vm.section3QData.subsection1.Q8.questions,"commentBox":false,"commentBoxText":_vm.section3QData.subsection1.Q8.commentBox,"currentInput":_vm.savedFeedbackData.section3.question23
            ? _vm.savedFeedbackData.section3.question23
            : []}})],1),_c('div',{staticClass:"feedbackInput"},[_c('NameAddress',{attrs:{"nameAddress":_vm.section3QData.subsection1.Q9.values,"title":_vm.section3QData.subsection1.Q9.title,"text":_vm.section3QData.subsection1.Q9.text,"ariaLabelText":_vm.section3QData.subsection1.Q9.optionDetail,"currentInput":_vm.savedFeedbackData.section3.question24
            ? _vm.savedFeedbackData.section3.question24
            : {}}})],1)],1),_c('div',{class:("inputSection " + (_vm.section === 3 && 'active')),attrs:{"id":"feedback-section-4"}},[_c('TextTitleFullWidth',{attrs:{"title":_vm.sectionTitleData.title,"text":_vm.sectionTitleData.text,"smallTitle":true}}),_c('div',{staticClass:"feedbackInput"},[_c('NameAddress',{attrs:{"nameAddress":_vm.section4QData.Q1.values,"title":_vm.section4QData.Q1.title,"text":_vm.section4QData.Q1.text,"ariaLabelText":_vm.section4QData.Q1.optionDetail,"currentInput":_vm.savedFeedbackData.section4.question25
            ? _vm.savedFeedbackData.section4.question25
            : {}}})],1),_c('div',{staticClass:"feedbackInput"},[_c('RadioColumn',{attrs:{"id":'travel',"titleText":_vm.section4QData.Q2.title,"questions":_vm.section4QData.Q2.questions,"commentBox":false,"currentInput":_vm.savedFeedbackData.section4.question26
            ? _vm.savedFeedbackData.section4.question26
            : []}})],1),_c('div',{staticClass:"feedbackInput"},[_c('RadioColumn',{attrs:{"id":'useriden',"titleText":_vm.section4QData.Q3.title,"questions":_vm.section4QData.Q3.questions,"commentBox":false,"currentInput":_vm.savedFeedbackData.section4.question27
            ? _vm.savedFeedbackData.section4.question27
            : []}})],1),_c('div',{staticClass:"feedbackInput"},[_c('RadioColumn',{attrs:{"id":'travel',"titleText":_vm.section4QData.Q4.title,"questions":_vm.section4QData.Q4.questions,"commentBox":false,"currentInput":_vm.savedFeedbackData.section4.question28
            ? _vm.savedFeedbackData.section4.question28
            : []}})],1),_c('div',{staticClass:"feedbackInput"},[_c('RadioButtonTable',{attrs:{"id":'concerns',"hasNoQuestions":true,"values":_vm.section4QData.Q5.values,"titleText":_vm.section4QData.Q5.title,"list":_vm.section4QData.Q5.paragraphs,"commentBox":true,"commentBoxText":_vm.section4QData.Q5.commentBox,"commentBoxPreviewText":_vm.section4QData.Q5.commentBoxPreviewText,"currentInput":_vm.savedFeedbackData.section4.question29
            ? _vm.savedFeedbackData.section4.question29
            : [],"listOn":false}})],1),_c('div',{staticClass:"feedbackInput"},[_c('RadioColumn',{attrs:{"id":'travel',"titleText":_vm.section4QData.Q6.title,"questions":_vm.section4QData.Q6.questions,"commentBox":false,"currentInput":_vm.savedFeedbackData.section4.question30
            ? _vm.savedFeedbackData.section4.question30
            : []}})],1),_c('div',{staticClass:"feedbackInput"},[_c('RadioColumn',{attrs:{"id":'travel',"titleText":_vm.section4QData.Q7.title,"questions":_vm.section4QData.Q7.questions,"commentBox":false,"currentInput":_vm.savedFeedbackData.section4.question31
            ? _vm.savedFeedbackData.section4.question31
            : []}})],1),_c('div',{staticClass:"feedbackInput"},[_c('RadioColumn',{attrs:{"id":'marriage',"titleText":_vm.section4QData.Q8.title,"questions":_vm.section4QData.Q8.questions,"commentBox":false,"currentInput":_vm.savedFeedbackData.section4.question32
            ? _vm.savedFeedbackData.section4.question32
            : []}})],1),_c('div',{staticClass:"feedbackInput"},[_c('RadioColumn',{attrs:{"id":'religion',"titleText":_vm.section4QData.Q9.title,"questions":_vm.section4QData.Q9.questions,"commentBox":true,"commentBoxText":_vm.section4QData.Q9.commentBox,"commentBoxPreviewText":_vm.section4QData.Q9.commentBoxPreviewText,"ariaLabelText":_vm.section4QData.Q9.optionDetail,"currentInput":_vm.savedFeedbackData.section4.question33
            ? _vm.savedFeedbackData.section4.question33
            : []}})],1)],1),(_vm.isThankYouPage)?_c('div',{class:("inputSection " + (_vm.section === 4 && 'active')),attrs:{"id":"feedback-section-5"}},[_c('ThankYouMessage',{attrs:{"message":_vm.thankYouMessageData.message,"titleText":_vm.thankYouMessageData.title,"btn":true,"btnText":_vm.thankYouMessageData.btn}})],1):_vm._e(),(_vm.section < 3)?_c('button',{staticClass:"button-primary  button-blue-on-white-transparent",on:{"click":function () {
        _vm.scrollToTop();
        _vm.sectionSaveNext(_vm.section, 4);
      }}},[_vm._v(" "+_vm._s(_vm.feedbackBtn)+" "),_vm._m(0)]):_vm._e(),(_vm.section === 3)?_c('button',{staticClass:"button-primary  button-blue-on-white-transparent",on:{"click":function () {
        _vm.switchThankYouPageOn();
        _vm.scrollToTop();
        _vm.submitData();
      }}},[_vm._v(" Submit "),_vm._m(1)]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"chevron"},[_c('i',{staticClass:"fas fa-chevron-right"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"chevron"},[_c('i',{staticClass:"fas fa-chevron-right"})])}]

export { render, staticRenderFns }