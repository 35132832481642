<template>
  <div id="home-view">
    <section class="MainHeadFoot">
      <Banner :main="true" style="height: 100vh;">

        <div class="bannerIllustration">
          <BannerIllustration />
        </div>
        <b-container>

          <div class="mainBannerTitle">

             <div class="landing-page-project-logo">
            </div>

<!--            <h2>Cynllun Ffordd Canol Tref</h2>-->
            <h2>Town Centre Road Scheme</h2>
            
            <div class="lang-choice-instructions">
<!--              <p class="lang-choice-instructions">Dewiswch Iaith</p>-->
              <p class="lang-choice-instructions">Select a Language</p>
            </div>

            <div class="lang-choice-buttons">
<!--              <button class="lang-choice" @click="chooseWelsh">Cymraeg</button>-->
              <button class="lang-choice" @click="chooseEnglish">English</button>
            </div>

          </div>
        </b-container>

      </Banner>

    </section>
  </div>
</template>

<script>
import Banner from "../components/Banner";
import BannerIllustration from "../components/Project/M5J10/BannerIllustration";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "LandingPage",
  components: {
    Banner,
    BannerIllustration,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["language", "content"]),
    currentLanguage() {
      const vm = this;
      if (this.language) {
        return this.language;
      } else return "english";
    },
  },
  methods: {
    ...mapActions(["setLanguageToWelsh","setLanguageToEnglish"]),
    chooseWelsh() {
      this.setLanguageToWelsh();
      this.$router.push('/cy/home');
    },
    chooseEnglish() {
      this.setLanguageToEnglish();
      this.$router.push('/en/home');
    }
  },
};
</script>

<style lang="scss" scoped>

  div.bannerIllustration {
    z-index: 10;
  }

  div.mainBannerTitle {
    text-align: center; 
    padding: 0px;
    background-color: #f2696d !important;
    z-index: 1000;
    margin-top: 45px;
  }

@media screen and (max-width: 425px) {

  h2 {
    color: white;
    font-size: 32pt;
  }

  div.lang-choice-instructions {
    margin-top: 20px;
  }

  p.lang-choice-instructions {
    font-size: 14px;
    line-height: 4px;
  }

  button.lang-choice {
      width: 150px !important;
      height: 48px !important;
      border-radius: 8px !important;
      border: solid 2px #f2696d !important;
      color: #f2696d       !important;
      background-color: white !important;
      font-size: 14px !important;
      margin-left: 12px !important;
      margin-right: 12px !important;
      margin-top: 12px !important;
      margin-bottom: 12px !important;

      &:hover {
        background: transparent !important;
        color: white !important;
        border: solid 2px white !important;
      }
    }

}

@media screen and (min-width: 426px) {

  h2 {
    color: white;
    font-size: 30px;
  }

  div.lang-choice-instructions {
    margin-top: 20px;
  }

  p.lang-choice-instructions {
    font-size: 16px;
    line-height: 4px;
  }

  button.lang-choice {
      width: 150px !important;
      height: 48px !important;
      border-radius: 8px !important;
      border: solid 2px #f2696d !important;
      color: #f2696d       !important;
      background-color: white !important;
      font-size: 16px !important;
      margin-left: 12px !important;
      margin-right: 12px !important;
      margin-top: 12px !important;
      margin-bottom: 12px !important;

      &:hover {
        background: transparent !important;
        color: white !important;
        border: solid 2px white !important;
      }
    }

}



</style>