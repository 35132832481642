<template>
  <div class="marginBottom">
    <section class="site-timeline">
        <ul class="timeline">
                <li>
                  <span class="date">Tuesday 14 December 2021 10am - 7pm</span>
                    <div class="timeline-card">
                      <h2>Event 1: Face to face event at Cheltenham West Community Fire and Rescue Station, GL51 9SN</h2>
                      <a class="section-link" href="#popup" role="link" @click="selectElement(0)">
                        View information about the event <span class="chevron"><i class="fas fa-chevron-right"></i></span> </a>
                        <!-- <div class="sr-only">Open popup content</div> -->
                    </div>
                 
                </li>
                <li>
                    <span class="date">Wednesday 15 December 2021 7pm - 8.30pm</span>
                    <div class="timeline-card">
                      <h2>Event 2: Virtual event on MS Teams</h2>
                      <a class="section-link" href="#popup" role="link" @click="selectElement(1)">
                        View information about the event <span class="chevron"><i class="fas fa-chevron-right"></i></span> </a>
                        <!-- <div class="sr-only">Open popup content</div> -->
                    </div>
         
                </li>
                <li>
                    <span class="date">Thursday 13 January 2022 2pm - 3.30pm</span>
                    <div class="timeline-card"> 
                      <h2>Event 3: Virtual event on MS Teams</h2>
                      <a class="section-link" href="#popup" role="link" @click="selectElement(2)">
                        View information about the event <span class="chevron"><i class="fas fa-chevron-right"></i></span> </a>
                        <!-- <div class="sr-only">Open popup content</div> -->
                    </div>
                    
                </li>
                <li>
                    <span class="date">Saturday 15 January 2022 10am - 5pm</span>
                    <div class="timeline-card">
                      <h2>Event 4: Face to face event at Hesters Way Community Resource Centre</h2>
                      <a class="section-link" href="#popup" role="link" @click="selectElement(3)">
                        View information about the event <span class="chevron"><i class="fas fa-chevron-right"></i></span> </a>
                        <!-- <div class="sr-only">Open popup content</div> -->
                    </div>       
                </li>
                <li>

                   <span class="date">Saturday 29 January 2022 2pm - 3.30pm</span>
                    <div class="timeline-card">
                       <h2>Event 5: Virtual event on MS Teams</h2>
                      <a class="section-link" href="#popup" role="link" @click="selectElement(4)">
                        View information about the event <span class="chevron"><i class="fas fa-chevron-right"></i></span> </a>
                        <!-- <div class="sr-only">Open popup content</div> -->
                    </div>
                </li>
                <li>
                    <span class="date">Wednesday 2 February 2022 7pm - 8.30pm</span>
                    <div class="timeline-card">
                      <h2>Event 6: Virtual event on MS Teams</h2>
                      <a class="section-link" href="#popup" role="link" @click="selectElement(5)">
                        View information about the event <span class="chevron"><i class="fas fa-chevron-right"></i></span> </a>
                        <!-- <div class="sr-only">Open popup content</div> -->
                    </div>
                </li>
        </ul>

      <div id="popup" class="overlay">
        <div class="popup">

          <h2 v-if="currentSelected.title">{{currentSelected.title}}</h2>
          <a class="closeButton" href="#/en/events" @click="enableScrolling()">
            <img src="@/assets/images/closePopupButton.png" alt="close popup button"/>
          </a>
          <div class="content">

            <h5 v-if="currentSelected.date">Date</h5>
            <p v-if="currentSelected.date">{{currentSelected.date}}</p>

            <br v-if="currentSelected.date">

            <h5 v-if="currentSelected.time">Time</h5>
            <p v-if="currentSelected.time"> {{currentSelected.time}}</p>


            <br v-if="currentSelected.time">

            <h5 v-if="currentSelected.eventLink">Link</h5>
            <div v-if="currentSelected.eventLink"> {{currentSelected.eventLink}}
               <p
                  v-for="(paragraph, index) in currentSelected.eventLink"
                  :key="`${$route.name}_paragraphs_${index}`"
                  v-html="paragraph"
              ></p>
            </div>

            <br v-if="currentSelected.eventLink">

            <h5 v-if="currentSelected.venue">Venue</h5>
            <p v-if="currentSelected.venue">{{currentSelected.venue}}</p>

            <br v-if="currentSelected.venue">

            <h5 v-if="currentSelected.platform">Platform</h5>
            <p v-if="currentSelected.platform">{{currentSelected.platform}}</p>

            <br v-if="currentSelected.platform">

            <h5 v-if="currentSelected.accessibility">Accessibility</h5>
            <div v-if="currentSelected.accessibility">
              <p
                  v-for="(paragraph, index) in currentSelected.accessibility"
                  :key="`${$route.name}_paragraphs_${index}`"
                  v-html="paragraph"
              ></p>
              </div>

            <br v-if="currentSelected.accessibility">

            <h5 v-if="currentSelected.gettingThere">How to get there</h5>
            <div v-if="currentSelected.gettingThere">
              <p
                  v-for="(paragraph, index) in currentSelected.gettingThere"
                  :key="`${$route.name}_paragraphs_${index}`"
                  v-html="paragraph"
              ></p>
            </div>

            <br v-if="currentSelected.gettingThere">

            <h5 v-if="currentSelected.covid">Covid-19 Details</h5>
            <div v-if="currentSelected.covid">
              <p
                  v-for="(paragraph, index) in currentSelected.covid"
                  :key="`${$route.name}_paragraphs_${index}`"
                  v-html="paragraph"
              ></p>
            </div>

            <br v-if="currentSelected.covid">

            <h5 v-if="currentSelected.timeline">Timeline/agenda</h5>
            <div v-if="currentSelected.timeline">
              <p
                  v-for="(paragraph, index) in currentSelected.timeline"
                  :key="`${$route.name}_paragraphs_${index}`"
                  v-html="paragraph"
              ></p>
            </div>

            <br v-if="currentSelected.timeline">

            <h5 v-if="currentSelected.submitQuestions">Submit questions</h5>
            <div v-if="currentSelected.submitQuestions">
              <p
                  v-for="(paragraph, index) in currentSelected.submitQuestions"
                  :key="`${$route.name}_paragraphs_${index}`"
                  v-html="paragraph"
              ></p>
            </div>

            <br v-if="currentSelected.submitQuestions">

            <h5 v-if="currentSelected.joinHere">Click here to register</h5>
            <p v-if="currentSelected.joinHere">{{currentSelected.joinHere}}</p>

              <h5 v-if="currentSelected.postTextHere">Event recording</h5>
               <p v-if="currentSelected.postTextHere">{{currentSelected.postTextHere}}</p>

            <br v-if="currentSelected.joinHere">

            <br v-if="currentSelected.postTextHere">

            <div v-if="currentSelected.hasButton">
              <div class="button" @click="openLink(currentSelected.buttonLink)">
                Go to the Eventbrite registration <i class="fas fa-chevron-right"></i></div>
            </div>

            <div v-if="currentSelected.hasVideoButton">
              <div class="button" @click="openLink(currentSelected.buttonLink)">View event recording <i class="fas fa-chevron-right"></i></div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: "EventsPageTimeline",
  props: {
    data: Array,

  },
  components: {
  },
  data() {
    return {
      currentSelected: Object
    };
  },
  computed: {
  },
  methods:{
    disableScrolling() {
      var x = window.scrollX;
      var y = window.scrollY;
      window.onscroll = function () {
        window.scrollTo(x, y);
      };
    },
    enableScrolling() {
      window.onscroll = function () {};
    },
    openLink(buttonLink){
      window.open(buttonLink,'_blank');
    },
    selectElement(id)
    {
      this.disableScrolling();
      this.currentSelected = this.data.events[id];
    },
  },
};
</script>

<style lang="scss" scoped>

@import "../Variables";


$lightGreen:#a2c748;
$fontHeading:'Helvetica Neue,Arial,sans-serif';
$fontBody:'Helvetica Neue,Arial,sans-serif';

.site-timeline {
    
    padding: 2rem 0;

  .marginLeftEvents{
    margin-left: 9%;
  }

  .marginRightEvets{
    margin-right: 9%;
  }

  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
    z-index: 10000;
  }
  .overlay:target {
    visibility: visible;
    opacity: 1;
  }



  .popup {
    margin-right: auto;
    margin-top: 5%;
    margin-left: auto;
    padding: 2em;
    background: #fff;
    width: 90%;
    position: relative;
    z-index: 1000;
    overflow-x: hidden;
    max-height: 85%;

    @media screen and (min-width: 1025px) {
      width: 50%;
    }
  }


  .popupContent{
    margin-top: 2%;
  }

  .popup h2 {
    margin-top: 3%;
    margin-bottom: 4%;
    color: $Gcc-Blue;
  }

  .popup h5{
    font-size: 16px;
    font-weight: 700;
    color: #333333;
  }

  .popup p{
    font-size: 16px;
    font-weight: 400;
    color: #333333;
  }

  .popup .closeButton {
    position: absolute;
    top: 24px;
    right: 27px;
    transition: all 200ms;
  }
  
  .popup .closeButton img {
    position: fixed;
    margin-left:-20px;
    transition: all 200ms;
  }

  .popup .content {
    max-height: 30%;
    overflow: auto;
  }

  @media screen and (max-width: 700px){
    .box{
      width: 100%;
    }
  }

  .button {
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    color: #ffffff;
    font-size: 15px;
    font-weight: 700;
    background: $Gcc-Blue;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
    width: 100%;
    text-align: center;
    cursor: pointer;

    @media screen and (min-width: 768px) {
      width: 425px;
      font-size: 18px;
    }
  }

  .button:hover {
    background: $lightGreen;
    text-decoration: none;
    color: #000000;
    font-size: 18px;
    font-weight: 700;
  }

  h2 {
        font-size: 28px;
        font-weight: 700;
        color: #0055a5;
        line-height: 1.3;
        font-family: $fontHeading;
        margin-top: 0;
        font-size: 38px;
    }
    p {
        color: rgba(0, 0, 0, 0.87);
        margin: 0 auto;
        font-size: 16px;
        line-height: 1.4446;
        font-family: "Helvetica Neue", Arial, sans-serif;
    }

  .icon{
    font-size: 28px;
  }

  .section-link {
    display: inline-block;
    position: relative;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    transform-origin: 50% 50%;
    transform: scale(1);
    color: #000000;

    &:hover {
      cursor: pointer;

      &:after {
        transform: scaleX(0);
      }
    }

    &:before {
      display: inline-block;
      content: '\2192';
      opacity: 0;
      position: absolute;
      top: 2px;
      right: -15px;
      font-size: 16px;
      line-height: 14px;
      border-radius: 50%;
      transition-delay: 0.2s;
      transform: translateX(0);
    }

    &:after {
      background: #333333;
      content: '';
      display: block;
      height: 2px;
      margin-top: 0px;
      width: 100%;
      z-index: 100;
      transform-origin: 100% 0;
      transform: scaleX(1);
    }
  }

  .section-link-white {
    display: inline-block;
    position: relative;
    margin-top: 15px;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    transform-origin: 50% 50%;
    transform: scale(1);
    color: #FFFFFF;

    &:hover {
      cursor: pointer;

      &:after {
        transform: scaleX(0);
      }
    }

    &:before {
      display: inline-block;
      content: '\2192';
      opacity: 0;
      position: absolute;
      top: 2px;
      right: -15px;
      font-size: 16px;
      line-height: 14px;
      border-radius: 50%;
      transition-delay: 0.2s;
      transform: translateX(0);
    }

    &:after {
      background: #ffffff;
      content: '';
      display: block;
      height: 2px;
      margin-top: 0px;
      width: 100%;
      z-index: 100;
      transform-origin: 100% 0;
      transform: scaleX(1);
    }
  }

    ul.timeline {
        margin: 0 auto;
        list-style: none;
        padding: 0;
        width: 100%;
        position: relative;

        h2 {
            font-size: 24px;
            font-weight: 700;
            color: #0054A4;
            line-height: 1.3;
            font-family: $fontBody;
        }
        &:before {
            content: "";
            display: block;
            width: 3px;
            height: 100%;
            background: #0054A4;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        li {
            list-style: none;
            width: 50%;
            margin: 2rem 0;
            position: relative;
            padding-right: 2rem;
            &:nth-child(even) {
                margin-left: 50%;
                padding-right: 0;
                padding-left: 2rem;
            }

            .timeline-card {
                border: 2px solid #0054A4;
                padding: 20px;
                position: relative;
                h2 {
                    margin: 0 auto;
                    font-size: 16px;
                }
                p {
                    color: #000000;
                    font-size: 16px;
                    margin: 0.5rem auto 0;
                }
            }
            .date {
                position: absolute;
                font-weight: 700;
                top: 50%;
                display: block;
                transform: translateY(-50%);


            }
            &:nth-child(odd) {
                .timeline-card {
                    background: $lightGreen;
                    color: #fff;
                    border-color: $lightGreen;
                    border-radius: 25px;
                    padding: 20px 30px;
                    h2 { color: #000000; }
                    p { color: #fff; }
                }
                .timeline-card:before {
                    content: "";
                    border: solid $lightGreen;
                    border-width: 0 2px 2px 0;
                    background: $lightGreen;
                    display: inline-block;
                    padding: 7px;
                    transform-origin: center;
                    transform: rotate(-45deg) translateY(-50%);
                    position: absolute;
                    top: 50%;
                    right: -15px;
                }
                .date {
                    left: 100%;
                    display: block;
                    width: 100%;
                    padding-left: 2rem;
                    text-align: left;
                }
                &:after {
                    content: "";
                    display: block;
                    width: 20px;
                    height: 20px;
                    border: 0;
                    border-radius: 50%;
                    background:#0054A4;
                    right: -10px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    background: #fff;
                    border: 3px solid #0054A4;;
                }
            }
            &:nth-child(even) {
                .timeline-card {
                    /*border: solid #a2c748;
                    background: #a2c748;
                    //background: #343958;
                    color: #fff;
                    //border-color: #343958;
                    border-radius: 25px;
                    padding: 20px 30px;
                    h2 { color: #000000; }
                    p { color: #fff; }
                   //h2 { color: #fff; }
                   //p { color: #fff; }*/


                  background: $lightGreen;
                  color: #fff;
                  border-color: $lightGreen;
                  border-radius: 25px;
                  padding: 20px 30px;
                  h2 { color: #000000; }
                  p { color: #fff; }
                }
                .timeline-card:before {
                    content: "";
                    border: solid #a2c748;
                    background: #a2c748;
                    //border: solid #343958;
                    border-width: 0 2px 2px 0;
                    //background: #343958;
                    display: inline-block;
                    padding: 7px;
                    transform-origin: center;
                    transform: rotate(-45deg) translateY(-50%) scale(-1);
                    position: absolute;
                    top: 50%;
                    left: -4px;
                }
                .date {
                    left: -100%;
                    display: block;
                    width: 100%;
                    padding-right: 2rem;
                    text-align: right;
                }
                &:after {
                    content: "";
                    display: block;
                    width: 20px;
                    height: 20px;
                    border: 0;
                    border-radius: 50%;
                    background:#0054A4;
                    left: -10px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    background: #fff;
                    border: 3px solid #0054A4;
                }
            }
        }

        @media screen and (max-width: 767px) {
            &:before {
                content: "";
                display: block;
                width: 3px;
                height: 100%;
                background: #0054A4;
                position: absolute;
                left: 0%;
                transform: translateX(0%);
            }
            li {
                width: 100%;
                margin: 2rem 0;
                position: relative;
                padding-left: 2rem;
                padding-right: 0;
                padding-top: 2rem;
                &:nth-child(even) {
                    margin-left: 0%;
                    padding-right: 0;
                    padding-left: 2rem;
                }
                &:nth-child(even) {
                    .timeline-card:before {
                        top: calc(50% - 1rem);
                    }
                    .date {
                        left: 0%;
                        top: 1rem;
                        display: block;
                        width: 100%;
                        padding: 0;
                        padding-left: 2rem;
                        text-align: left;
                    }
                }
                &:nth-child(odd) {
                    .timeline-card:before {
                        content: "";
                        border: solid #a2c748;
                        background: #a2c748;
                        border-width: 0 2px 2px 0;
                        display: inline-block;
                        padding: 7px;
                        transform-origin: center;
                        transform: rotate(-45deg) translateY(-50%) scale(-1);
                        position: absolute;
                        top: calc(50% - 1rem);
                        left: -4px;
                        right: auto;
                    }
                    .date {
                        left: 0%;
                        top: 1rem;
                        display: block;
                        width: 100%;
                        padding: 0;
                        padding-left: 2rem;
                        text-align: left;
                    }
                    &:after {
                        content: "";
                        display: block;
                        width: 20px;
                        height: 20px;
                        border: 0;
                        border-radius: 50%;
                        background:#0054A4;
                        left: -10px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        background: #fff;
                        border: 3px solid #0054A4;;
                    }
                }
                
            }
            
        }
        
    }
}


    
</style>