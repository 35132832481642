<template>
  <div class="checkbox-table-comp">
    <div class="CT-titleText">
      <p>{{ titleText }}</p>
      <li
          v-for="(item, index) in list"
          :key="`${$route.name}_checkTable_text_${index}`"
      >
        {{ item }}
      </li>
    </div>

    <!-- Desktop view -->
    <div class="CT-Table">

      <thead>
      <tr>
        <td v-if="areQuestions"></td>
        <th
            v-for="(answer, index) in answers"
            :key="`${$route.name}_checkTableQ-${id}_${index}`"
        >
          <b>{{ answer }}</b>
        </th>
      </tr>
      </thead>

      <tbody v-if="areQuestions">
      <tr
          v-for="(question, index) in questions"
          :key="`${$route.name}_checkTableA-${id}_${index}`"
      >
        <td class="question">{{ question }}</td>
        <td
            v-for="(check, j) in answers"
            :key="`${$route.name}_checkTableA-${id}_${index}_check${j}`"
        >
          <label class="tickLabel"><span class="sr-only">{{check}}</span>
            <input
                   type="checkbox"
                   :value="check"
                   v-model="feedbackData[index][j]"
                   :aria-label="check"
                   
            /> <span class="checkmark"></span>
          </label>
        </td>
      </tr>
      </tbody>

      <tbody v-else>
      <tr
          v-for="(question, index) in questions"
          :key="`${$route.name}_checkTableA-${id}_${index}`"
      >
        <td
            v-for="(check, j) in answers"
            :key="`${$route.name}_checkTableA-${id}_${index}_check${j}`"
        >
          <label class="tickLabel"><span class="sr-only">{{check}}</span>
            <input
                type="checkbox"
                :value="check"
                v-model="feedbackData[index][j]"
            /> <span class="checkmark"></span>
          </label>
        </td>
      </tr>
      </tbody>

    </div>

    <!-- Tablet/mobile view -->
    <div class="CT-column">
      <div
          v-for="(question, index) in questions"
          :key="`${$route.name}_checkTableCol-${id}_${index}`"
          class="CT-checkCol"
      >
        <p class="questionMobile">{{ question }}</p>
        <div
            v-for="(answer, j) in answers"
            :key="`${$route.name}_checkTableColA-${id}_${j}`"
        >
          <div class="check-input">
            <label class="tickLabel"><span class="sr-only">{{answer}}</span>
                <input type="checkbox"
                 v-model="feedbackData[index][j]"/>
                <span class="checkmark"></span>
                <p class="answerMobile">{{ answer }}</p>
          </label>
          </div>
        </div>
      </div>
    </div>

    <!-- Comment box -->
    <div v-if="commentBox" class="RT-commentbox">
      <p>{{ commentBoxText }}</p>
      <textarea :placeholder="commentBoxPreviewText" v-model="tableAnswers[questions.length]" :aria-label="commentBoxText">
      </textarea>
    </div>

  </div>
</template>

<script>
export default {
  name: "checkboxButtonTable",
  props: {
    id: {
      type: String,
      required: true,
    },
    titleText: {
      type: String,
      default: "Radio button table",
    },
    list: {
      type: Array,
      default() {
        return ["List paragraph for Radio Table"];
      },
    },
    answers: {
      type: Array,
      default() {
        return ["answer1", "answer2", "answer3", "answer4", "answer5"];
      },
    },
    areQuestions:{
      type: Boolean,
      default: true,
    },
    questions: {
      type: Array,
      default() {
        return ["question1", "question2", "question3"];
      },
    },
    commentBox: {
      type: Boolean,
      default: true,
    },
    commentBoxText: {
      type: String,
      default: "Comment box header here",
    },
    commentBoxPreviewText: {
      type: String,
      default: "Type your answer here",
    },
    currentInput: {
      type: Array,
      default() {
        let arr = [];
        this.arr.forEach((q) => {
          this.feedbackData.push([]);
        });
      },
    },
  },
  beforeMount() {
    this.feedbackData = this.currentInput;
    this.tableAnswers = this.currentInput;
  },
  data() {
    return {
      feedbackData: [],
      tableAnswers: [],
    };
  },
};
</script>

<style lang="scss">
@import '/src/Variables';

.checkbox-table-comp {

  .CT-column {
    display: none;

    .CT-checkCol {
      margin-bottom: 30px;
    }

    p{
      font-weight: 700;
    }


    .radio-input {

      //min-width: auto;

      span {
        margin-left: 20px;
        margin-right: 5px;
        margin-top: 3px;
      }

      p {
        font-weight: 400;
        margin-left: 10px;
      }
    }

    @media screen and (max-width: 1024px) {
      display: block;
    }

    .check-input {

      //min-width: auto;

      span {
       // margin-left: 20px;
        margin-right: 5px;
        margin-top: 3px;
      }

      p {
        font-weight: 400;
        margin-left: 10px;
      }


    }
  }


  .CT-Table {
    @media screen and (max-width: 1024px) {
      display: none;
    }

    .table {
      padding: 0px;
    }

    tbody {

      td {

        text-align: center;
        border-top: $Gcc-Blue solid 1px !important;
        border-right: $Gcc-Blue solid 1px !important;

        &:first-child {
          border-left: none !important;
        }

        &:last-child {
          border-right: none !important;
        }
      }

      tr {

        height: 5em;

        td {
          vertical-align: middle;

        }

        .question {
          min-width: 267px;
          text-align: left;
          padding-left: 3px;
        }

        &:first-child {
          vertical-align: middle;

          td {

            border-top: none !important;
          }
        }

        &:nth-child(odd) {
          background: rgba($Gcc-Blue, 0.1);
        }
      }
    }

    thead
    {

      th {
        font-weight: normal;
      }
      td, th {
        width: 180px;
        text-align: center;
        border-top: none;
      }

      tr {
        background: transparent !important;
      }
    }
  }

  .CT-titleText {
    margin-bottom: 2%;

    p {
      color: #000000;
      font-weight: bold;
    }

    li {
      list-style-image: none;
      list-style-type: none;


    }
  }

  .RT-commentbox {
    padding-top: 2%;

    textarea {
      width: 52%;
      border: #58595B solid 1.6px;
      border-radius: 8px;
      outline: none;
      padding: 10px;
      margin-top: 4px;
      background-color:transparent;

      @media screen and (max-width: 992px) {
        width: 90%;
        padding: 0;
      }

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(#000000, 0.5);
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba($Gcc-Blue, 0.5);
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba($Gcc-Blue, 0.5);
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: rgba($Gcc-Blue, 0.5);
      }
    }
  }

  /* The container */
  .tickLabel {
    position: relative;
    padding-left: 19px;
    padding-bottom: 4px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
     
  }

  /* Hide the browser's default checkbox */
  .tickLabel input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:focus {
       ~ .checkmark {
        outline: 3px solid #0054A4;
      }
    }
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border: #58595B solid 1.5px;
    border-radius: 3px;
  }

  /* On mouse-over, add a grey background color */
  .tickLabel:hover input ~ .checkmark {
    background-color: #0054A4;
  }

  /* When the checkbox is checked, add a blue background */
  .tickLabel input:checked ~ .checkmark {
    background-color: #0054A4;
    border: #0054A4 solid 1.5px;
    border-radius: 3px;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .tickLabel input:checked ~ .checkmark:after {
    display: block;

  }

  /* Style the checkmark/indicator */
  .tickLabel .checkmark:after {
    left: 25%;
    right: 25%;
    bottom: 25%;
    width: 50%;
    height: 75%;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
</style>