<template>
  <div>
    <div class="admin-login" v-if="!isLoggedIn">
      <b-container>
        <p>Please enter Admin password</p>
        <div>
          Password
          <input type="password" v-model="password" />
          <button @click="loginFunction">Login</button>
        </div>
      </b-container>
    </div>
    <div class="admin-portal" v-if="isLoggedIn">
      <b-container>
        <p>
          Use the functionality to collect and review data from the M25J10
          Feedback Survey
        </p>
        <pre class="data-preview">{{ this.data }}</pre>
        <b-row>
          <b-col cols="3">
            <button @click="getAllFeedbackData">Get Feedback Data</button>
          </b-col>
          <b-col cols="3" v-if="data !== ''">
            <button @click="copyDataToClipboard">Copy Json data</button>
          </b-col>
          <b-col cols="3" v-if="data !== ''">
            <button @click="downloadJSON">Download Json data</button>
          </b-col>
          <b-col cols="3" v-if="data !== ''">
            <button @click="downloadCSV">Download CSV data</button>
          </b-col>
          <b-col cols="3">
            <button @click="logoutFunction">Logout</button>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import api from "../services/index";

export default {
  name: "Admin",
  data() {
    return {
      isLoggedIn: false,
      password: "",
      data: [],
    };
  },
  methods: {
    loginFunction() {
      api.getPasswordData().then((data) => (this.password = data));

      if (this.password === this.password) {
        this.isLoggedIn = true;
        sessionStorage.setItem("authenticatedUser", true);
      }
    },
    logoutFunction() {
      this.isLoggedIn = false;
      this.password = "";
    },
    getAllFeedbackData() {
      api.getFeedbackData().then((data) => {
        this.data = data ;
      })
    },
    downloadJSON() {
      var dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(this.data));
      var downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute("download", "feedback" + ".json");
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },
    generateCSV() {
      const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
      const header = Object.keys(this.data[0]);


      let csv = [
        header.join(","), // header row first
        ...this.data.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(",")
        ),
      ].join("\r\n");


      csv = csv.replaceAll('\\"',"'");
      return csv;
    },
    downloadCSV() {
      let csv = this.generateCSV();

      var dataStr = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      var downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute("download", "feedback" + ".csv");
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },
    copyDataToClipboard() {
      navigator.clipboard.writeText(JSON.stringify(this.data));
      alert("JSON Data copied to clipboard");
    },
  },
  computed: {
    authStatus() {
      if (sessionStorage.getItem("authenticatedUser")) {
        return sessionStorage.getItem("authenticatedUser");
      } else return false;
    },
  },
};
</script>

<style lang="scss">
.admin-login {
  padding: 40px 0;
}

.admin-portal {
  padding: 40px 0;

  .data-preview {
    height: 300px;
    width: 100%;
    padding: 10px;
    border: solid 2px black;
    overflow: auto;
  }
}
</style>