<template>
  <div class="ImageLightBox-comp">
    <VueEasyLightbox
      v-if="imageSrcList"
      escDisabled
      :moveDisabled="false"
      :imgs="imageSrcList"
      :visible="visible"
      :index="index"
      @hide="handleHide"
    />
    <div v-if="imagesData">
      <b-row
        v-for="(image, index) in imagesData"
        :key="`${$route.name}_imageLightbox_${index}`"
        class="imageFullRow"
      >
        <b-col cols="12" lg="6">
          <div class="ILB-Image" v-if="index % 2 === 0">
            <img :src="image.image" />
          </div>
          <div class="ILB-text" v-else>
            <h2 v-if="image.title">{{ image.title }}</h2>
            <p v-if="image.text">
              {{ image.text }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" lg="6">
          <div class="ILB-Image" v-if="index % 2 !== 0">
            <img :src="image.image" @click="() => openLightBox(index)" />
          </div>

          <div class="ILB-text" v-else>
            <h2>{{ image.title }}</h2>
            <p>
              {{ image.text }}
            </p>
          </div>
        </b-col>
      </b-row>

      <b-row
        v-for="(image, index) in imagesData"
        :key="`${$route.name}_imageLightbox_${index}`"
        class="imageSmallRow"
      >
        <b-col cols="12">
          <div class="ILB-text">
            <h2>{{ image.title }}</h2>
            <p>
              {{ image.text }}
            </p>
          </div>
        </b-col>

        <b-col cols="12">
          <div class="ILB-Image">
            <img :src="image.image" @click="() => openLightBox(index)" />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  name: "ImageLightBox",
  components: { VueEasyLightbox },
  props: {
    imagesData: {
      type: Array,
    },
  },
  data() {
    return {
      visible: false,
      index: 0,
    };
  },
  methods: {
    disableScrolling() {
      var x = window.scrollX;
      var y = window.scrollY;
      window.onscroll = function () {
        window.scrollTo(x, y);
      };
    },
    enableScrolling() {
      window.onscroll = function () {};
    },
    openLightBox(_index) {
      this.disableScrolling();
      this.index = _index;
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.enableScrolling();
      this.visible = false;
    },
  },
  computed: {
    imageSrcList() {
      const vm = this;
      if (this.imagesData) {
        let srcArr = [];
        this.imagesData.forEach((image) => {
          srcArr.push(image.image);
        });
        return srcArr;
      } else return [];
    },
  },
};
</script>

<style lang="scss">
.ImageLightBox-comp {
  margin: 0 auto;
  max-width: 1920px;

  .imageFullRow {
    padding: 60px 0;

    .ILB-Image {
      position: relative;
      img {
        width: 100%;
        height: 455px;
        object-fit: cover;
        object-position: center;
      }

      .expandBtn {
        position: absolute;
        bottom: 32px;
        width: 66px;
        height: 66px;
        border-radius: 50%;
        border: none;
        color: white;
        font-size: 28px;
        padding: 0;

        &.even {
          right: 32px;
        }

        &.odd {
          left: 32px;
        }
      }
    }
    .ILB-text {
      position: relative;
      top: 50%;
      transform: translateY(-55%);
      padding: 125px;
    }

    @media screen and(max-width: 992px) {
      display: none;
    }
  }
  .imageSmallRow {
    padding: 60px 0;

    @media screen and (max-width: 500px) {
      padding: 30px 0;
    }

    .ILB-Image {
      img {
        width: 100%;
        height: 455px;
        object-fit: cover;
        object-position: center;
        cursor: pointer;

        @media screen and (max-width: 500px) {
          height: 200px;
        }
      }

      .expandBtn {
        position: absolute;
        bottom: 32px;
        width: 66px;
        height: 66px;
        border-radius: 50%;
        border: none;
        color: white;
        font-size: 28px;
        right: 32px;
        padding: 0;
      }
    }

    .ILB-text {
      position: relative;
      top: 50%;
      transform: translateY(-55%);
      padding: 60px;

      @media screen and (max-width: 500px) {
        padding: 30px;
      }
    }

    .col-12 {
      padding: 0;
    }

    @media screen and (min-width: 992px) {
      display: none;
    }
  }
}
</style>