<template>
  <div class="site-view" >
      <form class="contact-form homePageForm" @submit.prevent="submitForm">
        <div class="form-element">
          <label>Your name</label>
        <input
          label="Your name"
          v-model="fields.name"
          placeholder="Enter your name"
          required
          aria-label="Enter your name"
          tabindex="1"
        />
        </div>
        <div class="form-element">
          <label>Email</label>
        <input
          type="email"
          label="Your email"
          v-model="fields.email"
          placeholder="Enter your email here"
          required
          aria-label="Enter your email here"
          tabindex="2"
        />
        </div>
        <div class="form-element">
          <label>Phone number</label>
          <input
          label="Enter your phone number"
          v-model="fields.telephone"
          placeholder="Enter your phone number here"
          required
              aria-label="Enter your phone number here"
              tabindex="3"
        />
        </div>
        <div class="form-element">
          <label>Address</label>
        <textarea 
          label="Address"
          v-model="fields.address"
          placeholder="Enter your address here"
          required class="materialize-textarea"
          aria-label="Enter your address here"
          tabindex="4"
        ></textarea>
        </div>
        <div class="form-element">
          <label>Post code</label>
        <input
        aria-label="Enter your post code here"
          label="Postcode"
          v-model="fields.postcode"
          placeholder="Enter your post code here"
          required
          tabindex="5"
        />
        </div>

        <div>
          <label>Message</label>
          <textarea 
          aria-label="Enter your message here"
            label="Message"
            rows="10"
            cols="100"
            v-model="fields.message"
            placeholder="Enter your message here"
            required
            tabindex="6"
          />
        </div>
        <button class="button-primary">Send message<span class="chevron"><i class="fas fa-chevron-right"></i></span></button>
      </form>
  </div>
</template>

<script>
//import Apicall from "@/services/index";
import {  mapGetters } from "vuex";
import emailjs, { init } from "emailjs-com";
import Swal from 'sweetalert2'

import _ from "lodash";

export default {
  name: "homePageForm",
  components: {
  
  },
  data() {
    return {
      fields: {
        name: "",
        email: "",
        address: "",
        postcode: "",
        telephone: "",
        message: "",
        //checkedDoc: [],
      },
      
    };
  },
  computed: {

    ...mapGetters([]),

  },
 methods: {
   formatFeedback() {
        return {
            subject:'Contact Information M5 Contact Form',
            name: this.fields.name,
            email: this.fields.email,
            address: this.fields.address,
            postcode: this.fields.postcode,
            telephone: this.fields.telephone,
            message: this.fields.message,
           // documents: this.fields.checkedDoc,
        }
    },
    submitForm()  {
     emailjs.init("user_GNIxC3TA5DGfD5pXD5TH8");

      let sendReadyData = this.formatFeedback();
     
      emailjs.send("service_7znitzb","template_bub93tc",sendReadyData)
        .then(
          (result) => {
              this.fields = {
                name: "",
                email: "",
                address: "",
                postcode: "",
                telephone: "",
                message: "",
                //checkedDoc: [],
              };

            Swal.fire({
              text:  "Thank you for submitting your request.",
              confirmButtonColor: '#0054A4',
              confirmButtonText: 'OK',
              width: '500px'
            });

          },
          (error) => {alert('send email',error)
          }
        );
    },
    
  },
 
  
};
</script>
<style lang="scss" scoped>

// Contect Form Start///
.contact-form{
    color:#fff;

    label{
        color:#fff;
        &:focus {
          outline: 0;
          &:after {
            background: #ffffff;
          }
        }
    }
    .checkbox-container {
        overflow: hidden;
        position: relative;
        margin: 10px auto;
    }
    input[type="checkbox"] {
        position: absolute;
        left: -9999px;
        &:focus {
          background: #ffffff;
      }
    }
    ::-webkit-input-placeholder { /* Edge */
        color: #fff;
      }
      
      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #fff;
      }
      
      ::placeholder {
        color: #fff;
      }

}

// Checkbox Styles
[type="checkbox"] {
  // Text Label Style
  + label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-size: 1rem;
     user-select:none;
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
  }

  /* checkbox aspect */
  + label:before,
  &:not(.filled-in) + label:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    z-index: 0;
    border: 2px solid #ffffff;
    border-radius: 1px;
    margin-top: 2px;
  }

  &:not(.filled-in) + label:after {
    border: 2px solid #ffffff;
    transition: .2s;
  }

  &:not(:checked):disabled + label:before {
    border: none;
    background-color: #cccccc;
  }

}

[type="checkbox"]:checked {
  + label:before {
    width: 18px;
    height: 18px;
    border: 2px solid #0054a4;
    background: url(../../assets/images/Icons/tick.png) #ffffff 2px 3px  no-repeat;
    backface-visibility: hidden;
    background-size: 80%;

    &:focus {
      outline: 1px solid #ffffff;
    }
  }

}


//New Form styles
$white: #ffffff;
$primaryBlue: #0054a4;

.homePageForm {
  display: flex;
  flex-direction: column;

  .form-element {
    margin-bottom: 24px;
  }

  textarea {
    width: 100%;
    border: 1px solid $white;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 16px 22px;
    background: $primaryBlue;
    opacity: 0.6;
    min-height: 120px;
    color: #ffffff;

    &:focus {
      opacity: 1;
      outline-color: #ffffff;
    }
  }

  input {
    border: 1px solid $white;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 16px 22px;
    background: $primaryBlue;
    width: 100%;
    opacity: 0.6;
    color: #ffffff;
    &:focus {
      opacity: 1;
      outline-color: #ffffff;
    }
  }

  label {
    display: block;
  }

  .checkbox-container {
    margin: 0 0 -20px 0;
    &:nth-child(3) {
      margin: 0;
    }
  }

  button {
    align-self: flex-start;
    margin-top: 32px;
  }
}

    
</style>