<template>
  <div id="options-view">

    <section class="MainHeadFoot">
      <Header :navbar="headerData"/>
      <banner :data="bannerData"/>
    </section>

    <section class="site-timeline section withTop">
      <b-container class="mainContent">
            <b-row> 
              <b-col cols="12" md="12" lg="8">
                <div class="marginTop">
                  <p>
                    We will be holding face to face and virtual events throughout the consultation. This is one of the best ways to find out more about our proposals and have your say. You will also be able to speak to members of the project team, who will be happy to answer any questions you may have.
                  </p>
                  <p>
                   Our face to face events will be held in line with UK Government national guidance on COVID-19. Our events will go ahead unless national guidance on the pandemic changes. If we do need to cancel our face-to-face events, we will advertise this as widely as possible including on this website.

                  </p>
                  <p>
                    In order to join our virtual events, you will need to register in advance. Please click on the relevant virtual event below for more info. When you begin the registration process, you will be redirected to the Eventbrite site. You will then recieve an event link via email. A recording of each virtual event will also be uploaded to this website.
                  </p>
                  <p>Following each virtual event, recordings will be available for playback below. Please review the event dates to either visit us at one of our face to face events or register to attend a virtual event on this page.</p>
                  <p>
                    If you require the consultation materials in an alternative format, or have specific accessibility
                    needs in attending the events listed, please get in touch by emailing
                    <a href="mailto:M5Junction10@atkinsglobal.com">M5Junction10@atkinsglobal.com</a> or
                    by calling 01454 667900 and leaving a message.
                  </p>
                  <p>
                    Please click on an event below to find out more information.
                  </p>
                </div>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <div>
                  <EventsPageTimeline :data="this.content.EventPagePopupData"/>
                </div>
              </b-col>
            </b-row>
      </b-container>
    </section>

    <section class="footer">
      <Footer :footerNavs="footerData.navs" :copyWrite="footerData.copyWrite">
      </Footer>
    </section>

  </div>
</template>

<script>
import Banner from "../components/Banner";
import Header from "../components/Header";
import Footer from "../components/Footer";
import TitleText from "../components/TitleText";
import ImageLightBox from "../components/ImageLightBox";
import BannerIllustration from "../components/Project/M5J10/BannerIllustration";
import EventsPageTimeline from "@/components/EventsPageTimeline";

import {mapGetters, mapActions} from "vuex";

export default {
  name: "Events",
  components: {
    Banner,
    Header,
    Footer,
    TitleText,
    ImageLightBox,
    BannerIllustration,
    EventsPageTimeline
  },
  computed: {
    ...mapGetters(["language", "content"]),
    currentLanguage() {
      return "english";
    },
    headerData() {
      if (this.content && this.content.headerData) {
        return this.content.headerData;
      } else return {};
    },
    bannerData() {
      if (
          this.content &&
          this.content.EventPageData &&
          this.content.EventPageData.banner
      ) {
        return this.content.EventPageData.banner;
      } else return {};
    },
    optionsData() {
      if (
          this.content &&
          this.content.EventPageData &&
          this.content.EventPageData.eventInfo
      ) {
        return this.content.EventPageData.eventInfo;
      } else return [];
    },
    feedbackLink() {
      if (
          this.content &&
          this.content.EventPageData &&
          this.content.EventPageData.feedbackLink
      ) {
        return this.content.EventPageData.feedbackLink;
      } else return {};
    },
    footerData() {
      if (this.content && this.content.footerData) {
        return this.content.footerData;
      } else return {};
    },
  },
  methods: {
    ...mapActions(["toggleLanguage"]),
    toggleLang() {
      this.toggleLanguage();
    },
  },
};
</script>