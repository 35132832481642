<template>
  <div class="radio-column-comp">

    <!-- Title -->
    <div>
      <p>
        {{ titleText }}
      </p>
    </div>

    <!-- Options -->
    <div class="radios" >
      <b-row>
        <div
            class="radio"
            role="radiogroup"
            v-for="(question, index) in questions"
            :key="`${$route.name}_${$route.name}_radios_${id}_${index}`"
           
        >
          <div class="radio-input" :tabindex="tabindexvalueCal(index)"
               role="radio" aria-checked="false">
            <span class="sr-only">{{question.value}}</span>
            <label class="tickLabel">
              <input class="checkboxBlip"
                     type="radio"
                     v-model="radioFeedback[0]"
                     :value="question.value"
                     :aria-label="question.value"
                
              />
              <span class="checkmark"></span>
              <p> {{ question.value }}</p>
            </label>
          </div>

          <div class="radio-textarea" v-if="question.textbox">
            <textarea v-model="radioFeedback[1]" :aria-label="ariaLabelText"></textarea>
          </div>

        </div>
      </b-row>
    </div>

    <!-- Comment box -->
    <div v-if="commentBox" class="RT-commentbox">
      <p>{{ commentBoxText }}</p>
      <textarea :placeholder="commentBoxPreviewText" v-model="radioFeedback[1]" :aria-label="ariaLabelText"></textarea>
    </div>
  </div>
</template>

<script>

export default {
  name: "RadioColumn",
  props: {
    tabindexvalue:{
      type: Number,
      default: 1,
    },
    id: {
      type: String,
      required: true,
    },
    titleText: {
      type: String,
      default: "Radio Title Text",
    },
    questions: {
      type: Array,
      default() {
        return [
          {value: "Radio1 Value", textbox: false},
          {value: "Radio2 Value", textbox: true},
        ];
      },
    },
    commentBox: {
      type: Boolean,
      default: true,
    },
    ariaLabelText: {
      type: String,
      default: "",
    },
    commentBoxText: {
      type: String,
      default: "Comment box header here",
    },
    commentBoxPreviewText: {
      type: String,
      default: "Type your answer here",
    },
    currentInput: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  beforeMount() {
    this.radioFeedback = this.currentInput;
  //    var rg1 = new RadioGroup(document.getElementById('rg1'));
  // rg1.init();
  },
  data() {
    return {
      radioFeedback: ["", ""],
    };
  },
  methods:{
    tabindexvalueCal(indexval){
      if(indexval==0){
        return 0
      }else{
        return -1
      }
    }
  }
};
</script>

<style lang="scss">
@import '/src/Variables';

.radio-column-comp {

  margin-right: 47%;

  .checkboxBlip{
    position: relative;
  }

  p {
    color: #000000;
    font-weight: bold;
  }

  .RT-commentbox {
    padding-top: 2%;

    p{
      font-weight: 400;
    }

    textarea {
      width: 52%;
      border: #58595B solid 1.6px;
      border-radius: 8px;
      outline: none;
      padding: 10px;
      margin-top: 4px;
      background-color: transparent;
      box-sizing: border-box;

      @media screen and (max-width: 992px) {
        width: 90%;
        padding: 0;
      }

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(#000000, 0.5);
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba($Gcc-Blue, 0.5);
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba($Gcc-Blue, 0.5);
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        color: rgba($Gcc-Blue, 0.5);
      }
    }
  }

  .radios {
    .radio {
      margin: 10px 20px 10px 5px;
      //margin: 2px 0px 2px 5px;

      .radio-input {

        //min-width: auto;

        span {
          margin-left: 20px;
          margin-right: 5px;
          margin-top: 3px;
        }

        p {
          font-weight: 400;
          margin-left: 10px;
        }
      }
    }

    /* The container */
    .tickLabel {
      display: block;
      position: relative;
      padding-left: 35px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

    }

    /* Hide the browser's default checkbox */
    .tickLabel input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 16px;
      width: 16px;
      background-color: transparent;
      border: #0054A4 solid 1.5px;
      border-radius: 20px;
    }

    /* On mouse-over, add a grey background color */
    .tickLabel:hover input ~ .checkmark {
      background-color: #0054A4;
      border: #0054A4 solid 1.5px;
      border-radius: 20px;
    }

    /* When the checkbox is checked, add a blue background */
    .tickLabel input:checked ~ .checkmark {
      background-color: #0054A4;
      border: #0054A4 solid 1.5px;
      border-radius: 20px;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .tickLabel input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .tickLabel .checkmark:after {
     // width: 75%;
      //height: 75%;
      border-radius: 100%;
      background: white;
      /* Center vertically and horizontally */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

</style>