<template>
  <div class="page-banner">
      <div class="desktop">
        <img :src="data.img" :alt="data.ImageAltText"/>
      </div>
       <div v-if="data.imgMobile" class="mobile">
            <img :src="data.imgMobile" :alt="data.ImageAltText"/>
       </div>
  </div>
</template>

<script>
import TitleTextFullWidth from "../components/TitleTextFullWidth";

export default {
  name: "HeroPageBanner",
  components: {
    TitleTextFullWidth
  },
  props: {
    main: {
      type: Boolean,
      default: false,
    },
    data: Object,
    Background: String,
  },
  methods:
  {
    GoTo(link)
    {
      this.$router.push(link);
    }
  },
};
</script>

<style lang="scss">
@import "../Variables";
.page-banner {

  .desktop {
      display: none;
  } 

  .mobile {
    display: block;
    img {
      max-width: 100%;
      position: relative;
      left: -20px;
    }
  } 
 
 
 @media screen and (min-width: 1024px) {
    .desktop {
      display: block;
      position: relative;

      img {
        width: 100%;
      }
    } 
     .mobile {
      display: none;
    } 
  }
  
}
</style>