<template>
  <div>
   <div class="Portal" aria-hidden="true">
      <div class="mapsContainer">
        <div class="BackgroundImg" :style="BackgroundImageToLoad" :alt="AltTextToLoad">


<!--          <img src="@/assets/images/Shading.png" style="height: 20%; width: 100%; position: absolute" alt="decorative gradient shading image"/>-->

          <div class="gradient" style="height: 20%; width: 100%; position: absolute">
          </div>

          <div class="mapsHeader">

              <b-row class="contentHeader">

                <b-col lg="1" sm="1">

                  <button class="button-primary button-transparent" @click="$router.go(-1)">
                    <span class="chevron"><i class="fas fa-chevron-left"></i></span> back
                  </button>
                </b-col>

                <b-col lg="11" sm="11">

                  <h1>{{PageData.Title}}</h1>
                </b-col>
              </b-row>

              <b-row class="contentHeader">
                <b-col lg="9" md="6" sm="8">
                </b-col>

                <b-col lg="3" md="6" sm="4">
                  <div class ="overviewAcc">
                  <Accordion class="accordions-dropdown" :item="PageData.DescriptionBox"/>
                  </div>
                </b-col>
              </b-row>
            </div>

            <ContentBlips v-if="isDesktop" @openModal="openLargeBoxModal" :is-desktop="isDesktop"/>
            <ContentBlipsMobile v-else @openModal="openLargeBoxModal" :is-desktop="isDesktop"/>

            <div class="mapsFooter">
              <b-row class="contentFooter">
                <b-col lg="6" sm="6">
                  <img src="../../assets/images/key.png" alt="information filter legend key: environment, non-driving users & road improvements"/>
                </b-col>

                <b-col lg="6" sm="6">
                </b-col>
              </b-row>

            </div>
      </div>
      </div>

  </div>

    <RotatePhone/>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ContentBlips from "../../components/PortalComponents/ContentBlips";
import ContentBlipsMobile from "../../components/PortalComponents/ContentBlipsMobile";
import PortalModal from "../../components/PortalComponents/PortalModal";
import Accordion from "../../components/AccordionCard";
import RotatePhone from "../../components/PortalComponents/RotatePhone";

export default {
  name: "PortalContentPage",
  components: {
    ContentBlips,
    ContentBlipsMobile,
    PortalModal,

    Accordion,
    RotatePhone
  },
  props:
      {
        isDesktop: {
          type: Boolean,
        },
      },
  data() {
    return {
      isModalVisible: false,
      selectedModalData: Object
    };
  },
  mounted() {
    const queryDesktop = window.matchMedia("(min-width: 1025px)");

    if (queryDesktop.matches)
    {
      return this.isDesktop = true;
    }
    else
    {
      return this.isDesktop = false;
    }

  },
  computed: {
    ...mapGetters(["content"]),
    AltTextToLoad() {
      return this.PageData.ImageAltText
    },
    BackgroundImageToLoad() {
      const queryDesktop = window.matchMedia("(min-width: 1025px)");

      if (queryDesktop.matches) {
        return 'background-image: url(' + this.PageData.BackgroundImg + ')'
      } else {
        return 'background-image: url(' + this.PageData.BackgroundImgTablet + ')'
      }
    },
    PageData() {
      const vm = this;
      if (
          this.content &&
          this.content.PortalData &&
          this.content.PortalData.PageContent
      ) {
        return this.content.PortalData.PageContent.find(el => {
          if (el.Slug) {
            return el.Slug === vm.$route.params.Slug
          }
        })
      } else return {};
    },
  },
  methods:
      {
        openLargeBoxModal(item) {

          this.selectedModalData = item;
          this.isModalVisible = true;
        },
        closeModal() {
          this.isModalVisible = false;
          this.selectedModalData = null;
        }
      }
}
</script>

<style lang="scss">
@import '../../variables';

	*{
		padding: 0px;
		margin:0px;
		box-sizing: border-box;
	}

.BackgroundImg {
  background-repeat: no-repeat;
}

.mapsFooter {
  position: fixed;
  width: 30vw;
  bottom: 30px;
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
  }
  @media screen and (min-width: 1921px) {
      top: 910px;
      bottom: 0;
    }

  @media screen and (min-width: 1025px) {

    .contentFooter {
      display: block;
      padding-left: 4vw;
      padding-right: 4vw;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1025px){

    .contentFooter {
      display: block;
      padding-left: 4vw;
      padding-right: 4vw;
    }
  }

  @media screen and (max-width: 769px) {

    .contentFooter {
      display: none;
    }
  }

}

.mapsHeader {
  position: fixed;
  width: 100vw;
  z-index: 100;
   @media screen and (min-width: 1921px) {
    max-width: 1920px;
  }

  .contentHeader {
    padding-top: 4vh;
    padding-left: 4vw;
    padding-right: 4vw;

     .overviewAcc {

      @media screen and (max-width: 824px) {
        position: absolute;
        right: 0;
        width: 240px;
        .accordion-header {
          width: 65px;
          position: absolute;
          right: 0;
          .textArea {
            display: none;
            width:0;
          }
        }

        .iconArea {
          img {
            visibility: hidden;
          }
        }
        .accordions-dropdown {
          .accordion-body {
            margin-top: 45px;
            .textArea {
              padding: 10px;
              max-height: 230px;
              overflow: scroll;
            }
          }
        } 
      }
    }

    .BackButton {
      margin: 0;
      width: 200px;
      height: 44px;
      box-sizing: border-box;
      border-radius: 30px;

      color: $Gcc-DarkBlue;
      background: #FFFFFF;
      border: solid 2px #0054A4;

      transition: background 0.3s linear;


      text-align: center;
      place-content: center;


      &:hover {
        color: #FFFFFF !important;
        background: $Gcc-DarkBlue;
      }
    }

    h1 {
      text-align-last: right;
      align-self: center;
      color: #ffffff;
      font-size: 36px;

      @media screen and (max-width: 824px) {
         font-size: 20px;
      }
           
    }

    .accordions-dropdown {
      margin: 0;
      position: absolute;
      right: 15px;
      width: 80%;

      .accordion-header {
        border-radius: 6px;
      }
      .accordion-body
      {

        margin-top: 5px;

        .textArea
        {
          border-radius: 6px;
        }
      }
    }
  }

}

.KeyLegend
{
  z-index: 10;
  position: absolute;
  margin-left: 5%;
  margin-top: 20%;
}

.gradient{
  background: linear-gradient(180deg, rgba(0, 30, 63, 0.6) 0%, rgba(0, 46, 95, 0) 100%);
  mix-blend-mode: multiply;
}

</style>