import Vue from 'vue';
import Vuex from 'vuex';

import EnglishData from '../data/content_EN';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        languages: ["EN"],
        language: {
            name: 'english',
            code: 'EN'
        },
        content: {
            "EN": EnglishData,
        },
        feedback: {
            section1: {},
            section2: {},
            section3: {},
            section4: {},
        }
    },
    mutations: {
        toggleLanguage(state, payload)
        {
            if(state.languages.includes(payload)) {
                switch (payload) {
                    case "EN": {
                        state.language = {
                            name: 'english',
                            code: 'EN'
                        }
                        break;
                    }
                    case "CY": {
                        state.language = {
                            name: 'welsh',
                            code: 'CY'
                        }
                        break;
                    }
                    default: {
                        state.language = {
                            name: 'english',
                            code: 'EN'
                        }
                        break;
                    }
                }
            }
            else
            {
                state.language = {
                    name: 'english',
                    code: 'EN'
                }
            }
        },
        storeFeedbackSection1(state, payload) {
            state.feedback.section1 = payload;
        },
        storeFeedbackSection2(state, payload) {
            state.feedback.section2 = payload;
        },
        storeFeedbackSection3(state, payload) {
            state.feedback.section3 = payload;
        },
        storeFeedbackSection4(state, payload) {
            state.feedback.section4 = payload;
        }
    },
    actions: {
        toggleLanguage({ commit }, lang)
        {
            commit("toggleLanguage", lang)
        },
        saveFeedbackData({ commit }, data) {
            switch (data.sectionIndex) {
                case 0:
                    commit('storeFeedbackSection1', data);
                    break;
                case 1:
                    commit('storeFeedbackSection2', data);
                    break;
                case 2:
                    commit('storeFeedbackSection3', data);
                    break;
                case 3:
                    commit('storeFeedbackSection4', data);
                    break;
                default:
                    break;
            }
        }
    },
    getters: {
        availableLanguages: (state) => state.languages,
        language: (state) => state.language,
        content: (state) => state.content[state.language.code],
        feedback: (state) => state.feedback
    }
});