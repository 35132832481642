<template>
  <div class="MapBlips noTop">
      <div v-for="(item, index) in MapData"
           :key="index" @click="GoToPage('portal-content/' + item.pageContent)"
           class="Element"
           :style="BlipOffset(item)">
          <div class="tooltip-desktop" tabindex="0">
            <div class="OuterDot"></div>
            <div class="line"></div>
              <div class="Text">
                {{item.Title}}
              </div>
          </div>
          
      </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import mixin from "../../mixins";
export default {
  name: "MapBlips",
  mixins:[mixin],
  computed: {
    ...mapGetters(["content"]),
    MapData()
    {
      const vm = this;
      if (
          this.content &&
          this.content.PortalData &&
          this.content.PortalData.SmallBoxes
      ) {
        return this.content.PortalData.SmallBoxes;
      } else return {};
    },
  
  },
  methods:{
    BlipOffset(i)
    {
      const queryDesktop = window.matchMedia("(min-width: 1024px)");

      if (queryDesktop.matches)
      {
        return 'left:' + i.mapLocation[0][0] + 'px; top:' + i.mapLocation[0][1] + 'px;'
      }
    },
  }
}
</script>