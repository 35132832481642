<template>
  <div>
        <svg width="1920" height="736" viewBox="0 0 1920 736" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.8" clip-path="url(#clip0_1015_18814)">
          <mask id="mask0_1015_18814" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="-2" y="0" width="1922" height="1080">
            <rect x="-2" width="1922" height="1080" fill="#C4C4C4"/>
          </mask>
          <g mask="url(#mask0_1015_18814)">
            <path opacity="0.48" d="M-2 759L1920 759L1920 -450L-2 -450L-2 759Z" fill="url(#paint0_linear_1015_18814)"/>
            <path opacity="0.07" d="M809.943 1462.48C1204.84 1462.48 1524.97 1142.35 1524.97 747.451C1524.97 352.552 1204.84 32.424 809.943 32.424C415.044 32.424 94.916 352.552 94.916 747.451C94.916 1142.35 415.044 1462.48 809.943 1462.48Z" fill="white"/>
            <path opacity="0.5" d="M-37.9813 311.233C-23.7849 311.233 -29.5435 333.104 -18.4171 333.104C-3.4484 333.104 -7.29068 255.982 26.0886 255.982C47.96 255.982 49.1137 286.291 59.0865 286.291C69.0592 286.291 71.7574 197.623 103.983 197.623C136.209 197.623 139.622 268.672 152.665 268.672C165.707 268.672 171.523 228.352 202.986 228.352C236.747 228.352 241.352 293.966 268.982 293.966C291.235 293.966 296.221 266.727 315.023 266.727C342.672 266.727 349.937 308.544 367.585 308.544C385.233 308.544 388.255 291.707 407.819 291.707C427.383 291.707 442.409 330.797 463.899 330.797C485.389 330.797 491.873 331.951 491.873 341.132C491.873 346.91 487.277 346.853 474.997 346.853H-64.8392C-64.8392 346.853 -72.1328 346.08 -72.1328 341.475C-72.1328 336.87 -62.5414 336.87 -56.7923 336.87C-51.0432 336.87 -45.6563 311.233 -37.9813 311.233Z" fill="white"/>
            <path opacity="0.5" d="M636.116 465.133C640.132 464.015 644.366 463.93 648.424 464.889C652.482 465.848 656.231 467.818 659.322 470.616C660.418 471.493 659.665 463.169 671.478 465.133C673.614 465.334 663.717 466.649 661.296 473.247C661.744 473.466 645.879 464.046 636.116 465.133Z" fill="#4E4D4D"/>
            <path opacity="0.5" d="M632.302 482.438C634.946 481.603 637.774 481.544 640.45 482.267C643.127 482.991 645.54 484.466 647.404 486.519C648.129 487.167 647.633 480.97 655.318 482.438C656.71 482.581 650.274 483.563 648.701 488.483C648.987 488.645 638.699 481.628 632.302 482.438Z" fill="#4E4D4D"/>
            <path opacity="0.5" d="M696.724 493.326C697.985 492.921 699.335 492.886 700.616 493.224C701.897 493.562 703.054 494.259 703.951 495.233C704.294 495.548 704.056 492.583 707.765 493.326C708.432 493.393 705.352 493.86 704.599 496.187C704.704 496.291 699.765 492.935 696.724 493.326Z" fill="#4E4D4D"/>
            <path opacity="0.5" d="M670.782 477.662C672.573 477.096 674.488 477.056 676.302 477.546C678.115 478.036 679.749 479.035 681.011 480.426C681.498 480.874 681.164 476.68 686.369 477.662C687.323 477.766 682.947 478.434 681.879 481.761C682.079 481.866 675.091 477.118 670.782 477.662Z" fill="#4E4D4D"/>
            <path opacity="0.18" d="M1001.31 474.716C1008.54 474.716 1005.61 485.842 1011.28 485.842C1018.9 485.842 1016.94 446.58 1033.94 446.58C1045.07 446.58 1045.66 462.016 1050.74 462.016C1055.82 462.016 1057.18 416.891 1073.62 416.891C1090.06 416.891 1091.74 453.035 1098.41 453.035C1105.08 453.035 1108.01 432.517 1124.03 432.517C1141.19 432.517 1143.55 465.887 1157.63 465.887C1168.95 465.887 1171.49 452.015 1181.06 452.015C1195.13 452.015 1198.84 473.305 1207.82 473.305C1216.81 473.305 1218.31 464.724 1228.3 464.724C1238.3 464.724 1245.91 484.632 1256.91 484.632C1267.9 484.632 1271.21 485.213 1271.21 489.904C1271.21 492.841 1268.86 492.831 1262.63 492.831H987.651C987.651 492.831 983.942 492.44 983.942 490.095C983.942 487.749 988.814 487.749 991.751 487.749C994.687 487.749 997.405 474.716 1001.31 474.716Z" fill="white"/>
            <path d="M2089.68 721.194H-132.904V1002.44H2089.68V721.194Z" fill="#47466C"/>
            <path d="M1468.74 609.12C1468.74 609.12 1472.7 625.576 1471.79 633.194C1470.87 640.812 1467.82 645.378 1468.74 652.386C1469.65 659.394 1470.87 663.665 1463.56 673.714C1456.25 683.763 1454.72 696.596 1448.31 696.596C1441.89 696.596 1445.25 697.549 1442.52 701.163C1439.78 704.776 1439.16 711.526 1430.03 709.391C1420.89 707.255 1410.83 699.037 1404.73 695.681C1400.99 693.586 1397.68 690.793 1394.98 687.453C1394.98 687.453 1398.95 685.326 1399.25 681.36C1399.39 679.558 1399.59 676.069 1399.77 672.78C1399.99 668.832 1400.17 665.2 1400.17 665.2L1397.12 659.479C1397.12 659.479 1385.24 649.945 1388.89 644.492C1392.54 639.038 1398.34 634.138 1401.68 635.654C1405.03 637.17 1413.57 614.631 1419.36 611.589C1425.15 608.548 1461.42 612.171 1468.74 609.12Z" fill="#FFEDBB"/>
            <path opacity="0.55" d="M616.103 579.849C616.103 579.849 717.214 526.839 796.471 522.082C874.652 517.391 879.104 532.693 936.367 532.693C1082.12 532.693 1178.54 459.604 1375.35 459.604C1549.78 459.604 1834.66 602.531 1834.66 602.531L616.103 579.849Z" fill="#A5D7DA"/>
            <path opacity="0.75" d="M1090.94 605.144C1329.15 605.144 -267.375 605.554 -267.375 605.554C-267.375 605.554 204.893 482.038 302.142 482.038" fill="#76C7C9"/>
            <path d="M734.804 605.554C868.712 605.554 1069 527.736 1223.24 527.736C1336.38 527.736 1338.35 555.69 1455.76 555.69C1674.5 555.69 1738.59 413.096 2139.5 413.096C2376.62 413.096 2096.53 605.554 2096.53 605.554H734.804Z" fill="#74BFBC"/>
            <path d="M-234.024 462.026C-234.024 462.026 -30.4302 554.298 165.908 554.298C268.41 554.298 258.942 534.724 319.799 534.724C380.656 534.724 458.923 565.482 521.086 565.482C583.249 565.482 661.515 542.6 723.678 542.6C785.841 542.6 1018.73 604.915 1018.73 604.915L-311.441 607.632L-234.024 462.026Z" fill="#69B2AF"/>
            <path d="M2022.26 516.4C1784.43 516.4 1547.33 601.74 1547.33 601.74L2089.01 604.772H2240.01C2240.01 604.772 2260.07 516.4 2022.26 516.4Z" fill="#239693"/>
            <path d="M1041.33 601.826L1043.25 604.744L1047.58 599.872L1049.15 604.744L1053.83 597.793L1055.22 603.752L1061.12 595.457L1062.86 599.071L1067.02 593.388L1068.58 599.29L1072.23 591.548L1075 601.13L1080.57 589.136L1081.26 597.221L1085.78 587.858L1087.17 595.381L1091.74 586.028L1094.45 594.809L1100.01 583.31L1103.05 593.121L1111.74 579.668L1115.56 592.559L1122.41 576.417L1124.14 587.286L1129.95 574.053L1131.61 580.336L1136.02 572.146L1137.51 580.479L1143.41 569.829L1151.57 585.58L1155.22 573.376L1161.13 585.723L1163.2 574.939L1169.8 590.69L1173.53 578.067L1183.9 592.559L1185.13 581.232L1195.5 593.121L1195.81 584.149L1201.23 591.405L1202.13 585.885L1208.52 596.191L1210.26 588.278L1219.8 600.634L1221.74 591.243L1228.66 600.491L1230.33 593.588L1235.08 599.709L1236.8 595.362L1243.24 604.381L1039.25 604.744L1041.33 601.826Z" fill="#189390"/>
            <path d="M1246.79 596.602L1245.32 604.743H1251.58L1246.79 596.602Z" fill="#189390"/>
            <path d="M1257.08 600.043L1256.26 604.744H1265.29L1260.78 601.168V603.533L1257.08 600.043Z" fill="#189390"/>
            <path d="M620.403 607.089L632.054 593.455L633.322 601.33L637.555 593.131L639.901 598.947L645.736 588.268L648.262 597.86L653.582 586.733L655.756 593.455L662.058 586.076L663.526 594.78L671.316 581.69L676.712 591.806L684.988 577.857L689.803 585.342L699.432 569.83L707.031 590.089L711.588 577.495L719.95 595.934L720.703 588.268L725.518 594.466L727.797 582.968L736.158 598.947L738.694 586.733L746.035 598.756L748.314 591.806L752.366 599.767L754.902 588.087L761.233 603.237L763.006 591.806L770.605 603.418L771.368 594.78L778.204 603.056L779.215 595.019L786.813 602.865L776.688 605.42L620.403 607.089Z" fill="#189390"/>
            <path d="M2093.28 601.626H-205.03V742.732H2093.28V601.626Z" fill="url(#paint1_linear_1015_18814)"/>
            <path opacity="0.75" d="M2096.53 649.64H-201.789V673.104H2096.53V649.64Z" fill="#47466C"/>
            <path d="M-182.806 662.292L2080.21 660.728" stroke="#FEF9D4" stroke-miterlimit="10"/>
            <path d="M-141.075 616.128C-141.075 616.128 -103.529 598.966 6.76276 609.759C117.054 620.551 80.8721 621.877 125.94 621.905C171.009 621.934 143.445 612.238 208.821 619.045C274.197 625.852 397.465 670.634 453.736 669.395C510.007 668.156 493.15 660.814 550.985 652.777C608.819 644.74 703.78 648.315 780.759 657.964C857.738 667.612 888.705 711.031 950.792 679.968C1008.87 650.918 1294.14 661.92 1332.52 651.366C1370.91 640.812 1363.99 626.577 1427.66 616.089C1491.34 605.602 1502.37 598.299 1565.23 613.582C1628.09 628.865 1623.2 631.268 1689.64 641.279C1756.07 651.29 1888.24 648.534 1888.24 648.534C1888.24 648.534 1931.65 645.884 1961.74 640.173C1991.83 634.462 2029.1 630.419 2029.1 630.419L2077.33 635.701L2108.58 715.607L2077.33 736.115H-149.541L-141.075 616.128Z" fill="url(#paint2_linear_1015_18814)"/>
            <path d="M2309.03 537.127C2309.03 537.127 2118.74 655.656 1935.24 655.656C1839.43 655.656 1848.28 630.515 1791.39 630.515C1734.51 630.515 1661.36 670.062 1603.26 670.062C1545.15 670.062 1472 640.726 1413.91 640.726C1355.81 640.726 1138.12 720.813 1138.12 720.813L2381.38 724.302L2309.03 537.127Z" fill="url(#paint3_linear_1015_18814)"/>
            <path d="M1880.1 482.553L1879.57 483.624C1879.68 483.238 1879.86 482.875 1880.1 482.553V482.553Z" fill="#AAD4AC"/>
            <path d="M274.578 647.714V642.127H213.579L207.982 654.207L262.155 651.623L274.578 647.714Z" fill="#7A7B7B"/>
            <path d="M140.108 692.735C140.108 691.143 138.812 693.965 137.248 693.965C136.489 693.965 135.762 694.266 135.225 694.802C134.689 695.339 134.388 696.066 134.388 696.825V715.035L140.156 717.533L140.108 692.735Z" fill="white"/>
            <path d="M159.624 714.549C158.866 714.549 158.138 714.247 157.602 713.711C157.066 713.175 156.764 712.447 156.764 711.688V674.19C156.764 673.432 157.066 672.704 157.602 672.168C158.138 671.632 158.866 671.33 159.624 671.33C161.226 671.33 162.485 668.47 162.485 670.1V711.717C162.477 712.471 162.172 713.191 161.637 713.721C161.101 714.251 160.378 714.549 159.624 714.549V714.549Z" fill="white"/>
            <path d="M178.559 713.129C177.801 713.129 177.073 712.827 176.537 712.291C176.001 711.754 175.699 711.027 175.699 710.268V654.493C175.699 653.734 176.001 653.007 176.537 652.47C177.073 651.934 177.801 651.633 178.559 651.633C180.152 651.633 181.42 648.772 181.42 650.403V710.287C181.415 711.043 181.111 711.765 180.575 712.298C180.039 712.83 179.315 713.129 178.559 713.129Z" fill="white"/>
            <path d="M198.782 706.893C198.023 706.893 197.296 706.591 196.759 706.055C196.223 705.518 195.921 704.791 195.921 704.032V633.022C195.921 632.264 196.223 631.536 196.759 631C197.296 630.463 198.023 630.162 198.782 630.162C200.374 630.162 201.642 627.302 201.642 628.932V704.061C201.634 704.815 201.33 705.535 200.794 706.065C200.259 706.595 199.535 706.893 198.782 706.893V706.893Z" fill="white"/>
            <path d="M208.563 651.623L219.289 647.714H274.578L263.385 651.604L208.563 651.623Z" fill="#7A7B7B"/>
            <path d="M263.385 651.604H208.563V660.433H263.385V651.604Z" fill="#7A7B7B"/>
            <path d="M197.371 664.332L208.097 660.433H263.385L252.183 664.322L197.371 664.332Z" fill="#7A7B7B"/>
            <path d="M252.192 664.323H197.371V673.151H252.192V664.323Z" fill="#7A7B7B"/>
            <path d="M186.177 677.051L196.903 673.142H252.182L240.989 677.042L186.177 677.051Z" fill="#7A7B7B"/>
            <path d="M240.999 677.041H186.177V685.87H240.999V677.041Z" fill="#7A7B7B"/>
            <path d="M174.975 689.77L185.71 685.86H240.989L229.796 689.751L174.975 689.77Z" fill="#7A7B7B"/>
            <path d="M229.796 689.75H174.975V698.579H229.796V689.75Z" fill="#7A7B7B"/>
            <path d="M163.782 702.488L174.517 698.579H229.797L218.603 702.469L163.782 702.488Z" fill="#7A7B7B"/>
            <path d="M218.603 702.469H163.782V711.297H218.603V702.469Z" fill="#7A7B7B"/>
            <path d="M152.588 715.197L163.324 711.297H218.603L207.41 715.187L152.588 715.197Z" fill="#7A7B7B"/>
            <path d="M204.445 648.858L215.18 644.949H270.46L259.267 648.839L204.445 648.858Z" fill="#9A9999"/>
            <path d="M259.266 648.839H204.445V657.668H259.266V648.839Z" fill="#8C8C8B"/>
            <path d="M193.251 661.567L203.987 657.668H259.266L248.073 661.558L193.251 661.567Z" fill="#9A9999"/>
            <path d="M248.073 661.557H193.251V670.386H248.073V661.557Z" fill="#8C8C8B"/>
            <path d="M182.059 674.286L192.794 670.377H248.073L236.88 674.276L182.059 674.286Z" fill="#9A9999"/>
            <path d="M236.88 674.276H182.059V683.105H236.88V674.276Z" fill="#8C8C8B"/>
            <path d="M170.865 687.004L181.591 683.095H236.88L225.687 686.985L170.865 687.004Z" fill="#9A9999"/>
            <path d="M225.687 686.985H170.865V695.814H225.687V686.985Z" fill="#8C8C8B"/>
            <path d="M159.672 699.723L170.398 695.814H225.687L214.484 699.704L159.672 699.723Z" fill="#9A9999"/>
            <path d="M214.494 699.704H159.672V708.532H214.494V699.704Z" fill="#8C8C8B"/>
            <path d="M148.47 712.432L159.205 708.532H214.484L203.291 712.423L148.47 712.432Z" fill="#9A9999"/>
            <path d="M220.834 677.852C220.834 676.259 219.547 679.091 217.974 679.091C217.215 679.091 216.488 679.392 215.952 679.929C215.415 680.465 215.114 681.193 215.114 681.951V715.893L220.882 715.311L220.834 677.852Z" fill="white"/>
            <path d="M239.76 658.154C239.76 656.562 238.472 659.384 236.899 659.384C236.141 659.384 235.413 659.685 234.877 660.222C234.34 660.758 234.039 661.485 234.039 662.244V714.015L239.76 713.462V658.154Z" fill="white"/>
            <path d="M259.991 636.693C259.991 635.1 258.694 637.932 257.131 637.932C256.372 637.932 255.645 638.233 255.108 638.77C254.572 639.306 254.271 640.034 254.271 640.792V711.803C254.265 711.908 254.265 712.013 254.271 712.118L259.991 711.593V636.693Z" fill="white"/>
            <path d="M203.291 712.423H148.479V722.968C165.021 721.118 183.498 719.154 203.291 717.104V712.423Z" fill="#8C8C8B"/>
            <path d="M206.609 716.779V711.317L203.291 712.441V717.122L206.609 716.779Z" fill="#7A7B7B"/>
            <path d="M637.059 584.617C638.642 581.404 641.454 575.235 641.454 571.984C641.454 570.869 641.897 569.8 642.686 569.011C643.474 568.222 644.544 567.779 645.659 567.779C646.774 567.779 647.843 568.222 648.632 569.011C649.421 569.8 649.864 570.869 649.864 571.984C649.864 574.968 653.506 581.518 655.145 584.321C656.269 585.944 656.931 587.841 657.061 589.811C657.19 591.781 656.783 593.748 655.881 595.504C654.98 597.26 653.618 598.738 651.942 599.78C650.266 600.823 648.338 601.39 646.365 601.423C644.391 601.455 642.446 600.95 640.737 599.963C639.027 598.976 637.618 597.543 636.66 595.818C635.702 594.092 635.23 592.139 635.295 590.166C635.36 588.193 635.96 586.275 637.03 584.617H637.059Z" fill="#A4D1AA"/>
            <g style="mix-blend-mode:overlay" opacity="0.5">
                <path d="M641.283 573.586C641.301 574.646 641.25 575.707 641.13 576.76C640.558 582.128 639.891 582.748 639.223 585.856C638.556 588.964 638.089 590.08 639.176 593.636C640.32 597.373 641.798 598.994 644.391 599.671C646.675 600.321 649.074 600.461 651.418 600.081C649.289 601.248 646.831 601.67 644.435 601.283C642.038 600.895 639.839 599.719 638.187 597.94C636.534 596.162 635.522 593.883 635.311 591.464C635.1 589.046 635.702 586.626 637.021 584.588C638.413 581.861 640.654 576.98 641.283 573.586Z" fill="#A4D1AA"/>
            </g>
            <path d="M640.682 588.631L645.316 592.711L645.678 586.667C645.689 586.611 645.72 586.56 645.764 586.523C645.808 586.487 645.864 586.467 645.921 586.467C645.979 586.467 646.034 586.487 646.079 586.523C646.123 586.56 646.153 586.611 646.164 586.667L646.336 589.241L647.957 587.773C648.081 587.668 648.262 587.839 648.148 587.963L646.384 590.042L646.899 598.165L651.723 593.922C651.764 593.892 651.815 593.876 651.866 593.879C651.918 593.882 651.966 593.903 652.004 593.938C652.041 593.974 652.065 594.021 652.071 594.072C652.077 594.123 652.065 594.175 652.037 594.218L647.051 600.205L647.528 607.699H644.42L645.182 594.752L640.358 588.955C640.315 588.912 640.291 588.854 640.291 588.793C640.291 588.732 640.315 588.674 640.358 588.631C640.401 588.588 640.459 588.564 640.52 588.564C640.581 588.564 640.639 588.588 640.682 588.631V588.631Z" fill="#A4D1AA"/>
            <path d="M602.355 582.004C604.119 578.439 607.246 571.574 607.246 567.951C607.3 566.747 607.817 565.611 608.688 564.778C609.559 563.946 610.717 563.481 611.922 563.481C613.127 563.481 614.286 563.946 615.157 564.778C616.028 565.611 616.545 566.747 616.599 567.951C616.599 571.269 620.66 578.563 622.481 581.68C623.738 583.485 624.479 585.598 624.627 587.792C624.775 589.986 624.324 592.179 623.321 594.136C622.319 596.093 620.803 597.741 618.936 598.903C617.069 600.065 614.921 600.698 612.722 600.733C610.524 600.769 608.357 600.206 606.453 599.104C604.55 598.003 602.982 596.405 601.917 594.481C600.852 592.557 600.33 590.38 600.407 588.182C600.484 585.984 601.158 583.849 602.355 582.004V582.004Z" fill="#A4D1AA"/>
            <g style="mix-blend-mode:overlay" opacity="0.5">
                <path d="M607.055 569.724C607.071 570.909 607.014 572.094 606.884 573.271C606.245 579.24 605.501 579.945 604.805 583.397C604.109 586.848 603.537 588.087 604.748 592.044C606.026 596.211 607.675 598.013 610.554 598.718C613.095 599.443 615.765 599.599 618.372 599.176C616.003 600.475 613.267 600.946 610.599 600.514C607.931 600.083 605.483 598.773 603.644 596.792C601.805 594.812 600.679 592.274 600.446 589.582C600.212 586.889 600.884 584.196 602.355 581.928C603.852 578.944 606.35 573.51 607.055 569.724Z" fill="#A4D1AA"/>
            </g>
            <path d="M606.388 586.476L611.546 591.024L611.937 584.293C611.937 584.219 611.966 584.149 612.018 584.097C612.069 584.045 612.14 584.016 612.213 584.016C612.286 584.016 612.357 584.045 612.409 584.097C612.46 584.149 612.49 584.219 612.49 584.293L612.671 587.153L614.482 585.532C614.511 585.513 614.545 585.503 614.579 585.506C614.613 585.508 614.646 585.522 614.671 585.545C614.696 585.568 614.713 585.599 614.719 585.633C614.724 585.667 614.718 585.702 614.702 585.732L612.728 588.049L613.3 597.097L618.677 592.33C618.725 592.299 618.782 592.285 618.839 592.291C618.895 592.296 618.948 592.322 618.989 592.362C619.029 592.402 619.054 592.455 619.06 592.512C619.066 592.568 619.052 592.625 619.021 592.673L613.472 599.347L614.005 607.69H610.583L611.441 593.274L606.064 586.819C606.038 586.773 606.028 586.72 606.035 586.668C606.041 586.616 606.064 586.567 606.1 586.529C606.136 586.491 606.184 586.465 606.235 586.456C606.287 586.446 606.34 586.453 606.388 586.476V586.476Z" fill="#A4D1AA"/>
            <path d="M719.32 614.601H746.302V594.351L735.119 583.167H723.735L719.32 614.601Z" fill="#E2E0B5"/>
            <path d="M712.542 614.601H734.919V594.351L723.735 583.167L712.542 594.351V614.601Z" fill="#F4F3D3"/>
            <path d="M746.302 614.601H773.284V594.351L762.101 583.167H750.717L746.302 614.601Z" fill="#E2E0B5"/>
            <path d="M739.523 614.601H761.9V594.351L750.717 583.167L739.523 594.351V614.601Z" fill="#F4F3D3"/>
            <path d="M1558.95 614.601H1585.93V594.351L1574.75 583.167H1563.37L1558.95 614.601Z" fill="#E2E0B5"/>
            <path d="M1552.17 614.601H1574.55V594.351L1563.37 583.167L1552.17 594.351V614.601Z" fill="#F4F3D3"/>
            <path d="M1585.93 614.601H1612.91V594.351L1601.73 583.167H1590.35L1585.93 614.601Z" fill="#E2E0B5"/>
            <path d="M1579.15 614.601H1601.53V594.351L1590.35 583.167L1579.15 594.351V614.601Z" fill="#F4F3D3"/>
            <path d="M1612.91 614.601H1639.9V594.351L1628.71 583.167H1617.33L1612.91 614.601Z" fill="#E2E0B5"/>
            <path d="M1606.13 614.601H1628.51V594.351L1617.33 583.167L1606.13 594.351V614.601Z" fill="#F4F3D3"/>
            <path d="M1182.72 614.601H1209.7V594.351L1198.52 583.167H1187.15L1182.72 614.601Z" fill="#E2E0B5"/>
            <path d="M1175.94 614.601H1198.32V594.351L1187.14 583.167L1175.94 594.351V614.601Z" fill="#F4F3D3"/>
            <path d="M1215.2 614.601H1242.19V594.351L1231 583.167H1219.62L1215.2 614.601Z" fill="#E2E0B5"/>
            <path d="M1208.42 614.601H1230.8V594.351L1219.62 583.167L1208.42 594.351V614.601Z" fill="#F4F3D3"/>
            <path d="M1096.11 614.601H1123.09V594.351L1111.91 583.167H1100.53L1096.11 614.601Z" fill="#E2E0B5"/>
            <path d="M1089.33 614.601H1111.71V594.351L1100.53 583.167L1089.33 594.351V614.601Z" fill="#F4F3D3"/>
            <path d="M1123.09 614.601H1150.08V594.351L1138.89 583.167H1127.51L1123.09 614.601Z" fill="#E2E0B5"/>
            <path d="M1116.32 614.601H1138.69V594.351L1127.51 583.167L1116.32 594.351V614.601Z" fill="#F4F3D3"/>
            <path d="M589.121 614.601H616.103V594.351L604.92 583.167H593.536L589.121 614.601Z" fill="#E2E0B5"/>
            <path d="M582.342 614.601H604.719V594.351L593.535 583.167L582.342 594.351V614.601Z" fill="#F4F3D3"/>
            <path d="M616.103 614.601H643.085V594.351L631.901 583.167H620.517L616.103 614.601Z" fill="#E2E0B5"/>
            <path d="M609.324 614.601H631.701V594.351L620.517 583.167L609.324 594.351V614.601Z" fill="#F4F3D3"/>
            <path d="M643.085 614.601H670.067V594.351L658.883 583.167H647.499L643.085 614.601Z" fill="#E2E0B5"/>
            <path d="M636.306 614.601H658.683V594.351L647.499 583.167L636.306 594.351V614.601Z" fill="#F4F3D3"/>
            <g style="mix-blend-mode:overlay" opacity="0.17">
                <path d="M562.873 621.591C566.912 621.591 570.186 620.924 570.186 620.103C570.186 619.281 566.912 618.615 562.873 618.615C558.835 618.615 555.561 619.281 555.561 620.103C555.561 620.924 558.835 621.591 562.873 621.591Z" fill="#010101"/>
            </g>
            <path d="M553.74 596.649C555.313 593.445 558.125 587.277 558.125 584.026C558.125 582.911 558.568 581.841 559.357 581.053C560.146 580.264 561.215 579.821 562.33 579.821C563.445 579.821 564.515 580.264 565.303 581.053C566.092 581.841 566.535 582.911 566.535 584.026C566.535 587 570.177 593.56 571.817 596.363C573.079 598.192 573.745 600.366 573.723 602.589C573.726 604.286 573.333 605.961 572.574 607.479C571.815 608.998 570.712 610.318 569.352 611.335C567.992 612.351 566.414 613.036 564.743 613.335C563.072 613.634 561.354 613.538 559.726 613.055C558.099 612.572 556.607 611.716 555.369 610.554C554.131 609.392 553.181 607.958 552.596 606.364C552.011 604.771 551.806 603.062 551.998 601.376C552.19 599.689 552.773 598.07 553.702 596.649H553.74Z" fill="#A4D1AA"/>
            <g style="mix-blend-mode:overlay" opacity="0.5">
                <path d="M557.954 585.646C557.973 586.71 557.922 587.774 557.801 588.831C557.229 594.199 556.562 594.818 555.942 597.927C555.323 601.035 554.798 602.141 555.885 605.697C557.029 609.444 558.507 611.065 561.1 611.732C563.383 612.389 565.782 612.532 568.127 612.152C566.468 613.061 564.601 613.524 562.71 613.494C560.819 613.464 558.968 612.942 557.339 611.981C555.709 611.019 554.359 609.65 553.418 608.009C552.478 606.367 551.981 604.509 551.976 602.618C551.974 600.511 552.587 598.45 553.74 596.687C555.084 593.922 557.325 589.022 557.954 585.646Z" fill="#A4D1AA"/>
            </g>
            <path d="M557.353 600.692L561.987 604.782L562.349 598.728C562.349 598.662 562.375 598.599 562.422 598.553C562.468 598.506 562.531 598.48 562.597 598.48C562.663 598.48 562.726 598.506 562.772 598.553C562.819 598.599 562.845 598.662 562.845 598.728L563.007 601.302L564.628 599.843C564.655 599.827 564.686 599.82 564.718 599.823C564.749 599.826 564.778 599.84 564.801 599.862C564.824 599.883 564.839 599.912 564.844 599.943C564.849 599.975 564.843 600.006 564.828 600.034L563.055 602.112L563.57 610.235L568.394 605.983C568.435 605.944 568.489 605.922 568.546 605.922C568.603 605.923 568.657 605.945 568.698 605.984C568.739 606.024 568.763 606.078 568.765 606.134C568.767 606.191 568.746 606.246 568.708 606.288L563.722 612.276L564.189 619.779H561.119L561.892 606.822L557.058 601.026C557.014 600.986 556.987 600.931 556.983 600.872C556.979 600.813 557 600.755 557.039 600.711C557.078 600.667 557.133 600.64 557.192 600.636C557.251 600.633 557.309 600.653 557.353 600.692V600.692Z" fill="#8DBB90"/>
            <g style="mix-blend-mode:overlay" opacity="0.17">
                <path d="M512.761 614.192C516.8 614.192 520.074 613.526 520.074 612.704C520.074 611.883 516.8 611.217 512.761 611.217C508.723 611.217 505.449 611.883 505.449 612.704C505.449 613.526 508.723 614.192 512.761 614.192Z" fill="#010101"/>
            </g>
            <path d="M503.628 589.279C505.201 586.075 508.023 579.907 508.023 576.656C507.996 576.087 508.085 575.519 508.284 574.986C508.483 574.453 508.788 573.966 509.18 573.554C509.573 573.142 510.045 572.814 510.568 572.59C511.091 572.366 511.654 572.251 512.223 572.251C512.792 572.251 513.355 572.366 513.878 572.59C514.401 572.814 514.874 573.142 515.266 573.554C515.659 573.966 515.964 574.453 516.163 574.986C516.362 575.519 516.45 576.087 516.423 576.656C516.423 579.63 520.065 586.19 521.705 588.993C522.987 590.815 523.673 592.99 523.669 595.219C523.677 596.92 523.286 598.6 522.529 600.124C521.771 601.648 520.668 602.974 519.306 603.995C517.945 605.016 516.364 605.704 514.689 606.005C513.014 606.305 511.291 606.21 509.66 605.726C508.028 605.242 506.533 604.383 505.293 603.217C504.053 602.052 503.102 600.613 502.518 599.014C501.934 597.416 501.732 595.703 501.928 594.013C502.124 592.322 502.713 590.701 503.647 589.279H503.628Z" fill="#A4D1AA"/>
            <g style="mix-blend-mode:overlay" opacity="0.5">
                <path d="M507.842 578.248C507.861 579.311 507.81 580.375 507.69 581.432C507.127 586.8 506.45 587.419 505.783 590.528C505.116 593.636 504.639 594.742 505.726 598.298C506.87 602.045 508.357 603.666 510.941 604.343C513.225 604.992 515.623 605.132 517.967 604.753C515.839 605.924 513.38 606.351 510.981 605.965C508.582 605.578 506.381 604.402 504.727 602.621C503.074 600.841 502.063 598.559 501.855 596.138C501.648 593.717 502.255 591.296 503.581 589.26C504.972 586.523 507.213 581.651 507.842 578.248Z" fill="#A4D1AA"/>
            </g>
            <path d="M507.241 593.303L511.875 597.383L512.237 591.339C512.237 591.273 512.263 591.21 512.31 591.163C512.356 591.117 512.419 591.091 512.485 591.091C512.551 591.091 512.614 591.117 512.66 591.163C512.707 591.21 512.733 591.273 512.733 591.339L512.895 593.922L514.525 592.454C514.551 592.429 514.585 592.415 514.621 592.415C514.657 592.415 514.691 592.429 514.716 592.454C514.741 592.479 514.756 592.514 514.756 592.549C514.756 592.585 514.741 592.62 514.716 592.645L512.943 594.742L513.458 602.865L518.282 598.613C518.323 598.574 518.378 598.552 518.434 598.552C518.491 598.553 518.546 598.575 518.586 598.614C518.627 598.654 518.651 598.707 518.653 598.764C518.655 598.821 518.635 598.876 518.597 598.918L513.61 604.906L514.087 612.409H511.007L511.78 599.452L506.955 593.655C506.908 593.617 506.879 593.563 506.872 593.503C506.866 593.443 506.884 593.383 506.922 593.336C506.96 593.289 507.015 593.259 507.075 593.253C507.135 593.247 507.194 593.265 507.241 593.303Z" fill="#8DBB90"/>
            <path d="M1319.5 612.637C1319.36 612.594 1319.22 612.575 1319.08 612.58C1318.98 612.621 1318.9 612.694 1318.84 612.787C1318.79 612.88 1318.77 612.989 1318.78 613.095C1318.78 613.429 1318.9 613.81 1319.14 613.886C1319.36 613.931 1319.59 613.954 1319.81 613.953L1322.67 616.251L1322.53 614.582C1322.53 614.582 1320.85 613.629 1320.62 613.4C1320.39 613.171 1320.08 612.704 1319.95 612.618C1319.81 612.533 1319.51 612.504 1319.5 612.637Z" fill="#239693"/>
            <path d="M1324.97 627.387C1324.57 627.686 1324.11 627.888 1323.62 627.978C1322.88 626.891 1321.35 624.708 1320.99 624.05C1320.64 623.392 1322.84 620.38 1322.84 620.38L1324.59 619.846C1324.59 619.846 1323.64 622.21 1323.26 622.906C1323.13 623.214 1323.08 623.553 1323.11 623.888C1323.15 624.223 1323.27 624.542 1323.47 624.813C1324.04 625.27 1324.46 625.874 1324.69 626.557L1324.97 627.387Z" fill="#239693"/>
            <path d="M1323.54 609.835V610.283L1323.22 611.408L1321.93 611.636C1322.05 611.325 1322.16 611.007 1322.24 610.683C1322.28 610.446 1322.28 610.205 1322.24 609.968C1322.54 609.881 1322.82 609.743 1323.06 609.558C1323.17 609.5 1323.26 609.437 1323.36 609.368L1323.54 609.835Z" fill="#239693"/>
            <path d="M1324.59 607.994C1324.58 608.217 1324.55 608.438 1324.49 608.652C1324.44 608.832 1324.38 609.008 1324.29 609.177C1324.07 609.423 1323.82 609.644 1323.54 609.834L1323.25 609.281C1323.42 609.159 1323.56 608.991 1323.65 608.795C1323.67 608.702 1323.66 608.604 1323.63 608.515C1323.59 608.426 1323.53 608.351 1323.45 608.299C1323.32 608.233 1323.09 608.414 1323.03 608.5C1322.96 608.586 1322.82 608.719 1322.82 608.271C1322.82 607.823 1322.32 607.47 1322.04 607.156C1321.98 607.082 1321.93 607.002 1321.88 606.917C1322.17 606.739 1322.5 606.649 1322.84 606.66C1323.11 606.689 1323.38 606.753 1323.64 606.85C1323.98 607.026 1324.27 607.297 1324.47 607.632C1324.53 607.742 1324.58 607.866 1324.59 607.994V607.994Z" fill="#239693"/>
            <path d="M1324.46 607.852C1324.74 607.788 1325.03 607.788 1325.3 607.852C1325.53 607.966 1326.41 609.005 1326.98 609.11C1327.4 609.162 1327.82 609.113 1328.21 608.967C1328.21 608.967 1327.85 610.035 1327.43 610.178C1327.23 610.272 1327.02 610.309 1326.8 610.284C1326.58 610.259 1326.38 610.172 1326.21 610.035C1325.84 609.699 1325.55 609.29 1325.34 608.834C1325.18 608.605 1324.87 608.252 1324.76 608.243C1324.66 608.233 1324.46 607.852 1324.46 607.852Z" fill="#239693"/>
            <path d="M1324.84 613.01C1324.53 613.197 1324.19 613.329 1323.84 613.401C1323.58 613.401 1323.12 612.981 1323.04 612.571C1323.05 611.85 1323.17 611.135 1323.38 610.445C1323.38 610.35 1323.38 610.445 1323.63 610.254C1323.81 610.311 1323.99 610.391 1324.15 610.493C1324.59 610.741 1324.8 612.381 1324.84 613.01Z" fill="#239693"/>
            <path d="M1322.3 622.086C1322.94 622.029 1323.57 622.029 1324.21 622.086C1324.4 622.588 1324.48 623.124 1324.45 623.659L1323.84 623.802C1322.64 624.099 1321.42 624.272 1320.19 624.317C1319.93 624.34 1319.66 624.277 1319.44 624.136C1319.21 623.995 1319.04 623.784 1318.95 623.535C1318.78 622.934 1319.79 621.686 1320.25 621.094C1320.71 620.503 1322.79 618.063 1322.79 618.063L1325.28 618.482C1325.34 618.753 1325.31 619.039 1325.17 619.283C1324.75 620.012 1324.17 620.64 1323.47 621.123C1322.46 621.886 1322.4 621.981 1322.3 622.086Z" fill="#239693"/>
            <path d="M1325.41 618.501C1324.92 618.397 1324.42 618.352 1323.91 618.368C1323.48 618.379 1323.06 618.273 1322.68 618.062C1322.68 618.062 1322.52 616.88 1322.46 616.556C1322.41 616.232 1321.99 614.411 1321.82 613.972C1321.63 613.549 1321.5 613.099 1321.44 612.638C1321.5 612.299 1321.62 611.975 1321.8 611.684C1322 611.592 1322.18 611.47 1322.35 611.322C1322.63 611.074 1323.22 610.369 1323.3 610.283C1323.39 610.263 1323.49 610.263 1323.58 610.283C1323.38 610.473 1323.37 610.378 1323.33 610.473C1323.12 611.163 1323.01 611.879 1322.99 612.599C1323.07 613.009 1323.53 613.477 1323.79 613.429C1324.14 613.357 1324.48 613.225 1324.79 613.038C1324.79 613.098 1324.79 613.159 1324.79 613.219C1324.79 613.581 1324.67 614.449 1324.65 615.126C1324.69 615.868 1324.84 616.6 1325.09 617.3C1325.23 617.69 1325.34 618.092 1325.41 618.501Z" fill="#239693"/>
            <path d="M1325.16 614.764C1324.87 614.965 1324.61 615.191 1324.36 615.441C1323.85 615.947 1323.31 616.421 1322.75 616.861C1322.58 616.956 1322.21 616.928 1322.08 617.014C1322.05 617.039 1322.02 617.074 1322 617.115C1321.99 617.155 1321.98 617.199 1321.99 617.242C1321.99 617.242 1321.61 617.567 1321.57 617.767C1321.52 617.967 1321.57 618.339 1321.86 618.358C1322.07 618.384 1322.27 618.351 1322.46 618.263C1322.65 618.175 1322.8 618.036 1322.91 617.862C1323.48 617.398 1324.13 617.034 1324.82 616.785C1325.5 616.49 1326.14 616.109 1326.72 615.65C1326.93 615.317 1326.94 615.136 1326.72 614.621C1326.51 614.106 1324.88 611.398 1324.39 610.693C1324.33 610.568 1324.23 610.462 1324.11 610.386C1324 610.311 1323.86 610.268 1323.72 610.264C1323.64 610.269 1323.56 610.305 1323.5 610.365C1323.44 610.425 1323.41 610.505 1323.41 610.588L1323.3 612.676C1323.3 612.676 1324.82 614.468 1325.16 614.764Z" fill="#239693"/>
            <path d="M1328.49 624.451C1328.42 624.87 1328.34 625.309 1328.18 625.404C1328.12 625.441 1328.04 625.455 1327.96 625.444C1327.88 625.434 1327.81 625.4 1327.75 625.347C1327.65 624.926 1327.48 624.525 1327.25 624.155C1327.02 623.885 1326.8 623.598 1326.61 623.297L1326.25 622.029C1326.24 621.996 1326.24 621.961 1326.24 621.927C1326.25 621.893 1326.26 621.861 1326.28 621.832C1326.3 621.804 1326.33 621.779 1326.35 621.761C1326.38 621.742 1326.42 621.729 1326.45 621.724C1326.73 621.63 1327.02 621.579 1327.32 621.571C1327.46 621.701 1327.56 621.87 1327.6 622.058C1327.62 622.353 1327.65 622.646 1327.72 622.935C1328.01 623.323 1328.26 623.745 1328.46 624.193V624.193C1328.48 624.278 1328.49 624.364 1328.49 624.451V624.451Z" fill="#239693"/>
            <path d="M1328.46 624.155C1328.26 623.707 1328.01 623.284 1327.72 622.896C1327.65 622.608 1327.62 622.314 1327.6 622.019C1327.56 621.832 1327.46 621.662 1327.32 621.533H1327.63C1327.86 621.745 1328 622.033 1328.03 622.343C1328.03 622.81 1328.09 623.23 1328.09 623.23C1328.25 623.521 1328.38 623.832 1328.46 624.155V624.155Z" fill="#239693"/>
            <path d="M1326.3 630.219C1325.86 630.366 1325.41 630.465 1324.95 630.515C1324.56 630.515 1324.41 630.829 1323.9 631.03C1323.49 631.051 1323.08 631.009 1322.68 630.906C1322.53 630.791 1322.68 630.553 1322.85 630.476C1323.01 630.4 1323.47 630.391 1323.58 630.286C1323.68 630.181 1324.19 629.599 1324.42 629.561C1324.83 629.463 1325.25 629.387 1325.68 629.332C1325.88 629.332 1325.96 629.275 1326.12 629.656C1326.19 629.84 1326.25 630.028 1326.3 630.219V630.219Z" fill="#239693"/>
            <path d="M1326.34 630.409C1326.13 630.544 1325.91 630.649 1325.67 630.724C1325.37 630.778 1325.08 630.797 1324.78 630.781C1324.54 630.912 1324.29 630.993 1324.02 631.02H1323.9C1324.41 630.82 1324.56 630.524 1324.95 630.505C1325.41 630.456 1325.86 630.356 1326.3 630.209L1326.34 630.409Z" fill="#239693"/>
            <path d="M1326.61 623.259C1325.9 623.331 1325.19 623.449 1324.5 623.612C1324.53 623.076 1324.45 622.54 1324.26 622.038C1324.92 622.094 1325.59 622.078 1326.25 621.991C1326.53 622.248 1326.67 622.42 1326.69 622.611C1326.69 622.829 1326.66 623.047 1326.61 623.259V623.259Z" fill="#239693"/>
            <path d="M1325.71 629.447C1325.63 629.55 1325.53 629.634 1325.41 629.692C1325.3 629.75 1325.17 629.78 1325.04 629.78C1324.82 629.724 1324.61 629.651 1324.41 629.561C1324.32 629.164 1324.16 628.788 1323.92 628.455C1323.86 628.341 1323.75 628.179 1323.62 627.979C1324.11 627.889 1324.57 627.686 1324.97 627.387C1325.3 628.341 1325.67 629.342 1325.71 629.447Z" fill="#239693"/>
            <path d="M1324.94 608.407C1324.91 608.493 1324.84 608.563 1324.76 608.608C1324.68 608.653 1324.59 608.67 1324.49 608.656C1324.55 608.441 1324.59 608.219 1324.59 607.996C1324.58 607.868 1324.54 607.743 1324.48 607.632C1324.57 607.632 1324.71 607.632 1324.76 607.632C1324.85 607.741 1324.91 607.866 1324.94 608C1324.97 608.134 1324.97 608.273 1324.94 608.407Z" fill="#239693"/>
            <path d="M1323.65 608.795C1323.56 608.991 1323.42 609.159 1323.25 609.282L1323.31 609.425C1323.22 609.494 1323.12 609.557 1323.02 609.615C1322.77 609.8 1322.49 609.938 1322.2 610.025H1321.82C1321.57 610.025 1321.32 609.873 1321.32 609.673C1321.32 609.472 1321.32 609.205 1321.38 609.024C1321.38 608.984 1321.38 608.944 1321.36 608.906C1321.35 608.868 1321.33 608.834 1321.3 608.805L1321.11 608.614C1321.09 608.596 1321.08 608.572 1321.08 608.547C1321.08 608.523 1321.09 608.499 1321.11 608.481L1321.45 608.109C1321.51 608.04 1321.54 607.951 1321.54 607.861C1321.53 607.619 1321.57 607.377 1321.67 607.156C1321.72 607.061 1321.8 606.98 1321.88 606.917C1321.93 607.002 1321.98 607.082 1322.04 607.156C1322.32 607.47 1322.82 607.823 1322.82 608.271C1322.82 608.719 1322.96 608.576 1323.03 608.5C1323.09 608.424 1323.32 608.233 1323.45 608.3C1323.53 608.351 1323.59 608.426 1323.63 608.515C1323.66 608.604 1323.67 608.702 1323.65 608.795V608.795Z" fill="#239693"/>
            <path d="M-189.423 609.558C-189.423 609.558 1.35688 695.051 185.329 695.051C281.386 695.051 272.51 676.936 329.534 676.936C386.558 676.936 459.904 705.434 518.149 705.434C576.393 705.434 649.721 684.278 707.984 684.278C766.248 684.278 984.476 742.017 984.476 742.017L-261.949 744.534L-189.423 609.558Z" fill="url(#paint4_linear_1015_18814)"/>
            <g style="mix-blend-mode:overlay" opacity="0.5">
                <path d="M1443.87 464.762C1444.05 464.992 1444.23 465.194 1444.39 465.386C1444.17 465.221 1444 465.007 1443.87 464.762V464.762Z" fill="#010101"/>
            </g>
            <path d="M-8.21564 598.06C-8.21564 598.06 13.3126 572.127 63.2909 572.127C113.269 572.127 97.1278 656.591 127.895 656.591C158.661 656.591 215.323 635.615 235.574 643.996C251.725 650.67 242.496 664.018 260.182 664.018C277.868 664.018 281.739 648.105 317.101 646.971C340.174 646.236 307.872 676.612 324.023 676.612C343.244 676.612 366.316 644.015 408.62 644.015C460.152 644.015 384.784 689.941 388.598 695.128C392.412 700.314 408.62 689.207 419.355 696.615C430.091 704.023 419.355 715.14 425.514 719.583C431.673 724.026 465.51 724.026 469.372 735.143C470.144 737.383 453.926 743.418 422.873 743.561C295.182 744.162 -12.0103 743.371 -12.0103 743.371L-8.21564 598.06Z" fill="#8EC897"/>
            <g style="mix-blend-mode:overlay" opacity="0.17">
                <path d="M937.11 610.254C941.148 610.254 944.422 609.588 944.422 608.767C944.422 607.945 941.148 607.279 937.11 607.279C933.071 607.279 929.797 607.945 929.797 608.767C929.797 609.588 933.071 610.254 937.11 610.254Z" fill="#010101"/>
            </g>
            <g style="mix-blend-mode:overlay" opacity="0.17">
                <path d="M908.317 605.764C912.355 605.764 915.629 605.098 915.629 604.276C915.629 603.455 912.355 602.789 908.317 602.789C904.278 602.789 901.004 603.455 901.004 604.276C901.004 605.098 904.278 605.764 908.317 605.764Z" fill="#010101"/>
            </g>
            <path d="M927.976 585.341C929.55 582.128 932.372 575.969 932.372 572.708C932.423 571.629 932.888 570.611 933.67 569.865C934.452 569.12 935.491 568.704 936.572 568.704C937.652 568.704 938.691 569.12 939.473 569.865C940.255 570.611 940.72 571.629 940.771 572.708C940.771 575.693 944.423 582.243 946.063 585.046C947.191 586.669 947.856 588.569 947.988 590.541C948.119 592.513 947.712 594.484 946.811 596.243C945.909 598.002 944.546 599.483 942.868 600.528C941.189 601.572 939.259 602.141 937.282 602.173C935.306 602.205 933.358 601.7 931.646 600.711C929.935 599.722 928.525 598.286 927.566 596.557C926.607 594.829 926.136 592.872 926.204 590.896C926.271 588.921 926.874 587.001 927.948 585.341H927.976Z" fill="#A4D1AA"/>
            <g style="mix-blend-mode:overlay" opacity="0.5">
                <path d="M932.2 574.31C932.21 575.374 932.156 576.437 932.038 577.495C931.475 582.853 930.798 583.473 930.179 586.59C929.559 589.708 929.035 590.804 930.122 594.361C931.275 598.098 932.753 599.719 935.337 600.396C937.62 601.046 940.019 601.186 942.363 600.806C940.234 601.978 937.775 602.404 935.377 602.018C932.978 601.632 930.777 600.455 929.123 598.674C927.47 596.894 926.459 594.612 926.251 592.191C926.044 589.77 926.651 587.349 927.976 585.313C929.321 582.586 931.561 577.704 932.2 574.31Z" fill="#A4D1AA"/>
            </g>
            <path d="M931.59 589.356L936.223 593.436L936.586 587.392C936.586 587.326 936.612 587.263 936.658 587.216C936.705 587.17 936.768 587.144 936.834 587.144C936.899 587.144 936.962 587.17 937.009 587.216C937.055 587.263 937.081 587.326 937.081 587.392L937.243 589.966L938.874 588.507C938.899 588.488 938.93 588.479 938.961 588.481C938.993 588.483 939.022 588.496 939.045 588.517C939.068 588.539 939.082 588.568 939.086 588.599C939.089 588.631 939.082 588.662 939.064 588.688L937.291 590.767L937.806 598.899L942.63 594.647C942.671 594.616 942.722 594.601 942.773 594.604C942.825 594.607 942.873 594.628 942.911 594.663C942.948 594.698 942.972 594.746 942.979 594.797C942.985 594.848 942.973 594.899 942.945 594.942L937.968 600.93L938.445 608.423H935.365L936.138 595.476L931.313 589.68C931.285 589.64 931.27 589.591 931.272 589.542C931.274 589.493 931.293 589.446 931.324 589.409C931.356 589.372 931.4 589.346 931.448 589.336C931.496 589.327 931.546 589.333 931.59 589.356V589.356Z" fill="#8DBB90"/>
            <path d="M899.174 581.204C900.757 578 903.569 571.832 903.569 568.58C903.569 567.465 904.012 566.396 904.801 565.607C905.589 564.819 906.659 564.376 907.774 564.376C908.889 564.376 909.958 564.819 910.747 565.607C911.535 566.396 911.978 567.465 911.978 568.58C911.978 571.565 915.62 578.114 917.26 580.918C918.383 582.541 919.044 584.438 919.173 586.408C919.301 588.378 918.893 590.345 917.99 592.101C917.088 593.856 915.725 595.333 914.049 596.375C912.372 597.416 910.444 597.983 908.471 598.014C906.497 598.045 904.552 597.54 902.843 596.552C901.135 595.564 899.726 594.13 898.769 592.404C897.811 590.678 897.341 588.724 897.407 586.752C897.473 584.779 898.074 582.861 899.145 581.204H899.174Z" fill="#A4D1AA"/>
            <g style="mix-blend-mode:overlay" opacity="0.5">
                <path d="M903.397 570.172C903.416 571.236 903.365 572.3 903.245 573.357C902.673 578.725 902.005 579.344 901.338 582.452C900.67 585.561 900.194 586.667 901.281 590.223C902.425 593.97 903.903 595.59 906.496 596.267C908.78 596.917 911.179 597.057 913.523 596.677C911.394 597.844 908.936 598.267 906.54 597.879C904.143 597.491 901.944 596.315 900.292 594.536C898.639 592.758 897.627 590.479 897.416 588.061C897.205 585.642 897.806 583.222 899.126 581.184C900.527 578.458 902.797 573.576 903.397 570.172Z" fill="#A4D1AA"/>
            </g>
            <path d="M902.797 585.208L907.43 589.288L907.792 583.244C907.792 583.178 907.818 583.115 907.865 583.069C907.911 583.022 907.974 582.996 908.04 582.996C908.106 582.996 908.169 583.022 908.215 583.069C908.262 583.115 908.288 583.178 908.288 583.244L908.45 585.818L910.071 584.35C910.195 584.245 910.376 584.417 910.262 584.541L908.517 586.638L909.032 594.761L913.856 590.518C913.897 590.487 913.948 590.472 913.999 590.475C914.051 590.478 914.099 590.499 914.137 590.534C914.174 590.57 914.198 590.617 914.204 590.668C914.21 590.719 914.198 590.771 914.17 590.814L909.184 596.801L909.661 604.304H906.591L907.363 591.357L902.53 585.561C902.483 585.525 902.452 585.473 902.444 585.415C902.436 585.357 902.451 585.298 902.487 585.251C902.522 585.204 902.575 585.173 902.633 585.165C902.691 585.157 902.75 585.173 902.797 585.208Z" fill="#8DBB90"/>
            <g style="mix-blend-mode:overlay" opacity="0.17">
                <path d="M1530.38 621.257C1534.71 621.257 1538.22 620.544 1538.22 619.665C1538.22 618.786 1534.71 618.073 1530.38 618.073C1526.04 618.073 1522.53 618.786 1522.53 619.665C1522.53 620.544 1526.04 621.257 1530.38 621.257Z" fill="#010101"/>
            </g>
            <path d="M1520.61 594.552C1522.3 591.119 1525.32 584.512 1525.32 581.022C1525.32 579.829 1525.8 578.684 1526.64 577.84C1527.48 576.996 1528.63 576.522 1529.82 576.522C1531.02 576.522 1532.16 576.996 1533 577.84C1533.85 578.684 1534.32 579.829 1534.32 581.022C1534.32 584.216 1538.23 591.243 1540.04 594.246C1541.25 595.985 1541.97 598.02 1542.11 600.133C1542.25 602.246 1541.81 604.358 1540.85 606.243C1539.88 608.127 1538.42 609.714 1536.62 610.832C1534.82 611.951 1532.76 612.559 1530.64 612.592C1528.52 612.626 1526.43 612.083 1524.6 611.021C1522.77 609.96 1521.26 608.42 1520.23 606.567C1519.21 604.714 1518.71 602.617 1518.78 600.5C1518.86 598.384 1519.51 596.328 1520.66 594.552H1520.61Z" fill="#A4D1AA"/>
            <g style="mix-blend-mode:overlay" opacity="0.5">
                <path d="M1525.1 582.729C1525.12 583.869 1525.07 585.009 1524.94 586.142C1524.33 591.863 1523.61 592.559 1522.94 595.896C1522.27 599.233 1521.72 600.415 1522.88 604.219C1524.11 608.233 1525.74 609.94 1528.48 610.693C1530.92 611.394 1533.49 611.544 1536 611.131C1534.28 612.085 1532.34 612.584 1530.38 612.581C1528.27 612.581 1526.21 612.013 1524.4 610.938C1522.59 609.863 1521.11 608.319 1520.11 606.471C1519.11 604.622 1518.62 602.537 1518.7 600.436C1518.79 598.335 1519.44 596.295 1520.58 594.532C1522.02 591.605 1524.43 586.371 1525.1 582.729Z" fill="#010101"/>
            </g>
            <path d="M1524.43 598.861L1529.39 603.237L1529.78 596.754C1529.79 596.693 1529.82 596.638 1529.87 596.598C1529.92 596.559 1529.98 596.537 1530.04 596.537C1530.1 596.537 1530.16 596.559 1530.21 596.598C1530.26 596.638 1530.29 596.693 1530.3 596.754L1530.48 599.509L1532.23 597.945C1532.25 597.925 1532.29 597.915 1532.32 597.917C1532.36 597.92 1532.39 597.934 1532.41 597.959C1532.44 597.983 1532.45 598.015 1532.45 598.05C1532.46 598.084 1532.45 598.118 1532.43 598.146L1530.52 600.377L1531.07 609.081L1536.24 604.534C1536.28 604.489 1536.34 604.464 1536.4 604.463C1536.46 604.462 1536.52 604.486 1536.57 604.529C1536.61 604.572 1536.64 604.631 1536.64 604.692C1536.64 604.754 1536.62 604.814 1536.57 604.858L1531.23 611.265L1531.74 619.302H1528.45L1529.27 605.42L1524.1 599.204C1524.06 599.161 1524.03 599.102 1524.03 599.039C1524.03 598.977 1524.05 598.916 1524.09 598.87C1524.14 598.825 1524.19 598.798 1524.26 598.796C1524.32 598.795 1524.38 598.818 1524.43 598.861V598.861Z" fill="#8DBB90"/>
            <g style="mix-blend-mode:overlay" opacity="0.17">
                <path d="M1576.23 630.266C1580.57 630.266 1584.08 629.553 1584.08 628.674C1584.08 627.795 1580.57 627.082 1576.23 627.082C1571.9 627.082 1568.39 627.795 1568.39 628.674C1568.39 629.553 1571.9 630.266 1576.23 630.266Z" fill="#010101"/>
            </g>
            <path d="M1566.44 603.561C1568.13 600.129 1571.15 593.512 1571.15 590.023C1571.21 588.864 1571.71 587.77 1572.54 586.969C1573.38 586.168 1574.5 585.72 1575.66 585.72C1576.82 585.72 1577.93 586.168 1578.77 586.969C1579.61 587.77 1580.11 588.864 1580.16 590.023C1580.16 593.217 1584.07 600.253 1585.83 603.247C1587.04 604.986 1587.76 607.022 1587.9 609.138C1588.05 611.254 1587.62 613.369 1586.65 615.257C1585.68 617.146 1584.22 618.736 1582.42 619.857C1580.62 620.979 1578.55 621.589 1576.43 621.624C1574.31 621.658 1572.22 621.115 1570.39 620.053C1568.55 618.99 1567.04 617.448 1566.01 615.592C1564.99 613.737 1564.48 611.636 1564.56 609.517C1564.64 607.398 1565.29 605.339 1566.44 603.561V603.561Z" fill="#A4D1AA"/>
            <g style="mix-blend-mode:overlay" opacity="0.5">
                <path d="M1570.96 591.739C1570.98 592.879 1570.93 594.019 1570.8 595.152C1570.19 600.872 1569.47 601.568 1568.81 604.896C1568.15 608.223 1567.58 609.415 1568.75 613.229C1569.97 617.243 1571.61 618.949 1574.34 619.702C1576.79 620.398 1579.36 620.548 1581.87 620.141C1579.59 621.392 1576.95 621.845 1574.38 621.429C1571.82 621.014 1569.46 619.752 1567.69 617.846C1565.92 615.939 1564.83 613.495 1564.61 610.903C1564.38 608.31 1565.03 605.716 1566.44 603.532C1567.88 600.605 1570.29 595.381 1570.96 591.739Z" fill="#010101"/>
            </g>
            <path d="M1570.32 607.871L1575.29 612.247L1575.67 605.764C1575.67 605.693 1575.7 605.625 1575.75 605.575C1575.8 605.525 1575.87 605.497 1575.94 605.497C1576.01 605.497 1576.08 605.525 1576.13 605.575C1576.18 605.625 1576.21 605.693 1576.21 605.764L1576.38 608.519L1578.12 606.956C1578.15 606.935 1578.18 606.925 1578.22 606.927C1578.25 606.93 1578.29 606.945 1578.31 606.969C1578.33 606.993 1578.35 607.025 1578.35 607.06C1578.35 607.094 1578.34 607.128 1578.32 607.156L1576.42 609.377L1576.97 618.092L1582.14 613.534C1582.16 613.512 1582.18 613.495 1582.21 613.483C1582.24 613.471 1582.27 613.464 1582.3 613.464C1582.33 613.463 1582.36 613.469 1582.39 613.48C1582.42 613.492 1582.44 613.508 1582.47 613.529C1582.49 613.551 1582.51 613.576 1582.52 613.604C1582.53 613.632 1582.54 613.662 1582.54 613.693C1582.54 613.724 1582.53 613.754 1582.52 613.782C1582.51 613.811 1582.49 613.837 1582.47 613.858L1577.13 620.275L1577.65 628.313H1574.35L1575.17 614.431L1570 608.224C1569.95 608.181 1569.93 608.121 1569.92 608.057C1569.92 607.994 1569.94 607.932 1569.99 607.885C1570.03 607.838 1570.09 607.811 1570.15 607.808C1570.22 607.805 1570.28 607.828 1570.32 607.871V607.871Z" fill="#8DBB90"/>
            <g style="mix-blend-mode:overlay" opacity="0.17">
                <path d="M1495.31 638.247C1499.64 638.247 1503.15 637.534 1503.15 636.655C1503.15 635.776 1499.64 635.063 1495.31 635.063C1490.98 635.063 1487.46 635.776 1487.46 636.655C1487.46 637.534 1490.98 638.247 1495.31 638.247Z" fill="#010101"/>
            </g>
            <path d="M1485.52 611.541C1487.21 608.109 1490.23 601.492 1490.23 598.012C1490.23 596.819 1490.7 595.674 1491.55 594.83C1492.39 593.986 1493.53 593.512 1494.73 593.512C1495.92 593.512 1497.07 593.986 1497.91 594.83C1498.75 595.674 1499.23 596.819 1499.23 598.012C1499.23 601.197 1503.14 608.233 1504.95 611.227C1505.99 612.732 1506.67 614.461 1506.92 616.276C1507.18 618.091 1507 619.94 1506.41 621.673C1505.81 623.407 1504.82 624.977 1503.51 626.256C1502.2 627.534 1500.6 628.487 1498.85 629.035C1497.11 629.584 1495.25 629.713 1493.45 629.413C1491.64 629.113 1489.93 628.392 1488.45 627.308C1486.97 626.224 1485.77 624.808 1484.94 623.174C1484.11 621.54 1483.68 619.733 1483.68 617.901C1483.66 615.647 1484.3 613.436 1485.52 611.541V611.541Z" fill="#A4D1AA"/>
            <g style="mix-blend-mode:overlay" opacity="0.5">
                <path d="M1490.04 599.719C1490.05 600.859 1490 601.999 1489.87 603.132C1489.26 608.853 1488.54 609.549 1487.87 612.876C1487.21 616.203 1486.65 617.395 1487.82 621.209C1489.05 625.223 1490.68 626.929 1493.41 627.683C1495.86 628.378 1498.43 628.528 1500.93 628.121C1498.65 629.377 1496.02 629.834 1493.45 629.42C1490.87 629.006 1488.51 627.744 1486.74 625.835C1484.97 623.926 1483.89 621.48 1483.67 618.885C1483.44 616.289 1484.1 613.695 1485.52 611.513C1486.96 608.586 1489.36 603.323 1490.04 599.719Z" fill="#010101"/>
            </g>
            <path d="M1489.39 615.851L1494.36 620.227L1494.75 613.744C1494.76 613.683 1494.79 613.628 1494.84 613.589C1494.89 613.549 1494.95 613.527 1495.01 613.527C1495.07 613.527 1495.13 613.549 1495.18 613.589C1495.23 613.628 1495.26 613.683 1495.27 613.744L1495.45 616.499L1497.2 614.936C1497.23 614.915 1497.26 614.905 1497.29 614.908C1497.33 614.91 1497.36 614.925 1497.38 614.949C1497.41 614.974 1497.42 615.006 1497.43 615.04C1497.43 615.074 1497.42 615.109 1497.4 615.136L1495.49 617.358L1496.04 626.072L1501.22 621.514C1501.26 621.47 1501.32 621.445 1501.38 621.444C1501.45 621.443 1501.51 621.467 1501.55 621.51C1501.59 621.553 1501.62 621.611 1501.62 621.673C1501.62 621.735 1501.6 621.794 1501.55 621.839L1496.22 628.255L1496.72 636.292H1493.43L1494.25 622.411L1489.07 616.204C1489.03 616.162 1489 616.104 1489 616.041C1488.99 615.978 1489.01 615.917 1489.06 615.87C1489.1 615.823 1489.16 615.795 1489.22 615.792C1489.28 615.788 1489.34 615.809 1489.39 615.851Z" fill="#8DBB90"/>
            <g style="mix-blend-mode:overlay" opacity="0.17">
                <path d="M1672.96 608.157C1676.36 608.157 1679.11 607.598 1679.11 606.908C1679.11 606.218 1676.36 605.659 1672.96 605.659C1669.56 605.659 1666.81 606.218 1666.81 606.908C1666.81 607.598 1669.56 608.157 1672.96 608.157Z" fill="#010101"/>
            </g>
            <path d="M1665.29 587.229C1666.61 584.54 1668.97 579.363 1668.97 576.627C1668.97 575.691 1669.35 574.794 1670.01 574.133C1670.67 573.471 1671.57 573.099 1672.5 573.099C1673.44 573.099 1674.34 573.471 1675 574.133C1675.66 574.794 1676.03 575.691 1676.03 576.627C1676.03 579.125 1679.09 584.636 1680.47 586.991C1681.42 588.353 1681.98 589.948 1682.09 591.604C1682.2 593.26 1681.86 594.915 1681.1 596.392C1680.35 597.869 1679.2 599.113 1677.79 599.989C1676.38 600.866 1674.76 601.343 1673.1 601.369C1671.44 601.395 1669.81 600.969 1668.37 600.137C1666.94 599.305 1665.75 598.099 1664.95 596.646C1664.15 595.193 1663.75 593.55 1663.81 591.891C1663.87 590.232 1664.38 588.621 1665.29 587.229V587.229Z" fill="#A4D1AA"/>
            <g style="mix-blend-mode:overlay" opacity="0.5">
                <path d="M1668.82 577.962C1668.84 578.856 1668.8 579.751 1668.7 580.641C1668.22 585.15 1667.65 585.665 1667.13 588.268C1666.62 590.871 1666.18 591.815 1667.13 594.799C1668.09 597.946 1669.34 599.299 1671.51 599.872C1673.43 600.42 1675.44 600.537 1677.41 600.215C1675.62 601.198 1673.56 601.555 1671.55 601.23C1669.53 600.905 1667.68 599.916 1666.3 598.421C1664.91 596.925 1664.06 595.009 1663.88 592.976C1663.71 590.943 1664.22 588.91 1665.33 587.2C1666.41 584.922 1668.3 580.822 1668.82 577.962Z" fill="#010101"/>
            </g>
            <path d="M1668.32 590.604L1672.22 594.036L1672.51 588.955C1672.51 588.899 1672.53 588.846 1672.57 588.807C1672.61 588.767 1672.67 588.745 1672.72 588.745C1672.78 588.745 1672.83 588.767 1672.87 588.807C1672.91 588.846 1672.93 588.899 1672.93 588.955L1673.06 591.119L1674.44 589.889C1674.46 589.869 1674.48 589.858 1674.51 589.858C1674.54 589.858 1674.57 589.869 1674.59 589.889C1674.61 589.909 1674.62 589.937 1674.62 589.965C1674.62 589.994 1674.61 590.021 1674.59 590.042L1673.11 591.796L1673.54 598.622L1677.59 595.047C1677.63 595.024 1677.67 595.014 1677.71 595.018C1677.76 595.022 1677.8 595.041 1677.83 595.07C1677.86 595.1 1677.88 595.139 1677.88 595.182C1677.89 595.224 1677.88 595.267 1677.86 595.304L1673.67 600.329L1674.07 606.621H1671.49L1672.14 595.752L1668.09 590.881C1668.06 590.848 1668.04 590.807 1668.04 590.763C1668.04 590.719 1668.05 590.676 1668.08 590.643C1668.11 590.609 1668.15 590.587 1668.19 590.579C1668.23 590.572 1668.28 590.581 1668.32 590.604V590.604Z" fill="#8DBB90"/>
            <g style="mix-blend-mode:overlay" opacity="0.17">
                <path d="M1145.38 624.441C1149.42 624.441 1152.7 623.775 1152.7 622.953C1152.7 622.132 1149.42 621.466 1145.38 621.466C1141.35 621.466 1138.07 622.132 1138.07 622.953C1138.07 623.775 1141.35 624.441 1145.38 624.441Z" fill="#010101"/>
            </g>
            <path d="M1136.24 599.509C1137.82 596.306 1140.64 590.137 1140.64 586.877C1140.69 585.797 1141.15 584.779 1141.94 584.034C1142.72 583.288 1143.76 582.872 1144.84 582.872C1145.92 582.872 1146.96 583.288 1147.74 584.034C1148.52 584.779 1148.99 585.797 1149.04 586.877C1149.04 589.861 1152.69 596.411 1154.33 599.214C1155.45 600.836 1156.11 602.734 1156.24 604.703C1156.37 606.673 1155.97 608.641 1155.06 610.396C1154.16 612.152 1152.8 613.63 1151.12 614.673C1149.45 615.715 1147.52 616.283 1145.55 616.315C1143.57 616.347 1141.63 615.843 1139.92 614.855C1138.21 613.868 1136.8 612.435 1135.84 610.71C1134.88 608.984 1134.41 607.031 1134.48 605.058C1134.54 603.086 1135.14 601.168 1136.21 599.509H1136.24Z" fill="#A4D1AA"/>
            <g style="mix-blend-mode:overlay" opacity="0.5">
                <path d="M1140.46 588.498C1140.48 589.561 1140.43 590.625 1140.31 591.682C1139.74 597.04 1139.07 597.66 1138.41 600.778C1137.74 603.895 1137.27 604.992 1138.36 608.548C1139.5 612.295 1140.98 613.906 1143.57 614.583C1145.85 615.231 1148.25 615.371 1150.59 614.993C1148.46 616.155 1146.01 616.575 1143.61 616.185C1141.22 615.795 1139.02 614.619 1137.37 612.841C1135.72 611.064 1134.71 608.787 1134.5 606.371C1134.29 603.955 1134.89 601.537 1136.2 599.5C1137.59 596.773 1139.83 591.882 1140.46 588.498Z" fill="#A4D1AA"/>
            </g>
            <path d="M1139.86 603.543L1144.5 607.623L1144.85 601.579C1144.85 601.513 1144.88 601.45 1144.92 601.403C1144.97 601.357 1145.03 601.331 1145.1 601.331C1145.16 601.331 1145.23 601.357 1145.27 601.403C1145.32 601.45 1145.35 601.513 1145.35 601.579L1145.51 604.153L1147.14 602.694C1147.16 602.675 1147.2 602.666 1147.23 602.668C1147.26 602.67 1147.29 602.683 1147.31 602.704C1147.33 602.726 1147.35 602.755 1147.35 602.786C1147.36 602.818 1147.35 602.849 1147.33 602.875L1145.57 604.954L1146.08 613.086L1150.91 608.834C1150.95 608.803 1151 608.788 1151.05 608.791C1151.1 608.794 1151.15 608.815 1151.19 608.85C1151.22 608.885 1151.25 608.933 1151.25 608.984C1151.26 609.035 1151.25 609.086 1151.22 609.13L1146.23 615.117L1146.71 622.611H1143.64L1144.4 609.664L1139.58 603.867C1139.55 603.826 1139.53 603.776 1139.53 603.724C1139.53 603.673 1139.55 603.623 1139.58 603.584C1139.62 603.545 1139.67 603.52 1139.72 603.512C1139.77 603.505 1139.82 603.515 1139.86 603.543V603.543Z" fill="#8DBB90"/>
            <g style="mix-blend-mode:overlay" opacity="0.17">
                <path d="M1260.78 614.525C1264.82 614.525 1268.09 613.86 1268.09 613.038C1268.09 612.217 1264.82 611.551 1260.78 611.551C1256.74 611.551 1253.46 612.217 1253.46 613.038C1253.46 613.86 1256.74 614.525 1260.78 614.525Z" fill="#010101"/>
            </g>
            <path d="M1251.63 589.612C1253.22 586.409 1256.03 580.24 1256.03 576.98C1256.03 575.865 1256.47 574.795 1257.26 574.007C1258.05 573.218 1259.12 572.775 1260.23 572.775C1261.35 572.775 1262.42 573.218 1263.21 574.007C1264 574.795 1264.44 575.865 1264.44 576.98C1264.44 579.964 1268.08 586.514 1269.72 589.317C1270.84 590.94 1271.51 592.837 1271.64 594.806C1271.77 596.776 1271.36 598.744 1270.46 600.499C1269.55 602.255 1268.19 603.733 1266.52 604.776C1264.84 605.818 1262.91 606.386 1260.94 606.418C1258.97 606.45 1257.02 605.946 1255.31 604.958C1253.6 603.971 1252.19 602.538 1251.24 600.813C1250.28 599.087 1249.8 597.134 1249.87 595.161C1249.94 593.189 1250.54 591.271 1251.61 589.612H1251.63Z" fill="#A4D1AA"/>
            <g style="mix-blend-mode:overlay" opacity="0.5">
                <path d="M1255.86 578.581C1255.87 579.645 1255.82 580.708 1255.71 581.766C1255.13 587.124 1254.47 587.753 1253.8 590.861C1253.13 593.97 1252.66 595.076 1253.8 598.632C1254.94 602.379 1256.42 603.99 1259.01 604.667C1261.29 605.315 1263.69 605.455 1266.03 605.077C1263.9 606.239 1261.45 606.659 1259.05 606.269C1256.66 605.879 1254.46 604.703 1252.81 602.925C1251.16 601.148 1250.15 598.871 1249.94 596.455C1249.73 594.039 1250.33 591.621 1251.64 589.584C1252.98 586.857 1255.22 581.976 1255.86 578.581Z" fill="#A4D1AA"/>
            </g>
            <path d="M1255.26 593.627L1259.89 597.707L1260.25 591.663C1260.26 591.606 1260.29 591.556 1260.34 591.519C1260.38 591.483 1260.44 591.463 1260.5 591.463C1260.55 591.463 1260.61 591.483 1260.65 591.519C1260.7 591.556 1260.73 591.606 1260.74 591.663L1260.91 594.237L1262.53 592.778C1262.56 592.759 1262.59 592.75 1262.62 592.752C1262.65 592.754 1262.68 592.767 1262.7 592.788C1262.73 592.81 1262.74 592.839 1262.74 592.87C1262.75 592.901 1262.74 592.933 1262.72 592.959L1260.96 595.038L1261.47 603.171L1266.3 598.918C1266.34 598.879 1266.39 598.857 1266.45 598.857C1266.51 598.858 1266.56 598.88 1266.6 598.919C1266.64 598.959 1266.67 599.012 1266.67 599.069C1266.67 599.126 1266.65 599.181 1266.61 599.223L1261.63 605.201L1262.1 612.695H1259.03L1259.79 599.748L1254.97 593.951C1254.94 593.911 1254.92 593.86 1254.92 593.808C1254.92 593.756 1254.94 593.707 1254.98 593.668C1255.01 593.629 1255.06 593.604 1255.11 593.596C1255.16 593.589 1255.21 593.599 1255.26 593.627Z" fill="#8DBB90"/>
          </g>
      </g>
      <defs>
          <linearGradient id="paint0_linear_1015_18814" x1="959.008" y1="173.302" x2="959.008" y2="994.086" gradientUnits="userSpaceOnUse">
            <stop offset="0.13" stop-color="#05A2DC" stop-opacity="0.2"/>
            <stop offset="0.26" stop-color="#09A1DB" stop-opacity="0.34"/>
            <stop offset="0.54" stop-color="#139FDA" stop-opacity="0.7"/>
            <stop offset="0.76" stop-color="#1B9DD9"/>
          </linearGradient>
          <linearGradient id="paint1_linear_1015_18814" x1="944.127" y1="603.552" x2="944.127" y2="732.349" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BAD388"/>
            <stop offset="0.46" stop-color="#87C077"/>
            <stop offset="0.81" stop-color="#67B46C"/>
            <stop offset="1" stop-color="#5BB068"/>
          </linearGradient>
          <linearGradient id="paint2_linear_1015_18814" x1="2.32452e+06" y1="190637" x2="2.32452e+06" y2="202699" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BAD388"/>
            <stop offset="0.46" stop-color="#87C077"/>
            <stop offset="0.81" stop-color="#67B46C"/>
            <stop offset="1" stop-color="#5BB068"/>
          </linearGradient>
          <linearGradient id="paint3_linear_1015_18814" x1="2.29847e+06" y1="250648" x2="2.29847e+06" y2="291340" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BAD388"/>
            <stop offset="0.46" stop-color="#87C077"/>
            <stop offset="0.81" stop-color="#67B46C"/>
            <stop offset="1" stop-color="#5BB068"/>
          </linearGradient>
          <linearGradient id="paint4_linear_1015_18814" x1="474626" y1="185450" x2="474626" y2="214794" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BAD388"/>
            <stop offset="0.46" stop-color="#87C077"/>
            <stop offset="0.81" stop-color="#67B46C"/>
            <stop offset="1" stop-color="#5BB068"/>
          </linearGradient>
          <clipPath id="clip0_1015_18814">
            <rect width="1922" height="736" fill="white" transform="translate(-2)"/>
          </clipPath>
      </defs>
    </svg>

  </div>
</template>

<script>
export default {
  name: "FooterIllustration",
};
</script>

<style type="text/css">
          .cls-1, .cls-39 {
          fill: none;
        }

        .cls-2 {
          isolation: isolate;
        }

        .cls-3 {
          clip-path: url(#clip-path);
        }

        .cls-4 {
          fill: #fff;
        }

        .cls-5 {
          fill: #fefefe;
        }

        .cls-6 {
          fill: #fdfdfd;
        }

        .cls-7 {
          fill: #fcfcfc;
        }

        .cls-8 {
          fill: #fbfbfb;
        }

        .cls-9 {
          fill: #fafafa;
        }

        .cls-10 {
          fill: #f9f9f9;
        }

        .cls-11 {
          fill: #f8f8f8;
        }

        .cls-12 {
          fill: #f8f7f8;
        }

        .cls-13 {
          fill: #f7f6f7;
        }

        .cls-14 {
          fill: #f6f5f6;
        }

        .cls-15 {
          fill: #f5f5f5;
        }

        .cls-16 {
          fill: #f4f4f4;
        }

        .cls-17 {
          fill: #f3f3f3;
        }

        .cls-18 {
          fill: #f2f2f2;
        }

        .cls-19 {
          fill: #f1f1f1;
        }

        .cls-20 {
          fill: #f1f0f0;
        }

        .cls-21 {
          fill: #f0eff0;
        }

        .cls-22 {
          fill: #efeeef;
        }

        .cls-23 {
          fill: #eeedee;
        }

        .cls-24 {
          fill: #ededed;
        }

        .cls-25 {
          fill: #ececec;
        }

        .cls-26 {
          fill: #ebebeb;
        }

        .cls-27 {
          fill: #eaeaea;
        }

        .cls-28 {
          fill: #e9e9e9;
        }

        .cls-29 {
          fill: #e9e8e8;
        }

        .cls-30 {
          fill: #e8e7e7;
        }

        .cls-31 {
          fill: #e7e6e6;
        }

        .cls-32 {
          clip-path: url(#clip-path-2);
        }

        .cls-33 {
          fill: #a5d7da;
          opacity: 0.55;
        }

        .cls-34, .cls-60 {
          fill: #76c7c9;
        }

        .cls-34, .cls-38 {
          opacity: 0.75;
        }

        .cls-35 {
          fill: #74bfbc;
        }

        .cls-36 {
          fill: #189390;
        }

        .cls-37 {
          fill: url(#linear-gradient);
        }

        .cls-38 {
          fill: #47466c;
        }

        .cls-39 {
          stroke: #fef9d4;
          stroke-miterlimit: 10;
        }

        .cls-40 {
          fill: #e2e0b5;
        }

        .cls-41 {
          fill: #f4f3d3;
        }

        .cls-42 {
          fill: #239693;
        }

        .cls-43, .cls-47, .cls-51, .cls-52, .cls-54, .cls-56 {
          fill: #010101;
        }

        .cls-43 {
          opacity: 0.17;
        }

        .cls-43, .cls-45, .cls-47, .cls-51, .cls-52 {
          mix-blend-mode: overlay;
        }

        .cls-44, .cls-45 {
          fill: #a4d1aa;
        }

        .cls-45, .cls-47 {
          opacity: 0.5;
        }

        .cls-46 {
          fill: #8dbb90;
        }

        .cls-48 {
          clip-path: url(#clip-path-3);
        }

        .cls-49 {
          fill: url(#linear-gradient-2);
        }

        .cls-50 {
          fill: #69b2af;
          opacity: 0.94;
        }

        .cls-51, .cls-54 {
          opacity: 0.2;
        }

        .cls-52 {
          opacity: 0.35;
        }

        .cls-53 {
          fill: #918fa6;
        }

        .cls-54, .cls-56 {
          mix-blend-mode: multiply;
        }

        .cls-55 {
          fill: url(#linear-gradient-3);
        }

        .cls-56 {
          opacity: 0.05;
        }

        .cls-57 {
          fill: #cdcccc;
        }

        .cls-58 {
          fill: url(#linear-gradient-4);
        }

        .cls-59 {
          fill: #f2979a;
        }

        .cls-61 {
          fill: #f4ef9a;
        }
</style>