<template>
  <div class="data-protection-comp">
    <div>
      <p class="DP-title">{{ title }}</p>
    </div>
    <b-row>
       <b-col>
          <input type="checkbox" v-model="dataProtection" />
          <span class="checkmark"></span>
          <p class="DP-text" v-html="text"></p>
       </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "DataProtection",
  data() {
    return {
      dataProtection: false,
    };
  },
  beforeMount() {
    this.dataProtection = this.currentInput;
  },
  props: {
    title: {
      type: String,
      default: "Data protection",
    },
    text: {
      type: String,
      default: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    currentInput: {
      type: Object,
      default() {
        return false;
      },
    },
  },
};
</script>

<style lang="scss">
.data-protection-comp {
  margin: 32px 0;
   .DP-text{
        display: inline-block;
    margin-left: 1rem;
  }
  .DP-title {
    color: #27606c;
    font-weight: bold;
  }

  //   input {
  //     position: absolute;
  //     opacity: 0;
  //     cursor: pointer;
  //     height: 0;
  //     width: 0;
  //   }

  //   .checkmark {
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     height: 25px;
  //     width: 25px;
  //     background-color: blue;
  //   }
}
</style>