<template>

  <div class="mainContentThankYou">
    <div class="thankYou">

      <h4>{{ titleText }}</h4>
      <br>
      <p
          v-for="(text, index) in message"
          :key="`${$route.name}_thankyoumessage_${index}`"
          v-html="text"
      >
        {{ text }}
      </p>


    </div>
  </div>
</template>

<script>

export default {
  name: "ThankYouMessage",
  props: {
    message: {
      type: Array,
      default() {
        return ["Thank You"];
      },
    },
    titleText: {
      type: String,
      default: "",
    },
    btn: {
      type: Boolean,
      default: true,
    },
    btnText: {
      type: String,
      default: "Route",
    },
    route: {
      type: String,
      default: "/",
    },
  },
};
</script>

<style lang="scss">
@import '/src/Variables';

.mainContentThankYou{
  max-width: 1012px;

  @media screen and (max-width: 1140px) {
    margin: 5% 5% auto 0%;
  }

  @media screen and (max-width: 768px) {
    margin: 5% 5% auto 2%;
  }

  .thankYou {

    a {

      &:hover {
        text-decoration: underline;
      }
    }

    .logoGcc{
      width: 182px;
      height: 36px;
      margin-top: 15px;
    }

    .logoHE{
      width: 167px;
      height: 70px;
      margin-left: 15px;
    }
  }
}


</style>