<template>
  <div class="header-comp">
    <b-navbar v-if="navbar" toggleable="lg" type="dark" class="header-navbar">

      <a href="https://www.gloucestershire.gov.uk/highways/major-projects-list/m5-junction-10-improvements-scheme/" target="_blank">
        <img src="@/assets/images/logos/logo.png" style="height: 40px; width: 280px" alt="Gloucestershire County Council logo."/>
      </a>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" class="nav-collapse" is-nav>
        <b-navbar-nav v-if="navbar.pages" class="ml-auto nav-bar-nav">
          <b-nav-item v-for="(nav, index) in navbar.pages"
              :key="`${$route.name}_nav_${index}`"
              :to="nav.route"
              class="px-2"
          >
            <div v-if="!nav.button" :class="nav.name === $route.name ? 'active' : ''">
              {{ nav.nav }}
            </div>
            <button v-else-if="nav.buttonTransparent" class="header-button button-primary button-white-on-blue">
              <a>{{ nav.nav }}<span class="chevron"><i class="fas fa-chevron-right"></i></span></a>
            </button>
              <button v-else class="header-button button-primary button-white-on-blue">
                <a>{{ nav.nav }}<span class="chevron"><i class="fas fa-chevron-right"></i></span></a>
              </button>
          </b-nav-item>

          <LanguageSelector/>

        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import LanguageSelector from "./LanguageSelector";

export default
{
  name: "Header",
  components: {
    LanguageSelector,
  },
  props: {
    navbar: {
      type: Object,
      default()
      {
        return {
          title: "Navbar",
          home: {nav: "Home", route: "/"},
        };
      },
    },
  },
  methods:
  {
  },
};
</script>

<style lang="scss">
@import "../Variables";
.header-comp
{

  background-color: $Gcc-Blue;
  @media screen and (max-width: 813px) {
     padding: 15px 0;
  }

  .header-navbar
  {
    margin-left: 4vw;
    margin-right: 4vw;
    height: 100px;
    @media screen and (max-width: 813px) {
     height: auto;
  }
  }

  .nav-bar-nav
  {
    margin-top: 3%;
    margin-bottom: 3%;

  }

  .nav-collapse
  {
    z-index: 100;

    @media screen and (max-width: 813px) {
      margin-left: -4vw;
      margin-right: -4vw;
      background-color: $Gcc-DarkBlue;
      position: relative;
      top: 25px;
      padding: 0 20px;
    }

    &.show
    {
      margin-left: -4vw;
      margin-right: -4vw;
      background-color: $Gcc-DarkBlue;
      position: relative;
      top: 25px;
      padding: 0 20px;
    }
  }

  img
  {
    max-width: 200px;
    max-height: 100%;
  }

  .nav-link {
     border-bottom: 3px solid transparent;

     &:focus {
       outline: 1px solid #ffffff;

        .active {
          font-weight: bold;
          border-bottom: 3px solid #fff;
        }
     }

     .active {
      font-weight: bold;
      border-bottom: 3px solid #fff;
    }
  }

  .navbar-collapse {
    ul
    {
      li
      {
        list-style-type: none !important;
        list-style-image: none;
        align-self: center;

        @media screen and (max-width: 1000px)
        {
          align-self: auto;
        }
      }
    }
  }

  .navbar-toggler
  {
    margin: 0px;
    color: white;
    border-color: white !important;
    border-radius: 50% !important;
    width: 45px !important;
    height: 45px !important;

    @media screen and (max-width: 500px) {
      //padding: 0;
      padding-bottom: 2px;
      //width: 35px !important;
      //height: 35px !important;
    }

    .navbar-toggler-icon {
      color: white;
      width: 100%;
      height: 100%;
     // position: center;
    }
  }

  .header-button
  {

    margin-right: auto;
    display:inline-block;


    @media screen and (max-width: 1000px)
    {
      width: 335px;
      height: 44px;
    }

    @media screen and (max-width: 425px)
    {
      width: 335px;
    }
  }

}
</style>