<template>
  <div id="NotFound">
    <section class="MainHeadFoot-temp">
      <div
        class="bannerImage"
        :style="{
          backgroundImage: `url('${bannerData.img}')`,
        }"
      >
        <div class="bannerColor">
          <Banner :main="true">
            <div class="bannerIllustration">
              <BannerIllustration />
            </div>
            <b-container>
              <Header :navbar="headerData" :currentLanguage="currentLanguage">
                <div class="project-logo">
                </div>
              </Header>
              <div class="mainBannerTitle">
                <TitleText
                  :title="bannerData.title"
                  :text="bannerData.text"
                  :button="true"
                  :buttonText="bannerData.button"
                  :link="'/'"
                  :main="true"
                />
              </div>
            </b-container>
          </Banner>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Banner from "../components/Banner";
import Header from "../components/Header";
import TitleText from "../components/TitleText";
import BannerIllustration from "../components/Project/M5J10/BannerIllustration";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "NotFound",
  components: {
    Banner,
    Header,
    TitleText,
    BannerIllustration,
  },
  computed: {
    ...mapGetters(["language", "content"]),
    currentLanguage() {
      const vm = this;
      if (this.language) {
        return this.language;
      } else return "english";
    },
    headerData() {
      const vm = this;
      if (this.content && this.content.headerData) {
        return this.content.headerData;
      } else return {};
    },
    footerData() {
      const vm = this;
      if (this.content && this.content.footerData) {
        return this.content.footerData;
      } else return {};
    },
    bannerData() {
      const vm = this;
      if (
        this.content &&
        this.content.NotFound &&
        this.content.NotFound.banner
      ) {
        return this.content.NotFound.banner;
      } else return {};
    },
  },
  methods: {
    ...mapActions(["toggleLanguage"]),
    toggleLang() {
      this.toggleLanguage();
    },
  },
};
</script>

<style lang="scss">
// #NotFound {
//   .mainBannerTitle {
//     p {
//       font-size: 24px;
//     }
//   }
// }
</style>