<template>
  <div class="banner-comp">
    <div :class="data.largeBanner ? 'main' : 'page'">
      <img :src="data.img" alt="decorative image"/>
        <div class="BannerContent">
            <h1 v-if="data.title">{{ data.title }}</h1>

            <p v-if="data.text"  v-html="data.text">
              {{ data.text }}
            </p>


          <button class="banner-Button button-primary button-homepage" v-if="data.buttonText && data.link" @click="GoToPage(data.link)">
                {{ data.buttonText }} <span class="chevron"><i class="fas fa-chevron-right"></i></span>
            </button>

          </div>
    </div>
  </div>
</template>

<script>
import TitleTextFullWidth from "../components/TitleTextFullWidth";
import mixin from "../mixins";

export default {
  name: "Banner",
  mixins:[mixin],
  components: {
    TitleTextFullWidth
  },
  props: {
    main: {
      type: Boolean,
      default: false,
    },
    data: Object,
    Background: String,
  },
};
</script>

<style lang="scss">
@import "../Variables";
.banner-comp {
  overflow: hidden;
  position: relative;
  margin-bottom: 5vh;

  .main {
    width: 100%;
    height: 100%;

    .BannerContent {
      top: 13%;
    }

    img {
      
      @media screen and (min-width: 1024px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 768px) {
      height: 744px;
    }
  }

  .page {
    width: 100%;
    height: 100%;

    .BannerContent {
      top: 23%;
    }

    img {
      
      @media screen and (min-width: 1024px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 768px) {
      height: 250px;
      padding-top: 0;
      
    }
  }

  /* Bottom right text */
  .BannerContent
  {
    position: absolute;
    left: 13%;
    width: 90%;
    color: white;

    @media screen and (min-width: 375px) {
      width: 75%;
    }

    @media screen and (min-width: 768px) {
      width: 65%;
    }

    @media screen and (min-width: 992px) {
      width: 50%;
    }

    h1
    {
      color: $Gcc-Blue;
      font-size: 24px;
 
      margin-bottom: 24px;

      @media screen and (min-width: 500px) {
         font-size: 32px;
        line-height: 48px;
      }

      @media screen and (min-width: 1025px) {
          font-size: 56px;
          line-height: 60px;
      }
    }

    p
    {
      color: $Gcc-Blue;
      margin-bottom: 32px;
      font-size: 18px;
      font-weight: bold;

      @media screen and (max-width: 500px) {
        font-size: 16px;
      }

      @media screen and (max-width: 769px) {
        font-size: 16px;
      }
    }

    .banner-Button
    {
      @media screen and (max-width: 370px)
      {
        width: 200px;
      }
    }
  }
}
</style>