import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyD_TmbCm024eQZuMvvWDdqWCGQvyR3MgQk",
    authDomain: "m5j10webportal.firebaseapp.com",
    projectId: "m5j10webportal",
    storageBucket: "m5j10webportal.appspot.com",
    messagingSenderId: "1054980016555",
    appId: "1:1054980016555:web:f5f62e85a08aba868b6aee",
    measurementId: "G-L9RZTWGFYT"
};


const app = firebase.initializeApp(firebaseConfig);

const db = app.firestore();

export default db;
