<template>
  <div class="chevrons">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.63 53.18">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1" :class="isFaded()">
          <polygon
            :class="`cls-${svgFill}`"
            points="37.24 2.69 0 39.93 0 45.3 39.93 5.37 37.24 2.69"
          />
          <polygon
            :class="`cls-${svgFill}`"
            points="37.24 22.25 6.32 53.18 11.69 53.18 39.93 24.94 37.24 22.25"
          />
          <polygon
            :class="`cls-${svgFill}`"
            points="37.24 41.82 25.89 53.18 31.26 53.18 39.93 44.51 37.24 41.82"
          />
          <polygon
            :class="`cls-${svgFill}`"
            points="39.93 5.37 79.63 45.07 79.63 39.7 42.62 2.69 39.93 5.37"
          />
          <rect
            :class="`cls-${svgFill}`"
            x="38.03"
            y="0.79"
            width="3.8"
            height="3.8"
            transform="translate(9.79 29.02) rotate(-44.99)"
          />
          <polygon
            :class="`cls-${svgFill}`"
            points="39.93 24.94 68.17 53.18 73.54 53.18 42.62 22.25 39.93 24.94"
          />
          <rect
            :class="`cls-${svgFill}`"
            x="38.03"
            y="20.35"
            width="3.8"
            height="3.8"
            transform="translate(-4.04 34.75) rotate(-45)"
          />
          <polygon
            :class="`cls-${svgFill}`"
            points="39.93 44.51 48.6 53.18 53.98 53.18 42.62 41.82 39.93 44.51"
          />
          <rect
            :class="`cls-${svgFill}`"
            x="38.03"
            y="39.92"
            width="3.8"
            height="3.8"
            transform="translate(-17.88 40.49) rotate(-45)"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "Chevrons",

  props: {
    svgFill: {
      type: Number,
      default: 1,
    },
    faded: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    isFaded() {
      if (this.faded) {
        return "faded";
      }
    },
  },
};
</script>
