<template>
  <div class="site-view" >
    <section class="site-banner blueBanner">
       <b-container class="mainContent">
            <b-row>
              <b-col cols="12" md="12" lg="7">
                  <div>
                      <h2>View the latest visualisations for the M5 Junction 10 improvements scheme</h2> 
                      <button @click="GoToPage('portal-menu')" class="button-primary" title="Go to the visualisation portal">Go to the virtual information portal</button>
                    </div>
              </b-col>
            </b-row>
       </b-container>
    </section>
   
    <section class="site-timeline homepage">
       <b-container class="mainContent">
            <b-row>
              <b-col cols="12" md="12" lg="8">
                  <div>
                        <h2>What happens next?</h2>
                        <p>Your feedback is important to us in shaping our proposals before we submit our Development Consent Order (DCO) application. This is your opportunity to give your feedback and we will consider all the comments we receive during the statutory public consultation as we develop our proposals further.</p>
                        <p>A DCO is required for any Nationally Significant Infrastructure Project (NSIP), of which the scheme is categorised under the Planning Act 2008.</p>
                        <p>You can view our next steps below.</p>
                    </div>
              </b-col>

               <b-col cols="12" md="12" lg="10">
                  <div>
                      <HomePageTimeline/>
                  </div>
              </b-col>
            </b-row>
       </b-container>
    </section>
    
    <section
      class="site-bannerbox homePage"
      :style="{ backgroundImage: `url(${backgroundUrl2})` }"
    >
     <b-container class="mainContent">
          <div class="row">
            <div class="col s12 m12 l6">
              <div class="box">
                <h2>Can’t find what you’re looking for? </h2>
                <p>All of our consultation material can be found in
                  <a href="#/en/supporting-documents">the supporting documents tab. </a>
                  If you would like to request hard copies of our consultation material via the post please
                  fill out the form below detailing which documents you would like.
                  Please note that due to its size, a charge may apply for a paper copy of the PEIR.
                  You can also email <a href="mailto:M5Junction10@atkinsglobal.com">M5Junction10@atkinsglobal.com</a>
                  with your query or call <a href="callto:014154667900">01454 667900.</a>
                  We will only use this information for your query and your personal data will not be used for any other purpose. </p>

                <HomePageForm/>
              </div>
            </div>
          </div>
     </b-container>
    </section>
    
  </div>
</template>

<script>

import {  mapGetters } from "vuex";

import backgroundUrl from "@/assets/images/views/home/hero.jpg";
import backgroundUrl2 from "@/assets/images/views/home/questionnaire.jpg";
import HomePageForm from "@/components/HomePageComponents/HomePageForm";
import HomePageTimeline from "@/components/HomePageComponents/HomePageTimeline";
import _ from "lodash";
import mixin from "../../mixins";

export default {
  name: "homePageCom",
  components: {
  HomePageForm,HomePageTimeline
  },
  mixins:[mixin],
  data() {
    return {
    
      backgroundUrl: backgroundUrl,
      backgroundUrl2: backgroundUrl2,
    };
  },
  computed: {

    ...mapGetters([]),

  },

 
  
};
</script>
<style lang="scss" scoped>

// 2nd section starts/////////
$lightGreen:#a2c748;
.site-bannerbox {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-bottom: 120px;
    &:before {
        content: "";
        display: block;
        width: 100%;
        height: 2rem;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background: #ffffff;
    }
    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2rem;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        background: #ffffff;
    }

    .box {
        background: $lightGreen;
        padding: 20px;
        // min-height: 280px;
        position: relative;
        z-index: 2;
        border-radius: 25px;

        @media screen and (min-width: 1025px) {
           padding: 40px;
        }
    }

    h2 {
        font-size: 40px;
        font-weight: 700;
        color: #ffffff;
        line-height: 50px;
        margin: 0 auto 24px;

       @media screen and (min-width: 1025px) {
          font-size: 56px;
          line-height: 60px;
        }
    }
    p {
        font-size: 16px;
        line-height: 1.4446;
        margin-bottom: 24px;
        color: #ffffff;
        a {
            color: #0055a5;

        }
    }
    a.banner-btn {
        color: #FFFFFF;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        display: inline-block;
        border-radius: 25px;
        padding: 8px 25px;
        background: #0055a5;
        border: 2px solid #0055a5;
        margin-top: 2rem;
        transition: background 0.3s linear;
        &:hover {
            color: #0055a5;
            background: #FFFFFF;
            border: 2px solid #0055a5;
        }
    }
}

// 2nd section ends/////////

// Blue banner/////////

$white: #ffffff;
$primaryBlue: #0054a4;

.blueBanner {
  padding: 120px 20px;
  background: $primaryBlue;
  color: $white;
  margin-bottom: 75px;

  @media screen and (min-width: 768px) {
    padding: 120px 0;
  }

  h2 {
    color: $white;
    line-height: 38px;
    margin-bottom: 30px;
  }

}

// HomePage form/////////

.homePage {
  .box {
    background: $primaryBlue;
    width: 100%;
    border-radius: 16px;

     @media screen and (min-width: 1025px) {
       width: 47%;
    }

    a {
      color: $white;

      &:focus {
        outline: 1px solid #ffffff;
      }
    }
  }
}

    
</style>