<template>

  <b-modal id="modal" ref="modalComponent" hide-footer centered hide-header no-close-on-backdrop title="Portal modal"
           size="xl" no-fade="false" v-on:shown="setFocus" hide-backdrop="true">
    <!-- Desktop & mobile modals -->
    <div class="container-modal" v-bind:class="{ 'textOnly' :modalData.Type === 'Info'}">

      <!-- Desktop modals -->
      <div v-if="isDesktop" class="desktop">


        <div v-if="!isFullscreen" class="not-fullScreen">

          <b-row>
            <b-col lg="9" sm="9">
              <header class="header">
                <div class="Title" id="titleModal">
                  <h3>{{ modalData.Title }}</h3>
                </div>
              </header>
            </b-col>

            <b-col lg="3" sm="3">

              <!-- Modal's buttons (arrows, fullscreen, close) -->
              <div class="controls-modal">

                <div v-if="modalData.ImgSrc && modalData.ImgSrc.length > 1" style="display: flex">
                  <button class="btn btn-left" @click="SwapImg(-1)" aria-label="button to go to the previous image">
                    <i class="fas fa-chevron-circle-left"></i>
                  </button>
                  <button class="btn btn-right" @click="SwapImg(1)" aria-label="button to go to the next image">
                    <i class="fas fa-chevron-circle-right"></i>
                  </button>
                </div>

                <button class="btn btn-fullScreen" @click="FullScreen"  aria-label="button to see a content in fullscreen"
                        v-if="modalData.Type === 'Image'|| modalData.Type === 'Video'">
                  <i class="fas fa-expand-arrows-alt"></i>
                </button>

                <button class="btn btn-close" @click="hideModal" aria-label="button to close modal">
                  <i class="fas fa-times-circle"></i>
                </button>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col lg="9" sm="9">
              <section class="body" v-if="!isFullscreen && modalData.Type !== 'Info' && modalData.DescriptionBox">
                <div class="body-modal" v-if="isLargeBox">
                  <p
                      v-for="(text, index) in modalData.text"
                      :key="`${$route.name}_modaldescription_${index}`"
                      v-html="text"
                      id="descModal"
                  >
                  </p>
                </div>
                <div class="body-modal" v-else>
                  <p
                      v-for="(text, index) in modalData.DescriptionBox.text"
                      :key="`${$route.name}_modaldescription_${index}`"
                      v-html="text"
                  >
                  </p>
                </div>
              </section>
            </b-col>

            <b-col lg="3" sm="3">
            </b-col>
          </b-row>

        </div>

        <!-- Video -->
        <section v-if="modalData.Type === 'Video'" class="videoWrapper">
          <iframe :src="modalData.VideoSrc" allowfullscreen></iframe>
          <div class="fullscreenButtonHolder" v-if="isFullscreen">

            <button class="btn btn-fullScreenWhite" @click="FullScreen" aria-label="button to see minimised content">
              <i class="fas fa-compress-arrows-alt"></i>
            </button>

            <button class="btn btn-closeWhite" @click="hideModal" aria-label="Close modal">
              <i class="far fa-times-circle"></i>
            </button>

          </div>
        </section>

        <!-- Image -->
        <section v-if="modalData.Type === 'Image'" class="imageWrapper">
          <div class="fullscreenButtonHolder" v-if="isFullscreen">

            <button class="btn btn-fullScreenWhite" @click="FullScreen" aria-label="button to see minimised content">
              <i class="fas fa-compress-arrows-alt"></i>
            </button>

            <button class="btn btn-closeWhite" @click="hideModal" aria-label="Close modal">
              <i class="far fa-times-circle"></i>
            </button>

          </div>

          <img :src="modalData.ImgSrc[this.imgNumber]" :alt="AltTextToLoad">
        </section>

        <!-- Text -->
        <section v-if="modalData.Type === 'Info'" class="textWrapper">
          <p v-for="(item, index) in modalData.DescriptionBox.text" :key="index"  v-html="item">
            {{ item }}
          </p>
        </section>


      </div>

      <!-- Mobile/tablet modals -->
      <div v-else class="mobile">

        <!-- Modal's top content (buttons, title, description) -->
        <div class="mobile-controls" v-bind:class="{ 'textOnly' :modalData.Type === 'Info'}">
          <b-row>
            <b-col lg="9" sm="9">
              <header class="header">
                <div class="Title">
                  <h3>{{ modalData.Title }}</h3>
                </div>
              </header>
            </b-col>

            <b-col lg="3" sm="3">
              <div class="controls-modal">

                <div v-if="modalData.ImgSrc && modalData.ImgSrc.length > 1" style="display: flex">
                  <div class="btn btn-left" @click="SwapImg(-1)" aria-label="button to go to the previous image">
                    <i class="fas fa-chevron-circle-left"></i>
                  </div>
                  <div class="btn btn-right" @click="SwapImg(1)" aria-label="button to go to the next image">
                    <i class="fas fa-chevron-circle-right"></i>
                  </div>
                </div>

                <div class="btn-closeWhite" @click="hideModal" v-bind:class="{ 'btn-blue' :modalData.Type === 'Info'}"
                     aria-label="Close modal">
                  <i class="far fa-times-circle"></i>
                </div>

              </div>
            </b-col>
          </b-row>

          <!--Accordion-->
          <b-row>
            <b-col lg="6" sm="6">
              <section class="body" >
                <div class="body-modal">
                  <Accordion v-if="modalData.DescriptionBox && modalData.Type === 'Image'" class="accordions-dropdown modal-accordion"
                             :item="modalData.DescriptionBox" :id="1" />
                </div>
              </section>
            </b-col>

            <b-col lg="6" sm="6">
            </b-col>
          </b-row>

        </div>

        <!-- Video -->
        <section v-if="modalData.Type === 'Video'" class="videoWrapper">
          <iframe :src="modalData.VideoSrc" allowfullscreen></iframe>
        </section>

        <!-- Image -->
        <section v-if="modalData.Type === 'Image'" class="imageWrapper" >
          <img :src="modalData.ImgSrc[this.imgNumber]" :alt="AltTextToLoad">
        </section>

        <!-- Text -->
        <section v-if="modalData.Type === 'Info'" class="textWrapper">
          <p v-for="(item, index) in modalData.DescriptionBox.text" :key="index"  v-html="item">
            {{ item }}
          </p>
        </section>

      </div>

    </div>
  </b-modal>

</template>


<script>
import {Pano} from 'vuejs-vr'
import Accordion from "../../components/AccordionCard";

export default {
  name: "PortalModal",
  components: {
    Pano,
    Accordion,
  },
  mounted() {
    this.$refs['modalComponent'].show()
  },
  props:
      {
        isModalOpen: {
        Type: Boolean,
          default: false
        },
        isPromptOpen: {
          type: Boolean,
          default: true
        },
        isFullscreen: {
          type: Boolean,
          default: false
        },
        isDesktop: {
          type: Boolean,
        },
        isLargeBox: {
          type: Boolean,
          default: false,
        },
        modalData: Object,
        imgNumber:
            {
              type: Number,
              default: 0
            }
      },
  computed: {
    AltTextToLoad(){
      return this.modalData.ImageAltText
    },
  },
  methods: {
    ClosePanoPrompt() {

      var content = document.getElementById('instructions360');

      if (this.isPromptOpen)
      {
        content.classList.add('fade-out')
        this.isPromptOpen = false;

      }
    },
    SwapImg(direction) {
      this.imgNumber += direction;

      if (this.imgNumber < 0) {
        this.imgNumber = this.modalData.ImgSrc.length - 1;
      } else if (this.imgNumber >= this.modalData.ImgSrc.length) {
        this.imgNumber = 0;
      }
    },
    FullScreen() {
      let fullscreen = document.getElementById("modal").children[0];

      if (!this.isFullscreen) {
        fullscreen.classList.add('fullScreen');
        this.isFullscreen = true;
      }
      else if (this.isFullscreen) {
        fullscreen.classList.remove('fullScreen');
        this.isFullscreen = false;
      }
    },
    ImageText360() {
      const queryDesktop = window.matchMedia("(min-width: 1025px)");

      if (queryDesktop.matches) {
        return "<b>Use your mouse</b> to move around this 360 view"
      } else {
        return "<b>Use your fingers</b> to move around this 360 view"
      }
    },
    hideModal()
    {
      this.$emit("closeModal");
      this.$refs['modalComponent'].hide()
    },
  }
}
</script>

<style lang="scss">
@import '../../variables';

.modal, .backdrop-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(#0054A4, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  width: 100%;
  height: 100%;

  .modal-body {
    padding: 0;
  }

  .fullscreenButtonHolder {
    position: fixed;
    right: 60px;
    top: 40px;
    z-index: 10000;
  }

  .btn {
    top: 0;
    right: 0;
    border: none;
    font-size: 32px;
    cursor: pointer;
    color: #0054A4;
    background: transparent;
    text-align: center;

    &:hover {
      color: rgba(#0054A4, 0.80);
    }

    &-close {
      font-size: 32px;
      width: 44px;
      padding: 2px 7px;
    }

    &-fullScreen {
      font-size: 26px;
      width: 38px;
      padding: 5px 7px;
      margin-right: 4px;
    }

    &-fullScreenWhite {
      font-size: 26px;
      width: 38px;
      color: #ffffff;
      padding: 5px 7px;
      margin-right: 4px;
    }

    &-closePanoPrompt {
      font-size: 32px;
      width: 32px;
      display: block;
    }


    &-left {
      font-size: 32px;
      width: 44px;
    }

    &-right {
      font-size: 32px;
      width: 44px;
    }

    &-closeWhite {
      color: #ffffff;
      font-size: 32px;
      width: 44px;
      padding: 2px 7px;

      &.btn-blue {
        color: #0054A4;
      }
    }

    &-pano {
      position: absolute;
      top: 25px;
      right: 50px;
      color: #ffffff;

      &:hover {
        color: rgba(#ffffff, 0.80);
      }
    }
  }

  .instructions360 {
    width: 400px;
    height: 400px;
    background-color: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border-radius: 50%;
    display: block;
    //pointer-events: none;
    color: $Gcc-DarkBlue;
    opacity: 1;

    @media screen and (max-width: 1025px){
      width: 300px;
      height: 300px;

    }

    &.fade-out {
      opacity: 0;
      transition: opacity 1000ms ease-in;
    }

    &.fade-in {
      opacity: 1;
      transition: opacity 1000ms ease-in;
    }

    .content-360{
      margin-left: 50px;
      margin-right: 50px;
      font-size: 22px;
      text-align: center;
    }

    .text-360 {
      margin-top: 100px;

      @media screen and (max-width: 1024px){
        margin-top: 30%;
      }
    }

    .text-360-arrows {
      margin-top: 50px;

      @media screen and (max-width: 1024px){
        margin-top: 5%;
      }
    }

    .fas-large {
      margin-left: 25px;
      margin-right: 25px;
      font-size: 96px;
    }
  }


  .modal-content {

    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin: auto;
    background: transparent;
    position: relative;
    border: none;
    border-radius: 0;
    transition: none;
  

    .desktop {
      overflow-x: hidden;
      overflow-y: auto;
      min-height: 50%;
    }

    .header {
      padding: 30px 30px 10px;
      display: flex;
      position: relative;
      color: #0054A4;
      justify-content: space-between;

      .Title {
        flex: auto;
      }

      h2, h3 {
        color: #0054A4;
      }
    }


    .body {
      position: relative;
      padding: 0 30px 20px 30px;
      overflow-wrap: break-word;
      color: #0054A4;
      display: flex;

      p {
        color: #0054A4;
      }
    }

    .textWrapper {
      padding: 0 30px 20px 30px;
      color: #0054A4;

     @media screen and (min-width: 1024px) {
        padding: 0 70px 20px 30px;
      }

      p {
        color: #0054A4;
      }

    }

    .body-modal {
      width: 100%;
    }

    .controls-modal {
      position: absolute;
      right: 35px;
      top: 20px;
      display: flex;

      @media screen and (min-width: 1025px) {
        right: 35px;
      }
    }

    .imageWrapper {
      img {
        width: 100%;
        display: block;
      }

    }


    .videoWrapper {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      max-width: 100%;

      iframe,
      object,
      embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        z-index: 999;
      }

        .fullscreenButtonHolder {
          z-index: 10000;
          width: 110px;
          right: 30px;

          .btn-fullScreenWhite {
            width: 50px;
          }

          .btn-closeWhite {
            width: 55px;
          }
        }
    }

    .mobile {
      position: relative;
      background: transparent;
      width: 100%;
      height: 100%;
      min-height: 16em;

      .textWrapper {
        position: relative;
        top: 120px;
      }

      .modal-accordion {
        .accordion-header {
          background: #ffffff;
          position: relative;
          padding: 5px;

          .textArea {
            background: #ffffff;
          }

          .accordionIcon {
            display: none;
          }

          .title {
            position: relative;
            width: 100%;
          }

          .icon {
            position: absolute;
            top: -5px;
            right: 0;
          }

          .iconArea{
            top: 0;
            position: absolute;
            right: -8px;
          }
        }

        .accordion-body {
          background: #F2F2F2;
          height: 200px;
          overflow:  auto;

           @media screen and (min-width: 1024px) {
            height: auto;
          }
        }
      }

      &-controls {
        position: absolute;
        width: 100%;
        z-index: 1000;

         .header {
          h3 {
            color: #ffffff;
            font-size: 20px;
          }
        }

        &.textOnly,
        .btn-closeWhite {
          h3 {
            color: #0054A4;
          }
        }

      }
    }
  }
  .panoPromtCloseButton {
    border: none;
    font-size: 32px;
    cursor: pointer;
    background: transparent;
    text-align: center;
    position: absolute;
    top: 40px;
    right: 80px;
    color: $Gcc-Blue;
    display: block;
    z-index: 10000;

    &:hover {
      color: rgba(#0054A4, 0.80);
    }

    @media screen and (max-width: 1025px){
      top: 20px;
      right: 80px;
    }

  }


  .panoTitle{
    position: absolute;
    flex:auto;
    z-index:1000;
    top: 35px;
    left: 50px;

    h3{
      color: #ffffff;
    }
  }

  .v-pano {
    width: 100vw;
    height: 100vh;
  }
}

.container-modal {
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;

    &.textOnly {
      background: #ffffff;
      overflow-y: auto;
  
      .mobile-controls {
        background: #ffffff;
        height: 94px;
        width: 100%;
      }
    }
}

.fullScreen.modal-dialog {
    max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;
    position: fixed;
    z-index: 100000;

    .videoWrapper, .imageWrapper {
      position: absolute;
      width: 100vw;
      height: 100vh;
      padding: 0;
      background: #000000;

    video, iframe, object {
      object-fit: cover;
    }

    img {
      width: auto;
      height: 100%;
      max-width: 100%;
      max-height: 100vh;
      margin: auto;
    }
  }
}

  
</style>