<template>
  <div id="faqs-view" class="faqs-view">

    <section class="MainHeadFoot">
      <Header :navbar="headerData"/>
    </section>

    <section class="section withTop">


      <b-container class="mainContent">
        <TitleText title="Frequently Asked Questions" class="Faq-Title"/>

        <div class="accordionSection">
          <Accordions :items="faqs1" :idIndex="0" />
        </div>

        <div class="accordionSection">
          <Accordions :items="faqs2" :idIndex="1" />
        </div>

        <div class="accordionSection">
          <Accordions :items="faqs3" :idIndex="2" />
        </div>

        <div class="accordionSection">
          <Accordions :items="faqs4" :idIndex="3" />
        </div>

        <div class="accordionSection">
          <Accordions :items="faqs5" :idIndex="4" />
        </div>

        <div class="accordionSection">
          <Accordions :items="faqs6" :idIndex="5" />
        </div>

        <div class="accordionSection">
          <Accordions :items="faqs7" :idIndex="6" />
        </div>

      </b-container>

    </section>


    <section class="footer">
      <Footer :footerNavs="footerData.navs" :copyWrite="footerData.copyWrite">
      </Footer>
    </section>

  </div>
</template>

<script>
import Banner from "../components/Banner";
import Header from "../components/Header";
import TitleText from "../components/TitleText";
import Accordions from "../components/Accordions";
import Footer from "../components/Footer";
import BannerIllustration from "../components/Project/M5J10/BannerIllustration";
import FooterIllustration from "../components/Project/M5J10/FooterIllustration";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "FAQs",
  components: {
    Banner,
    Header,
    TitleText,
    Accordions,
    Footer,
    BannerIllustration,
    FooterIllustration,
  },
  computed: {
    ...mapGetters(["language", "content"]),
    currentLanguage() {
      const vm = this;
      if (this.language) {
        return this.language;
      } else return "english";
    },
    headerData() {
      const vm = this;
      if (this.content && this.content.headerData) {
        return this.content.headerData;
      } else return {};
    },
    bannerData() {
      const vm = this;
      if (
        this.content &&
        this.content.FAQSdata &&
        this.content.FAQSdata.banner
      ) {
        return this.content.FAQSdata.banner;
      } else return {};
    },
    faqs1() {
      if (this.content && this.content.FAQSdata && this.content.FAQSdata.accordion1)
      {
        return this.content.FAQSdata.accordion1;
      } else return {};
    },
    faqs2() {
      if (this.content && this.content.FAQSdata && this.content.FAQSdata.accordion2)
      {
        return this.content.FAQSdata.accordion2;
      } else return {};
    },
    faqs3() {
      if (this.content && this.content.FAQSdata && this.content.FAQSdata.accordion3)
      {
        return this.content.FAQSdata.accordion3;
      } else return {};
    },
    faqs4() {
      if (this.content && this.content.FAQSdata && this.content.FAQSdata.accordion4)
      {
        return this.content.FAQSdata.accordion4;
      } else return {};
    },
    faqs5() {
      if (this.content && this.content.FAQSdata && this.content.FAQSdata.accordion5)
      {
        return this.content.FAQSdata.accordion5;
      } else return {};
    },
    faqs6() {
      if (this.content && this.content.FAQSdata && this.content.FAQSdata.accordion6)
      {
        return this.content.FAQSdata.accordion6;
      } else return {};
    },
    faqs7() {
      if (this.content && this.content.FAQSdata && this.content.FAQSdata.accordion7)
      {
        return this.content.FAQSdata.accordion7;
      } else return {};
    },
    footerData() {
      const vm = this;
      if (this.content && this.content.footerData) {
        return this.content.footerData;
      } else return {};
    },
  },
  methods: {
    ...mapActions(["toggleLanguage"]),
    toggleLang() {
      this.toggleLanguage();
    },
  },
};
</script>

<style lang="scss">
@import "../Variables";
.faqs-view
{
  .Faq-Title{
    margin-bottom: 8vh;
    width: 100%;
  }

  .accordionSection{
    margin-top: 3%;
  }

}
</style>