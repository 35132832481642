import db from './firebase';

export default {

    async getPasswordData() {
        try {
            let data = [];
            let document = await db.collection('password').get().then(docs => {
                docs.forEach(doc => data.push(doc.data()));
            });

            return data;
        } catch (error) {
            //console.log(error);
        }
    },

    async getFeedbackData() {
        try {
            let data = [];
            let document = await db.collection('feedback').get().then(docs =>
            {
                docs.forEach(doc =>
                {
                    let d = this.sortObjectByKeys(doc.data());
                    data.push(d)
                });
            });

            return data;
        } catch (error) {
            //console.log(error);
        }
    },
    sortObjectByKeys(o)
    {
        return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});
    },
    async postFeedback(dataObj) {
        //console.log('DATA to POST:', dataObj)
        let datasent = false;
        let document = await db.collection('feedback')
            .add(dataObj)
            .then((result) => {
                datasent = true ;
            })
            .catch((error) => {
                datasent = false;
            });

        return datasent;
    }

}