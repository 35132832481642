<template>
  <div class="radio-table-comp">

    <!-- Title and paragraphs -->
    <div class="RT-titleText">
      <p>{{ titleText }}</p>
      <div v-if="listOn">
        <li
            v-for="(paragraph, index) in list"
            :key="`${$route.name}_rtTextList-${id}_${index}`"
        >
          {{ paragraph }}
        </li>
      </div>
      <div v-else>
        <div
            v-for="(paragraph, index) in list"
            :key="`${$route.name}_rtTextList-${id}_${index}`"
        >
          {{ paragraph }}
        </div>
      </div>
    </div>

    <!-- Ethnicity table-->
    <div v-if="hasNoQuestions">

      <!-- Desktop view -->
      <div class="RT-TableEthnicity">

        <thead >
        <tr>
          <th v-for="(columnName, index) in values"
              :key="`${$route.name}_radioTableQ-${id}_${index}`">
            <b>{{ columnName.title }}</b>
          </th>
        </tr>
        </thead>

        <tbody>

          <td class="radio-table alignTop" v-for="(columnName, i) in values"
              :key="`${$route.name}_radioTableQ-${id}_${i}`"
              :tabindex="tabindexvalueCal(i)" role="radio" aria-checked="false">

              <div class="boxBorders" v-for="(value, j) in  columnName.options"
                   :key="`${$route.name}_radioTableA-${id}_${i}_radio${j}`"
                   role="radiogroup" >

              <div class="radio-input-table" >
                <label class="tickLabelTable">
                  <span class="sr-only">{{value}}</span>
                  <input
                  type="radio"
                  :value="value"
                  name="answer"
                  v-model="tableAnswers"
                  :aria-label="value"
              />
                  <span class="checkmarkTable"></span>
                  <p>{{ value }}</p>
                </label>
              </div>

            </div>

          </td>

        </tbody>


      </div>

      <!-- Tablet/mobile view -->
      <div class="RT-Column">
        <div
            v-for="(question, index) in values"
            :key="`${$route.name}_radioTableCol-${id}_${index}`"
            class="RT-radioCol"
        >
          <p class="columnTitle">{{ question.title }}</p>
          <div
              v-for="(answer, j) in question.options"
              :key="`${$route.name}_radioTableColA-${id}_${j}`"
              :tabindex="tabindexvalueCal(j)" role="radio" aria-checked="false"
          >
            <div class="radio-input">
              <label class="tickLabel"><span class="sr-only">{{answer}}</span>
            <input
                type="radio"
                :value="answer"
                :aria-label="answer"
                v-model="tableAnswers"/>
                <span class="checkmark"></span>
                <p>{{ answer }}</p>
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- Comment box -->
      <div v-if="commentBox" class="RT-commentbox">
        <p>{{ commentBoxText }}</p>
        <textarea :placeholder="commentBoxPreviewText" v-model="commentFeedback" :aria-label="commentBoxText"></textarea>
      </div>
    </div>

    <!-- Standard table with questions and answers -->
    <div v-else>

      <!-- Desktop view -->
      <div class="RT-Table">

        <thead>
        <tr>
          <td></td>
          <th
              v-for="(answer, index) in answers"
              :key="`${$route.name}_radioTableQ-${id}_${index}`"
          >
            <b>{{ answer }}</b>
          </th>
        </tr>
        </thead>

        <tbody>
        <tr
            v-for="(question, index) in questions"
            :key="`${$route.name}_radioTableA-${id}_${index}`"
        >
          <td class="questionRadio">{{ question }}</td>
          <td class="radio-table topBorder" role="radiogroup"
              v-for="(check, j) in answers"
              :key="`${$route.name}_radioTableA-${id}_${index}_radio${j}`"
          >
            <div class="radio-input-table"  :tabindex="tabindexvalueCal(j)"
                 role="radio" aria-checked="false">
              <label class="tickLabelTable">
                <span class="sr-only" >{{check}}</span>
                <input
                    type="radio"
                    :value="check"
                    :aria-label="check"
                    v-model="tableAnswers[index]"
                />
                <span class="checkmarkTable" ></span>
              </label>

            </div>
          </td>

        </tr>
        </tbody>

      </div>

      <!-- Tablet/mobile view -->
      <div class="RT-Column">
        <div
            v-for="(question, index) in questions"
            :key="`${$route.name}_radioTableCol-${id}_${index}`"
            class="RT-radioCol"
        >
          <p>{{ question }}</p>
          <div
              v-for="(answer, j) in answers"
              :key="`${$route.name}_radioTableColA-${id}_${j}`"
              :tabindex="tabindexvalueCal(j)" role="radio" aria-checked="false"
          >
            <div class="radio-input">
              <label class="tickLabel"><span class="sr-only">{{check}}</span>
            <input type="radio" :value="answer" v-model="tableAnswers[index]"
                   :aria-label="answer"/>
                <span class="checkmark"></span>
                <p>{{ answer }}</p>
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- Comment box -->
      <div v-if="commentBox" class="RT-commentbox">
        <p>{{ commentBoxText }}</p>
        <textarea :placeholder="commentBoxPreviewText" v-model="tableAnswers[questions.length]"></textarea>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "RadioButtonTable",
  props: {
    valueNo: {
      type: Number,
    },
    id: {
      type: String,
      required: true,
    },
    values: {
      type: Object,
    },
    titleText: {
      type: String,
      default: "Radio button table",
    },
    list: {
      type: Array,
      default() {
        return ["List paragraph for Radio Table"];
      },
    },
    answers: {
      type: Array,
      default() {
        return ["answer1", "answer2", "answer3", "answer4", "answer5"];
      },
    },
    questions: {
      type: Array,
      default() {
        return ["question1", "question2", "question3"];
      },
    },
    commentBox: {
      type: Boolean,
      default: true,
    },
    commentBoxPreviewText: {
      type: String,
      default: "Type your answer here",
    },
    hasNoQuestions: {
      type: Boolean,
      default: false,
    },
    commentBoxText: {
      type: String,
      default: "Comment box header here",
    },
    currentInput: {
      type: Array,
      default() {
        return [];
      },
    },
    listOn: {
      type: Boolean,
      default: true,
    },
  },
  computed: {},
  beforeMount() {
    this.radioFeedback = this.currentInput;
    this.commentFeedback = this.currentInput;
    this.tableAnswers = this.currentInput;

  },
  data() {
    return {
      radioFeedback: ["", ""],
      commentFeedback: [""],
      tableAnswers: [],
    };
  },
  methods: {
    tabindexvalueCal(indexval){
      if(indexval==0){
        return 0
      }else{
        return -1
      }
      // alert(this.tabindexvalue+indexval)

    }
  },
};
</script>

<style lang="scss">
@import '/src/Variables';

.radio-table-comp {

  .topBorder{
    border-top: $Gcc-Blue solid 1px !important;
  }

  .radio-table {
    margin: 10px 20px 10px 5px;

    .radio-input-table {

      margin-top: 5%;

      p {
        font-weight: 400;
        margin-left: 10px;
      }

      .checkboxBlip {
        position: relative;
      }

    }
  }

  .alignTop{
    vertical-align: top;
  }

  .RT-Column {
    display: none;

    @media screen and (max-width: 1024px) {
      display: block;
    }

    p{
      font-weight: bold;
    }

    .columnTitle{
      font-weight: bold;
    }

    .RT-radioCol {
      margin-bottom: 30px;
    }

      /* The container */
      .tickLabel {
        display: block;
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        p{
          font-weight: normal;
        }

      }

      /* Hide the browser's default checkbox */
      .tickLabel input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:focus {
          ~ .checkmark {
           outline: 3px solid #0054A4;
         }
       }
      }

      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: transparent;
        border: #0054A4 solid 1.5px;
        border-radius: 20px;
      }

      /* On mouse-over, add a grey background color */
      .tickLabel:hover input ~ .checkmark {
        background-color: #0054A4;
        border: #0054A4 solid 1.5px;
        border-radius: 20px;
      }

      /* When the checkbox is checked, add a blue background */
      .tickLabel input:checked ~ .checkmark {
        background-color: #0054A4;
        border: #0054A4 solid 1.5px;
        border-radius: 20px;
      }

      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      /* Show the checkmark when checked */
      .tickLabel input:checked ~ .checkmark:after {
        display: block;
      }

      /* Style the checkmark/indicator */
      .tickLabel .checkmark:after {
        width: 75%;
        height: 75%;
        border-radius: 100%;
        background: white;
        /* Center vertically and horizontally */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }


  .RT-TableEthnicity {

    .alignTop{
      vertical-align: top;

      &:nth-child(odd) {
        background: rgba($Gcc-Blue, 0.1);
      }

    }

    .wide{
      min-width: 1510px;
    }

    @media screen and (max-width: 1024px) {
      display: none;
    }

    tbody {

      td {

        text-align: center;
        //border-right: $Gcc-Blue solid 1px !important;

        &:first-child {
          border-left: none !important;
        }

        &:last-child {
          border-right: none !important;
        }
      }

      tr {

        height: 5em;

        .questionRadio {
          min-width: 267px;
          text-align: left;
          padding-left: 3px;
          border-top: $Gcc-Blue solid 1px !important;
        }

        &:first-child {
          vertical-align: middle;

          td {
            border-top: none !important;
            //border-bottom: solid 2px $Gcc-Blue !important;
          }
        }

        &:nth-child(odd) {
          background: rgba($Gcc-Blue, 0.1);
        }
      }

    }

    thead {

      th {
        font-weight: normal;
      }
      td, th {
        width: 180px;
        text-align: center;
        border-top: none;
      }


      tr {
        background: transparent !important;
      }
    }
  }

  .RT-Table {

    .wide{
      min-width: 1510px;
    }

    @media screen and (max-width: 1024px) {
      display: none;
    }

    tbody {

      td {

        text-align: center;
        border-right: $Gcc-Blue solid 1px !important;

        &:first-child {
          border-left: none !important;
        }

        &:last-child {
          border-right: none !important;
        }
      }

      tr {

        height: 5em;

        .questionRadio {
          min-width: 267px;
          text-align: left;
          padding-left: 3px;
          border-top: $Gcc-Blue solid 1px !important;
        }

        &:first-child {
          vertical-align: middle;

          td {
            border-top: none !important;
            //border-bottom: solid 2px $Gcc-Blue !important;
          }
        }

        &:nth-child(odd) {
          background: rgba($Gcc-Blue, 0.1);
        }
      }

    }

    thead {

      th {
        font-weight: normal;
      }
      td, th {
        width: 180px;
        text-align: center;
        border-top: none;
      }


      tr {
        background: transparent !important;
      }
    }
  }

  .RT-titleText {
    margin-bottom: 2%;

    p {
      color: #000000;
      font-weight: bold;
      max-width: 70%;

      @media screen and (max-width: 1024px) {
        width: 50%;
      }

    }

    li {
      list-style-image: none;
      list-style-type: none;

    }
  }

  .RT-commentbox {
    padding-top: 2%;

    textarea {
      width: 52%;
      border: #58595B solid 1.6px;
      border-radius: 8px;
      outline: none;
      padding: 10px;
      margin-top: 4px;
      background-color: transparent;

      @media screen and (max-width: 1024px) {
        width: 90%;
        padding: 0;
      }

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(#000000, 0.5);
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba($Gcc-Blue, 0.5);
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba($Gcc-Blue, 0.5);
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        color: rgba($Gcc-Blue, 0.5);
      }
    }
  }

  .boxBorders {
    padding: 15px;
    height: 6em;
    width: 300px;

    &:first-child {
      //vertical-align: middle;
      border-bottom: $Gcc-Blue solid 1px !important;

    }

    &:nth-child(even) {
      //background: rgba($Gcc-Blue, 0.1);
      border-bottom: $Gcc-Blue solid 1px !important;
    }

    &:nth-child(odd) {
      //background: rgba($Gcc-Blue, 0.1);
      border-bottom: $Gcc-Blue solid 1px !important;
    }

  }

  /* The container */
  .tickLabelTable {
    position: relative;
    padding-left: 19px;
    padding-bottom: 4px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

  }

  /* Hide the browser's default checkbox */
  .tickLabelTable input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

  }

  /* Create a custom checkbox */
  .checkmarkTable {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border: #0054A4 solid 1.5px;
    border-radius: 20px;
  }

  /* On mouse-over, add a grey background color */
  .tickLabelTable:hover input ~ .checkmarkTable {
    background-color: #0054A4;
    border: #0054A4 solid 1.5px;
    border-radius: 20px;
  }

  /* When the checkbox is checked, add a blue background */
  .tickLabelTable input:checked ~ .checkmarkTable {
    background-color: #0054A4;
    border: #0054A4 solid 1.5px;
    border-radius: 20px;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmarkTable:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .tickLabelTable input:checked ~ .checkmarkTable:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .tickLabelTable .checkmarkTable:after {
    width: 75%;
    height: 75%;
    border-radius: 100%;
    background: white;
    /* Center vertically and horizontally */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}
</style>