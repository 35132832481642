<template>
  <div class="title-text-comp">
    <h1 v-if="title" :class="smallTitle ? 'smallTitle' : ''">{{ title }}</h1>
    <p class="mainText" v-if="text && main">
      {{ text }}
    </p>
    <p v-if="text && !main" v-html="text">
      {{ text }}
    </p>
    <div v-if="useParagraphs">
      <p
        v-for="(paragraph, index) in paragraphs"
        :key="`${$route.name}_paragraphs_${index}`"
        v-html="paragraph"
      ></p>
    </div>
    <div class="titlelink" v-if="button">
      <button v-if="link">
        <router-link :to="link">{{ buttonText }}</router-link>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleText",
  props: {
    title: {
      type: String,
      default: "Title",
    },
    text: {
      type: String,
      default: "",
    },
    button: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: "Button",
    },
    main: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: "#",
    },
    smallTitle: {
      type: Boolean,
      default: false,
    },
    useParagraphs: {
      type: Boolean,
      default: false,
    },
    paragraphs: {
      type: Array,
      default() {
        return ["Paragraph1", "Paragraph2", "Paragraph3"];
      },
    },
  },
};
</script>

<style lang="scss">
.title-text-comp {
  width: 50%;
  position: relative;
  z-index: 20;

  @media screen and (max-width: 992px) {
    width: 80%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  h1 {
    font-size: 64px;

    &.smallTitle {
      font-size: 32px;
    }

    @media screen and (max-width: 500px) {
      font-size: 24px;
    }

    @media screen and (max-width: 769px) {
      font-size: 30px;
    }
  }
  p {
    &.mainText {
      font-size: 24px;

      @media screen and (max-width: 500px) {
        font-size: 16px;
      }

      @media screen and (max-width: 769px) {
        font-size: 20px;
      }
    }
  }
  .titlelink {
    width: 208px;
    height: 50px;

    button {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      border: solid 2px #f2696d;
      font-size: 16px;

      a {
        color: white;
      }

      &:hover {
        background: transparent;

        a {
          color: #f2696d;
        }
      }
    }

    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }
}
</style>