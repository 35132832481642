<template>
  <div class="titleParas-comp">
    <b-row>
      <b-col cols="12" lg="3">
        <h2 v-if="title">{{ title }}</h2>
      </b-col>
      <b-col cols="12" lg="9">
        <div v-if="paragraphs">
          <p
            v-for="(paragraph, index) in paragraphs"
            :key="`${$route.name}_paragraphs_${index}`"
          >
            {{ paragraph }}
          </p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "TitleParagraphs",
  props: {
    title: {
      type: String,
      default: "Title",
    },
    paragraphs: {
      type: Array,
      default() {
        return ["paragraph 1", "paragraph 2", "paragraph 3"];
      },
    },
  },
};
</script>