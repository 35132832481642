<template>
  <div class="Blips">

    <div v-for="(item, index) in PageData.Markers"
         :key="index" class="Element"
         :style="CheckDeviceMode(item)">

      <div class="tooltip-mobile">
        <div class="tooltip-wrapper" v-bind:class="{ show:index===currentBlip}" @click="showModal(item)">
          <div class="Text" :style="TextBoxHeightOffset(item)">
                  <span>
                    <img class="filterIcon" :src="getFilterImgUrl(item.Filter)" height="25"
                         :alt="item.Filter"/>
                  </span>
            {{ item.Title }}
            <span>
                    <img class="typeIcon" :src="getImgUrl(item.Icon)" height="25"
                         :alt="item.Filter"/>
                  </span>
          </div>
          <div class="vertical-line" :style="VerticalLineHeightOffset(item)"></div>
          <div class="OuterDot" :style="OuterDotOffset(item)">
            <div class="InnerDot"></div>
          </div>
        </div>
        <div class="icon-wrapper " tabindex="0" v-on:keyup.enter="addClass(item)"
             v-on:click="addClass(index)" :style="OuterDotOffset(item)">
          <span class="icon"><i class="fas fa-arrow-right" ></i></span>
        </div>
      </div>

    </div>

    <PortalModal v-if="this.showM && !isModalPano" id="modalId" ref="modalComponent" :is-desktop="isDesktop"
                 @closeModal="closeModal" :modal-data="this.dataM"/>

    <PortalModalPano v-if="this.showM && isModalPano" id="modalIdPano" ref="modalComponent" :is-desktop="isDesktop"
                 @closeModal="closeModal" :modal-data="this.dataM"/>

  </div>

</template>

<script>
import {mapGetters} from "vuex";
import PortalModal from "../PortalComponents/PortalModal.vue"
import PortalModalPano from "../../components/PortalComponents/PortalModalPano";

export default {
  name: "ContentBlipsMobile",
  components: {
    PortalModal,
    PortalModalPano,
  },
  data()
  {
    return{
      currentBlip: Number
    };
  },
  props:{
    isModalPano: {
      Type: Boolean,
      default: false
    },
    showM: {
      Type: Boolean,
      default: false
    },
    clickInfo: Object,
    isDesktop: {
      type: Boolean,
    },
  },
  mounted(){
    this.clickInfo = this.$refs.modalComponent;
  },
  computed: {
    ...mapGetters(["content"]),
    PageData() {
      const vm = this;
      if (
          this.content &&
          this.content.PortalData &&
          this.content.PortalData.PageContent
      ) {
        return this.content.PortalData.PageContent.find(el => {
          if (el.Slug) {
            return el.Slug === vm.$route.params.Slug
          }
        })
      } else return {};
    },
  },
  methods:
      {
        showModal(item)
        {

          if(item.Popup.Type === "360")
          {
            this.isModalPano = true
          }
          else
          {
            this.isModalPano = false
          }

          this.dataM = item.Popup;
          this.showM = true;
        },
        closeModal()
        {
          this.showM = false;
        },
        hideModal() {
          this.$refs.modalComponent.$children[0].hide();
        },
        TextBoxHeightOffset(i)
        {
          return 'transform: translate(' + (i.TextBoxOffset[1][0] ? i.TextBoxOffset[1][0] : '0px') + ',' + (i.TextBoxOffset[1][1] ? i.TextBoxOffset[1][1] : '0px') + ');'
        },
        VerticalLineHeightOffset(i)
        {
          const queryDesktop = window.matchMedia("(min-width: 1025px)");

          if (queryDesktop.matches)
          {
            return 'transform: translateX(' + (i.Offset[0][0] ? i.Offset[0][0] : '0px') + ');' + (i.Height[0][0] ? 'height:' + i.Height[0][0] : '')
          }
          else
          {
            return 'transform: translate(' + (i.Offset[1][0] ? i.Offset[1][0] : '0px') + ',' + (i.Height[1][1] ? i.Height[1][1] : '0px') + ');' + (i.Height[1][0] ? 'height:' + i.Height[1][0] : '')
          }
        },
        OuterDotOffset(i)
        {
          const queryDesktop = window.matchMedia("(min-width: 1025px)");

          if (queryDesktop.matches)
          {
            return  'transform: translateX(' + (i.Offset[0][0] ? i.Offset[0][0] : '0px') + ')'
          }
          else
          {
            return 'transform: translate(' + (i.Offset[1][0] ? i.Offset[1][0] : '0px') + ',' + (i.Offset[1][1] ? i.Offset[1][1] : '0px') + ')'
          }
        },
        CheckDeviceMode(i)
        {
          const queryDesktop = window.matchMedia("(min-width: 1025px)");

          if (queryDesktop.matches)
          {
            return 'left:' + i.Position[0][0] + 'px; top:' + i.Position[0][1] + 'px;'
          }
          else
          {
            return 'left:' + i.Position[1][0] + 'px; top:' + i.Position[1][1] + 'px;'
          }
        },
        getFilterImgUrl(pet) {
          if (pet) {
            var images = require.context('../../assets/images/Filters/', false, /\.png$/)
            return images('./' + pet + ".png")
          } else {
            return []
          }

        },
        getImgUrl(pet) {
          var images = require.context('../../assets/images/Icons/', false, /\.png$/)
          return images('./' + pet + ".png")
        },
        addClass(index)
        {
          if(index === this.currentBlip)
          {
            return;
          }
          this.currentBlip = index;
        }
      }
}
</script>
