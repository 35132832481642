<template>
  <div>

    <div class="Portal">
      <div class="HomeBackground">
      <div class="Section">
          <div class="Container">
              <div class="PageContent" v-if="!mapView">
                <PortalLargeBoxes :data="LargeBoxData" :isMapView="false" @openModal="openLargeBoxModal"
                                  :is-modal-open="isModalOpen" :is-desktop="isDesktop"/>
                <PortalSmallBoxes :data="SmallBoxData" :is-modal-open="isModalOpen"/>
              </div>

              <div class="PageContent" v-if="mapView">
                <PortalLargeBoxes :data="LargeBoxData" :isMapView="true" @openModal="openLargeBoxModal"
                                  :is-modal-open="isModalOpen" :is-desktop="isDesktop"/>
                <PortalMapView/>
              </div>

              <div class="MenuContent">
                <PortalSideMenu :state="mapView" @change="triggerToggleEvent" />
              </div>
          </div>
      </div>


<!--        <PortalModal v-if="isModalVisible && selectedModalData&& selectedModalData.Popup"
@closePopup="closeModal"-->
<!--                     :modalData="selectedModalData.Popup" :is-desktop="CheckDeviceMode" -->
<!--                     :is-large-box="true" :is-modal-open="isModalOpen"  />-->
      </div>
    </div>

    <RotatePhone/>
  </div>
</template>

<script>

import PortalSideMenu from "../../components/PortalComponents/PortalSideMenu";
import PortalLargeBoxes from "../../components/PortalComponents/PortalLargeBoxes";
import PortalSmallBoxes from "../../components/PortalComponents/PortalSmallBoxes";
import PortalMapView from "../../components/PortalComponents/PortalMapView";
import PortalModal from "../../components/PortalComponents/PortalModal";
import RotatePhone from "../../components/PortalComponents/RotatePhone";

import {mapGetters} from "vuex";

export default {
  name: "PortalMenu",
  components: {
    PortalLargeBoxes,
    PortalSideMenu,
    PortalSmallBoxes,
    PortalMapView,
    PortalModal,
    RotatePhone,
  },
  props: {
    mapView: {
      type: Boolean,
      default: false
    },
    isDesktop: {
      type: Boolean,
    },
  },
  data() {
    return {
      isModalOpen: false,
      isModalVisible: false,
      selectedModalData: Object,

    };
  },
  mounted() {
    const queryDesktop = window.matchMedia("(min-width: 1025px)");

    if (queryDesktop.matches)
    {
      return this.isDesktop = true;
    }
    else
    {
      return this.isDesktop = false;
    }

  },
  computed: {
    ...mapGetters(["language", "content"]),
    LargeBoxData() {
      const vm = this;
      if (
          this.content &&
          this.content.PortalData &&
          this.content.PortalData.LargeBoxes
      ) {
        return this.content.PortalData.LargeBoxes;
      } else return {};
    },
    SmallBoxData()
    {
      if (
          this.content &&
          this.content.PortalData &&
          this.content.PortalData.SmallBoxes
      ) {
        return this.content.PortalData.SmallBoxes;
      } else return {};
    },
  },
  methods:
  {
    triggerToggleEvent(value)
    {
      this.mapView = value;
    },
    openLargeBoxModal(item)
    {
        this.selectedModalData = item;
        this.isModalVisible = true;
        this.isModalOpen = true;

       // console.log("modal is open ", this.isModalOpen)
    },
    closeModal()
    {
        this.isModalOpen = false;
        this.isModalVisible = false;
        this.selectedModalData = null;
    }
  }
}
</script>

<style lang="scss">
.background
{
  height: 100%;
}
.PageContent
{
  flex: 10;
  overflow: auto;

   @media screen and (min-width: 769px) and (max-width: 1024px) {
    position: relative;
    top: 130px;
  }
}

.MenuContent
{
  flex: 2;
  min-width: 300px;

   @media screen and (min-width: 769px) and (max-width: 1024px) {
    position: absolute;
    top: 0;
    width: 100%;
  }
}
</style>