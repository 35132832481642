<template>
  <div class="feedback">

        <!-- Header -->
        <div class="feedbackHeader">

          <b-container class="mainContentSurvey">

            <b-row class="row">
              <div class="logoBoxBlue">
                <img src="@/assets/images/logos/logoBlue.png" alt="Gloucestershire County Council logo." style="max-height: 101px; max-width: 367px"/>
              </div>
            </b-row>

            <div class="logoBoxWhite">
              <img src="@/assets/images/logos/logo.png" alt="Gloucestershire County Council logo." style="max-height: 28px; max-width: 151px" />
            </div>

            <b-row class="row">
              <h1 class="title">{{ content.FeedbackPage.intro.title }}</h1>
            </b-row>

            <b-row class="row">

              <button class="button-primary button-transparent-blue" @click="goHome('/')" >
                <router-link :to="route">
                  <span class="chevronLeft"><i class="fas fa-chevron-left"></i></span>
                  Back home
                </router-link>
              </button>
            </b-row>

            <!-- Button holder for mobile -->
            <div class="buttonHolder">
              <button class="button-primary button-white-on-blue" onclick="goHome('/')">
                <router-link :to="route">
                  <span class="chevronLeft"><i class="fas fa-chevron-left"></i></span>
                  Back home
                </router-link>
              </button>
            </div>

          </b-container>

        </div>

        <!-- Intro -->
        <section v-if="isIntroPage" class="section topNone">

          <b-container class="mainContentSurvey">

            <div v-if="content.FeedbackPage.intro" class="introText">
              <p
                  v-for="(text, index) in content.FeedbackPage.intro.message"
                  :key="`${$route.name}_thankyoumessage_${index}`"
                  v-html="text"
              >
                {{ text }}
              </p>

            </div>
            <button class="button-primary button-blue-on-white-transparent" @click="switchIntroPageOff()">
              <router-link :to="route">
                {{ content.FeedbackPage.intro.btn }}
                <span class="chevron"><i class="fas fa-chevron-right"></i></span>
              </router-link>
            </button>

          </b-container>

        </section>

        <!-- Questionnaire -->
        <section v-if="!isIntroPage" class="section topNone">

          <b-container class="mainContentSurvey">
            <Questionnaire :data="feedbackContent"/>
          </b-container>

        </section>

    </div>

</template>

<script>
import Questionnaire from "../components/Project/M5J10/Questionnaire";

import {mapGetters} from "vuex";
import router from "@/router";

export default {
  name: "Feedback",
  components: {
    Questionnaire,
  },
  props: {
    isIntroPage: {
      type: Boolean,
      default: true,
    },
    btnText: {
      type: String,
      default: "Route",
    },
    route: {
      type: String,
      default: "/en/feedback",
    },
  },
  computed: {
    ...mapGetters(["language", "content"]),
    currentLanguage() {
      const vm = this;
      if (this.language) {
        return this.language;
      } else return "english";
    },
    feedbackContent() {
      const vm = this;
      if (
          this.content &&
          this.content.FeedbackPage &&
          this.content.FeedbackPage.content
      ) {
        return this.content.FeedbackPage.content;
      } else return {};
    },
  },
  methods: {
    goHome(link){
      router.push(link);
    },
    scrollToTop() {
      window.scrollTo({top: 0, behavior: 'smooth'});

    },
    switchIntroPageOff() {
      this.scrollToTop();
      this.isIntroPage = false;
    },
    checkPage() {
      if (this.isIntroPage) {
        return "true"
      } else
        return "false"
    },
  },
};
</script>

<style lang="scss">
@import "../Variables";

.feedback {
  background-size: 100%;
  background-position-y: bottom;
  bottom:0px;
  background-color: #E7F6FD;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/survey%2FsurveyFooter.jpg?alt=media&token=345eb138-fee1-4842-be3e-04d5965119d0');
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  min-height: 1080px;

  a {
    color: #000000;
    font-weight: bold;

    &:hover {
        color: #000000;
    }
  }

  .mainContentSurvey{
    max-width: 1550px;

    @media screen and (max-width: 1140px) {
      margin: 5% 5% auto 3%;
    }

    @media screen and (max-width: 768px) {
      margin: 5% 5% auto 2%;
    }
  }

  .feedbackHeader{
    position: relative;
    padding: 2% 0 2% 1.5%;

    .buttonHolder{
      display: none;
    }

    .row{
      display: block;
      margin-top: 2%;
    }

    .title {
      font-size: 18px;
      color: $Gcc-Blue;
    }

    .logoBoxBlue{
      display: block;
    }

    .logoBoxWhite{
      display: none;
    }

    @media screen and (max-width: 768px) {
      padding: 2%;
      background-color: $Gcc-Blue;
      min-height: 64px;

      .buttonHolder{
        display: block;
        position: absolute;
        right: 2%;
        top: 13%;
      }

      .row{
        display: none;
      }

      .title {
        font-size: 0px;
        color: $Gcc-Blue;
      }

      .logoBoxBlue{
        display: none;
      }

      .logoBoxWhite{
        position: absolute;
        display: block;
        left: 4%;
        top: 30%;
      }
    }
  }

  .introText{
    max-width: 60%;

    @media screen and (max-width: 1140px) {
      max-width: 85%;
    }

    @media screen and (max-width: 768px) {
      margin: 3% 7% 5% 0%;
      max-width: 100%;
    }

  }

  .inputSection {
    display: none;

    &.active {
      display: block;
    }
  }
}
</style>