import router from "./router";

export default {
    methods:
        {
        GoToPage(link)
        {
            if(link.startsWith("/"))
            {
               router.push(link);
            }
            else
            {
                router.push("/" +link);
            }
        }
    },
};