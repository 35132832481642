<template>
  <section class="portal-small-cards" v-if="data">
    <ul class="list">
      <li class="card" tabindex="0" v-for="(item, index) in data" :key="index" @click="GoToPage('portal-content/' + item.pageContent)">
        <div class="cardImage">
          <img :src="item.ImgSrc" style="width: 100%" :alt="item.ImageAltText"/>
        </div>

        <div class="cardText">
          <h3>{{item.Title}}</h3>
        </div>

        <button class="button-primary button-white" @click="GoToPage('portal-content/' + item.pageContent)">
          View more  <span class="chevron"><i class="fas fa-chevron-right"></i></span>
        </button>

      </li>
    </ul>
  </section>
</template>

<script>
import mixin from "../../mixins";

export default {
  name: "PortalSmallBoxes",
  mixins:[mixin],
  props: {
    data: Array,
  }
}
</script>

<style lang="scss">
@import '../../variables';
.portal-small-cards
{
  .list {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;

    .card {
      text-align: center;
      background-color: $Gcc-White;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      padding: 20px;
      border-radius: 6px;
      width: 100%;
      border: 3px solid $Gcc-White;
      margin-bottom: 25px;

      @media screen and (min-width: 813px) {
        margin-right: 22px;
        width: 47.8%;

        &:nth-of-type(2),
        &:nth-of-type(4){
          margin-right: 0;
        }
      }

       @media screen and (min-width: 1250px) {
        margin-right: 22px;
        width: 48.6%;

        &:nth-of-type(2),
        &:nth-of-type(4){
          margin-right: 0;
        }
      }

      
       @media screen and (min-width: 1366px) {
        margin-right: 22px;
        width: 23.3%;

        &:nth-of-type(2) {
          margin-right: 22px;
        }

        &:nth-of-type(4){
          margin-right: 0;
        }
      }

       @media screen and (min-width: 1490px) {
        margin-right: 22px;
        width: 31.3%;

         &:nth-of-type(2){
          margin-right: 22px;
        }

        &:nth-of-type(3){
          margin-right: 0;
        }
      }

      @media screen and (min-width: 1738px) {
        margin-right: 22px;
        width: 32.2%;

        &:nth-of-type(2){
          margin-right: 22px;
        }

        &:nth-of-type(3){
          margin-right: 0;
        }
      }

      @media screen and (min-width: 1795px) {
        margin-right: 22px;
        width: 23.8%;

        &:nth-of-type(2),
        &:nth-of-type(3){
          margin-right: 22px;
        }

        &:last-of-type{
          margin-right: 0;
        }
      }

      &:hover,
      &:focus {
        border: 3px solid $Gcc-DarkBlue;
        cursor: pointer;
      }

       &:first-of-type{
        margin-left: 3px;
      }

      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        color: $Gcc-DarkBlue;
      }

      a
      {
        color: $Gcc-DarkBlue;
        
        &:hover {
          color: $Gcc-DarkBlue;
        }
      }

      .cardText
      {
        padding: 18px 20px 10px;
        margin-bottom: auto;

        p{
          font-size: 18px;
        }
      }

      .cardImage
      {
        align-self: center;

      }
    }

    button {
      margin: 10px 0;
    }
  }
}
</style>