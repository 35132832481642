<template>
  <div id="faqs-view">

    <section class="MainHeadFoot">
      <Header :navbar="headerData"/>
      <banner :data="bannerData"/>
    </section>

    <section class="section withTop supportingDocs">

      <b-container class="mainContent">
        <b-col  md="12" lg="7" sm="12">
        <TitleTextFullWidth
            :title="descriptionData.title"
            :paragraphs="descriptionData.paragraphs"
            :useParagraphs="true"
            :smallTitle="true"
        />
        </b-col>
      </b-container>

      <b-container class="mainContent">
        <FurtherInfoCards :data="furtherInfoItems" :hoverText="'View Document'" />
      </b-container>
    </section>

    <section class="footer">
      <Footer :footerNavs="footerData.navs" :copyWrite="footerData.copyWrite">
      </Footer>
    </section>

  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Banner from "../components/Banner";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BannerIllustration from "../components/Project/M5J10/BannerIllustration";
import FooterIllustration from "../components/Project/M5J10/FooterIllustration";
import FurtherInfoCards from "../components/BoxLink.vue";
import TitleText from "../components/TitleText";
import TitleTextFullWidth from "@/components/TitleTextFullWidth";

export default {
  name: "supporting-documents",
  data() {
    return {};
  },
  components: {
    Banner,
    Header,
    Footer,
    BannerIllustration,
    FooterIllustration,
    FurtherInfoCards,
    TitleText,
    TitleTextFullWidth,
  },
  methods: {},
  computed: {
    ...mapState(["entities", "entityTypes"]),
    ...mapGetters(["language", "content", "user", "allEntityTypes", "allEntities"]),
    currentLanguage() {
      return "english";
    },
    headerData() {
      const vm = this;
      if (this.content && this.content.headerData) {
        return this.content.headerData;
      } else return {};
    },
    bannerData() {
      const vm = this;
      if (
          this.content &&
          this.content.SupportingDocsPageData &&
          this.content.SupportingDocsPageData.banner
      ) {
        return this.content.SupportingDocsPageData.banner;
      } else return {};
    },
    footerData() {
      const vm = this;
      if (this.content && this.content.footerData) {
        return this.content.footerData;
      } else return {};
    },
    descriptionData() {
      const vm = this;
      if (
          this.content &&
          this.content.SupportingDocsPageData &&
          this.content.SupportingDocsPageData.description
      ) {
        return this.content.SupportingDocsPageData.description;
      } else return {};
    },
    furtherInfoItems() {
      const vm = this;
      if (
          this.content &&
          this.content.SupportingDocsPageData &&
          this.content.SupportingDocsPageData.boxLinks
      ) {
        return this.content.SupportingDocsPageData.boxLinks;
      } else return {};

    },
  },
  watch: {
    $route(to, from) {
      this.activeInstance = to.params.root;
    },
  },
};
</script>
