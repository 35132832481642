<template>
  <Section class="RotateScreen" v-if="this.currentState ==='portrait'">
    <img src="@/assets/images/Rotation/Force-Rotate.png" style="height: 100%">

    <div class="RotateScreen-background">
        <img src="@/assets/images/Rotation/Rotate-Phone.png" >
        <h2 class="message">Your viewing experience will work better in landscape</h2>
        <h2 class="messageBold">please rotate your device</h2>
      </div>
  </Section>
</template>

<script>
export default {
  name: "RotatePhone",
  mounted() {
    window.addEventListener(
        "orientationchange",
        this.handleOrientationChange
    );
  this.handleOrientationChange();
  },
  data() {
    return {
      currentState: "landscape"
    }
  },
  methods: {
    handleOrientationChange()
    {
      const orientation = window.screen.orientation.type
      if (orientation === "portrait-primary")
      {
        this.currentState = "portrait";
        // portrait mode
      } else if (orientation === "landscape-primary")
      {
        this.currentState = "landscape";
        // landscape mode
      }
      this.$emit('orientationChange', orientation)
    }
  }
}
</script>

<style lang="scss">
@import "../../Variables";

.RotateScreen
{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  background-color: $Gcc-Pale-BG;
  z-index: 1000;

  .RotateScreen-background
  {
    width: 100%;
    height: 100%;
    padding-top: 25vh;
    padding-left: 15vw;
    padding-right: 15vw;
    text-align-last: center;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;

    .message
    {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: $Gcc-Blue;
    }

    .messageBold
    {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      text-align: center;

      color: $Gcc-Blue;
    }
  }
}
</style>