import Vue from 'vue';
import VueRouter from 'vue-router';

// Views
import Home from '../views/Home.vue';
import LandingPage from '../views/LandingPage.vue';
import Events from '../views/Events.vue';
import FAQs from '../views/FAQs.vue';
//import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import NotFound from '../views/NotFound.vue';
import Admin from '../views/Admin.vue';
import Feedback from '../views/Feedback.vue';
import SupportingDocuments from "@/views/SupportingDocuments";
import Popup from "@/components/EventsPageTimeline";
import PortalMenu from "../views/Portal/PortalMenu";
import store from "../store/index"
import PortalContentPage from "../views/Portal/PortalContentPage";

Vue.use(VueRouter);

const routes = [
    {
        path: '*',
        name: 'Not Found',
        component: NotFound
    },
    {
        path: '/',
        redirect: "/home",
    },
    {
        path: '/home',
        redirect: to =>
        {
            return store.state.language.code + "/home";
        }
    },
    {
        path: '/:lang/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/:lang/events',
        name: 'Events',
        component: Events
    },
    {
        path: '/:lang/faqs',
        name: 'FAQs',
        component: FAQs
    },
   /*{
        path: '/:lang/privacy-policy',
        name: 'privacy-policy',
        component: PrivacyPolicy
    },*/
    {
        path: '/admin',
        name: 'admin',
        component: Admin
    },
    {
        path: '/:lang/feedback',
        name: 'feedback',
        component: Feedback
    },
    {
        path: '/:lang/supporting-documents',
        name: 'supporting-documents',
        component: SupportingDocuments
    },
    {
        path: '/portal-menu',
        name: 'portal-menu',
        component: PortalMenu
    },
    {
        path: '/portal-content/:Slug',
        name: 'portal-content',
        component: PortalContentPage
    },
    {
        path: '/popup',
        name: 'popup',
        component: Popup
    }
];

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
});

export default router;