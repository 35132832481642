import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.config.silent = true
Vue.prototype.$http = axios;

import router from './router';
import store from "./store";

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

import VueEasyLightbox from 'vue-easy-lightbox';

Vue.use(Vuex);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueEasyLightbox);


import VueAgile from 'vue-agile';
Vue.use(VueAgile)


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
