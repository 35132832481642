<template>
    <section  class="background-cover">
        <video
            playsinline
            autoplay
            muted
            loop
            poster="@/assets/images/views/home/Hero_Image_v2_poster.png"
            id="bgvid"
        >
            <source
                
            src="https://player.vimeo.com/external/464195454.hd.mp4?s=ff199e28f49b9209d8a49160ec386990165a5106&profile_id=175"
            type="video/mp4"
            />
        </video>
        <div class="content">
            <div class="container">
            <div class="row">
                <div class="col s12 m12 l12 textBG">
                <h1 class="subheading">M5 Junction 10 Improvements Scheme</h1>
                <h1>Options Consultation <br> Have your Say <br>14 October to 25 November 2020</h1>
                </div>
            </div>
            </div>
        </div>
        <div class="arrow bounce">
            <p><i class="material-icons">arrow_downward</i></p>
        </div>
    </section>
</template>

<script>

import backgroundUrl from "@/assets/images/views/home/hero.jpg";
import backgroundUrl2 from "@/assets/images/views/home/questionnaire.jpg";

export default {
  name: "HomeHeroHeader",
  components: {
  },
  data() {
    return {
      backgroundUrl: backgroundUrl,
      backgroundUrl2: backgroundUrl2,
    };
  },
  computed: {
  
  },

 
  
};
</script>
<style lang="scss" scoped>

$fontHeading:red;

.background-cover {
    height: 80vh;
    margin-top: -75px;
    width: 100%;
    background-color: #3fd0fd;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    @media screen and (max-width: 768px) {
        background-color: rgb(0, 102, 155);
    }

    video {
        // width:100%;
        // object-fit: cover;
        // bottom: -2%;
        // position: absolute;

        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // transform: scale(1.5);
        transform: scale(1.5) translateY(-8%);
        @media (min-aspect-ratio: 16/9) {
            width: 100%;
            height: auto;   
        }
        @media (max-aspect-ratio: 16/9) {
            width: auto;
            height: 100%;
        }
        @media (min-aspect-ratio: 16/9) {
            height: 300%;
            top: -100%;
        }
        @media (max-aspect-ratio: 16/9) {
            width: 300%;
            left: -100%;
        }

        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    .row {
        margin-bottom: 20%;
    }

    .content {
        position: relative;
        z-index: 80;
        .textBG{
            background-color: #00669b4a;
            // opacity: 0.6;
            color: white;
            padding: 16px 30px;
        }
        .container {
            max-width: 960px;
            @media screen and (min-width: 933px) {
                width: 100% !important;
            }
        }
        h1 {
            font-size: 30px;
            margin: 0 auto 20px;
            font-weight: 700;
            color: #fff;
            font-family: $fontHeading;
            @media screen and (max-width: 768px) {
                font-size: 18px;
                line-height: 1.4446;
            }
        }
        .subheading {
            font-size: 45px;
            margin: 0 auto;
            font-weight: 700;
            line-height: 1.1;
            color: #fff;
            font-family: $fontHeading;
            @media screen and (max-width: 768px) {
                font-size: 30px;
                margin-bottom: 20px;
                line-height: 1.2;
            }
        }
    
        a {
            color: #FFFFFF;
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            display: inline-block;
            border-radius: 25px;
            padding: 8px 25px;
            background: #0055a5;
            border: 2px solid #0055a5;
            margin-top: 2rem;
            transition: background 0.3s linear;
            &:hover {
                color: #0055a5;
                background: #FFFFFF;
                border: 2px solid #0055a5;
            }
        }
    }

    .arrow {
        position: absolute;
        bottom: 20px;
        i {
            display: block;
            margin: 0 auto;
            font-size: 35px;
            color: #343958;
        }
        p {
            margin: 0 auto;
            font-size: 16px;
            color: #FFFFFF;
            text-transform: uppercase;
            font-weight: 700;
            text-align: center;
            width: 50px;
            height: 50px;
            border: 2px solid #FFFFFF;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background: #FFFFFF;
            border-radius: 50%;
        }
    }
}

</style>