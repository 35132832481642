class SurveyFeedbackFormatter {

    constructor(_feedbackData) {
        this.feedbackData = _feedbackData;
    }

    checkFormat(feedback) {
        let data = '';
        feedback.forEach((answer, index) => {
            if (answer) {
                data = data + ", " + answer;
            }

        });

        return data;
    }

    question2format(feedback) {
        let data = '';
        feedback.forEach((answer, index) => {
            if (answer && index === 0) {
                data = data + 'Commuting for work, ';
            }
            if (answer && index === 1) {
                data = data + 'Commuting for education, ';
            }
            if (answer && index === 2) {
                data = data + 'Employers business, ';
            }
            if (answer && index === 3) {
                data = data + 'Leisure, ';
            }
            if (answer && index === 4) {
                data = data + 'Shopping, ';
            }
            if (answer && index === 5) {
                data = data + 'Other, ';
            }
            if (answer && index === 6) {
                data = data + 'Do not Use, ';
            }
        })

        return data;
    }

    question20format(feedback) {
        let data = '';

        feedback.forEach((answer, index) => {
            if (answer && index === 0) {
                data = data + 'Postcard or letter through the door, ';
            }
            if (answer && index === 1) {
                data = data + 'Local council website, ';
            }
            if (answer && index === 2) {
                data = data + 'Local community group/society, ';
            }
            if (answer && index === 3) {
                data = data + 'Newspaper, ';
            }
            if (answer && index === 4) {
                data = data + 'Social media, ';
            }
            if (answer && index === 5) {
                data = data + 'Other, ';
            }
        })

        return data;
    }

    formatData() {
        return {
            /** Section 1 **/
            Q01: this.feedbackData.section1.question1.length > 0 ? this.feedbackData.section1.question1[0] : "",
            Q02_M5J10: this.question2format(this.feedbackData.section1.question2.length > 0 ? this.feedbackData.section1.question2[0] : ""),
            Q02_A4019: this.question2format(this.feedbackData.section1.question2.length > 1 ? this.feedbackData.section1.question2[1] : ""),
            Q02_comments: this.feedbackData.section1.question2.length > 2 ? this.feedbackData.section1.question2[2] : "",
            Q03_M5J10: this.feedbackData.section1.question3.length > 0 ? this.feedbackData.section1.question3[0] : "",
            Q03_A4019: this.feedbackData.section1.question3.length > 1 ? this.feedbackData.section1.question3[1] : "",
            Q04_M5J10: this.feedbackData.section1.question4.length > 0 ? this.feedbackData.section1.question4[0] : "",
            Q04_A4019: this.feedbackData.section1.question4.length > 1 ? this.feedbackData.section1.question4[1] : "",

            /** Section 2 **/
            Q05: this.feedbackData.section2.question5.length > 0 ? this.feedbackData.section2.question5[0] : "",
            Q06: this.feedbackData.section2.question6.length > 0 ? this.feedbackData.section2.question6[0] : "",
            Q07: this.feedbackData.section2.question7.length > 0 ? this.feedbackData.section2.question7[0] : "",
            Q08: this.feedbackData.section2.question8.length > 0 ? this.feedbackData.section2.question8[0] : "",
            Q09: this.feedbackData.section2.question9.length > 0 ? this.feedbackData.section2.question9[0] : "",
            Q09_comment: this.feedbackData.section2.question9.length > 4 ? this.feedbackData.section2.question9[4] : "",
            Q10: this.feedbackData.section2.question10.length > 0 ? this.feedbackData.section2.question10[0] : "",
            Q11: this.feedbackData.section2.question11.length > 0 ? this.feedbackData.section2.question11[0] : "",
            Q12: this.feedbackData.section2.question12.length > 0 ? this.feedbackData.section2.question12[0] : "",
            Q13: this.feedbackData.section2.question13.length > 0 ? this.feedbackData.section2.question13[0] : "",
            Q14: this.feedbackData.section2.question14.length > 0 ? this.feedbackData.section2.question14[0] : "",
            Q15: this.feedbackData.section2.question15.length > 0 ? this.feedbackData.section2.question15[0] : "",
            
            /** Section 3 **/
            Q16: this.feedbackData.section3.question16.length > 0 ? this.feedbackData.section3.question16[0] : "",
            Q17: this.feedbackData.section3.question17.length > 0 ? this.feedbackData.section3.question17[0] : "",
            Q18: this.feedbackData.section3.question18.length > 0 ? this.feedbackData.section3.question18[0] : "",
            Q19: this.feedbackData.section3.question19.length > 0 ? this.feedbackData.section3.question19[0] : "",
            Q20: this.question20format(this.feedbackData.section3.question20.length > 0 ? this.feedbackData.section3.question20[0] : ""),
            Q20_comment: this.feedbackData.section3.question20.length > 1 ? this.feedbackData.section3.question20[1] : "",
            Q21: this.feedbackData.section3.question21.length > 0 ? this.feedbackData.section3.question21[0] : "",
            Q22: this.feedbackData.section3.question22.length > 0 ? this.feedbackData.section3.question22[0] : "",
            Q22_comment: this.feedbackData.section3.question22.length > 1 ? this.feedbackData.section3.question22[1] : "",
            Q23: this.feedbackData.section3.question23.length > 0 ? this.feedbackData.section3.question23[0] : "",
            Q23_email: this.feedbackData.section3.question24.email ? this.feedbackData.section3.question24.email : "",
            Q23_number: this.feedbackData.section3.question24.number >0 ? this.feedbackData.section3.question24.number : "",
            
            /** Section 4 **/
            Q24_postcode: this.feedbackData.section4.question25.postCode ? this.feedbackData.section4.question25.postCode : "",
            Q25: this.feedbackData.section4.question26.length > 0 ? this.feedbackData.section4.question26[0] : "",
            Q26: this.feedbackData.section4.question27.length > 0 ? this.feedbackData.section4.question27[0] : "",
            Q27: this.feedbackData.section4.question28.length > 0 ? this.feedbackData.section4.question28[0] : "",
            Q28: this.feedbackData.section4.question29.length > 0 ? this.feedbackData.section4.question29 : "",
            Q28_comment: this.feedbackData.section4.question29_comment.length > 0 ? this.feedbackData.section4.question29_comment : "",
            Q29: this.feedbackData.section4.question30.length > 0 ? this.feedbackData.section4.question30[0] : "",
            Q30: this.feedbackData.section4.question31.length > 0 ? this.feedbackData.section4.question31[0] : "",
            Q31: this.feedbackData.section4.question32.length > 0 ? this.feedbackData.section4.question32[0] : "",
            Q32: this.feedbackData.section4.question33.length > 0 ? this.feedbackData.section4.question33[0] : "",
            Q32_comment: this.feedbackData.section4.question33.length > 1 ? this.feedbackData.section4.question33[1] : "",

            /** Meta **/
            Guid: this.createGuid(),
            DateTime : new Date(Date.now()).toLocaleString(),
        };
    }

    createGuid()
    {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }
}

export default SurveyFeedbackFormatter;