<template >
  <div class="MapView">
      <MapBlips/>
  </div>
</template>

<script>
import MapBlips from "./MapBlips";

export default {
  name: "PortalMapView",
  components: {
    MapBlips,
  },
}
</script>

<style scoped>
.MapView {
  background-repeat: no-repeat;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/mapImage%2Fmap_19201080.jpg?alt=media&token=9132e947-fb42-49e3-a767-eb807a990a18');
  width: 1920px;
  height: 1080px;
}
</style>