export default {
  langCode: 'EN',
  headerData: {
    title: 'M5J10',
    pages: [
      {
        nav: 'Home',
        name: 'Home',
        route: '../en/home',
        home: true,
      },
      {
        nav: 'Supporting Documents',
        route: '../en/supporting-documents',
        name: 'supporting-documents',
        order: 1,
      },
      {
        nav: 'Events',
        route: '../en/events',
        name: 'Events',
        order: 2,
      },
      {
        nav: 'FAQs',
        route: '../en/faqs',
        name: 'FAQs',
        order: 3,
      },
      {
        nav: 'Complete survey ',
        route: '../en/feedback',
        name: 'Feedback',
        order: 4,
        button: true,
        buttonTransparent: true,
      },
      {
        nav: 'Go to virtual information portal ',
        route: '../portal-menu',
        name: 'Portal',
        order: 5,
        button: true,
      },
    ],
  },
  footerData: {
    copyWrite: '© 2021 Copyright Gloucestershire County Council',
  },
  sliderData: {
    title: 'About the consultation',
    text:
      '\n' +
      '<p>Our ten-week statutory consultation gives you the opportunity to review and provide feedback on our proposals for the M5 Junction 10 Improvements Scheme including:</p>\n' +
      '<ul>\n' +
      '<li>\n' +
      'Improvements to M5 Junction 10\n' +
      '</li>\n' +
      '<li>\n' +
      'A new road linking Junction 10 to West Cheltenham\n' +
      '</li>\n' +
      '<li>\n' +
      'Widening of the A4019 Tewkesbury Road\n' +
      '</li>\n' +
      '</ul>\n' +
      '<p>\n' +
      'Your feedback will help shape our proposals and inform our final design, before we submit our Development Consent Order (DCO) application.\n' +
      '</p>\n' +
      '<p>\n' +
      'The image is a graphical representation of what the new M5 Junction 10 will look like when the scheme is complete.\n' +
      '</p>',
    button: 'Give Feedback',
    carousel: [
      {
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Home%2Fhome_aboutViz.png?alt=media&token=7e3cc8f7-de67-41d8-95a2-69de0de4e409',
      },
    ],
  },
  homePageData: {
    banner: {
      title:
        'Have your say on our proposed M5 Junction 10 Improvements Scheme',
      text: 'Statutory Public Consultation <br> 8 December 2021 to 15 February 2022',
      buttonText: 'Go to the virtual information portal',
      link: 'portal-menu',
      img: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/hero_image_No_text.png?alt=media&token=a099c02e-6a7b-49c8-ba54-6ec276cc0722',
      largeBanner: true,
      ImageAltText: 'Decorative image showing the M5 junction 10 branding with cars, road, hills and trees.',
    },
    section1: {
      title: 'Need for the scheme',
      paragraphs: [
        '<p>New housing and employment sites are proposed for development close to Junction 10 of the M5, including the West and North-West Cheltenham developments. To unlock these housing and job opportunities, we need to ensure that there is sufficient highway capacity to accommodate the increased motorised and non-motorised traffic these will generate. There is also a need to address existing pressure on the capacity of the local highway network.</p><p> The planned housing and economic growth have been included by Cheltenham Borough, Tewkesbury Borough and Gloucester City Councils in their adopted  <a href="http://www.jointcorestrategy.org" target="_blank" title="Joint Core Strategy">Joint Core Strategy</a>.</p><p>National Highways has also identified in their <a href="http://www.gov.uk/government/publications/route-strategies-april-2015-march-2020" target="_blank" title="Birmingham to Exeter Route Strategy">Birmingham to Exeter Route Strategy</a> that improvements to M5 Junction 10 are a critical requirement to maintain the safe and efficient operation of the M5 corridor, whilst enabling the planned development and economic growth around Cheltenham, Gloucester and Tewkesbury.</p><p>More information about the need for the scheme can be found in our <a href="https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FConsultation%20Brochure.pdf?alt=media&token=f583151a-7693-436c-a6ec-c96894d3de54" target="_blank" title="Consultation Brochure"> Consultation Brochure</a> on our Supporting Documents tab.</p>',
      ],
    },
    section2: {
      title: 'The objectives of the scheme are to:',
      paragraphs: [
        '<ul><li>Support economic growth and facilitate growth in jobs and housing, by providing improved transport network connections in West and North-West Cheltenham.</li><li>Enhance the transport network in the West and North-West of Cheltenham area, with the resilience to meet current and future needs.</li><li>Improve the connectivity between the Strategic Road Network (SRN) and the local transport network in West and North-West Cheltenham.</li><li>Deliver a package of measures which is in keeping with the local environment, establishes biodiversity net gain and meets climate change requirements.</li><li>Provide safe access to services for the local community and for users of sustainable transport modes within and to West and North-West Cheltenham.</li></ul>',
      ],
    },
    homeBanner: {
      img: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Home%2Fhome_banner.png?alt=media&token=c12901a2-56b2-4532-bf32-1aef075d0cea',
      imgMobile:
        'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Home%2Fhomepage_banner_tablet.png?alt=media&token=9eeb0a59-ce32-4f82-8c49-3e3965b2572f',
      largeBanner: true,
      ImageAltText: 'Decorative image showing the M5 junction 10 branding with cars, road, hills and trees.',
    },
  },
  aboutPageData: {
    banner: {
      title: 'About the plan',
      text: '',
    },
    section1: {
      title: 'Our current findings and issues',
      text: '',
      list: [
        {
          title: 'Lorem ipsum dolor ',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse viverra rhoncus libero nec faucibus.',
        },
        {
          title: 'Lorem ipsum dolor ',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse viverra rhoncus libero nec faucibus.',
        },
        {
          title: 'Lorem ipsum dolor ',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse viverra rhoncus libero nec faucibus.',
        },
        {
          title: 'Lorem ipsum dolor ',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse viverra rhoncus libero nec faucibus.',
        },
      ],
    },
    section2: {
      title: 'What we will be developing',
      text: '',
    },
    section3: {
      title: 'Scheme Timeline',
      points: [
        {
          year: '2021',
          title: 'Type something',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque accumsan venenatis risus',
        },
        {
          year: '',
          title: 'Type something',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque accumsan venenatis risus',
        },
        {
          year: '2022',
          title: 'Type something',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque accumsan venenatis risus',
        },
        {
          year: '',
          title: 'Type something',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque accumsan venenatis risus',
        },
      ],
    },
  },
  EventPageData: {
    banner: {
      title: 'Events',
      text: '',
      img: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/BG_image_No_Text.png?alt=media&token=e37002c6-ec0e-4522-84cf-81ea46106ab7',
      largeBanner: false,
    },
    eventInfo: [
      {
        title:
          'Event 1: Face to face event at Cheltenham West Community Fire and Rescue Station, GL51 9SN',
        date: 'Tuesday 14 December 2021 10am - 7pm',
        link: 'https://www.google.com/?safe=active&ssui=on',
      },
      {
        title:
          'Event 1: Face to face event at Cheltenham West Fire Station, GL51 9SN',
        date: 'Tuesday 14 December 2021 10am - 7pm',
        link: 'https://www.google.com/?safe=active&ssui=on',
      },
      {
        title:
          'Event 1: Face to face event at Cheltenham West Fire Station, GL51 9SN',
        date: 'Tuesday 14 December 2021 10am - 7pm',
        link: 'https://www.google.com/?safe=active&ssui=on',
      },
      {
        title:
          'Event 1: Face to face event at Cheltenham West Fire Station, GL51 9SN',
        date: 'Tuesday 14 December 2021 10am - 7pm',
        link: 'https://www.google.com/?safe=active&ssui=on',
      },
      {
        title:
          'Event 1: Face to face event at Cheltenham West Fire Station, GL51 9SN',
        date: 'Tuesday 14 December 2021 10am - 7pm',
        link: 'https://www.google.com/?safe=active&ssui=on',
      },
      {
        title:
          'Event 1: Face to face event at Cheltenham West Fire Station, GL51 9SN',
        date: 'Tuesday 14 December 2021 10am - 7pm',
        link: 'https://www.google.com/?safe=active&ssui=on',
      },
    ],
  },
  FAQSdata: {
    banner: {
      title: 'FAQs',
      text: 'There is a need to overcome traffic congestion, ensure improved traffic movement and parking but also promote better working relationships between the traffic, pedestrians and sustainable transport within and around the town. ',
    },
    accordion1: [
      {
        title: '1A) What does the scheme involve?',
        text: [
          'Gloucestershire County Council (GCC) is currently working on a proposed improvements scheme ' +
            'around M5 Junction 10. The scheme’s aim is to remove constraints on the highway network, improve ' +
            'connectivity between the Strategic Road Network and the local transport network, and ensure there ' +
            'is enough capacity to accommodate traffic demand associated with the housing and employment growth in the area. ' +
            'The scheme also aims to provide safe access to services for the local community and for active travel users as ' +
            'well as establishing biodiversity net gain and meet climate change requirements.',
          '<b>The scheme will provide:</b>',
          '<li>Improvements to M5 Junction 10',
          '<li>A new road linking Junction 10 to West Cheltenham',
          '<li>Widening the A4019 Tewkesbury Road',
        ],
      },
      {
        title: '1B) Why is the scheme needed?',
        text: [
          'To unlock proposed housing and employment sites close to Junction 10 on the M5, we need to ensure ' +
            'that there is sufficient highway capacity to accommodate the increased motorised and non-motorised traffic it will generate.',
          'An all-movements junction has been identified as a key infrastructure requirement to enable the housing ' +
            'and economic development proposed by the ' +
            '<a href="https://www.gfirstlep.com/about-us/our-vision/strategic-economic-plan" title="Gloucestershire Local Enterprise Partnerships Strategic Economic Plan" target="_blank"> ' +
            "Gloucestershire Local Enterprise Partnership's Strategic Economic Plan</a> and is central to the transport network sought by the council in our adopted Gloucestershire " + '<a href="https://www.gloucestershire.gov.uk/media/2105626/ltp-policy-document-final-v131.pdf" target="_blank" title="Local transport plan">Local Transport Plan</a>.',
          'The planned housing and economic growth have been included by Cheltenham Borough, Tewkesbury Borough and Gloucester ' +
            'City Councils in the adopted Joint Core Strategy. More information can be found ' +
            '<a href="https://www.jointcorestrategy.org/" target="_blank" title="Joint core strategy">here</a>.',
          'National Highways has also identified in their Birmingham to Exeter Route Strategy that improvements to M5 Junction 10 are ' +
            'a critical requirement to maintain the safe and efficient operation of the M5 corridor, whilst enabling the planned ' +
            'development and economic growth around Cheltenham, Gloucester and Tewkesbury.',
        ],
      },
      {
        title: '1C) The story so far',
        text: [
          'Following a successful bid to Homes England in March 2020, work has been progressing to produce' +
            ' a preferred design for the scheme. Several options were considered and subjected to various traffic and environmental surveys and assessments. ',
          'We have worked with Tewkesbury Borough Council and Cheltenham Borough Council to understand local constraints and ensure that their aspirations ' +
            'for growth and development are accurately represented. ',
            'An options consultation was held in autumn 2020 where the public and other stakeholders were able to view and comment on our different options. Over 400 survey responses were received during the consultation period including valuable feedback on specific elements of the proposed designs for the junction, link road, and widening along the A4109. This feedback helped shape these elements of the scheme going forward.',
          'Since publishing the PRA, we have been working to refine the scheme so we can ask you for further feedback on our detailed proposals and to refine ' +
            'our design before applying for planning permission.',
        ],
      },
      {
        title: '1D) What are the timelines for the scheme?',
        text: [
          '<b>We are working towards the following key milestones:</b>',
          '<li>Late 2022: Planning application (Development Consent Order application) submitted.',
          '<li>2024: Work commences (subject to planning consent).',
          '<li>Late 2025: Work complete and open to traffic (subject to planning consent).',
        ],
      },
    ],
    accordion2: [
      {
        title:
          '2A) What opportunities are there to have my say on the proposals? ',
        text: [
          'A statutory consultation will take place from 08 December 2021 to 15 February 2022. The consultation will ' +
            'include face to face and virtual events where you will be able to learn more about the proposed scheme and have your say. ' +
            'This is your opportunity to give feedback and we will consider all the comments we receive during the statutory consultation ' +
            'as we develop our proposals further.',
          '<br>',
          '<b>You can provide feedback to us in a number of ways, by:</b>',
          '<li>Completing the online consultation survey found on this website.',
          '<li>Attending one of our face to face consultation events where there will be the opportunity to meet our project team, ' +
            'ask questions and complete an online or paper copy of the consultation survey.',
          '<li>Participating in one of our virtual consultation events, where there will be the opportunity to hear about the scheme, ask ' +
            'questions and then complete our consultation survey online.',
          '<li>Request a paper copy of the consultation survey free of charge by contacting ' +
            '<a href="mailto:M5Junction10@atkinsglobal.com" title="Email">M5Junction10@atkinsglobal.com</a>.' +
            ' Completed paper copies can either be given to project team members at consultation events or returned by Freepost M5 JUNCTION 10.',
          '<li>If you are unable to access a digital or paper copy of the survey, our customer service centre will complete it over the phone on your behalf. ' +
            'The customer contact centre telephone number is 01452 426256. The lines will be open Monday to Friday (excluding Bank Holidays) from 9am until 4pm. ' +
            'This is a dedicated number set up for the consultation period. For any other project queries please use 01454 667900.',
        ],
      },
      {
        title:
          '2B) What types of events are being held as part of the statutory consultation?',
        text: [
          'We will host a series of face to face and virtual consultation events during the consultation period, providing multiple platforms for the local community and stakeholders to provide feedback. ' +
            'At these events, attendees will have the opportunity to listen to members of the project team present the proposals and ask any questions they may have.',
          '<br>',
          'These events are free, open to everyone, and have been planned for a range of times.',
          '<br>',
          '<b>Our face to face consultation events will be held on:</b>',
          '<li><b>Tuesday 14 December 2021</b>, 10am – 7pm, Cheltenham West Community Fire and Rescue Station Tewksbury Road, Uckington, Cheltenham, GL51 9SN.',
          '<li><b>Saturday 15 January 2022</b>, 10am – 5pm, Hester Way Community Resource Centre, Cassin Drive, Cheltenham, GL51 7SU.',
          '<br>',
          '<b>The virtual events will take place on the following dates:</b>',
          '<li>Virtual event 1 – <b>Wednesday 15 December 2021</b>, 7-8:30pm.',
          '<li>Virtual event 2 – <b>Thursday 13 January 2022</b>, 2-3:30pm.',
          '<li>Virtual event 3 – <b>Saturday 29 January 2022</b>, 2-3:30pm.',
          '<li>Virtual event 4 – <b>Wednesday 2 February 2022</b>, 7-8:30pm.',
          '<br>',
          'To register and attend one of these virtual events, please see the events page on this website.',
        ],
      },
      {
        title:
          '2C) How do the scheme consultations adhere to COVID-19 social distancing guidelines?',
        text: [
          'The approach to the upcoming statutory consultation has been considered in line with current COVID-19 ' +
            'guidelines and will include a mix of face to face and virtual consultation events.',
          '<br>',
          'We will implement a number of important measures to reassure and protect those attending events. ' +
            'These measures are implemented in accordance with the current advice and guidance from UK and local government and will be continually reviewed. ',
          '<br>',
          'If the COVID-19 situation requires us to cancel events we will advertise any cancellations as widely as possible in the circumstances, including an update on the scheme webpage.',
        ],
      },
      {
        title: '2D) How does the consultation meet accessibility standards? ',
        text: [
          'All consultation digital materials will be provided in a clear and uncomplicated manner that ' +
            'considers those with learning disabilities, colour blindness, and visual impairments in compliance with Web Content Accessibility Guide (WCAG) ' +
            '2.0 standards. ',
          '<br>',
          'Hard copy materials will be sent to consultees on request via post and be made available in public places. The consultation survey can also be completed either online or in paper form.',
          '<br>',
          'To request a paper copy of the brochure and/or survey free of charge, contact ' +
            '<a href="mailto:M5Junction10@atkinsglobal.com" title="Email">M5Junction10@atkinsglobal.com </a>.' +
            'Completed paper copies can either be given to project team members at consultation events or returned by Freepost M5 JUNCTION 10.',
          '<br>',
          'A dedicated phone line will be provided for those unable to access a digital or paper copy of the consultation materials and/or survey. ' +
            'If you require it, our customer service operators can complete the survey for you over the phone on your behalf. ',
          '<br>',
          'The customer contact centre telephone number is 01452 426256. The lines will be open Monday to Friday (excluding Bank Holidays) from 9am until 4pm. ' +
            'This is a dedicated number set up for the consultation period.',
        ],
      },
      {
        title:
          '2E) When will the consultation responses be analysed and how will the findings be reported?',
        text: [
          'We will record and review all responses received during the consultation, taking time to consider and analyse your feedback ' +
            'when making further refinements to our proposed design and to develop our planned mitigation measures.',
          '<br>',
          'We will summarise our findings in a consultation report which will be issued in spring 2022. This will include a description of how ' +
            'our application was informed by the responses received and outline any changes made as a result of the consultation. ' +
            'The report will form part of our Development Consent Order application to the Planning Inspectorate that is planned for ' +
            'late 2022 and will also be available on the scheme webpage.',
        ],
      },
    ],
    accordion3: [
      {
        title: '3A) Who is funding the scheme?',
        text: [
          'GCC submitted a bid in March 2019 to Homes England for funding for the scheme. In March 2020, the council was informed that this bid to Homes England had been successful.',
        ],
      },
      {
        title: '3B) Does funding have to be spent on M5 Junction 10? ',
        text: [
          'The funding from Homes England is ring-fenced for infrastructure to unlock new housing developments and cannot be ' +
            'spent on routine maintenance or other projects. Without this investment, transport modelling has shown that journey ' +
            'time and congestion in the local area will increase if improvements to the junction are not made. ' +
            'These funds are additional to the highway budget that GCC spend on routine maintenance of county roads.',
        ],
      },
      {
        title:
          '3C) Does the positive funding announcement guarantee that the M5 Junction 10 improvements will happen?',
        text: [
          'The successful funding bid announced by Homes England does not mean that the scheme will automatically ' +
            'go ahead, although for information on why the scheme is needed, see Question 1B.',
          '<br>',
          'The proposed improvements to M5 Junction 10 are still subject to planning permission. ' +
            'Prior to the submission of a planning application, the public were able to provide their feedback on the scheme ' +
            'during the options consultation in autumn 2020. Over 400 survey responses were received with more than 80% of ' +
            'respondents agreeing or strongly agreeing that the scheme is necessary. ' +
            'These survey responses included valuable feedback on specific elements of the proposed designs for the junction, ' +
            'link road, and widening along the A4019. This feedback helped shape these elements of the scheme going forward.',
          '<br>',
          'The public will be able to provide feedback again during the upcoming statutory consultation between 8 December 2021 and 15 February 2022. ' +
            'Further work will be undertaken to confirm our scheme before applying for planning consent in late 2022. ',
        ],
      },
      {
        title: '3D) What is the planning route for the scheme?',
        text: [
          'The M5 Junction 10 Improvements Scheme is categorised as a Nationally Significant Infrastructure Project (NSIP) ' +
            'under the Planning Act 2008. A NSIP is a major infrastructure project and can include the construction of new roads. ' +
            'NSIPs need development consent before construction can start. This is granted through a Development Consent Order (DCO) ' +
            'which gives permission to construct and maintain projects categorised as NSIPs.',
          '<br>',
          'As such, we are required to make an application for a DCO to obtain planning permission to construct the scheme, ' +
            'rather than the traditional route of applying for planning permission from the local planning authority under the Town and Country Planning Act 1990. ',
          '<br>',
          'A DCO application to the Planning Inspectorate, the government agency responsible for operating the planning process for NSIPs, is currently planned to be submitted in late 2022. A successful DCO submission has many requirements, including the need to engage with stakeholders and to undertake a statutory consultation. ',
          '<br>',
          'For more information on this process of submitting a DCO to the Planning Inspectorate, please visit the ' +
            '<a href="https://infrastructure.planninginspectorate.gov.uk/" target="_blank" title="National Infrastructure Planning">National Infrastructure Planning website.</a>',
        ],
      },
      {
        title:
          '3E) What is the Development Consent Order process and how does it work? ',
        text: [
          'Following the statutory consultation, the DCO is developed and submitted to the Planning Inspectorate. ' +
            'When they receive and accept the application, they will appoint an independent Examining Authority to carry out the examination.',
          '<br>',
          'Before the examination starts, statutory consultees, relevant local authorities, ' +
            'and anyone with an interest in the affected land are contacted to notify them of their opportunity to ' +
            'register to take part in the examination. It is also publicised in local and national press as well as the ' +
            'Planning Inspectorate including the application in their register of applications received on their webpage. ' +
            'There is a specific webpage for each DCO application where the public can access and read applications and accompanying documents, ' +
            'including reports of pre-application consultations.' +
            ' This webpage is available ' +
            '<a href="https://infrastructure.planninginspectorate.gov.uk/projects/south-west/m5-junction-10-improvements-scheme/" title="M5 Improvements Scheme" target="_blank">here.</a>',
          '<br>',
          'The Examining Authority considers contributions, including any Local Impact Reports received from local authorities, ' +
            'representations from interested parties, written answers provided to written questions, and evidence provided at hearings.',
          '<br>',
          'The Examining Authority manages the examination of applications and decides which main issues it will examine. ' +
            'At the end of an examination, the Examining Authority submit a report to the Secretary of State. ' +
            'This includes a recommendation on whether to grant development consent. The Secretary of State decides to grant or refuse development consent.',
          '<br>',
          'For more information about this process, please visit the ' +
            '<a href="https://infrastructure.planninginspectorate.gov.uk/" target="_blank" title="National Infrastructure Planning">' +
            'National Infrastructure Planning website </a>' +
            'or call the Planning Inspectorate on <a href="tel:0303 444 5000" title="Mobile number">0303 444 5000.</a>',
        ],
      },
      {
        title:
          '3F) How are we meeting our statutory duties under the Planning Act 2008?',
        text: [
          'We intend to make an application to the Secretary of State for Transport under Section 37 of the Planning ' +
            'Act 2008 for a DCO to authorise the construction and operation of the M5 Junction 10 Improvements Scheme. ' +
            'Under Section 48 of the Planning Act 2008, we have issued a notice of this proposed application for a DCO. ' +
            'There are a variety of other statutory duties that must be met throughout the process of submitting a DCO.',
          '<br>',
          'We have published a Statement of Community Consultation <i>(SoCC)</i> in accordance with Section 47 of the Planning Act 2008, ' +
            'as part of the process of submitting a DCO application. The SoCC sets out how we will consult with the local community about the Scheme. ' +
            'The document has been made available for inspection by the public with a notice being issued stating where and when the SoCC can be viewed, ' +
            'as required by Section 47. The SoCC can be viewed at GCC’s scheme webpage, on any council owned public computer, or at our face to face events.',
          '<br>',
          'Before the DCO application can be submitted, we must consult with a variety of persons and bodies about the proposed application in ' +
            'accordance with the requirements of the Planning Act 2008. This includes land that may be affected by the Scheme and that may be subject to ' +
            'powers of compulsory acquisition in the DCO. Under Section 42 of the Planning Act 2008, we are seeking to consult people who have an interest ' +
            'in this land or people who could have a claim for compensation as a result of the Scheme going ahead. ' +
            'We have issued a notice to inform anyone who may have a relevant interest in the land (under Section 44 of the Planning Act 2008) of the consultation.',
        ],
      },
      {
        title:
          '3G) What is Gloucestershire County Council and what is their role?',
        text: [
          'GCC is the Highway Authority for Gloucestershire. Alongside day-to-day highway and maintenance work and smaller ' +
            'improvements schemes, GCC also undertakes major projects on key parts of the road network.',
          '<br>',
          'The M5 Junction 10 Improvements Scheme will be delivered by GCC, with support from National Highways and Homes England. ',
          '<br>',
          'For more information about GCC, see ' +
            '<a href="https://www.gloucestershire.gov.uk/" target="_blank" title="Gloucestershire website">their website.</a>',
        ],
      },
      {
        title: '3H) What is National Highways and what is their role?',
        text: [
          'National Highways, previously known as Highways England, is the government-owned company charged with operating, ' +
            'maintaining and improving England’s motorways and major A roads. In the south west, its network totals 620 miles, ' +
            'and encompasses the M5 motorway amongst other major roads in Gloucestershire.',
          '<br>',
          'National Highways has been working closely with GCC on the development of the M5 Junction 10 Improvements Scheme to ' +
            'date and will continue to support it moving forward. Its formal role is that of a statutory consultee for the GCC-led scheme. ',
          '<br>',
          'For more information about National Highways, see ' +
            '<a href="https://highwaysengland.co.uk/" target="_blank" title="Highways England website">their website.</a>',
        ],
      },
      {
        title: '3I) What is Homes England and what is their role?',
        text: [
          'Homes England is the government’s housing agency. GCC is working with Homes England to secure infrastructure funding. ' +
            'They have the appetite, influence, expertise and resources to drive positive market change. By using their investment ' +
            'products to drive market change and releasing more land to developers who want to make a difference, ' +
            'they are making possible the new homes that England needs and helping to improve neighbourhoods and grow communities.',
          '<br>',
          'The Housing Infrastructure Fund is administered and monitored by Homes England. The programme is helping to deliver up to ' +
            '300,000 new homes across England by providing local authorities with grant funding for new infrastructure to unlock homes in ' +
            'areas of greatest housing demand.',
          '<br>',
          'For more information about Homes England, see ' +
            '<a href="https://www.gov.uk/government/organisations/homes-england" target="_blank">their website.</a>',
        ],
      },
    ],
    accordion4: [
      {
        title:
          '4A) Is my land / property subject to a Compulsory Purchase Order (CPO)?',
        text: [
          'No CPOs have been made to acquire any land for this scheme. ' +
            'This is a lengthy, statutory process which takes place under the Compulsory Purchase Act 1965 which outlines ' +
            'the power of local authorities to “take land compulsorily for public purposes” e.g., to deliver development projects.',
          '<br>',
          'What is right for GCC to do now for a project of this size is to agree a resolution in principle that they can exercise ' +
            'these powers, if required, to deliver the scheme. This means that GCC have in principle the power to compulsorily acquire ' +
            'land in order to deliver this project. ',
          '<br>',
          'It always remains the case that, where any third-party land is required to deliver highway works, the council’s clear ' +
            'preference is a negotiated settlement route. This enables a more flexible and “mutually agreed settlement” to be achieved.',
          '<br>',
          'Landowners and tenants that might be affected have been offered meetings throughout the process and ' +
            'we will continue to work closely with them throughout the development of the scheme. If you think that ' +
            'your property or land is directly affected but you have not been contacted, please contact ' +
            '<a href="mailto:M5Junction10@atkinsglobal.com" title="Email">M5Junction10@atkinsglobal.com.</a>',
        ],
      },
    ],
    accordion5: [
      {
        title:
          '5A) Will the scheme reduce the congestion and queuing on the M5?',
        text: [
          'Yes. Work done on modelling journey time and congestion demonstrates that the improvements will improve the operation of the motorway junction and reduce queuing.',
        ],
      },
      {
        title:
          '5B) What will happen to traffic if the motorway junction is upgraded?',
        text: [
          'Providing a more direct route from the M5 to north and west Cheltenham will take traffic, ' +
            'particularly at peak times, away from inappropriate local roads on the west side of the town, such as Princess Elizabeth Way. ' +
            'This will improve journey times for local users and provide positive quality of life benefits in terms of noise and air quality.',
        ],
      },
      {
        title: '5C) What are the current provisions for active travel?',
        text: [
          'The plans for active travel (walking and cycling) included in the preferred route announcement are currently being progressed. ' +
            'As part of the scheme elements, we are proposing to improve the provision for walking and cycling. We are now reaching a ' +
            'stage where more detail will be provided as we receive results of surveys, assessments, and investigations. ',
          '<br>',
          'The detailed proposals for walking and cycling which aim to deliver high quality and segregated active travel provision are presented as part of our statutory consultation which is taking place between 8 December 2021 and 15 February 2022. Stakeholders and the local community have the opportunity to provide feedback on our proposals during this consultation.',
        ],
      },
    ],
    accordion6: [
      {
        title:
          '6A) How does this scheme reflect the recent action plan set out as part of GCC’s response to the UK Government Climate Emergency announcement?',
        text: [
          'We are committed to creating a sustainable future. We jointly hosted a Climate Change Summit in May 2019 with the ' +
            'Gloucestershire First Local Enterprise Partnership (GFirst LEP), and as a result, we will be updating our Climate Change Strategy for the county. ' +
            'For more information, and to view our previous Corporate Climate Change Strategy and Action Plan, visit ' +
            '<a href="https://www.gloucestershire.gov.uk/planning-and-environment/other-planning-and-environment-matters/environment-strategies/" title="Gov UK Plan" target="_blank">this website.</a>',
          '<br>',
          'All Gloucestershire councils have now declared a climate emergency. Our declaration in May 2019 included commitments for us to be net zero by 2030, and deliver a carbon ' +
            'neutral county by 2050, working with partners to deliver an 80% reduction by 2030.',
          '<br>',
          'Our approach to how we tackle climate change is also being informed by national policy, such as the Transport Decarbonisation Plan that presents a view of current and future ' +
            'technology enabling a transition to a low carbon transport system without people needing to change their lifestyles.',
          '<br>',
          'Our Local Transport Plan (LTP) is in the process of being updated, and as part of this, we are considering decarbonising road and rail freight by 2050.',
          '<br>',
          'As a local authority, it is our role to ensure that anyone who lives, works or travels through Gloucestershire is prepared for the impacts of climate change; ' +
            'that’s why it’s important that we invest in, maintain and improve Gloucestershire’s transport network, however, we recognise that this should not be at great expense to the local environment.',
          '<br>',
          'Compared to continuing to use the existing road network without the scheme in place, the M5 Junction 10 Improvements Scheme may result in a reduction in emissions due to ' +
            'reducing stop-start traffic on local roads.',
        ],
      },
      {
        title:
          '6B) I’ve been contacted about surveys, why do you want to survey my land?',
        text: [
          'Environmental surveys are being carried out on all land that falls close to any scheme element. ' +
            'Surveying this area will allow us to track and understand wildlife movements that occur in the vicinity of our proposals.',
        ],
      },
      {
        title:
          '6C) What type of environmental surveys will be undertaken on my land and why?',
        text: [
          'Environmental surveys will help us to understand what wildlife and habitats are present, ' +
            'and whether there are any protected species that need to be considered when developing designs.',
          '<br>',
          '<b>The type of survey depends on the nature of your land, for example if it contains ponds or lakes, ' +
            'hedges or woodland, or flowing water or rivers, surveys may include:</b>',
          '<li>Bats: A survey conducted on foot at dusk or dawn to record bat activity.',
          '<li>Dormice: A survey involving the deployment of plastic/wood nest tubes in woodland hedgerow and scrub habitats, which are then checked at regular intervals for signs of dormice.',
          '<li>Otter and Water Vole: A survey conducted on foot to search watercourses for signs of use by otter and water vole.',
          '<li>Breeding and wintering birds: A survey conducted on foot to assess the presence of breeding and wintering birds.',
          '<li>Reptiles: A survey involving the deployment of artificial refuges (usually small pieces of roofing material) in suitable grassland habitats, ' +
            'which are then checked to search for basking reptiles.',
          '<li>Habitat suitability and eDNA: A survey conducted on foot to assess suitability of habitats for great crested newts and collect water samples from suitable waterbodies.',
          '<li>Invertebrates: A walkover survey to assess habitat suitability for invertebrates.',
          '<li>Hedgerows: A walkover survey to assess hedgerow habitats.',
          '<li>Invasive plants: A walkover survey to search for invasive non-native species of plants.',
          '<br>',
          'If we have contacted you about undertaking surveys on your land, someone from our survey coordination team will be in touch ahead of surveys ' +
            'taking place to provide you with more details about the survey specifics and to reconfirm your consent.',
        ],
      },
      {
        title:
          '6D) Will widening of the A4019 to a dual carriageway increase the level of vibrations for local residents?',
        text: [
          'The Design Manual for Roads and Bridges guidance (LA 111) states that the main source of ground-borne vibration is the ' +
            'passage of vehicles over poor quality road surfaces from irregularities such as potholes. ',
          '<br>',
          'The widening of the A4019 will require it to be resurfaced, meaning the road surfaces would be smooth when laid and there would be no vibration. ' +
            'As time goes on, maintenance would address issues with the surfacing as they arise. ',
          '<br>',
          'It is unlikely that vibrations from such surfaces will be noticeable across the M5 Junction 10 Improvements Scheme when in operation.',
        ],
      },
    ],
    accordion7: [
      {
        title:
          '7A) Are the proposed improvements linked to the West Cheltenham Transport Improvement Scheme; UK Cyber Business Park (WCTIS)?',
        text: [
          'The proposed improvements around M5 Junction 10 are separate to WCTIS, which is being funded through the GFirst LEP Growth Deal. ' +
            'Both schemes are complementary and will deliver highway improvements that will improve connectivity around Gloucestershire. ',
          '<br>',
          'You can find more information about WCTIS ' +
            '<a href="https://www.gloucestershire.gov.uk/highways/major-projects-list/west-cheltenham-transport-improvement-scheme/" target="_blank" title="West Cheltenham Transport Improvement Scheme UK">here.</a>',
        ],
      },
      {
        title:
          '7B) Is there a link between the proposed M5 Junction 10 Improvements Scheme and the M5 Junction 9 and A46 (Ashchurch) Transport Scheme?',
        text: [
          'Our improvements proposed around M5 Junction 10 are separate to the M5 Junction 9 and A46 (Ashchurch) Transport Scheme. ' +
            'The interaction between the M5 Junction 9 improvements and Junction 10 improvements will be a key consideration in the design and technical work for the Junction 9 scheme. ',
          '<br>',
          'More information about the M5 Junction 9 and A46 (Ashchurch) Transport Scheme can be found ' +
            '<a href="https://www.gloucestershire.gov.uk/highways/major-projects-list/m5-junction-9-and-a46-ashchurch-transport-scheme/" target="_blank" title="Ashchurch">here.</a>',
        ],
      },
      {
        title:
          '7C) There are works happening now on M5 Junction 11. Is there a link between this and the proposed M5 Junction 10 Improvements Scheme?',
        text: [
          'The M5 Junction 10 Improvements Scheme is planned to be open to traffic in 2025. Whilst we make every effort to co-ordinate works to ensure ' +
            'the network remains safe and serviceable, essential maintenance projects must happen now.',
          '<br>',
          '<b>During 2021 two major schemes affecting the network in Gloucestershire will be taking place. These are led by National Highways, but are not related to the ' +
            'M5 Junction 10 Improvements Scheme, and include:</b>',
          '<li>Bridge deck refurbishment works to improve the Staverton Overbridge, a structure that crosses the M5 between Junctions 10 and 11, ' +
            'expected until spring 2022; and',
          '<li>Junction 11 Golden Valley gyratory bridge refurbishment, an 18-month scheme for a full refurbishment of the north and south bridges, ' +
            'expected to last until summer 2022.',
        ],
      },
      {
        title:
          '7D) How will the scheme impact the objectives outlined in the Joint Core Strategy (JCS)?',
        text: [
          'The JCS sets out that by 2031, considering all the development allocations set out in the Joint Core Strategy, ' +
            'an all-movements Junction 10 will be required. The funding decision will have a direct impact on the delivery of the JCS objectives. ' +
            'Without the funding from Homes England, improvements to the motorway cannot be made at present; this may impact on the level of development that can be ' +
            'delivered. The local planning authorities are in close engagement with both GCC and National Highways and have worked as key partners in the delivery ' +
            'of the bid and in progressing the scheme. ',
        ],
      },
      {
        title: '7E) How were the housing and employment areas agreed?',
        text: [
          'The homes and employment areas are in the JCS agreed between the three local planning authorities: ' +
            'Cheltenham Borough, Tewkesbury Borough and Gloucester City councils. The JCS forms part of the statutory development plan for these areas.',
        ],
      },
      {
        title:
          '7F) Will schools, community facilities and shops be built too, as well as the road?',
        text: [
          'Yes, they will in the context of the schemes being promoted for North West and West Cheltenham. ' +
            'The JCS sets out the planning policy for these areas, which includes schools, community facilities and shops.',
        ],
      },
      {
        title:
          '7G) How will the Elms Park development and A4109 widening be developed together?',
        text: [
          'The Elms Park development is a live planning application which currently proposes improvements to the A4019 between the Fire Station near ' +
            'Homecroft Drive and Gallagher Retail Park. During the options consultation, concerns were raised about a disconnect between the ' +
            'Elms Park development proposals and our A4019 proposals resulting in a lack of continuity and consistency for the road network and walking, cycling and horse-riding facilities.',
          '<br>',
          'Following your feedback, we have decided to incorporate the A4019 widening elements of the Elms Park access arrangements into the proposed improvements to ' +
            'the A4019 and we will be working closely with the Elms Park development team to ensure consistency between the schemes. ' +
            'Incorporating the access arrangements into the proposed improvements to the A4019 will help address your concerns around continuity along the A4019. ',
          '<br>',
          'It should be noted that our proposals are an alternative to those shown in the live planning application submitted by the Elms Park developer. ' +
            'We are working with both the developer and local planning authorities to agree on a single solution for widening this section of the A4019. ',
          '<br>',
          'To view the live planning application submitted by the Elms Park developer please visit ' +
            '<a href="https://www.tewkesbury.gov.uk/elms-park-development/" target="_blank" title="Tewkesbury Borough Council">Tewkesbury Borough Council’s website.</a>',
        ],
      },
      {
        title:
          '7H) Isn’t the upgrade to Arle Court Park and Ride included in this scheme?',
        text: [
          'An upgrade to Arle Court Park and Ride is included as part of the package of improvements ' +
            'funded by Homes England however GCC has decided to take this forward through a different planning route meaning that this scheme will be progressed separately.',
          '<br>',
          'More information about the Arle Court Transport Hub proposals can be found ' +
            '<a href="https://www.gloucestershire.gov.uk/highways/major-projects-list/arle-court-transport-hub/" title="Arle Court Transport Hub" target="_blank">here.</a>',
        ],
      },
      {
        title:
          '7I) Aren’t the A38/A4019 Junction Improvements at Coombe Hill included in this scheme?',
        text: [
          'Since finishing the options consultation in autumn 2020, we have decided to progress the A38/A4019 Junction Improvements at Coombe Hill as a separate scheme. ' +
            'This will provide a more resilient local road network in advance of the Junction 10 works commencing. ' +
            'All feedback given during the options consultation phase has been considered and we continue work with the local community and our stakeholders as the scheme design progresses. ',
          '<br>',
          'Please visit  ' +
            '<a href="https://www.gloucestershire.gov.uk/ch-junction/" target="_blank">this website</a>' +
            ' for the latest information.',
        ],
      },
    ],
  },
  PrivacyPolicy: {
    banner: {
      title: 'Privacy Policy',
    },
    articles: [
      {
        title: 'Our responsibilities',
        paragraphs: [
          '',
        ],
      },
    ],
  },
  NotFound: {
    banner: {
      title: 'Uh oh, looks like you’ve reached a dead end',
      text: 'Not to worry, lets get you back to where you belong! ',
      button: 'Back to Homepage',
      img: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/notfound.png?alt=media&token=324f31f9-7b5e-4a71-a9fd-b39548771123',
    },
  },
  FeedbackPage: {
    banner: {
      title: 'Give Feedback',
      text: 'We Need Your Feedback Now you have viewed the options, please share your views with us by completing the feedback form.',
    },
    intro: {
      index: 0,
      title: 'M5 Junction 10 Improvements Scheme',
      message: [
        'If you require our consultation materials (including our survey) in an accessible format, ' +
          'or have specific accessibility needs in relation to our consultation, please get in touch by emailing ' +
          '<a href="mailto:m5junction10@atkinsglobal.com/" title="Email">m5junction10@atkinsglobal.com</a>' +
          ' or by calling <a href="tel:01454667900" title="Telephone">01454 667900</a> and leaving a message.',
        'If you require assistance filling out this survey, please call Gloucestershire County Council ' +
          'Customer Services on 01452 426256 (Monday - Friday 9am - 4pm, excluding bank holidays).',
        'This consultation is the second stage of gathering public views on our proposals for the M5 ' +
          'Junction 10 Improvements Scheme. Your feedback will inform our continuing development of the scheme, ' +
          'to the point when we’re ready to submit our Development Consent Order application.',
        'We have produced a consultation brochure which provides an overview of our proposals. This can be viewed at ' +
          '<a href="#/en/supporting-documents" title="Supporting documents" target="_blank">www.gloucestershire.gov.uk/J10/</a>. ' +
          'We recommend that you read this before completing the survey. The closing date for receiving responses is 11:59pm on Tuesday 15 February 2022.',
        'Please complete all the sections that are relevant to you. To complete the entire survey should take no longer than 15 minutes. ' +
          'Please do not enter any details that could identify you as an individual. ',
        '<h5 class="title">Your Data</>',
        'The information and responses that you provide will be treated as confidential and in accordance with ' +
          'UK data protection legislation. Gloucestershire County Council is the data controller for all information ' +
          'collected from this survey. Our lawful ' +
          'basis for processing this data is for the performance of task carried out in the public interest in line ' +
          'with the Planning Act 2008. Your data will be retained for the duration of the project following its submission ' +
          'before being destroyed. This survey and subsequent analysis are being carried out by our partner Atkins and their ' +
          'supplier Accent. Your information will not be shared with or passed onto any other party. ' +
          'For more information, please see Privacy Notices, available here: ' +
        '<a href="https://www.gloucestershire.gov.uk/highways/highways-privacy-notices/" ' +
        'target="_blank" title="Highways Privacy Notice">https://www.gloucestershire.gov.uk/highways/highways-privacy-notices/</a>',
        "Responses received as part of the consultation will be anonymised, stored, and handled in accordance with Gloucestershire County Council's Data" +
          ' Protection Policy. To view our information management and security policies, go to ' +
          '<a href="https://www.gloucestershire.gov.uk/council-and-democracy/strategies-plans-policies/information-management-and-security-policies/" ' +
          'target="_blank" title="Information Management and security policies">https://www.gloucestershire.gov.uk/council-and-democracy/strategies-plans-policies/information-management-and-security-policies/</a>.',
      ],
      btn: 'Start survey',
    },

    content: {
      sectionBtn: 'Save and next step',
      submit: 'Submit',
      sectionTitle: [
        {
          title: 'M5 Junction 10 Improvements Scheme.',
          text: '',
        },
        {
          title: 'Our proposals',
          text: 
            'We are now going to ask you for your views on our scheme proposals. Please visit our virtual information portal or refer to our consultation brochure for more information. ' 
        },
        {
          title: 'Our effect on environment',
          text:
            'Information on how we are assessing our environmental impact and our mitigation proposals can be found in our consultation brochure, ' +
            'as well as our Preliminary Environmental Information Report and its Non-Technical Summary. These can be found on the ' +
              '<a href="#/en/supporting-documents" target="_blank" title="supporting documents page">Supporting Documents</a> page.',
        },
        {
          title: 'About you and equality monitoring',
          text:
            '*The council is committed to ensuring that our services are delivered fairly. ' +
            'We are asking you to answer the following questions about yourself so that we can understand how ' +
            'representative the responses we receive are. We do this to follow the Public Sector Equality Duty ' +
            'and pay due regard to the Equality Act 2010. This information will be used to inform updates to our ' +
            'Equality Impact Assessment and to review our consultation approach going forward. The information that ' +
            'you provide will be kept confidential and only used for the purpose we have outlined. You do not have to ' +
            'answer these questions, and it will make no difference at all to the way the council treats you if you ' +
            'prefer not to answer these questions. Our legal basis for using your special category information is for ' +
            'reasons of substantial public interest, meeting Schedule 1, Part 2(6)(2)(a) of the Data Protection Act for ' +
            'statutory and government purposes.',
        },
        { title: 'Complete', text: '' },
      ],

      //general
      section1Questions: {
        index: 1,
        Q1: {
          title:
            '1. To what extent do you agree or disagree with the proposals for improvements to M5 Junction 10?',
          questions: [
            { value: 'Strongly agree', textbox: false },
            { value: 'Agree', textbox: false },
            { value: 'Disagree', textbox: false },
            { value: 'Strongly disagree', textbox: false },
            { value: "Don't know", textbox: false },
          ],
        },
        Q2: {
          title:
            '2. What do you currently use M5 Junction 10 and A4019 Tewkesbury Road for?',

          paragraphs: [
            'Please select all that apply for M5 Junction 10 and for A4019 Tewkesbury Road.',
          ],
          answers: [
            'Commuting for work',
            'Commuting for education',
            "Employers' business",
            'Leisure',
            'Shopping',
            'Other',
            "Don't Use",
          ],
          questions: ['M5 Junction 10', 'A4019 Tewkesbury Road'],
          commentBox: "If you responded 'other', please state for what purpose",
          commentBoxPreviewText: 'Type your answer here',
        },
        Q3: {
          title:
            '3. How often do you currently use M5 Junction 10 or A4019 Tewkesbury Road?',

          paragraphs: [
            'Please select one time period for both M5 Junction 10 and A4019 Tewkesbury Road.',
          ],
          answers: [
            'Daily',
            '3-4 times a week',
            'About once a week',
            'Once or twice a month',
            'Rarely',
            'Never',
          ],
          questions: ['M5 Junction 10', 'A4019 Tewkesbury Road'],
        },
        Q4: {
          title:
            '4. How do you typically travel when you use M5 Junction 10 and A4019 Tewkesbury Road?',

          paragraphs: [
            'Please select one circle for each means of transport for both M5 Junction 10 and A4019 Tewkesbury Road.',
          ],
          answers: [
            'Car or van',
            'Cycle',
            'HGV',
            'Motorcycle',
            'Bus or coach',
            'Walk',
            'Other',
            "Don't use",
          ],
          questions: ['M5 Junction 10', 'A4019 Tewkesbury Road'],
        },
      },

      //our proposal
      section2Questions: {
        index: 2,
        subsection1: {
          title: 'M5 Junction 10',
          Q1: {
            title:
              '5. To what extent do you agree or disagree with our proposals for M5 Junction 10?',
            questions: [
              { value: 'Strongly agree', textbox: false },
              { value: 'Agree', textbox: false },
              { value: 'Disagree', textbox: false },
              { value: 'Strongly disagree', textbox: false },
              { value: "Don't know", textbox: false },
            ],
          },
          Q2: {
            title:
              '6. Do you have any comments on our proposals for M5 Junction 10?',
            question: [{ optionDetail: 'Placeholder text asking to enter comment here' }],
            commentBoxPreviewText: 'Please enter your comments here',
          },
        },
        subsection2: {
          title: 'West Cheltenham Link Road',
          Q1: {
            title:
              '7. To what extent do you agree or disagree with our proposals for West Cheltenham Link Road?',
            questions: [
              { value: 'Strongly agree', textbox: false },
              { value: 'Agree', textbox: false },
              { value: 'Disagree', textbox: false },
              { value: 'Strongly disagree', textbox: false },
              { value: "Don't know", textbox: false },
            ],
          },
          Q2: {
            title:
              '8. Do you have any comments on our proposals for West Cheltenham Link Road?',
            question: [{ optionDetail: 'Placeholder text asking to enter comment here' }],
            commentBoxPreviewText: 'Please enter your comments here',
          },
        },
        subsection3: {
          title: 'Withybridge Lane',
          Q1: {
            title:
              '9. Part of our West Cheltenham Link Road proposals identify changes to Withybridge Lane. What is your preferred option for Withybridge Lane? Please select one.',
            questions: [
              {
                value:
                  'Option 1: Withybridge Lane kept open for traffic with Withybridge Lane/A4019 junction having a left in and left out turning only',
                textbox: false,
              },
              {
                value:
                  'Option 2: Permanently closing to through traffic at the northern end of Withybridge Lane',
                textbox: false,
              },
              {
                value:
                  'Option 3: Permanently closing to through traffic in the middle of Withybridge Lane',
                textbox: false,
              },
              { value: 'None of the above', textbox: false },
            ],
            commentBox:
              "If you responded 'none of the above', please let us know why:",
            commentBoxPreviewText: 'Please type the purpose here',
            optionDetail: 'Placeholder text asking to enter comment here',
          },
        },
        subsection4: {
          title: 'A4019 Tewkesbury Road Widening',
          extrasubsection1: {
            title: 'A4019 Subsection 1',
            Q1: {
              title:
                '10. To what extent do you agree or disagree with our proposals for A4019 Subsection 1?',
              questions: [
                { value: 'Strongly agree', textbox: false },
                { value: 'Agree', textbox: false },
                { value: 'Disagree', textbox: false },
                { value: 'Strongly disagree', textbox: false },
                { value: "Don't know", textbox: false },
              ],
            },
            Q2: {
              title:
                '11. Do you have any comments on our proposals for A4019 Subsection 1?',
              question: [{ optionDetail: 'Placeholder text asking to enter comment here' }],
              commentBoxPreviewText: 'Please enter your comments here',
            },
          },
          extrasubsection2: {
            title: 'A4019 Subsection 2',
            Q1: {
              title:
                '12. To what extent do you agree or disagree with our proposals for A4019 Subsection 2?',
              questions: [
                { value: 'Strongly agree', textbox: false },
                { value: 'Agree', textbox: false },
                { value: 'Disagree', textbox: false },
                { value: 'Strongly disagree', textbox: false },
                { value: "Don't know", textbox: false },
              ],
            },
            Q2: {
              title:
                '13. Do you have any comments on our proposals for A4019 Subsection 2?',
              question: [{ optionDetail: 'Placeholder text asking to enter comment here' }],
              commentBoxPreviewText: 'Please enter your comments here',
            },
          },
        },
        subsection5: {
          title: 'Gallagher Retail Park Junction',
          Q1: {
            title:
              '14. To what extent do you agree or disagree with our proposals for Gallagher Retail Park Junction in 2031?',
            questions: [
              { value: 'Strongly agree', textbox: false },
              { value: 'Agree', textbox: false },
              { value: 'Disagree', textbox: false },
              { value: 'Strongly disagree', textbox: false },
              { value: "Don't know", textbox: false },
            ],
          },
          Q2: {
            title:
              '15. Do you have any comments on our proposals for Gallagher Retail Park Junction?',
            question: [{ optionDetail: 'Placeholder text asking to enter comment here' }],
            commentBoxPreviewText: 'Please enter your comments here',
          },
        },
      },

      //environment
      section3Questions: {
        index: 3,
        Q1: {
          title:
            '16. To what extent do you agree or disagree with our proposed approach to improve the environmental impacts of the M5 Junction 10 Improvements Scheme?',
          questions: [
            { value: 'Strongly agree', textbox: false },
            { value: 'Agree', textbox: false },
            { value: 'Disagree', textbox: false },
            { value: 'Strongly disagree', textbox: false },
            { value: "Don't know", textbox: false },
          ],
        },
        Q2: {
          title:
            '17. Do you have any comments on our proposed approach to improve the environmental impacts of the M5 Junction 10 Improvements Scheme?',
          question: [{ optionDetail: 'Placeholder text asking to enter comment here' }],
          commentBoxPreviewText: 'Please enter your comments here',
        },
        Q3: {
          title: '18. Do you have any comments on our proposed landscape designs?',
          question: [{ optionDetail: 'Placeholder text asking to enter comment here' }],
          commentBoxPreviewText: 'Please enter your comments here',
        },
        subsection0: {
          title: "Overall comments",
          Q4: {
            title:
                '19. Do you have any other comments you would like to make about our proposals?',
            question: [{ optionDetail: 'Placeholder text asking to enter comment here' }],
            commentBoxPreviewText: 'Please enter your comments here',
          },
        },
        subsection1:{
          title: "About the consultation",
          Q5: {
            title:
                '20. How did you find out about our consultation?',
            paragraphs: [
              'Please select all that apply.',
            ],
            answers: [
              'Postcard or letter through the door',
              'Local council website',
              "Local community group/society",
              'Newspaper',
              'Social media',
              'Other',
            ],
            questions: [''],
            commentBox: "If you responded 'other', please state how:",
            commentBoxPreviewText: 'Please enter the purpose here',
          },

          Q6: {
            title: '21. Do you have any comments on our consultation process?',
            question: [{ optionDetail: 'Placeholder text asking to enter comment here' }],
            commentBoxPreviewText: 'Please enter your comments here',
          },
          Q7: {
            title: '22. Are you responding on behalf of an organisation?',
            questions: [
              { value: 'Yes', textbox: false },
              { value: 'No', textbox: false },
            ],
            commentBox: 'If yes, please state which organisation.',
            commentBoxPreviewText: 'Please enter the name of your organisation here',
            optionDetail: 'Placeholder text asking to enter comment here',
          },
          Q8: {
            title:
                '23. Would you like to be kept informed of the progress on our scheme going forward?',
            questions: [
              { value: 'Yes', textbox: false },
              { value: 'No', textbox: false },
            ],
          },
          Q9: {
            title: '',
            text: 'If you responded yes, then please provide an email address and/or contact number:',
            values: {
              number: {
                title: 'Contact number',
                placeholder: 'Enter your contact number',
              },
              email: {
                title: 'Email address',
                placeholder: 'Enter email address',
              },
            },
            optionDetail: 'Placeholder text asking to enter comment here',
          },
        },
      },

      //about you
      section4Questions: {
        index: 4,
        Q1: {
          title: '',

          text: '',
          values: {
            postCode: { title: 'Postcode', placeholder: 'Postcode' },
            subtitle: '24. Please provide us with the first part of your postcode (e.g. GL51). This will help us to understand where your feedback is coming from.',
          },
          optionDetail: 'Placeholder text asking to enter comment here',
        },
        Q2: {
          title: '25. Gender: What gender do you identify as?',
          questions: [
            { value: 'Male', textbox: false },
            { value: 'Female', textbox: false },
            { value: 'Non-binary', textbox: false },
            { value: 'Prefer not to say', textbox: false },
          ],
        },
        Q3: {
          title:
            '26. Gender re-assignment: Is your gender identity the same as the gender you were assigned at birth?',
          questions: [
            { value: 'Yes', textbox: false },
            { value: 'No', textbox: false },
            { value: 'Prefer not to say', textbox: false },
          ],
        },
        Q4: {
          title:
            '27. Sexual orientation: How would you describe your sexual orientation?',
          questions: [
            { value: 'Heterosexual / straight', textbox: false },
            { value: 'Gay woman / lesbian', textbox: false },
            { value: 'Gay man', textbox: false },
            { value: 'Bisexual', textbox: false },
            { value: 'Other', textbox: false },
            { value: 'Prefer not to say', textbox: false },
          ],
        },
        Q5: {
          title:
            '28. Ethnicity: Ethnic origin categories are not about nationality, place of birth or citizenship. ' +
            'They are about the group to which you as an individual perceive you belong.',
          paragraphs: [
            'Please indicate your ethnic origin by selecting the appropriate box',
          ],

          values: [
            {
              title: 'White',
              options: [
                'English',
                'Welsh',
                'Scottish',
                'Northern Irish',
                'Irish',
                'Gypsy or Irish Traveller',
                'Any other white background',
                'Prefer not to say'
              ],
            },
            {
              title: 'Mixed/multiple ethnic groups',
              options: [
                'White and Black Caribbean',
                'White and Black African',
                'White and Asian',
                'Any other mixed background',
              ],
            },
            {
              title: 'Asian/Asian British',
              options: [
                'Indian',
                'Pakistani',
                'Bangladeshi',
                'Chinese',
                'Any other Asian background',
              ],
            },
            {
              title: 'Black/African/Caribbean/Black British',
              options: [
                'African',
                'Caribbean',
                'Any other Black / African / Caribbean background',
              ],
            },
            {
              title: 'Other ethnic group',
              options: ['Arab', 'Any other ethnic group'],
            },
          ],
          commentBox: 'Other (please state)',
          commentBoxPreviewText: 'Please enter your ethnicity here',
        },
        Q6: {
          title: '29. Age: What is your age?',
          questions: [
            { value: '15 or under', textbox: false },
            { value: '16-24', textbox: false },
            { value: '25-29', textbox: false },
            { value: '30-34', textbox: false },
            { value: '35-39', textbox: false },
            { value: '40-44', textbox: false },
            { value: '45-49', textbox: false },
            { value: '50-54', textbox: false },
            { value: '55-59', textbox: false },
            { value: '60-64', textbox: false },
            { value: '65+', textbox: false },
            { value: 'Prefer not to say', textbox: false },
          ],
        },
        Q7: {
          title: '30. Disability: Do you consider yourself to have a disability?',
          questions: [
            { value: 'Yes', textbox: false },
            { value: 'No', textbox: false },
            { value: 'Prefer not to say', textbox: false },
          ],
        },
        Q8: {
          title:
            '31. Marriage and civil partnership: Are you married or in a civil partnership?',
          questions: [
            { value: 'Yes', textbox: false },
            { value: 'No', textbox: false },
            { value: 'Prefer not to say', textbox: false },
          ],
        },
        Q9: {
          title: '32. Religion and/or belief: What is your religion or belief?',
          questions: [
            { value: 'No religion', textbox: false },
            { value: 'Buddhist', textbox: false },
            { value: 'Christian', textbox: false },
            { value: 'Hindu', textbox: false },
            { value: 'Jewish', textbox: false },
            { value: 'Muslim', textbox: false },
            { value: 'Sikh', textbox: false },
            { value: 'Any other religion', textbox: false },
            { value: 'Prefer not to say', textbox: false },
            { value: 'Other (please state):', textbox: false },
          ],
          commentBox: 'If other, please state',
          commentBoxPreviewText: 'Please enter your religion here',
          optionDetail: 'Placeholder text asking to enter comment here',
        },
      },

      //Thank you - complete content
      section5message: {
        title: 'Thank you for completing our survey.',
        message: [
          'Any surveys received after the consultation deadline of <b>11.59pm on Tuesday 15 February 2022</b> may ' +
            'not be accepted.',
          'To exercise your rights under the Data Protection Act 2018, please contact: Data Protection Officer, ' +
            'Information Management Service, Gloucestershire County Council, First Floor, Block 4 (a), Shire Hall, ' +
            'Westgate Street, Gloucester, GL1 2TG or by e-mail at: ' +
            '<a href="mailto:managemyrequests@gloucestershire.gov.uk" title="Email">managemyrequests@gloucestershire.gov.uk</a>. ',
          'Gloucestershire County Council is registered as a data controller with the ' +
            'Information Commissioner’s Office (ICO). Our registration ' +
            'number is Z7334901. ',
          'For more information about this survey, M5 Junction 10 consultation and the overall scheme proposals, email' +
            '<a href="mailto:m5junction10@atkinsglobal.com" title="Email"> M5junction10@atkinsglobal.com</a>.' +
            ' For help completing our survey over the phone, call Gloucestershire County Council Customer Services on ' +
            '01452 426256 (Monday - Friday 9am - 4pm, excluding bank holidays).',
        ],
        btn: 'Back to home',
      },
    },
  },
  SupportingDocsPageData: {
    banner: {
      title: 'Supporting documents',
      text: '',
      img: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/BG_image_No_Text.png?alt=media&token=e37002c6-ec0e-4522-84cf-81ea46106ab7',
      largeBanner: false,
    },
    description: {
      title: '',
      paragraphs: [
        'The documents below provide more information on our technical work undertaken between the Options Consultation, ' +
        'held in Autumn 2020, and this  consultation. It also includes information on how we’re meeting ' +
        'our statutory duties  for the DCO as set out in the Planning Act 2008 such as how we’re engaging with the local community and how we’ve publicised our consultation. ',
        'Click on the links to view and download each document. ',
        'We will continue to revisit and reassess our technical work as the scheme progresses to ensure your feedback is taken into consideration and so that impacts and benefits of the scheme are fully understood. ',
        'If you require the consultation materials in an alternative format, please get in touch by emailing <a href="mailto:M5Junction10@atkinsglobal.com" title="EMail">M5Junction10@atkinsglobal.com</a> ' +
          'or by calling 01454 667900 and leaving a message.',
      ],
    },
    boxLinks: [
      {
        title: 'Consultation brochure',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FConsultation%20Brochure.pdf?alt=media&token=f583151a-7693-436c-a6ec-c96894d3de54',
        ImageAltText: 'Decorative photograph showing a photograph of the M5 Junction 10 scheme consultation brochure.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2FConsultation%20brochure%20453x339.jpg?alt=media&token=a8cfb777-1c12-417c-85c3-f3c9de91443a',
      },
      {
        title: 'Statement of community consultation (SoCC)',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FStatement%20of%20Community%20Consultation%20(SoCC).pdf?alt=media&token=664de884-16e2-47f7-ba18-af1ed1102064',
        ImageAltText: 'Decorative photograph showing a group of people raising their hands.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2FStatement%20of%20community%20consultation%20453x339.jpg?alt=media&token=efaa2dc5-4432-4506-8bdb-af7ad433b3b9',
      },
      {
        title: 'Scheme plan',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FScheme%20Plan%20.pdf?alt=media&token=fb89da5e-cfeb-4519-9eef-5abf797132f7',
        ImageAltText: 'Decorative photograph showing a person holding a tablet an looking a plan image on it.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2FScheme%20plan%20453x339.jpg?alt=media&token=997d9b77-865e-4e36-b38c-c18ac8d784e2',
      },
      {
        title: 'Indicative application red line boundary plan ',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FIndicative%20Application%20Red%20Line%20Boundary%20Plan.pdf?alt=media&token=962eecbe-1ef3-4fe8-8860-2aad9fc3792b',
        ImageAltText: 'Decorative photograph showing a tablet with a plan image on it.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2FIndicative%20application%20red%20line%20boundary%20453x339.jpg?alt=media&token=3c073f64-22c4-4b76-8a32-d0fffd2cf033',
      },
      {
        title: 'Section 47 notice',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FM5%20Junction%2010%20Section%2047%20Notice.pdf?alt=media&token=02c86868-0aa9-4612-888c-e8953b0d3645',
        ImageAltText: 'Decorative photograph showing a person sitting and taking notes.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2FSection%2047%20notice%20453x339.jpg?alt=media&token=a184b44a-396f-4ed8-a31f-0bf192a1f069',
      },
      {
        title: 'Section 48 notice',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FM5%20Junction%2010%20Section%2048%20Notice.pdf?alt=media&token=28e53c10-dfdd-48e4-a09f-745c0d28c3e3',
        ImageAltText: 'Decorative photograph showing an empty road and road cones set up for roadworks.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2FSection%2048%20notice%20453x339.jpg?alt=media&token=423e8dc8-74ba-4f54-87d5-e92c8f681c24',
      },
      {
        title: 'Position statement document',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FPosition%20Statements%20.pdf?alt=media&token=2ab8ab22-a955-4df7-9a93-a1b0f9cd4e6b',
        ImageAltText: 'Decorative photograph showing two people looking at a laptop screen and discussing.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2FPosition%20statement%20document%20453x339.jpg?alt=media&token=41cddd82-2229-4115-8365-8a8bd8f01308',
      },
      {
        title: 'PEIR non-technical summary (NTS)',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FPEIR%20Non-Technical%20Summary%20(NTS).pdf?alt=media&token=abbe9355-6fb9-41b8-9065-a107ba834f32',
        ImageAltText: 'Decorative photograph showing a woman reading text on a laptop.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2FNon-technical%20summary%20453x339.jpg?alt=media&token=b68e7b11-d954-405c-8847-a274263d9ec9',
      },
      {
        title: 'PEIR chapters 1 - 4 introduction and assessment approach',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FPEIR%20Chapters%201%20-%204%20Introduction%20and%20Assessment%20Approach.pdf?alt=media&token=81d74010-cf9c-48b7-841c-0ecd34e1f1a2',
        ImageAltText: 'Decorative photograph showing hand typing on a laptop keyboard.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2FIntro%20and%20assessment%20approach%20453x339.jpg?alt=media&token=5344bba7-fd39-4707-98e3-72305f2337a2',
      },
      {
        title: 'PEIR chapter 5 air quality',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FPEIR%20Chapter%205%20Air%20Quality.pdf?alt=media&token=810e7675-2103-4227-bd72-6e1985213a9f',
        ImageAltText: 'Decorative photograph showing sky and clouds.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2FAir%20quality%20453x339.jpg?alt=media&token=c9f3dfc2-a6a7-4881-9240-00f490d53fa2',
      },
      {
        title: 'PEIR chapter 6 noise and vibration',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FPEIR%20Chapter%206%20Noise%20and%20Vibration.pdf?alt=media&token=7518261c-ba53-4a2d-8e3e-b647ca23eab5',
        ImageAltText: 'Decorative photograph showing a busy motorway.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2FNoise%20and%20vibration%20453x339.jpg?alt=media&token=1939a238-0151-4c09-9d3b-e01c342a233b',
      },
      {
        title: 'PEIR chapter 7 biodiversity',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FPEIR%20Chapter%207%20Biodiversity.pdf?alt=media&token=7986b49b-eabf-4046-b58b-3f6c7b2fccca',
        ImageAltText: 'Decorative photograph showing a bird.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2FBiodiversity%20453x339.jpg?alt=media&token=c1736574-ba44-4af5-a70e-7abbdad6dfd0',
      },
      {
        title: 'PEIR chapter 8 road drainage and the water environment',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FPEIR%20Chapter%208%20Road%20Drainage%20and%20the%20Water%20Environment.pdf?alt=media&token=47f2f48d-4a79-4ab4-88ac-ba31f51b3587',
        ImageAltText: 'Decorative photograph showing a side of a road and vehicles.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2FRoad%20drainage%20453x339.jpg?alt=media&token=45511164-d83c-4833-b73d-d54163d2c182',
      },
      {
        title: 'PEIR chapter 9 landscape and visual',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FPEIR%20Chapter%209%20Landscape%20and%20Visual.pdf?alt=media&token=5b13a8ea-a627-4ee1-a5c6-6c86b212611f',
        ImageAltText: 'Decorative photograph showing landscape with road, windmills and surrounding forrest.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2FLandscape%20and%20visual%20453x339.jpg?alt=media&token=e5ad764f-b9ef-4a36-b51e-825f4a015fa4',
      },
      {
        title: 'PEIR chapter 10 geology and soils',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FPEIR%20Chapter%2010%20Geology%20and%20Soils.pdf?alt=media&token=e981cce0-a218-43a8-a4a2-a932cbb4c24d',
        ImageAltText: 'Decorative photograph showing rocky hill and sky.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2FGeology%20and%20soils%20453x339.jpg?alt=media&token=1ff722f5-ab30-4678-bd41-e194b2b9ef42',
      },
      {
        title: 'PEIR chapter 11 cultural heritage',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FPEIR%20Chapter%2011%20Cultural%20Heritage.pdf?alt=media&token=daa02a5e-2125-4aa9-bc5d-9ff1b372e64a',
        ImageAltText: 'Decorative photograph showing countryside with fields, houses and trees.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2FCultural%20heritage%20453x339.jpg?alt=media&token=6821dc83-6a3f-4c13-9577-56462ccbfdbd',
      },
      {
        title: 'PEIR chapter 12 materials and waste',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FPEIR%20Chapter%2012%20Materials%20and%20Waste.pdf?alt=media&token=733f1828-d93d-403d-85f6-74f80bff26e7',
        ImageAltText: 'Decorative photograph showing roadworks.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2FMaterials%20and%20waste%20453x339.jpg?alt=media&token=07dd779f-7b33-40dc-b803-89dc70c451c1',
      },
      {
        title: 'PEIR chapter 13 population and human health',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FPEIR%20Chapter%2013%20Population%20and%20Human%20Health.pdf?alt=media&token=e5244417-face-4624-b064-421b5e1c03af',
        ImageAltText: 'Decorative photograph showing two people cycling.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2FPopulation%20and%20human%20health%20453x339.jpg?alt=media&token=1b34fb98-85be-450a-8313-970fe9ba25dc',
      },
      {
        title: 'PEIR chapter 14 climate',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FPEIR%20Chapter%2014%20Climate.pdf?alt=media&token=7aa028e1-610c-4261-8bc5-ab84821ea60c',
        ImageAltText: 'Decorative photograph showing trees.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2FClimate%20453x339.jpg?alt=media&token=51beb263-6064-4084-9669-1b51c96ce122',
      },
      {
        title: 'PEIR chapter 15 cumulative effects assessment',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FPEIR%20Chapter%2015%20Cumulative%20Effects%20Assessment.pdf?alt=media&token=297b572b-54d4-4fe9-a662-72f3bdd3ba7e',
        ImageAltText: 'Decorative photograph showing hand on laptop keyboard typing.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2FCumulative%20effects%20assessment%20453x339.jpg?alt=media&token=904610a7-9334-4435-9847-f4aab0038e3d',
      },
      {
        title: 'Event Exhibition Board: M5 Junction 10',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FM5Junction10.pdf?alt=media&token=05be5592-9b3f-4553-9805-bb619b896b70',
        ImageAltText: 'Decorative graphical image of M5 Junction 10 branding showing a road, hills, trees and houses.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2F0.placeholderBoxLinkImage_color.jpg?alt=media&token=f6d84b51-e88b-43ed-b048-ef3f7f852349',
      },
      {
        title: 'Event Exhibition Board: A4019 Tewkesbury Road Gallagher Junction',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FA4019_TawkesburyRoadGallagherJunction.pdf?alt=media&token=a48e2b10-91d9-459b-a475-0f79a8d5c6c3',
        ImageAltText: 'Decorative graphical image of M5 Junction 10 branding showing a road, hills, trees and houses.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2F0.placeholderBoxLinkImage_color.jpg?alt=media&token=f6d84b51-e88b-43ed-b048-ef3f7f852349',
      },
      {
        title: 'Event Exhibition Board: A4019 Tewkesbury Road Subsection 1',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FA4019TawkesburyRoadSubsection1.pdf?alt=media&token=0d491ffa-bf7d-41d5-8d2b-bb73973d50d7',
        ImageAltText: 'Decorative graphical image of M5 Junction 10 branding showing a road, hills, trees and houses.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2F0.placeholderBoxLinkImage_color.jpg?alt=media&token=f6d84b51-e88b-43ed-b048-ef3f7f852349',
      },
      {
        title: 'Event Exhibition Board: A4019 Tewkesbury Road Subsection 2',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FA4019TawkesburyRoadSubsection2.pdf?alt=media&token=c58bd3ae-dc01-4d54-8aae-7bb2754ce29f',
        ImageAltText: 'Decorative graphical image of M5 Junction 10 branding showing a road, hills, trees and houses.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2F0.placeholderBoxLinkImage_color.jpg?alt=media&token=f6d84b51-e88b-43ed-b048-ef3f7f852349',
      },
      {
        title: 'Event Exhibition Board: West Cheltenham Link Road and Withybridge Lane',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FWestChaltenhamLinkRoadAndWithybridgeLane.pdf?alt=media&token=0d6a4526-6f80-4c6c-8b12-b107dce6b5b2',
        ImageAltText: 'Decorative graphical image of M5 Junction 10 branding showing a road, hills, trees and houses.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2F0.placeholderBoxLinkImage_color.jpg?alt=media&token=f6d84b51-e88b-43ed-b048-ef3f7f852349',
      },
      {
        title: 'Event Exhibition Board: Need for scheme',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FNeedForScheme.pdf?alt=media&token=598dcc0c-426c-499f-ba82-ad17b32fbad4',
        ImageAltText: 'Decorative graphical image of M5 Junction 10 branding showing a road, hills, trees and houses.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2F0.placeholderBoxLinkImage_color.jpg?alt=media&token=f6d84b51-e88b-43ed-b048-ef3f7f852349',
      },
      {
        title: 'Event Exhibition Board: Our effect on environment',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FOurEffectOnEnvironment.pdf?alt=media&token=a8e9f619-95a3-4b72-a1dd-0b3d4ba1dfc1',
        ImageAltText: 'Decorative graphical image of M5 Junction 10 branding showing a road, hills, trees and houses.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2F0.placeholderBoxLinkImage_color.jpg?alt=media&token=f6d84b51-e88b-43ed-b048-ef3f7f852349',
      },
      {
        title: 'Event Exhibition Board: Our proposed mitigation',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FOurProposalMitigation.pdf?alt=media&token=f37bafce-e3df-448a-941c-d33c65f9580b',
        ImageAltText: 'Decorative graphical image of M5 Junction 10 branding showing a road, hills, trees and houses.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2F0.placeholderBoxLinkImage_color.jpg?alt=media&token=f6d84b51-e88b-43ed-b048-ef3f7f852349',
      },
      {
        title: 'Event Exhibition Board: Scheme timeline',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FSchemeTimeline.pdf?alt=media&token=5e7b709e-7d15-4277-97e7-527a5bf0ec94',
        ImageAltText: 'Decorative graphical image of M5 Junction 10 branding showing a road, hills, trees and houses.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2F0.placeholderBoxLinkImage_color.jpg?alt=media&token=f6d84b51-e88b-43ed-b048-ef3f7f852349',
      },
      {
        title: 'Event Exhibition Board: Who are we',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FWhoAreWe.pdf?alt=media&token=391e5a66-c89a-402d-83d0-834ff262f9c1',
        ImageAltText: 'Decorative graphical image of M5 Junction 10 branding showing a road, hills, trees and houses.',
        image:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2F0.placeholderBoxLinkImage_color.jpg?alt=media&token=f6d84b51-e88b-43ed-b048-ef3f7f852349',
      },
      {
        title: 'Virtual event presentation',
        link: 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2FM5%20J10%20Presentation_Final_7pm_annotations.pdf?alt=media&token=602fbe3c-7d62-4c75-9b3e-d06f6529909f',
        ImageAltText: 'Decorative graphical image of M5 Junction 10 branding showing a road, hills, trees and houses.',
        image:
            'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/SupportingDocuments%2F0.images%2F0.placeholderBoxLinkImage_color.jpg?alt=media&token=f6d84b51-e88b-43ed-b048-ef3f7f852349',
      },
    ],
  },
  EventPagePopupData: {
    events: [
      {
        id: 0,
        title: 'Event 1',
        date: 'Tuesday 14 December 2021',
        time: 'Drop in 10am - 7pm',
        venue:
          'Cheltenham West Community Fire and Rescue Station, Tewksbury Road, Uckington, Cheltenham, GL51 9TZ',
        platform: '',
        accessibility: [
          'Please visit the Cheltenham West Fire and Rescue Station website here ' +
            '<a href="https://www.gloucestershire.gov.uk/glosfire/about-us/our-stations-and-locations-find-a-station/cheltenham-west-community-fire-and-rescue-station/" target="_blank">Cheltenham West Community Fire and Rescue Station - Glosfire.</a>',
          '<br>',
          'We have taken practical steps to improve the accessibility of our face to face events, ' +
            'ensuring that everyone in the community feels safe and welcome to attend. The fire station ' +
            'will provide step-free access to the event and several accessible parking spaces will be available.',
          '<br>',
          'If you have specific accessibility needs in attending the event at Cheltenham West Community Fire ' +
            'and Rescue Station, please get in touch by emailing <a href="mailto:M5Junction10@atkinsglobal.com" title="Email">M5Junction10@atkinsglobal.com</a>.',
        ],
        gettingThere: [
          'Those driving to the event are advised to park in nearby facilities and walk to the event.',
          '<br>',
          'Nearby car parking is available at:',
          '•\tGallagher retail park (Sainsbury’s car park), GL51 9RR: 13-minute walk (free for 2 hours).',
          '•\tKingsditch retail park, GL51 9DU : 19-minute walk (free for 2 hours).',
          '<br>',
          'Those using public transport are advised that the venue is within close proximity to several main ' +
            'bus routes within walking distance of the site. Services include: 40/41/42/43/43A. Most routes pass retail parks nearby.',
          '<br>',
          'To help plan your journey by public transport, please use the Traveline South West journey planner: ' +
            '<a href="https://travelinesw.com/" target="_blank">travelinesw.com.</a>',
        ],
        covid: [
          'Our face to face events will be held in line with current UK Government advice on COVID-19 ' +
            'hygiene procedures and social distancing guidelines.',
          '<br>',
          'Ensuring the safety of all those attending the event, including our project team, is our top ' +
            'priority. Our events will have the following safety measures in place:',
          '•\tSanitising stations and regular cleaning of surfaces',
          '•\tOne-way system around the venue to minimise crowding',
          '•\tMask wearing is advisable',
          '•\tVenues will have limited capacity so you may be asked to queue outside',
          '•\tStaff unless exempt will be required to wear appropriate PPE',
          '•\tCheck into the venue using the NHS Track and Trace app',
          '<br>',
          'Please do not attend if you are displaying COVID-19 symptoms.',
        ],
        hasButton: false,
        buttonLink: '',
      },
      {
        id: 1,
        title: 'Event 2',
        date: 'Wednesday 15 December 2021',
        time: '7pm - 8:30pm',
        venue: '',
        platform: 'Microsoft Teams Live',
        accessibility: [
          'Please find instructions on how to join a Microsoft Teams Live Event here : ' +
            '<a href="https://support.microsoft.com/en-gb/office/attend-a-live-event-in-teams-a1c7b989-ebb1-4479-b750-c86c9bc98d84" ' +
            'target="_blank" title="Attend a live event">Attend a live event in Teams (microsoft.com).</a>',
          '<br>',
          'We have taken practical steps to improve the accessibility of our virtual events, ensuring that everyone in the community can attend.',
          '<br>',
          'A recording of the event will be available within 5 working days of the event.',
          '<br>',
          'Live captions will be available for all virtual events in 6 different languages:',
          '<br>',
          '• Arabic',
          '• Bengali',
          '• Gujarati',
          '• Polish',
          '• Romanian',
          '• Slovak',
          '<br>',
          'Please find instructions on how to access live captions in: ' +
            '<a title="Click here to watch event recording" href="https://vimeo.com/663301816/584a59435f" ' +
            'target="_blank">Use live captions in a live event (microsoft.com).</a>' +
            ' (See: attendees: use live captions and subtitles)',
        ],
       // timeline: [
         // 'An agenda for the meeting to be confirmed in the coming weeks',
        //],
        // submitQuestions: [
        //   'If you would like to submit a question to the project team ahead of the virtual event, please complete the registration form via the link below. We will endeavour to answer as many questions as ' +
        //     'possible during the event. However, we cannot guarantee an answer to every question submitted. You will also have the ' +
        //     'opportunity to submit questions live during the event. Pre-submitted questions will be treated as anonymous, ' +
        //     'however, you will have the option to submit questions both anonymously or with your name during the live event.',
        // ],
        postTextHere:
          'This event has already taken place. Please click the button below to access the live event recording or select an alternative event to attend. ',
        hasVideoButton: true,
        buttonLink:
          'https://vimeo.com/663301816/584a59435f',
      },
      {
        id: 2,
        title: 'Event 3',
        date: 'Thursday 13 January 2022',
        time: '2pm - 3:30pm',
        venue: '',
        platform: 'Microsoft Teams Live',
        accessibility: [
          'Please find instructions on how to join a Microsoft Teams Live Event here : ' +
            '<a title="Attend a live event" href="https://support.microsoft.com/en-gb/office/attend-a-live-event-in-teams-a1c7b989-ebb1-4479-b750-c86c9bc98d84" ' +
            'target="_blank">Attend a live event in Teams (microsoft.com).</a>',
          '<br>',
          'We have taken practical steps to improve the accessibility of our virtual events, ensuring that everyone in the community can attend.',
          '<br>',
          'A recording of the event will be available within 5 working days of the event.',
          '<br>',
          'Live captions will be available for all virtual events in 6 different languages:',
          '<br>',
          '• Arabic',
          '• Bengali',
          '• Gujarati',
          '• Polish',
          '• Romanian',
          '• Slovak',
          '<br>',
          'Please find instructions on how to access live captions in: ' +
            '<a title="Attend a live event" href="https://support.microsoft.com/en-gb/office/use-live-captions-in-a-live-event-1d6778d4-6c65-4189-ab13-e2d77beb9e2a#bkmk_attendees" ' +
            'target="_blank">Use live captions in a live event (microsoft.com).</a>' +
            ' (See : attendees : use live captions and subtitles)',
        ],
     //   timeline: [
       //   'An agenda for the meeting to be confirmed in the coming weeks',
        //],
        // submitQuestions: [
        //   'If you would like to submit a question to the project team ahead of the virtual event, please complete the registration form via the link below. We will endeavour to answer as many questions as ' +
        //     'possible during the event. However, we cannot guarantee an answer to every question submitted. You will also have the ' +
        //     'opportunity to submit questions live during the event. Pre-submitted questions will be treated as anonymous, ' +
        //     'however, you will have the option to submit questions both anonymously or with your name during the live event.',
        // ],
        postTextHere:
        'This event has already taken place. Please click the button below to access the live event recording or select an alternative event to attend. ',
      hasVideoButton: true,
      buttonLink:
        'https://vimeo.com/667244661/83c8a57cba',
      },
      {
        id: 3,
        title: 'Event 4',
        date: 'Saturday 15 January 2022',
        time: 'Drop in between 10am – 5pm',
        venue:
          "Hester's Way Community Resource Centre, Cassin Drive, Cheltenham GL51 7SU",
        platform: '',
        accessibility: [
          'We have taken practical steps to improve the accessibility of our face to face events, ' +
            'ensuring that everyone in the community feels safe and welcome to attend. Hester\'s Way Community Resource Centre ' +
            'will provide step-free access to the event and several accessible parking spaces will be available.',
          '<br>',
          'If you have specific accessibility needs in attending the event at Hester Way Community Resource Centre' +
            ' please get in touch by emailing <a title="Email" href="mailto:M5Junction10@atkinsglobal.com">M5Junction10@atkinsglobal.com</a>.',
        ],
        gettingThere: [
          'Please visit the Hesters Way Community Resource Centre website here: ' +
            '<a title="HWCRC site" href="https://hwnp.org/centres/hwcrc/" target="_blank">Hesters Way Community Resource Centre – Hesters Way Neighbourhood Project (hwnp.org).</a>',
          '<br>',
          'To help plan your journey by public transport, please use the Traveline South West journey planner: ' +
            '<a href="https://travelinesw.com/" target="_blank" title="Traveline SW Site">travelinesw.com.</a>',
        ],
        covid: [
          'Our face to face events will be held in line with current UK Government advice on COVID-19 ' +
            'hygiene procedures and social distancing guidelines.',
          '<br>',
          'Ensuring the safety of all those attending the event, including our project team, is our top ' +
            'priority. Our events will have the following safety measures in place:',
          '•\tSanitising stations and regular cleaning of surfaces',
          '•\tOne-way system around the venue to minimise crowding',
          '•\tMask wearing is advisable',
          '•\tVenues will have limited capacity so you may be asked to queue outside',
          '•\tStaff unless exempt will be required to wear appropriate PPE',
          '•\tCheck into the venue using the NHS Track and Trace app',
          '<br>',
          'Please do not attend if you are displaying COVID-19 symptoms.',
        ],
        joinHere: '',
        hasButton: false,
        buttonLink: '',
      },
      {
        id: 4,
        title: 'Event 5',
        date: 'Saturday 29 January 2022',
        time: '2pm - 3:30pm',
        venue: '',
        platform: 'Microsoft Teams Live',
        accessibility: [
          'Please find instructions on how to join a Microsoft Teams Live Event here : ' +
            '<a title="Attend live event" href="https://support.microsoft.com/en-gb/office/attend-a-live-event-in-teams-a1c7b989-ebb1-4479-b750-c86c9bc98d84" ' +
            'target="_blank">Attend a live event in Teams (microsoft.com).</a>',
          '<br>',
          'We have taken practical steps to improve the accessibility of our virtual events, ensuring that everyone in the community can attend.',
          '<br>',
          'A recording of the event will be available within 5 working days of the event.',
          '<br>',
          'Live captions will be available for all virtual events in 6 different languages:',
          '<br>',
          '• Arabic',
          '• Bengali',
          '• Gujarati',
          '• Polish',
          '• Romanian',
          '• Slovak',
          '<br>',
          'Please find instructions on how to access live captions in: ' +
            '<a title="Attend live event" href="https://support.microsoft.com/en-gb/office/use-live-captions-in-a-live-event-1d6778d4-6c65-4189-ab13-e2d77beb9e2a#bkmk_attendees" ' +
            'target="_blank">Use live captions in a live event (microsoft.com).</a>' +
            ' (See : attendees : use live captions and subtitles)',
        ],
     //  timeline: [
       //   'An agenda for the meeting to be confirmed in the coming weeks',
        //],
        // submitQuestions: [
        //   'If you would like to submit a question to the project team ahead of the virtual event, please complete the registration form via the link below. We will endeavour to answer as many questions as ' +
        //     'possible during the event. However, we cannot guarantee an answer to every question submitted. You will also have the ' +
        //     'opportunity to submit questions live during the event. Pre-submitted questions will be treated as anonymous, ' +
        //     'however, you will have the option to submit questions both anonymously or with your name during the live event.',
        // ],
        postTextHere:
            'This event has already taken place. Please click the button below to access the live event recording or select an alternative event to attend. ',
        hasVideoButton: true,
        buttonLink:
            'https://vimeo.com/672311560/6aab5bcbf8',

        // joinHere:
        //   'Please register to access the live event as the joining link will not be public on the website. ' +
        //   'Recordings of the events will be available on the website',
        // hasButton: true,
        // buttonLink:
        //   'https://www.eventbrite.co.uk/e/m5-junction-10-improvements-scheme-virtual-statutory-consultation-events-registration-214280959117',
        //
      },
      {
        id: 5,
        title: 'Event 6',
        date: 'Wednesday 2 February 2022',
        time: '7pm - 8:30pm',
        venue: '',
        platform: 'Microsoft Teams Live',
        accessibility: [
          'Please find instructions on how to join a Microsoft Teams Live Event here : ' +
            '<a title="Attend live event" href="https://support.microsoft.com/en-gb/office/attend-a-live-event-in-teams-a1c7b989-ebb1-4479-b750-c86c9bc98d84" ' +
            'target="_blank">Attend a live event in Teams (microsoft.com).</a>',
          '<br>',
          'We have taken practical steps to improve the accessibility of our virtual events, ensuring that everyone in the community can attend.',
          '<br>',
          ' A recording of the event will be available within 5 working days of the event.',
          '<br>',
          'Live captions will be available for all virtual events in 6 different languages:',
          '<br>',
          '• Arabic',
          '• Bengali',
          '• Gujarati',
          '• Polish',
          '• Romanian',
          '• Slovak',
          '<br>',
          'Please find instructions on how to access live captions in: ' +
            '<a title="Attend live event" href="https://support.microsoft.com/en-gb/office/use-live-captions-in-a-live-event-1d6778d4-6c65-4189-ab13-e2d77beb9e2a#bkmk_attendees" ' +
            'target="_blank">Use live captions in a live event (microsoft.com).</a>' +
            ' (See : attendees : use live captions and subtitles)',
        ],
      //  timeline: [
        //  'An agenda for the meeting to be confirmed in the coming weeks',
        //],
        // submitQuestions: [
        //   'If you would like to submit a question to the project team ahead of the virtual event, please complete the registration form via the link below. We will endeavour to answer as many questions as ' +
        //     'possible during the event. However, we cannot guarantee an answer to every question submitted. You will also have the ' +
        //     'opportunity to submit questions live during the event. Pre-submitted questions will be treated as anonymous, ' +
        //     'however, you will have the option to submit questions both anonymously or with your name during the live event.',
        // ],
        // joinHere:
        //   'Please register to access the live event as the joining link will not be public on the website. ' +
        //   'Recordings of the events will be made on the website.',
        // hasButton: true,
        // buttonLink:
        //   'https://www.eventbrite.co.uk/e/m5-junction-10-improvements-scheme-virtual-statutory-consultation-events-registration-214282744457',
        //
        postTextHere:
            'This event has already taken place. Please click the button below to access the live event recording or select an alternative event to attend. ',
        hasVideoButton: true,
        buttonLink:
            'https://vimeo.com/673182271/33c04283db',

      },
    ],
  },
  PortalData: {
    LargeBoxes: [
      {
        Title: 'Project overview',
        Text: ' Watch the animation to see an overview of the scheme.',
        ImageAltText: 'Graphical representation of what the new M5 Junction 10 will look like when the scheme is complete.',
        ImageSrc:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/projectOverviewBoxImages%2F01.png?alt=media&token=0def1a59-5dfa-4b7e-a641-00ca08f168b4',
        Popup: {
          Type: 'Video',
          Title: 'Project overview',
          Description:
              'Graphical image captured from the video where Gloucestershire County Council and their partners talk about the benefits of the scheme.',
          VideoSrc: ['https://player.vimeo.com/video/650122173?h=e10a89e33a'],
        },
      },
      {
        Title: 'Hear from our team',
        Text: 'Watch our video on the M5 Junction 10 Improvements Scheme where Gloucestershire County Council and their delivery partners talk about the benefits of the scheme for the local community.',
        ImageAltText: 'Graphical representation of what the M5 Junction 10 will look like when the scheme is complete.',
        ImageSrc:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/projectOverviewBoxImages%2F03.png?alt=media&token=0cc0e5b4-58f6-4685-868c-d77377b8af3a',
        Popup: {
          Type: 'Video',
          Title: 'Hear from our team',
          Description:
              'Watch our video on the M5 Junction 10 Improvements Scheme where Gloucestershire County Council and their delivery partners talk about the benefits of the scheme for the local community.',
          VideoSrc: 'https://player.vimeo.com/video/643526727?h=ed7c4550b3',
        },
      },
    ],
    SmallBoxes: [
      {
        Title: 'M5 Junction 10',
        Text: 'Watch the animation to see an overview of the scheme or click below to find out more detail on the various scheme elements.',
        pageContent: 'M5-Junction-10',
        mapLocation: [
            [200, 260],
            [11, 24],
        ],
        ImageAltText: 'Graphical representation of what the M5 Junction 10 will look like when the scheme is complete.',
        ImgSrc:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/smallCardImages%2Fsmallcard1.png?alt=media&token=6827bae5-04ba-42b9-ab89-844c871f1ef6',
      },
      {
        Title: 'West Cheltenham Link Road',
        Text: 'Watch the animation or use the filters on the right to find out how the scheme will benifit you',
        pageContent: 'Cheltenham-Link-Road',
        mapLocation: [
            [460, 430],
            [26, 38],
        ],
        ImageAltText: 'Graphical representation of what the West Cheltenham Link Road will look like when the scheme is complete.',
        ImgSrc:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/smallCardImages%2Fsmallcard2.png?alt=media&token=f6e8d636-0329-4fa7-95d0-d0b81f422e5d',
      },
      {
        Title: 'A4019 Tewkesbury Road Subsection 1',
        Text: 'Watch the animation or use the filters on the right to find out how the scheme will benifit you',
        pageContent: 'Tewkesbury-Road-Subsection-1',
        mapLocation: [
            [630, 540],
            [46, 58],
        ],
        ImageAltText: 'Graphical representation of what the A4019 Tewkesbury Road Subsection 1 will look like when the scheme is complete.',
        ImgSrc:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/smallCardImages%2Fsmallcard3.png?alt=media&token=11a0d6ac-6d9d-4378-8e36-fcde5e2c25e8',
      },
      {
        Title: 'A4019 Tewkesbury Road Subsection 2',
        Text: 'Watch the animation or use the filters on the right to find out how the scheme will benifit you',
        pageContent: 'Tewkesbury-Road-Subsection-2',
        mapLocation:[
            [1050, 730],
            [56, 67],
        ],
        ImageAltText: 'Graphical representation of what the A4019 Tewkesbury Road Subsection 2 will look like when the scheme is complete.',
        ImgSrc:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/smallCardImages%2Fsmallcard4.png?alt=media&token=64d9049d-8cfd-487f-86c6-ec639b1f21ae',
      },
    ],
    PageContent: [
      {
        //M5-J10
        Title: 'M5 Junction 10',
        Slug: 'M5-Junction-10',
        BackgroundImg:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Portal%2FM5-J10%2FM5J10_View01_Still_19201080.jpg?alt=media&token=2ae1a4f1-aa70-4e1b-9990-9ce1121f186f',
        BackgroundImgTablet:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Portal%2FM5-J10%2FM5J10_View01_Still_1024768.jpg?alt=media&token=6c41766e-1097-4d17-b6c4-3b7c5d348abb',
        ImageAltText: 'Graphical representation of what the M5 Junction 10 will look like when the scheme is complete.',
        //Labels - check filter, vimeo videos, and geo-position for all, marker title?
        //Filters - "environment", "NonDrivingUsers" or "RoadImprovements"

        Markers: [
          //Completed
          {
            Title: 'Two new slip roads',
            Icon: 'Image',
            Filter: 'RoadImprovements',
            Position: [
              [200, 500],
              [135, 390],
              [185, 180],
            ],
            Offset: [
                ['0px', '0px'],
                ['-100px', '0px'],
            ],
            Height: [
                ['50px', '0px'],
                ['75px', '-25px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['0px', '-25px'],
            ],
            Popup: {
              Type: 'Image',
              Title: 'Two new slip roads',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text: [
                    'Two new slip roads will be created to allow movement of vehicles from the south and to the south of the M5. This will improve the flow of traffic and reduce pressure on M5 Junction 11.',
                    ]
              },
              ImgSrc: [
                'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Portal%2FM5-J10%2FM5J10_Label_1_web.jpg?alt=media&token=ec85da34-7e71-4908-a98a-cb44cdf8ae66',
              ],
              ImageAltText: 'Graphical representation of what two new slip roads on the M5 Junction 10 will look like when the scheme is complete.',
            },
          },
          //Completed
          {
            Title: 'Realigned slip roads',
            Icon: 'Image',
            Filter: 'RoadImprovements',
            Position: [
              [1120, 180],
              [540, 200],
              [370, 70],
            ],
            Offset: [
              ['0px','0px'],
              ['100px','0px'],
            ],
            Height: [
              ['50px', '0px'],
              ['50px', '0px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['0px', '0px'],
            ],
            Popup: {
              Type: 'Image',
              Title: 'Realigned slip roads',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text:[
                  'The existing slip roads will be realigned to link with the new roundabout.',
                ]
              },
              ImgSrc: [
                'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Portal%2FM5-J10%2FM5J10_Label2.jpg?alt=media&token=fe5e3dc1-ca3a-4eb6-83b2-3de3b3ab3548',
              ],
              ImageAltText: 'Graphical representation of what realigned new slip roads on the M5 Junction 10 will look like when the scheme is complete.',
            },
          },
          //Completed
          {
            Title: 'New roundabout with two new bridges',
            Icon: 'Video',
            Filter: 'RoadImprovements',
            Position: [
              [525, 350],
              [280, 330],
              [210, 130],
            ],
            Offset: [
              ['0px', '0px'],
              ['50px','0px'],
            ],
            Height: [
              ['50px', '0px'],
              ['50px', '0px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['0px', '0px'],
            ],
            Popup: {
              Type: 'Video',
              Title: 'New roundabout with two new bridges',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text: [
                    'The existing bridge will be demolished and a new roundabout with two bridges will replace it. This will reduce ' +
                    'the maintenance costs of maintaining a 50-year-old bridge. The roundabout will be signalised to improve the flow of vehicles using the junction.',
                    ]
              },
              VideoSrc: 'https://player.vimeo.com/video/650446440?h=5e928366a4',
            },
          },
          //Completed
          {
            Title: 'Widening of A4019 approach to M5 Junction 10',
            Icon: 'Video',
            Filter: 'RoadImprovements',
            Position: [
              [1350, 535],
              [813, 410],
              [400, 175],
            ],
            Offset: [
              ['100px','0px'],
              ['100px','0px'],
            ],
            Height: [
              ['50px', '0px'],
              ['50px', '0px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['0px', '0px'],
            ],
            Popup: {
              Type: 'Video',
              Title: 'Widening of A4019 approach to M5 Junction 10',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text: [
                    'The existing A4019 carriageway on the approach to M5 Junction 10 will be widened to three lanes westbound and two lanes eastbound, ' +
                'to increase capacity of the road.',
                ]
              },
              VideoSrc: 'https://player.vimeo.com/video/650446846?h=023ace774f',
            },
          },
          //360
          {
            Title: 'Segregated footpath and cycleway',
            Icon: '360',
            Filter: 'NonDrivingUsers',
            Position: [
              [810, 235],
              [430, 225],
              [300, 90],
            ],
            Offset: [
              ['0px', '0px'],
              ['50px','0px'],
            ],
            Height: [
              ['50px', '0px'],
              ['75px', '-25px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['-50px', '-25px'],
            ],
            Popup: {
              Type: '360',
              Title: 'Segregated footpath and cycleway',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text:[
                    'Signal-controlled crossings of the northern slip roads will ensure' +
                ' a safe crossing of the M5 for all active travel users. This includes a segregated 4m cycling route and 2m walking route.',
                    ]
              },
              Img360Src:
                'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Portal%2FM5-J10%2FM5J10_Label_5_web2.jpg?alt=media&token=65f5bb6d-2e0e-4d46-a15c-fa15750c0fc7',
            },
          },
          //Completed
          {
            Title: 'New flood storage area',
            Icon: 'Info',
            Filter: 'Environment',
            Position: [
              [1100, 650],
              [568, 594],
              [400, 250],
            ],
            Offset: [
              ['0px', '0px'],
              ['0px', '0px'],
            ],
            Height: [
              ['50px', '0px'],
              ['50px', '0px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['0px', '0px'],
            ],
            Popup: {
              Type: 'Info',
              Title: 'New flood storage area',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text: [
                    'A flood compensation area will be provided southeast of Junction 10, providing a key component of the flood ' +
                    'management plan for the scheme. The area will include a permanent waterbody and will be landscaped to provide a ' +
                    'net benefit to biodiversity through the creation of a mixture of wet and dry habitats.',
                ]
              },
            },
          },
        ],
        DescriptionBox: {
          contentMap: true,
          title: 'Overview',
          Icon: 'Info',
          text:[
              'The proposed improvements will increase the capacity and improve the accessibility and movement of M5 Junction 10, ' +
              'by adding a southbound slip road and a slip road off the M5 northbound to create an all-movements signalised junction.',
              ]
        },
      },

      {
        //West Cheltenham Link Road
        Title: 'West Cheltenham Link Road',
        Slug: 'Cheltenham-Link-Road',
        BackgroundImg:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Portal%2FWest%20Chelktenham%20Link%20Road%2FM5J10_View04_B4634_Still_16_9.jpg?alt=media&token=b95248ce-34de-4bbc-8ac3-f959dd8bb2dd',
        BackgroundImgTablet:
            'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Portal%2FWest%20Chelktenham%20Link%20Road%2FView04_Link%20Road_4_3.jpg?alt=media&token=67e0a138-e4a0-435f-bac3-08123f6e95d1',
        ImageAltText: 'Graphical representation of what the West Cheltenham Link Road will look like when the scheme is complete.',
        Markers: [
          {
            //Imagery array?
            Title: 'Withybridge Lane',
            Icon: 'Image',
            Filter: 'RoadImprovements',
            Position: [
              [1050, 220],
              [550, 250],
              [400, 100],
            ],
            Offset: [
              ['0px', '0px'],
              ['100px', '0px'],
            ],
            Height: [
              ['50px', '0px'],
              ['50px', '0px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['0px', '0px'],
            ],
            Popup: {
              Type: 'Image',
              Title: 'Withybridge Lane',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text:[
                    'With the new West Cheltenham link road taking traffic into the new West Cheltenham development, there is the opportunity to explore restricting traffic movement along ' +
                    'Withybridge Lane. As part of our proposals, we are looking at three different options.The option to stop up the lane would still allow ' +
                    'residents to access their properties but would remove passing traffic. The proposals for Withybridge Lane are still in development and we welcome your views on the following three options:',
                    '<li> Option 1: Withybridge Lane kept open for traffic with Withybridge Lane/A4019 junction having a left in and left out turning only.',
                    '<li> Option 2: Permanently closing to through traffic at the northern end of Withybridge Lane',
                    '<li> Option 3: Permanently closing to through traffic in the middle of Withybridge Lane',
                ]
              },
              ImgSrc: [
                  'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Portal%2FWest%20Chelktenham%20Link%20Road%2FOption_Fin.jpg?alt=media&token=355b0f01-8f88-4343-b827-fb9e3f88b24a',
               // 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Portal%2FWest%20Chelktenham%20Link%20Road%2FLabel1-Option-2.jpg?alt=media&token=a2c0c655-788b-49e3-ad7c-c777768b5224',
              //  'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Portal%2FWest%20Chelktenham%20Link%20Road%2FLabel1-Option-3.jpg?alt=media&token=8a6655cf-c994-4826-ab80-e11f8ba6e99a',
              ],
              ImageAltText: 'Map drawing of what the option for Withybridge Lane plan will look like when the scheme is complete.',
            },
          },
          //360
          {
            Title: 'A new signalised junction on A4019',
            Icon: '360',
            Filter: 'RoadImprovements',
            Position: [
              [1425, 675],
              [775, 525],
              [500, 250],
            ],
            Offset: [
              ['0px', '0px'],
              ['0px', '0px'],
            ],
            Height: [
              ['50px', '0px'],
              ['50px', '0px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['0px', '0px'],
            ],
            Popup: {
              Type: '360',
              Title: 'A new signalised junction on A4019',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text: [
                  'A new signalised junction will connect the A4019 to the new West Cheltenham link road. This junction will provide safe and easy access to future developments and the new link road link road.',
                ]
              },
              Img360Src:
                'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Portal%2FWest%20Chelktenham%20Link%20Road%2FLabel_02.jpg?alt=media&token=1d499b22-d586-41c0-b5d4-7958532ead0b',
            },
          },
          //Completed
          {
            Title: 'New River Chelt bridge',
            Icon: 'Info',
            Filter: 'Environment',
            Position: [
              [375, 200],
              [240, 200],
              [160, 65],
            ],
            Offset: [
              ['0px', '0px'],
              ['0px', '0px'],
            ],
            Height: [
              ['10px', '0px'],
              ['50px', '0px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['0px', '0px'],
            ],
            Popup: {
              Type: 'Info',
              Title: 'New River Chelt bridge',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text: [
                  'The link road crosses the River Chelt and its floodplain. Therefore, a new single span bridge built across the River Chelt and structures to allow flood water to pass under the link road.' +
                  ' The height of the bridge will allow pedestrians and livestock to travel safely under the bridge. There will also be dedicated mammal crossing structures under the new link road ' +
                  'to the north and south of the River Chelt.',
                ]
              },
            },
          },
          //Completed
          {
            Title: 'New single carriageway link road',
            Icon: 'Video',
            Filter: 'RoadImprovements',
            Position: [
              [740, 375],
              [475, 320],
              [290, 160],
            ],
            Offset: [
              ['0px', '0px'],
              ['0px', '0px'],
            ],
            Height: [
              ['25px', '0px'],
              ['100px', '-75px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['0px', '-75px'],
            ],
            Popup: {
              Type: 'Video',
              Title: 'New single carriageway link road',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text: [
                  'The single carriageway link road connects the A4019 to the B4634. This will allow traffic from the proposed West Cheltenham development to use Junction 10, ' +
                  'rather than travelling to the already busy Junction 11. A section of the route has been raised above ground level to reduce the potential for flooding across the road.',
                ]
              },
              VideoSrc: 'https://player.vimeo.com/video/650446972?h=a58b77033e',
            },
          },
          //Completed
          {
            Title: 'A new signalised junction on B4634',
            Icon: 'Info',
            Filter: 'RoadImprovements',
            Position: [
              [100, 110],
              [150, 140],
              [150, 30],
            ],
            Offset: [
              ['-100px', '0px'],
              ['-100px', '0px'],
            ],
            Height: [
              ['10px', '0px'],
              ['0px', '0px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['0px', '20px'],
            ],
            Popup: {
              Type: 'Info',
              Title: 'A new signalised junction on B4634',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text: [
                  'A new signalised junction will connect the B4634 to the new West Cheltenham link road. This junction will provide safe and easy access to the proposed West Cheltenham development and the new link road.',
                ]
              },
            },
          },
          {
            Title: 'New flood mitigation structures',
            Icon: 'Info',
            Filter: 'Environment',
            Position: [
              [550, 300],
              [460, 270],
              [210, 100],
            ],
            Offset: [
              ['0px', '0px'],
              ['-100px', '0px'],
            ],
            Height: [
              ['30px', '0px'],
              ['50px', '0px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['0px', '0px'],
            ],
            Popup: {
              Type: 'Info',
              Title: 'New flood mitigation structures',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text: [
                  'New flood mitigation structures will be built to ensure that the link road does not impede the movement of flood water across this area. This will help reduce predicted incidents of flooding.',
                ]
              },
            },
          },
          //Completed
          {
            Title: 'Segregated footpath and cycleway',
            Icon: 'Video',
            Filter: 'NonDrivingUsers',
            Position: [
              [1000, 490],
              [600, 340],
              [400, 160],
            ],
            Offset: [
              ['-50px', '0px'],
              ['-50px', '0px'],
            ],
            Height: [
              ['10px', '0px'],
              ['150px', '-100px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['0px', '-100px'],
            ],
            Popup: {
              Type: 'Video',
              Title: 'Segregated footpath and cycleway',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text: [
                  'We will create an active travel corridor on the western side of the link road. This will be a continuation of the facilities proposed for the A4019 ' +
                  'and will help to encourage the use of alternative modes of transport between the development sites. This will improve access to local facilities.',
                ]
              },
              VideoSrc: 'https://player.vimeo.com/video/650447340?h=d6dfb21036',
            },
          },
          //Completed
          {
            Title: 'New drainage (attenuation) basin',
            Icon: 'Info',
            Filter: 'Environment',
            Position: [
              [1300, 400],
              [820, 380],
              [450, 170],
            ],
            Offset: [
              ['0px', '0px'],
              ['-100px', '0px'],
            ],
            Height: [
              ['100px', '0px'],
              ['50px', '0px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['0px', '0px'],
            ],
            Popup: {
              Type: 'Info',
              Title: 'New drainage (attenuation) basin',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text: [
                  'New attenuation ponds will be built to compensate for the loss of flood plain area which will be used to build the scheme.',
                ]
              },
            },
          },
        ],
        DescriptionBox: {
          contentMap: true,
          title: 'Overview',
          Icon: 'Info',
          text: ['A new link road is required to allow traffic from the proposed housing and economic development in West Cheltenham to use Junction 10 and reduce ' +
          'pressure on Junction 11 and local roads. We are proposing a single carriageway link road and planning for the future by including signalised junctions on ' +
          'the A4019 and B4634 for safe and easy access to future development.',
              ]
        },
      },

      {
        //Tewkesbury Road Subsection 1
        Title: 'A4019 Tewkesbury Road Subsection 1',
        Slug: 'Tewkesbury-Road-Subsection-1',
        BackgroundImg:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Portal%2FA4019%20Tewkesbury%20Road%20Subsection%201%2FM5J10_View05_Still_16_9.jpg?alt=media&token=c606ef48-1a6e-45c0-841a-280357fc188f',
        BackgroundImgTablet:
            'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Portal%2FA4019%20Tewkesbury%20Road%20Subsection%201%2FM5J10_View05_A4019_Still_4_3.jpg?alt=media&token=ef68ef3a-34f2-4f0a-b279-8c499e6e8cb9',
        ImageAltText: 'Graphical representation of what the A4019 Tewkesbury Road Subsection 1 will look like when the scheme is complete.',
        Markers: [
          {
            Title: 'Noise mitigation barrier',
            Icon: 'Image',
            Filter: 'Environment',
            Position: [
              [360, 160],
              [520, 300],
              [100, 70],
            ],
            Offset: [
              ['0px', '0px'],
              ['-100px', '0px'],
            ],
            Height: [
              ['75px', '0px'],
              ['50px', '0px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['0px', '0px'],
            ],
            Popup: {
              Type: 'Image',
              Title: 'Noise mitigation barrier',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text: [
                  'Noise mitigation has been proposed to improve noise levels at a number of Noise Important Areas close to the scheme. These areas include: ' +
                  'the west side of the M5; north of Junction 10; and along various sections of the A4019, on the north side and south side of the road. ' +
                  'Most local properties will experience a decrease in noise pollution.',
                ]
              },
              ImgSrc: [
                'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Portal%2FA4019%20Tewkesbury%20Road%20Subsection%201%2FNoise%20Mitigation%20Final.jpg?alt=media&token=c4969259-ebee-4764-9bea-4adff3af69de',
              ],
              ImageAltText: 'Map drawing of noise barrier plan by the A4019 Tewkesbury Road Subsection 1.',
            },
          },
          {
            //Completed
            Title: 'A new signalised junction on A4019',
            Icon: '360',
            Filter: 'RoadImprovements',
            Position: [
              [460, 340],
              [420, 275],
              [10, 70],
            ],
            Offset: [
              ['0px', '0px'],
              ['-130px', '0px'],
            ],
            Height: [
              ['10px', '0px'],
              ['35px', '0px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['0px', '0px'],
            ],
            Popup: {
              Type: '360',
              Title: 'A new signalised junction on A4019',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text: [
                  'A new signalised junction at Uckington will be created to provide access to the future development sites and the new link road.',
                ]
              },
              Img360Src:
                'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Portal%2FA4019%20Tewkesbury%20Road%20Subsection%201%2FM5J10_360_View03_01.jpg?alt=media&token=ea4cb0fc-ef06-469e-9443-aee3294af3b5',
            },
          },
          //Completed
          {
            Title:
              'Existing A4019 widened to dual carriageway',
            Icon: 'Video',
            Filter: 'RoadImprovements',
            Position: [
              [830, 360],
              [525, 340],
              [230, 130],
            ],
            Offset: [
              ['-100px', '0px'],
              ['0px', '0px'],
            ],
            Height: [
              ['50px', '0px'],
              ['50px', '0px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['0px', '0px'],
            ],
            Popup: {
              Type: 'Video',
              Title:
                'Existing A4019 widened to dual carriageway to increase capacity',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text: [
                  'The A4019 will be widened to a dual carriageway in each direction, which will provide a consistent road link from Junction 10 into Cheltenham.',
                ]
              },
              VideoSrc: 'https://player.vimeo.com/video/650443556?h=e6b1ebfddc',
            },
          },
          //Completed
          {
            Title: 'Segregated cycle track and footway',
            Icon: 'Info',
            Filter: 'NonDrivingUsers',
            Position: [
              [1400, 500],
              [650, 400],
              [570, 180],
            ],
            Offset: [
              ['-100px', '0px'],
              ['100px', '0px'],
            ],
            Height: [
              ['50px', '0px'],
              ['100px', '-70px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['0px', '-65px'],
            ],
            Popup: {
              Type: 'Info',
              Title: 'Segregated cycle track and footway',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text: [
                  'The active travel corridor on the northern side of the A4019 will be a continuation of the facilities from M5 Junction 10 and the new West Cheltenham link road. ' +
                  'Signal-controlled crossings for pedestrians and cyclists will be created at each junction on the A0419. The junction at Uckington will include a staggered ' +
                  'pedestrian crossing to ensure connectivity between the footways and the proposed cycle and pedestrian facilities.',
                ]
              },
            },
          },
          //Completed
          {
            Title: 'Localised road improvements',
            Icon: 'Info',
            Filter: 'RoadImprovements',
            Position: [
              [130, 270],
              [170, 180],
              [170, 70],
            ],
            Offset: [
              ['-50px', '0px'],
              ['-50px', '0px'],
            ],
            Height: [
              ['25px', '0px'],
              ['25px', '0px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['0px', '0px'],
            ],
            Popup: {
              Type: 'Info',
              Title: 'Localised road improvements',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text: [
                  'Service roads on the northern side of the A4019 will be provided. These were created with local residents and businesses in mind to facilitate access to homes and businesses along the A4019 in both directions. This will provide safer and more direct access to bus stops. We will also link Moat Lane with Cooks Lane to provide an alternative access for Cooks Lane residents, via Moat Lane.',
                ]
              },
            },
          },
          //Completed

          //Completed
          {
            Title: 'Landscaping along the A4019',
            Icon: 'Info',
            Filter: 'Environment',
            Position: [
              [1550, 600],
              [800, 450],
              [550, 210],
            ],
            Offset: [
              ['50px', '0px'],
              ['100px', '0px'],
            ],
            Height: [
              ['50px', '0px'],
              ['100px', '-60px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['0px', '-50px'],
            ],
            Popup: {
              Type: 'Info',
              Title: 'Landscaping along the A4019',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text: [
                  'Landscaping is proposed along the length of the A4019, both along the edges and in the central reserve. This will increase the green area, improving visual amenity.',
                ]
              },
            },
          },

          //Completed
          {
            Title: 'New drainage (attenuation) basin',
            Icon: 'Info',
            Filter: 'Environment',
            Position: [
              [1020, 500],
              [600, 400],
              [370, 180],
            ],
            Offset: [
              ['0px', '0px'],
              ['0px', '0px'],
            ],
            Height: [
              ['50px', '0px'],
              ['75px', '-25px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['129px', '-20px'],
            ],
            Popup: {
              Type: 'Info',
              Title: 'New drainage (attenuation) basin',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text: [
                  'New attenuation ponds will be built near the A4019 to compensate for the loss of flood plain area which will be used to build the scheme.',
                ]
              },
              //ImgSrc: [
                //'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Portal%2FA4019%20Tewkesbury%20Road%20Subsection%201%2FLabel07.jpg?alt=media&token=a83c35eb-b948-4fc6-9819-4ccc0e380c98',
                //'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Portal%2FA4019%20Tewkesbury%20Road%20Subsection%201%2FLabel8_Option_2.jpg?alt=media&token=117774c8-adb9-4f8c-ab2b-1032fad0b235',
                //'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Portal%2FA4019%20Tewkesbury%20Road%20Subsection%201%2FLabel8_Option_3.jpg?alt=media&token=cfa699e8-4fff-40b8-8b78-1321a0ebf900',
                //'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Portal%2FA4019%20Tewkesbury%20Road%20Subsection%201%2FLabel8_Option_4.jpg?alt=media&token=a73d1177-06ba-4fde-bdab-99383dc4a790',
               // 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Portal%2FA4019%20Tewkesbury%20Road%20Subsection%201%2FLabel8_Option_5.jpg?alt=media&token=5e0400cb-cc63-42fe-8026-90803ae9cff5',
             // ],
              ImageAltText: 'Graphical representation of what new drainage basin near the A4019 Tewkesbury Road Subsection 1 will look like when the scheme is complete.',
            },
          },
        ],
        DescriptionBox: {
          contentMap: true,
          title: 'Overview',
          Icon: 'Info',
          text:[
              'The A4019 is being widened for both motorised traffic and active travel users. This will increase capacity to meet anticipated additional demand from the proposed housing and employment development.',
          ]
        },
      },

      {
        //Tewkesbury Road Subsection 2
        Title: 'A4019 Tewkesbury Road Subsection 2',
        Slug: 'Tewkesbury-Road-Subsection-2',
        BackgroundImg:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Portal%2FA4019%20Tewkesbury%20Road%20Subsection%202%2FM5J10_View07_Still_A4019_16_9.jpg?alt=media&token=854ac103-967e-4495-854a-2e0ad261825c',
        BackgroundImgTablet:
          'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Portal%2FA4019%20Tewkesbury%20Road%20Subsection%202%2FM5J10_View07_Still_V2_1024768.jpg?alt=media&token=74454262-9413-4a1e-b0fb-c8d7fe606d94',
        ImageAltText: 'Graphical representation of what the A4019 Tewkesbury Road Subsection 2 will look like when the scheme is complete.',
        Markers: [
          {
            Title: 'New signalised junction for access to future development',
            Icon: 'Video',
            Filter: 'RoadImprovements',
            Position: [
              [950, 270],
              [620, 365],
              [380, 130],
            ],
            Offset: [
              ['0px', '0px'],
              ['0px', '0px'],
            ],
            Height: [
              ['100px', '0px'],
              ['50px', '0px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['0px', '0px'],
            ],
            Popup: {
              Type: 'Video',
              Title: 'New signalised junction for access to future development',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text: [
                  'A new signalised junction will be created to provide access to the future development.',
                ]
              },
              VideoSrc: 'https://player.vimeo.com/video/650444784?h=69c21287bc',
            },
          },
          //Completed
          {
            Title: 'New service road for safe access to properties',
            Icon: 'Info',
            Filter: 'RoadImprovements',
            Position: [
              [550, 335],
              [450, 360],
              [300, 120],
            ],
            Offset: [
              ['0px', '0px'],
              ['0px', '0px'],
            ],
            Height: [
              ['25px', '0px'],
              ['25px', '0px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['0px', '0px'],
            ],
            Popup: {
              Type: 'Info',
              Title: 'New service road for safe access to properties',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text: [
                  'A service road will be provided for safe access to properties along the southern side of the A4019.',
                ]
              },
            },
          },
          //Completed
          {
            Title: 'New signalised junction for access to future development',
            Icon: 'Video',
            Filter: 'RoadImprovements',
            Position: [
              [80, 170],
              [175, 250],
              [150, 90],
            ],
            Offset: [
              ['-150px', '0px'],
              ['-100px','75px'],
            ],
            Height: [
              ['100px', '0px'],
              ['100px', '0px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['0px', '50px'],
            ],
            Popup: {
              Type: 'Video',
              Title: 'New signalised junction for access to future development',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text: [
                  'A new signalised junction will be created to provide access to the future development.',
                ]
              },
              VideoSrc: 'https://player.vimeo.com/video/650444784?h=69c21287bc',
            },
          },
          //Completed
          {
            Title: 'Segregated cycle track and footway',
            Icon: 'Info',
            Filter: 'NonDrivingUsers',
            Position: [
              [300, 250],
              [250, 325],
              [150, 100],
            ],
            Offset: [
              ['0px', '0px'],
              ['0px', '0px'],
            ],
            Height: [
              ['25px', '0px'],
              ['25px', '0px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['0px', '0px'],
            ],
            Popup: {
              Type: 'Info',
              Title: 'Segregated cycle track and footway',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text: [
                  'The active travel corridor on the northern side of the A4019 will be a continuation of the facilities from M5 Junction 10 and the new West ' +
                  'Cheltenham link road. Signal controlled crossings for pedestrians and cyclists will be created at each junction on the A0419.',
                ]
              },
            },
          },
          //Completed
          {
            Title: 'Existing A4019 widened to dual carriageway',
            Icon: 'Info',
            Filter: 'RoadImprovements',
            Position: [
              [1250, 350],
              [750, 385],
              [445, 140],
            ],
            Offset: [
              ['-100px', '0px'],
              ['0px', '0px'],
            ],
            Height: [
              ['50px', '0px'],
              ['75px', '-25px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['0px', '-25px'],
            ],
            Popup: {
              Type: 'Info',
              Title: 'Existing A4019 widened to dual carriageway',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Info',
                text: [
                  'The A4019 will be widened to a dual carriageway in each direction. This will provide a consistent road link from Junction 10 into Cheltenham.',
                ]
              },
              //ImgSrc: [
               // 'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Portal%2FA4019%20Tewkesbury%20Road%20Subsection%202%2FAMENDE~1.JPG?alt=media&token=a800c31f-cf67-4465-9282-db549da2819a',
             // ],
              ImageAltText: 'Map drawing of what the existing A4019 widened dual carriage plan will look like when the scheme is complete.',
            },
          },
          //Completed
          {
            Title: 'Amended junction layout at Gallagher Retail park',
            Icon: 'Info',
            Filter: 'RoadImprovements',
            Position: [
              [1450, 450],
              [810, 425],
              [450, 150],
            ],
            Offset: [
              ['100px', '0px'],
              ['100px', '0px'],
            ],
            Height: [
              ['25px', '0px'],
              ['100px', '-50px'],
            ],
            TextBoxOffset:[
              ['0px', '0px'],
              ['0px', '-50px'],
            ],
            Popup: {
              Type: 'Image',
              Title:
                  'Amended junction layout at Gallagher Retail park junction',
              DescriptionBox: {
                title: 'Overview',
                Icon: 'Image',
                text: [
                  'The proposed improvements at the Gallagher Retail Park junction will potentially be implemented in two stages. The first stage will be completed for the scheme opening ' +
                  'year in 2025. Depending on traffic and development growth, the second stage will then be completed in 2031.',
                  'The proposed layouts for the Gallagher Retail park junction are shown in the maps below and we are particularly interested in your views on our 2031 proposals.' +
                  '<li> Stage 1 (2025): The junction will be increased in size to provide additional capacity and improved with pedestrian and cycle facilities.' +
                  '<li> Stage 2 (2031): The junction will be further adapted by changing the alignment of the road and removing the right turns from A4019 into the side roads. ' +
                  'Right turns from the side roads onto the A4019 will remain open.',
                ]

              },
              ImgSrc: [
                'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Portal%2FA4019%20Tewkesbury%20Road%20Subsection%202%2F2025%20no%20label.jpg?alt=media&token=ea345031-336e-47e8-8c5d-3f90a7a0c8f4',
                'https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Portal%2FA4019%20Tewkesbury%20Road%20Subsection%202%2F2031%20no%20label.jpg?alt=media&token=0454f8c5-690d-4981-90d5-188953e6f5ae',
              ],
              ImageAltText: 'Map drawing showing amended junction layout at Gallagher Retail park junction.',
            },
          },
        ],
        DescriptionBox: {
          contentMap: true,
          title: 'Overview',
          Icon: 'Info',
          text:[
              'The A4019 is being widened for both motorised traffic and active travel users. This will increase capacity to meet anticipated additional demand from the proposed housing and employment development.',
              ]
        },
      },
    ],
  },
};
