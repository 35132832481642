<template>
  <div id="home-view">
    <section class="MainHeadFoot">
      <Header :navbar="headerData"/>
      <div class="homepageHero">
        <banner :data="titleData"/>

        <span id="scroll" class="scroll"> <!-- v-on:scroll.passive='handleScroll'>-->
          <i class="fa fa-arrow-circle-down" aria-hidden="true"></i>
          <span class="scroll-text">Scroll</span>
        </span>

      </div>
    </section>

    <section class="section homepage marginBottom"> 
          <b-container class="mainContent"> 
            <TextSlider
              :title="sliderData.title"
              :text="sliderData.text"
              :button="false"
              :link="`/options`"
              :image="true"
            />
          </b-container>
      </section>
      
      <section class="section fullwidthText homepage">
         <div class="bannerIllustration">
              <HeroPageBanner :data="homeBanner"/>
          </div>
          <b-container class="mainContent textCenter">
            <b-row>
              <b-col  md="12" lg="6" sm="12">
                  <TitleTextFullWidth
                    :title="section1Data.title"
                    :paragraphs="section1Data.paragraphs"
                    :useParagraphs="true"
                    :smallTitle="true"
                  />
              </b-col>
               <b-col  md="12" lg="6" sm="12">
                  <TitleTextFullWidth
                    :title="section2Data.title"
                    :paragraphs="section2Data.paragraphs"
                    :useParagraphs="true"
                    :smallTitle="true"
                  />
              </b-col>
            </b-row>
          </b-container>
      </section>
      <section class="homeComponent"> 
          <HomeComp/>
      </section>

    <section class="footer">
      <Footer :footerNavs="footerData.navs" :copyWrite="footerData.copyWrite">
      </Footer>
    </section>

    </div>
</template>

<script>
import HomeHeroHeader  from "../components/HomePageComponents/HomeHeroHeader";
import HomeComp from "../components/HomePageComponents/HomeComponents";
import Banner from "../components/Banner";
import HeroPageBanner from "../components/HeroPageBanner";
import Header from "../components/Header";
import TitleTextFullWidth from "../components/TitleTextFullWidth";
import RowImageCards from "../components/RowImageCards";
import TextSlider from "../components/TextSlider";
import Footer from "../components/Footer";
import TitleParagraphs from "../components/TitleParagraphs";
import Chevrons from "../components/Project/M5J10/Chevrons";
import BannerIllustration from "../components/Project/M5J10/BannerIllustration";
import FooterIllustration from "../components/Project/M5J10/FooterIllustration";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "Home",
  components: {
    HomeHeroHeader,
    HomeComp,
    Banner,
    HeroPageBanner,
    Header,
    TitleTextFullWidth,
    RowImageCards,
    TextSlider,
    Footer,
    TitleParagraphs,
    Chevrons,
    BannerIllustration,
    FooterIllustration,
  },
  data() {
    return {
      topPos: Number,
      show: true,
    };

  },
  mounted()
  {

  },
  created () {
    //window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    //window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    ...mapGetters(["language", "content"]),
    currentLanguage() {
      const vm = this;
      if (this.language) {
        return this.language;
      } else return "english";
    },
    headerData() {
      const vm = this;
      if (this.content && this.content.headerData) {
        return this.content.headerData;
      } else return {};
    },
    titleData() {
      const vm = this;
      if (
        this.content &&
        this.content.homePageData &&
        this.content.homePageData.banner
      ) {
        return this.content.homePageData.banner;
      } else return {};
    },
    section1Data() {
      const vm = this;
      if (
        this.content &&
        this.content.homePageData &&
        this.content.homePageData.section1
      ) {
        return this.content.homePageData.section1;
      } else return {};
    },
    section2Data() {
      const vm = this;
      if (
        this.content &&
        this.content.homePageData &&
        this.content.homePageData.section2
      ) {
        return this.content.homePageData.section2;
      } else return {};
    },
    homeBanner() {
      const vm = this;
      if (
        this.content &&
        this.content.homePageData.homeBanner
      ) {
        return this.content.homePageData.homeBanner;
      } else return {};
    },
    sliderData() {
      const vm = this;
      if (this.content && this.content.sliderData) {
        return this.content.sliderData;
      } else return {};
    },
    footerData() {
      const vm = this;
      if (this.content && this.content.footerData) {
        return this.content.footerData;
      } else return {};
    },
  },
  methods: {
    ...mapActions(["toggleLanguage"]),
    toggleLang() {
      this.toggleLanguage();
    },
    handleScroll (event) {

      /*let scroll = document.getElementById('scroll');
      this.topPos = scroll.getBoundingClientRect().top;
      var fadePos = scroll.getBoundingClientRect().y;

      console.log("TOP POS IS ", this.topPos)
      console.log("FADE POS IS ", fadePos)


      if(this.topPos)
      {
        scroll.style.opacity = 100;
      }
      else
      {
          scroll.style.opacity = 0;
      }*/



      /*if ((event.target.scrollHeight-300) <= event.target.scrollTop) {
        console.log("aslnakljblkjbk.jcbd.kcvjbdk.")
      }*/


     /* setTimeout(function(){
      scroll.style.opacity = 0;
    }, 1000)*/
    }
  },
};
</script>