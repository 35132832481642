<template>
    <section>
      <!-- Tile view PO boxes -->
      <h1 class="sr-only">Virtual information portal</h1>
       <div class="portal-large-cards" v-if="!isMapView && data">
         <ul class="list">
           <li class="card" v-for="(item, index) in data" :key="index">

             <div class="cardText">
               <h2>{{item.Title}}</h2>
               <p class="text-1-1 rp">
                 {{ item.Text }}
               </p>

               <button class="button-primary" @click="showModal(item)" v-on:keyup.enter="showModal(item)">
                 <a>View More<span class="chevron"><i class="fas fa-chevron-right"></i></span></a>
               </button>
             </div>

             <div class="cardImage">
               <img :src="item.ImageSrc" style="width: 100%" alt="decorative image"/>

               <div class="PlayButton" @click="showModal(item)" v-on:keyup.enter="showModal(item)">
                 <img class="imgPlay" src="../../assets/images/Temp/Play Button.png" alt="play button"/>
               </div>
             </div>

           </li>
         </ul>
       </div>

      <!-- Map view PO boxes -->
      <div class="portal-map-cards" v-if="isMapView && data">
        <ul class="list">
          <li class="cardSmall small" v-for="(item, index) in data" :key="index">

            <div class="cardText">
              <h2>{{item.Title}}</h2>

              <button class="viewMoreButton" @click="showModal(item)">
                <a @click="showModal(item)">View More <span class="chevron"><i class="fas fa-chevron-right"></i></span></a>
              </button>
            </div>

            <div class="cardMapImage">
              <img :src="item.ImageSrc" style="width: 100%" :alt="item.ImageAltText"/>

              <div class="PlayButtonMap" @click="showModal(item)">
                <img class="imgPlayMap" src="../../assets/images/Temp/Play Button.png" alt="play button"/>
              </div>
            </div>

          </li>
        </ul>
      </div>

      <PortalModal v-if="this.showM && !isModalPano" id="modalId" ref="modalComponent" :is-desktop="isDesktop"
                   @closeModal="closeModal" :modal-data="this.dataM"/>


    </section>

</template>

<script>
 //import TextOnlyModal from "./PortalModal";
 import PortalModal from "../PortalComponents/PortalModal.vue"

export default {
  name: "PortalLargeBoxes",
  components:
      {
        //TextOnlyModal
        PortalModal,
      },
  data()
  {
    return{
      currentBlip: Number,
      dataM: Object,

    };
  },
  props: {
    data: Array,
    isMapView:{
      type: Boolean,
    },
    isModalPano: {
      Type: Boolean,
      default: false
    },
    showM: {
      Type: Boolean,
      default: false
    },
    clickInfo: Object,
    isDesktop: {
      type: Boolean,
      default: false
    },
  },
  mounted(){
    this.clickInfo = this.$refs.modalComponent;
  },

  methods:
      {
        showModal(item)
        {
          if(item.Popup.Type === "360")
          {
            this.isModalPano = true
          }
          else
          {
            this.isModalPano = false
          }


          this.dataM = item.Popup;
          this.showM = true;
        },
        closeModal()
        {
          this.showM = false;
        },
        hideModal() {
          this.$refs.modalComponent.$children[0].hide();
        },
        /*showModal(item)
        {
            this.$emit('openModal', item)
        }*/
      }
}
</script>

<style lang="scss">
@import '../../variables';

.portal-large-cards
{
  .list {
    padding: 20px;
    display: block;  

    @media screen and (min-width: 1025px) {
      display: flex;
      overflow: hidden;
    }

    .card
    {
      min-height: 365px;
      background-color: $Gcc-Blue;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 35px 25px 0;
      border-radius: 3px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      width: 100%;

      @media screen and (min-width: 1025px) {
        width: 50%;
      }

      @media screen and (min-width: 1400px) {
        width: 50%;
      }

      &:first-of-type{
        margin: 0 0 25px 0;

      @media screen and (min-width: 1025px) {
        margin: 0 25px 0 0;
      }

        @media screen and (min-width: 1400px) {
          margin: 0 25px 0 0;
        }

        p {
          font-size: 18px;
          //line-height: 30px;
        }
      }

      h2
      {
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 48px;
        color: #FFFFFF;

        @media screen and (max-width: 1440px) {
          font-size: 26px;
          line-height: 36px;
        }

         @media screen and (min-width: 1480px) {
          font-size: 32px;
          line-height: 48px;
        }
      }

      p{
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: #FFFFFF;
        margin-bottom: 24px;
      };

      .cardText
      {
        margin: 25px 20px 20px 10px;
        //align-self: center;

        @media screen and (min-width: 824px) and (max-width: 1024px) {
          min-width: 75%;
        }


      }

      .cardImage
      {
        position: relative;
        align-self: center;
        margin: -25px 0 0 0;

        img {

          min-width: 200px;
          min-height: 200px;
          max-width: 200px;
          max-height: 200px;
          
          @media screen and (min-width: 1480px) {
            min-width: 325px;
            min-height: 325px;
            max-width: 325px;
            max-height: 325px;
          }

        }

        .PlayButton
        {
          width: 100%;
          height: 100%;

          .imgPlay
          {
            top: 50%;
            left: 50%;
            width: 30%;
            transform: translate(-50%, -50%);
            position: absolute;

            min-width: 68px;
            min-height: 65px;

             @media screen and (min-width: 1480px) {
              min-width: 88px;
              min-height: 85px;
            }
          }

          &:hover
          {
            .imgPlay
            {
              width: 32%;

            }
          }
        }
      }
    }
  }
}

.portal-map-cards
{
  position: fixed;
  z-index: 100;

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    top: 160px;
  }

  .list {
    padding: 10px;
    display: flex;
    overflow: hidden;

    .cardSmall
    {
      max-height: 156px;
      max-width: 321px;
      background-color: $Gcc-DarkBlue;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 35px 25px 0;
      border-radius: 3px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      width: 50%;

      &.small {
         padding: 15px;

         .viewMoreButton {
           margin-left: -5px;
         }

         .cardMapImage {
           margin: 0;
         }
      }

      &:first-of-type{
        margin-right: 25px;
        p {
          font-size: 18px;
          //line-height: 30px;
        }
      }

      h2
      {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        color: #FFFFFF;
      }

      p{
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: #FFFFFF;
      };

      .cardText
      {
        align-self: center;
      }

      .cardMapImage
      {
        position: relative;
        align-self: center;
        margin: -25px 0 0 0;

        img {
          max-width: 112px;
          max-height: 112px;
        }


        .PlayButtonMap
        {
          width: 100%;
          height: 100%;

          .imgPlayMap
          {
            top: 50%;
            left: 50%;
            width: 10%;
            transform: translate(-50%, -50%);
            position: absolute;
            min-width: 44px;
            min-height: 44px;
          }

          &:hover
          {
            .imgPlayMap
            {
              width: 50%;

            }
          }
        }
      }

      .viewMoreButton
      {
        color: #ffffff;
        background: none;
        border: none;

        //transition: background 0.3s linear;


        text-align: center;
        place-content: center;

        a {
          color: #ffffff;
          font-size: 16px;
          font-weight: 400;
        }

        &:hover,
        &:focus {
          // background: #ffffff;
          outline: 0;
          text-decoration: underline;
          a {
            text-decoration: underline;

            min-width: 68px;
            min-height: 65px;
            max-width: 68px;
            max-height: 65px;

            @media screen and (min-width: 1480px) {
              min-width: 88px;
              min-height: 85px;
              max-width: 88px;
              max-height: 85px;
            }

          }
        }
      }
    }
  }
}
</style>