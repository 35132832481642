<template>
  <div class="rowImageCards-comp">
    <h2 v-if="title">{{ title }}</h2>
    <b-row v-if="cards">
      <b-col
        cols="12"
        md="6"
        lg="3"
        v-for="(card, index) in cards"
        :key="`${$route.name}_RowCard_${index}`"
      >
        <div class="RowCard">
          <div class="RC-Image">
            <div
              :style="{
                'background-image': `url(${card.image})`,
              }"
            ></div>
          </div>
          <div class="RC-Text">
            <h5 v-if="card.title">{{ card.title }}</h5>
            <p v-if="card.text">
              {{ card.text }}
            </p>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "RowImageCards",
  props: {
    title: {
      type: String,
      default: "Card Row Title",
    },
    cards: {
      type: Array,
      default() {
        return [
          {
            title: "Cards Title",
            text:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse viverra rhoncus libero nec faucibus.",
          },
        ];
      },
    },
  },
};
</script>

<style lang="scss">
.rowImageCards-comp {
  h2 {
    margin-bottom: 25px;
  }
  .RC-Image {
    width: 127px;
    height: 127px;
    margin-bottom: 25px;

    div {
      width: inherit;
      height: inherit;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .RC-Text {
    font-size: 16px;
  }
}
</style>