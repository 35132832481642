<template>
  <div class="name-address-comp">
    <div class="NA-title">
      <h4 v-if="title">{{ title }}</h4>
      <p>
        {{ text }}
      </p>
    </div>

    <b-row v-if="nameAddress.postCode">
      <b-col cols="12" lg="6" class="NA-input">
        <p></p>
        <div class="NA-input-label">{{ nameAddress.subtitle  }}</div>
        <br>
        <div class="NA-input-label">{{ nameAddress.postCode.title }}</div>
        <label for="txtbox"><span class="sr-only"> {{ nameAddress.subtitle  }}</span>
          <input id="txtbox" class="inputBox"
              type="text"
              :placeholder="nameAddress.postCode.placeholder"
              v-model="nameAddressData.postCode"
               :aria-label="nameAddress.postCode.placeholder"
          />
        </label>
      </b-col>
    </b-row>

    <b-row v-if="nameAddress.email" >
      <b-col cols="12" lg="6" class="NA-input">
        <div class="NA-input-label">{{ nameAddress.email.title }}</div>
        <div>
          <input class="inputBox"
              type="email"
              :placeholder="nameAddress.email.placeholder"
              v-model="nameAddressData.email"
               :aria-label="nameAddress.email.placeholder"
          />
        </div>
      </b-col>
      <b-col cols="12" lg="6" class="NA-input">
        <div class="NA-input-label">
          {{ nameAddress.number.title }}
        </div>
        <div>
          <input class="inputBox"
              type="text"
              :placeholder="nameAddress.number.placeholder"
              v-model="nameAddressData.number"
               :aria-label="nameAddress.number.placeholder"
          />
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
export default {
  name: "NameAddress",
  props: {
    title: {
      type: String,
      default: "Name and Address",
    },
    text: {
      type: String,
      default: "Use this section to enter user name and address details",
    },
    nameAddress: {
      type: Object,
      default() {
        return {
          postCode: { title: "Post code", placeholder: "Enter post code here" },
          email: { title: "Email address", placeholder: "Enter email address here" },
          number: { title: "Contact number", placeholder: "Enter your contact number here" },
        };
      },
    },
    ariaLabelText: {
      type: String,
      default: "",
    },
    currentInput: {
      type: Object,
      default() {
        return {
          postCode: "",
          emailAddress: "",
          number: "",
        };
      },
    },
  },
  beforeMount() {
    this.nameAddressData = this.currentInput;
  },
  data() {
    return {
      nameAddressData: {
        postCode: "",
        emailAddress: "",
        number: "",
      },
    };
  },
  methods:{
    tabindexvalueCal(indexval){
      if(indexval==0){
        return 0
      }else{
        return -1
      }
    }
  }
};
</script>

<style lang="scss">

@import '/src/Variables';

.name-address-comp {

  p{
    max-width: 70%;
  }

  @media screen and (max-width: 1024px) {
    p{
      max-width: 50%;
    }
  }

  .inputBox{
    color: #000000;
  }

  .NA-input {
    padding-top: 1%;

    .NA-title {
      color: #000000;
      font-weight: bold;
      margin-right: 47%;
    }

    .NA-input-label {
      font-size: 16px;
      color: #000000;
      font-weight: bold;
      margin-bottom: 1%;
    }

    input {
      border: #58595B solid 1.6px;
      border-radius: 8px;
      outline: none;
      background-color: transparent;
      width: 52%;
      padding: 10px;
      margin-top: 4px;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(#333333, 0.5);
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(#333333, 0.5);
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(#333333, 0.5);
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(#333333, 0.5);
      }
    }
  }
}
</style>