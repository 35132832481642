<template>
  <div>
    <section class="site-timeline">
        <ul class="timeline">
                <li>
                     <span class="date">2022 </span>
                    <div class="timeline-card">
                   Analysis and implementation of feedback from statutory public consultation
                    </div>
                   
                </li>
                <li>
                    <span class="date">Late 2022 </span>
                    <div class="timeline-card">
                   DCO application is submitted
                    </div>
                  
                </li>
                <li>
                    <span class="date">2024</span>
                    <div class="timeline-card">
                   Work commences (if planning consent is granted)
                    </div>
                 
                </li>
                <li>
                      <span class="date">Late 2025</span>
                    <div class="timeline-card">
                   Work complete and open to traffic (subject to planning consent)
                    </div>
                </li>
        </ul>
    </section>
  </div>
</template>

<script>

export default {
  name: "homePageTimeline",
  components: {
  
  },
  data() {
    return {
    
    };
  },
  computed: {



  },

 
  
};
</script>
<style lang="scss" scoped>
$lightGreen:#a2c748;
$fontHeading:'Helvetica Neue,Arial,sans-serif';
$fontBody:'Helvetica Neue,Arial,sans-serif';

.site-timeline {
    
    padding: 2rem 0 5rem;

    h2 {
        font-size: 28px;
        font-weight: 700;
        color: #0055a5;
        line-height: 1.3;
        font-family: 'Helvetica', 'Roboto', sans-serif !important;
        margin-top: 0;
    }
    p {
        color: rgba(0, 0, 0, 0.87);
        margin: 0 auto;
        line-height: 1.4446;
        font-family: 'Helvetica', 'Roboto', sans-serif !important;
    }

    ul.timeline {
        margin: 0 auto;
        list-style: none;
        padding: 0;
        width: 100%;
        position: relative;

        @media screen and (min-width: 1024px) {
            width: 73%;
        }

        &:before {
            content: "";
            display: block;
            width: 3px;
            height: 100%;
            background: #0054A4;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        li {
            list-style: none;
            width: 50%;
            margin: 2rem 0;
            position: relative;
            padding-right: 2rem;
            &:nth-child(even) {
                margin-left: 50%;
                padding-right: 0;
                padding-left: 2rem;
            }

            .timeline-card {
                border: 2px solid #0054A4;
                padding: 20px;
                position: relative;
                margin: 0 auto;
                font-size: 18px;
                font-weight: normal;
                p {
                    color: #000000;
                    font-size: 18px;
                    margin: 0.5rem auto 0;
                }
            }
            .date {
                position: absolute;
                font-weight: 700;
                top: 50%;
                display: block;
                transform: translateY(-50%);


            }
            &:nth-child(odd) {
                .timeline-card {
                    background: $lightGreen;
                    color: #000000;
                    border-color: $lightGreen;
                    border-radius: 25px;
                    padding: 30px 30px;

                    p { color: #fff; }
                }
                .timeline-card:before {
                    content: "";
                    border: solid $lightGreen;
                    border-width: 0 2px 2px 0;
                    background: $lightGreen;
                    display: inline-block;
                    padding: 7px;
                    transform-origin: center;
                    transform: rotate(-45deg) translateY(-50%);
                    position: absolute;
                    top: 50%;
                    right: -15px;
                }
                .date {
                    left: 100%;
                    display: block;
                    width: 100%;
                    padding-left: 2rem;
                    text-align: left;
                }
                &:after {
                    content: "";
                    display: block;
                    width: 20px;
                    height: 20px;
                    border: 0;
                    border-radius: 50%;
                    background:#0054A4;
                    right: -10px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    background: #fff;
                    border: 3px solid #0054A4;;
                }
            }
            &:nth-child(even) {
                .timeline-card {
                    background: #343958;
                    color: #fff;
                    border-color: #343958;
                    border-radius: 25px;
                    padding: 30px 30px;
                    h2 { color: #fff; }
                    p { color: #fff; }
                }
                .timeline-card:before {
                    content: "";
                    border: solid #343958;
                    border-width: 0 2px 2px 0;
                    background: #343958;
                    display: inline-block;
                    padding: 7px;
                    transform-origin: center;
                    transform: rotate(-45deg) translateY(-50%) scale(-1);
                    position: absolute;
                    top: 50%;
                    left: -4px;
                }
                .date {
                    left: -100%;
                    display: block;
                    width: 100%;
                    padding-right: 2rem;
                    text-align: right;
                }
                &:after {
                    content: "";
                    display: block;
                    width: 20px;
                    height: 20px;
                    border: 0;
                    border-radius: 50%;
                    background:#0054A4;
                    left: -10px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    background: #fff;
                    border: 3px solid #0054A4;
                }
            }
        }

        @media screen and (max-width: 767px) {
            &:before {
                content: "";
                display: block;
                width: 3px;
                height: 100%;
                background: #0054A4;
                position: absolute;
                left: 0%;
                transform: translateX(0%);
            }
            li {
                width: 100%;
                margin: 2rem 0;
                position: relative;
                padding-left: 2rem;
                padding-right: 0;
                padding-top: 2rem;
                &:nth-child(even) {
                    margin-left: 0%;
                    padding-right: 0;
                    padding-left: 2rem;
                }
                &:nth-child(even) {
                    .timeline-card:before {
                        top: calc(50% - 1rem);
                    }
                    .date {
                        left: 0%;
                        top: 1rem;
                        display: block;
                        width: 100%;
                        padding: 0;
                        padding-left: 2rem;
                        text-align: left;
                    }
                }
                &:nth-child(odd) {
                    .timeline-card:before {
                        content: "";
                        border: solid #a2c748;
                        border-width: 0 2px 2px 0;
                        background: #a2c748;
                        display: inline-block;
                        padding: 7px;
                        transform-origin: center;
                        transform: rotate(-45deg) translateY(-50%) scale(-1);
                        position: absolute;
                        top: calc(50% - 1rem);
                        left: -4px;
                        right: auto;

                    }
                    .date {
                        left: 0%;
                        top: 1rem;
                        display: block;
                        width: 100%;
                        padding: 0;
                        padding-left: 2rem;
                        text-align: left;
                    }
                    &:after {
                        content: "";
                        display: block;
                        width: 20px;
                        height: 20px;
                        border: 0;
                        border-radius: 50%;
                        background:#0054A4;
                        left: -10px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        background: #fff;
                        border: 3px solid #0054A4;;
                    }
                }
                
            }
            
        }
        
    }
}


    
</style>