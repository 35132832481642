<template>
  <div class="textSlider-comp">
    <b-row>
      <b-col cols="12" md="12" lg="5">
        <div class="TS-Text">
          <h2 v-if="title">{{ title }}</h2>
          <p v-if="text" v-html="text"></p>
          <button v-if="button && buttonText && link">
            <router-link :to="link">{{ buttonText }}</router-link>
          </button>
        </div>
      </b-col>
      <b-col cols="12" md="12" lg="7">
        <div class="TS-Slider">
           <img class="image" src="https://firebasestorage.googleapis.com/v0/b/m5j10webportal.appspot.com/o/Home%2Fhome_aboutViz.png?alt=media&token=7e3cc8f7-de67-41d8-95a2-69de0de4e409"
                alt="Graphical representation of what the M5 Junction 10 will look like when the scheme is complete." v-if="image"/>

        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { VueAgile } from "vue-agile";

export default {
  name: "TextSlider",
  props: {
    title: {
      type: String,
      default: "Slider Title",
    },
    text: {
      type: String,
      default: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    image: {
      type: Boolean,
      default: false,
    },
    button: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: "Button",
    },
    carousel: {
      type: Array,
      default() {
        return [{}];
      },
    },
    link: {
      type: String,
      default: "/",
    },
  },
  components: {
    VueAgile,
  },
};
</script>

<style lang="scss">
.textSlider-comp {
  .TS-Slider {
    .slide {
      height: 455px;
      width: 100%;
      background: grey;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover; 
      }
    }

    .image {
      width: auto;

       @media screen and (max-width: 1024px) {
         width: 100%;
         height: auto;
         position: relative;
         object-fit: cover;
         z-index: 1000;
       }
    }

    .agile__actions {
      position: relative;
      top: -280px;

      button {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        background: #f2696d;
        border: none;
        color: white;
        font-size: 30px;
        padding: 0;
        padding-bottom: 5px;

        &:hover {
          background: #f14a4f;
        }

        &.agile__nav-button.agile__nav-button--prev {
          position: absolute;
          left: -33px;

          @media screen and (max-width: 576px) {
            left: 0;
          }
        }

        &.agile__nav-button.agile__nav-button--next {
          position: absolute;
          right: -33px;

          @media screen and (max-width: 576px) {
            right: 0;
          }
        }
      }

      .agile__dots {
        display: none;
      }
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
      height: auto;
      position: relative;
    }
  }
  .TS-Text {
    padding-right: 7%;
    height: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    button {
      background-color: #f2696d;
      padding: 14px 47px;
      border-radius: 8px;
      outline: none;
      border: solid #f2696d 2px;

      a {
        color: white;
        font-family: "GothamBold", "Roboto", sans-serif !important;
      }

      &:hover {
        background-color: white;
        border: solid #f2696d 2px;
        color: #f2696d;

        a {
          color: #f2696d;
        }
      }

      @media screen and (max-width: 425px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 992px) {
      margin-bottom: 50px;
      padding-right: 0;
    }
  }
}
</style>