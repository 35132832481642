<template>
  <div class="questionnaire">

    <SectionMap v-if="!isThankYouPage" :currentIndex="section" @update="sectionUpdate"/>

    <!-- General questions -->
    <div
        id="feedback-section-1"
        :class="`inputSection ${section === 0 && 'active'}`"
    >
      <TextTitleFullWidth
          :title="sectionTitleData.title"
          :text="sectionTitleData.text"
          :smallTitle="true"
          
      />
      <div class="feedbackInput">
        <RadioColumn
            :id="'agreeWithScheme'"
            :titleText="section1QData.Q1.title"
            :questions="section1QData.Q1.questions"
            :commentBox="false"
            :currentInput="
            savedFeedbackData.section1.question1
              ? savedFeedbackData.section1.question1
              : []
          "
          
        />
      </div>
      <div class="feedbackInput">
        <CheckboxTable
            :id="'schemeOptionOpinion'"
            :titleText="section1QData.Q2.title"
            :list="section1QData.Q2.paragraphs"
            :answers="section1QData.Q2.answers"
            :questions="section1QData.Q2.questions"
            :commentBox="true"
            :commentBoxText="section1QData.Q2.commentBox"
            :commentBoxPreviewText="section1QData.Q2.commentBoxPreviewText"
            :currentInput="
            savedFeedbackData.section1.question2
              ? savedFeedbackData.section1.question2
              : [[], [], [], []]
          "
        />
      </div>

      <div class="feedbackInput">
        <RadioButtonTable
            :id="'concerns'"
            :titleText="section1QData.Q3.title"
            :list="section1QData.Q3.paragraphs"
            :answers="section1QData.Q3.answers"
            :questions="section1QData.Q3.questions"
            :commentBox="false"
            :commentBoxText="section1QData.Q3.commentBox"
            :currentInput="
            savedFeedbackData.section1.question3
              ? savedFeedbackData.section1.question3
              : []
          "
            :listOn="false"
        />
      </div>

      <div class="feedbackInput">
        <RadioButtonTable
            :id="'concerns'"
            :titleText="section1QData.Q4.title"
            :list="section1QData.Q4.paragraphs"
            :answers="section1QData.Q4.answers"
            :questions="section1QData.Q4.questions"
            :commentBox="false"
            :commentBoxText="section1QData.Q4.commentBox"
            :currentInput="
            savedFeedbackData.section1.question4
              ? savedFeedbackData.section1.question4
              : []
          "
            :listOn="false"
        />
      </div>

    </div>


    <!-- Our proposal -->
    <div
        id="feedback-section-2"
        :class="`inputSection ${section === 1 && 'active'}`"
    >
      <TextTitleFullWidth
          :title="sectionTitleData.title"
          :text="sectionTitleData.text"
          :smallTitle="true"
      />

      <div class="feedbackInput">
        <div v-if="section2QData.subsection1">
          <h3>{{ section2QData.subsection1.title }}</h3>

          <RadioColumn
              :id="'travel'"
              :titleText="section2QData.subsection1.Q1.title"
              :questions="section2QData.subsection1.Q1.questions"
              :commentBox="false"
              :currentInput="
            savedFeedbackData.section2.question5
              ? savedFeedbackData.section2.question5
              : []
          "
          />

          <OptionComments
              :textTitle="section2QData.subsection1.Q2.title"
              :options="section2QData.subsection1.Q2.question"
              :commentBoxPreviewText="section2QData.subsection1.Q2.commentBoxPreviewText"
              :currentInput="
            savedFeedbackData.section2.question6
              ? savedFeedbackData.section2.question6
              : []
          "
          />

        </div>
      </div>

      <div class="feedbackInput">
        <div v-if="section2QData.subsection2">
          <h3>{{ section2QData.subsection2.title }}</h3>

          <RadioColumn
              :id="'travel'"
              :titleText="section2QData.subsection2.Q1.title"
              :questions="section2QData.subsection2.Q1.questions"
              :commentBox="false"
              :currentInput="
            savedFeedbackData.section2.question7
              ? savedFeedbackData.section2.question7
              : []
          "
          />

          <OptionComments
              :textTitle="section2QData.subsection2.Q2.title"
              :options="section2QData.subsection2.Q2.question"
              :commentBoxPreviewText="section2QData.subsection2.Q2.commentBoxPreviewText"
              :currentInput="
            savedFeedbackData.section2.question8
              ? savedFeedbackData.section2.question8
              : []
          "
          />

        </div>
      </div>

      <div class="feedbackInput">
        <div v-if="section2QData.subsection3">
          <h3>{{ section2QData.subsection3.title }}</h3>

          <RadioColumn
              :id="'travel'"
              :titleText="section2QData.subsection3.Q1.title"
              :questions="section2QData.subsection3.Q1.questions"
              :commentBox="true"
              :commentBoxText="section2QData.subsection3.Q1.commentBox"
              :commentBoxPreviewText="section2QData.subsection3.Q1.commentBoxPreviewText"
              :ariaLabelText="section2QData.subsection3.Q1.optionDetail"
              :currentInput="
            savedFeedbackData.section2.question9
              ? savedFeedbackData.section2.question9
              : []
          "
          />

        </div>
      </div>

      <div class="feedbackInput">
        <div v-if="section2QData.subsection4">
          <h3>{{ section2QData.subsection4.title }}</h3>

          <h4 class="h5">{{ section2QData.subsection4.extrasubsection1.title }}</h4>
          <RadioColumn
              :id="'travel'"
              :titleText="section2QData.subsection4.extrasubsection1.Q1.title"
              :questions="section2QData.subsection4.extrasubsection1.Q1.questions"
              :commentBox="false"
              :currentInput="
            savedFeedbackData.section2.question10
              ? savedFeedbackData.section2.question10
              : []
          "
          />

          <OptionComments
              :textTitle="section2QData.subsection4.extrasubsection1.Q2.title"
              :options="section2QData.subsection4.extrasubsection1.Q2.question"
              :commentBoxPreviewText="section2QData.subsection4.extrasubsection1.Q2.commentBoxPreviewText"
              :currentInput="
            savedFeedbackData.section2.question11
              ? savedFeedbackData.section2.question11
              : []
          "
          />

          <h4 class="h5">{{ section2QData.subsection4.extrasubsection2.title }}</h4>
          <RadioColumn
              :id="'travel'"
              :titleText="section2QData.subsection4.extrasubsection2.Q1.title"
              :questions="section2QData.subsection4.extrasubsection2.Q1.questions"
              :commentBox="false"
              :currentInput="
            savedFeedbackData.section2.question12
              ? savedFeedbackData.section2.question12
              : []
          "
          />

          <OptionComments
              :textTitle="section2QData.subsection4.extrasubsection2.Q2.title"
              :options="section2QData.subsection4.extrasubsection2.Q2.question"
              :commentBoxPreviewText="section2QData.subsection4.extrasubsection2.Q2.commentBoxPreviewText"
              :currentInput="
            savedFeedbackData.section2.question13
              ? savedFeedbackData.section2.question13
              : []
          "
          />


        </div>
      </div>

      <div class="feedbackInput">
        <div v-if="section2QData.subsection5">
          <h3>{{ section2QData.subsection5.title }}</h3>

          <RadioColumn
              :id="'travel'"
              :titleText="section2QData.subsection5.Q1.title"
              :questions="section2QData.subsection5.Q1.questions"
              :commentBox="false"
              :currentInput="
            savedFeedbackData.section2.question14
              ? savedFeedbackData.section2.question14
              : []
          "
          />

          <OptionComments
              :textTitle="section2QData.subsection5.Q2.title"
              :options="section2QData.subsection5.Q2.question"
              :commentBoxPreviewText="section2QData.subsection5.Q2.commentBoxPreviewText"
              :currentInput="
            savedFeedbackData.section2.question15
              ? savedFeedbackData.section2.question15
              : []
          "
          />

        </div>
      </div>

    </div>

    <!-- Environment -->
    <div
        id="feedback-section-3"
        :class="`inputSection ${section === 2 && 'active'}`"

    >
      <TextTitleFullWidth
          :title="sectionTitleData.title"
          :text="sectionTitleData.text"
          :smallTitle="true"
      />

      <div class="feedbackInput">
        <RadioColumn
            :id="'travel'"
            :titleText="section3QData.Q1.title"
            :questions="section3QData.Q1.questions"
            :commentBox="false"
            :currentInput="
            savedFeedbackData.section3.question16
              ? savedFeedbackData.section3.question16
              : []
          "
        />
      </div>

      <div class="feedbackInput">
        <OptionComments
            :textTitle="section3QData.Q2.title"
            :options="section3QData.Q2.question"
            :commentBoxPreviewText="section3QData.Q2.commentBoxPreviewText"
            :currentInput="
            savedFeedbackData.section3.question17
              ? savedFeedbackData.section3.question17
              : []
          "
        />
      </div>

      <div class="feedbackInput">
        <OptionComments
            :textTitle="section3QData.Q3.title"
            :options="section3QData.Q3.question"
            :commentBoxPreviewText="section3QData.Q3.commentBoxPreviewText"
            :currentInput="
            savedFeedbackData.section3.question18
              ? savedFeedbackData.section3.question18
              : []
          "
        />
      </div>

      <h3>{{ section3QData.subsection0.title }}</h3>

      <div class="feedbackInput">
        <OptionComments
            :textTitle="section3QData.subsection0.Q4.title"
            :options="section3QData.subsection0.Q4.question"
            :commentBoxPreviewText="section3QData.subsection0.Q4.commentBoxPreviewText"
            :currentInput="
            savedFeedbackData.section3.question19
              ? savedFeedbackData.section3.question19
              : []
          "
        />
      </div>

      <h3>{{ section3QData.subsection1.title }}</h3>

      <div class="feedbackInput">
        <CheckboxTable
            :id="'travel'"
            :titleText="section3QData.subsection1.Q5.title"
            :list="section3QData.subsection1.Q5.paragraphs"
            :answers="section3QData.subsection1.Q5.answers"
            :questions="section3QData.subsection1.Q5.questions"
            :areQuestions="false"
            :commentBox="true"
            :commentBoxText="section3QData.subsection1.Q5.commentBox"
            :commentBoxPreviewText="section3QData.subsection1.Q5.commentBoxPreviewText"
            :currentInput="
            savedFeedbackData.section3.question20
              ? savedFeedbackData.section3.question20
              : [[], [], [], []]
          "
        />
      </div>

      <div class="feedbackInput">
        <OptionComments
            :textTitle="section3QData.subsection1.Q6.title"
            :options="section3QData.subsection1.Q6.question"
            :commentBoxPreviewText="section3QData.subsection1.Q6.commentBoxPreviewText"
            :currentInput="
            savedFeedbackData.section3.question21
              ? savedFeedbackData.section3.question21
              : []
          "
        />
      </div>

      <div class="feedbackInput">
        <RadioColumn
            :id="'travel'"
            :titleText="section3QData.subsection1.Q7.title"
            :questions="section3QData.subsection1.Q7.questions"
            :commentBox="true"
            :commentBoxText="section3QData.subsection1.Q7.commentBox"
            :commentBoxPreviewText="section3QData.subsection1.Q7.commentBoxPreviewText"
            :ariaLabelText="section3QData.subsection1.Q7.optionDetail"
            :currentInput="
            savedFeedbackData.section3.question22
              ? savedFeedbackData.section3.question22
              : []
          "
        />
      </div>

      <div class="feedbackInput">
        <RadioColumn
            :id="'travel'"
            :titleText="section3QData.subsection1.Q8.title"
            :questions="section3QData.subsection1.Q8.questions"
            :commentBox="false"
            :commentBoxText="section3QData.subsection1.Q8.commentBox"
            :currentInput="
            savedFeedbackData.section3.question23
              ? savedFeedbackData.section3.question23
              : []
          "
        />
      </div>

      <div class="feedbackInput">
        <NameAddress
            :nameAddress="section3QData.subsection1.Q9.values"
            :title="section3QData.subsection1.Q9.title"
            :text="section3QData.subsection1.Q9.text"
            :ariaLabelText="section3QData.subsection1.Q9.optionDetail"
            :currentInput="
              savedFeedbackData.section3.question24
              ? savedFeedbackData.section3.question24
              : {}"
        />
      </div>
    </div>

    <!-- About you-->
    <div
        id="feedback-section-4"
        :class="`inputSection ${section === 3 && 'active'}`"
    >
      <TextTitleFullWidth
          :title="sectionTitleData.title"
          :text="sectionTitleData.text"
          :smallTitle="true"
      />

      <div class="feedbackInput">
        <NameAddress
            :nameAddress="section4QData.Q1.values"
            :title="section4QData.Q1.title"
            :text="section4QData.Q1.text"
            :ariaLabelText="section4QData.Q1.optionDetail"
            :currentInput="
              savedFeedbackData.section4.question25
              ? savedFeedbackData.section4.question25
              : {}"
        />
      </div>
      <div class="feedbackInput">
        <RadioColumn
            :id="'travel'"
            :titleText="section4QData.Q2.title"
            :questions="section4QData.Q2.questions"
            :commentBox="false"
            :currentInput="
            savedFeedbackData.section4.question26
              ? savedFeedbackData.section4.question26
              : []
          "
        />
      </div>

      <div class="feedbackInput">
        <RadioColumn
            :id="'useriden'"
            :titleText="section4QData.Q3.title"
            :questions="section4QData.Q3.questions"
            :commentBox="false"
            :currentInput="
            savedFeedbackData.section4.question27
              ? savedFeedbackData.section4.question27
              : []
          "
        />
      </div>
      <div class="feedbackInput">
        <RadioColumn
            :id="'travel'"
            :titleText="section4QData.Q4.title"
            :questions="section4QData.Q4.questions"
            :commentBox="false"
            :currentInput="
            savedFeedbackData.section4.question28
              ? savedFeedbackData.section4.question28
              : []
          "
        />
      </div>

      <div class="feedbackInput">
        <RadioButtonTable
            :id="'concerns'"
            :hasNoQuestions="true"
            :values="section4QData.Q5.values"
            :titleText="section4QData.Q5.title"
            :list="section4QData.Q5.paragraphs"
            :commentBox="true"
            :commentBoxText="section4QData.Q5.commentBox"
            :commentBoxPreviewText="section4QData.Q5.commentBoxPreviewText"
            :currentInput="
            savedFeedbackData.section4.question29
              ? savedFeedbackData.section4.question29
              : []
          "
            :listOn="false"
        />
      </div>

      <div class="feedbackInput">
        <RadioColumn
            :id="'travel'"
            :titleText="section4QData.Q6.title"
            :questions="section4QData.Q6.questions"
            :commentBox="false"
            :currentInput="
            savedFeedbackData.section4.question30
              ? savedFeedbackData.section4.question30
              : []
          "
        />
      </div>
      <div class="feedbackInput">
        <RadioColumn
            :id="'travel'"
            :titleText="section4QData.Q7.title"
            :questions="section4QData.Q7.questions"
            :commentBox="false"
            :currentInput="
            savedFeedbackData.section4.question31
              ? savedFeedbackData.section4.question31
              : []
          "
        />
      </div>
      <div class="feedbackInput">
        <RadioColumn
            :id="'marriage'"
            :titleText="section4QData.Q8.title"
            :questions="section4QData.Q8.questions"
            :commentBox="false"
            :currentInput="
            savedFeedbackData.section4.question32
              ? savedFeedbackData.section4.question32
              : []
          "
        />
      </div>
      <div class="feedbackInput">
        <RadioColumn
            :id="'religion'"
            :titleText="section4QData.Q9.title"
            :questions="section4QData.Q9.questions"
            :commentBox="true"
            :commentBoxText="section4QData.Q9.commentBox"
            :commentBoxPreviewText="section4QData.Q9.commentBoxPreviewText"
            :ariaLabelText="section4QData.Q9.optionDetail"
            :currentInput="
            savedFeedbackData.section4.question33
              ? savedFeedbackData.section4.question33
              : []
          "
        />
      </div>
    </div>

    <div v-if="isThankYouPage"
         id="feedback-section-5"
         :class="`inputSection ${section === 4 && 'active'}`"
    >
      <ThankYouMessage
          :message="thankYouMessageData.message"
          :titleText="thankYouMessageData.title"
          :btn="true"
          :btnText="thankYouMessageData.btn"
      />
    </div>

    <button class="button-primary  button-blue-on-white-transparent"
        v-if="section < 3"
        @click="
        () => {
          scrollToTop();
          sectionSaveNext(section, 4);
        }
      "
    >
      {{ feedbackBtn }}
      <span class="chevron"><i class="fas fa-chevron-right"></i></span>
    </button>

    <button class="button-primary  button-blue-on-white-transparent"
        v-if="section === 3"
        @click="
        () => {
          switchThankYouPageOn();
          scrollToTop();
          submitData();
        }
      "
    >
      Submit
      <span class="chevron"><i class="fas fa-chevron-right"></i></span>
    </button>
  </div>
</template>

<script>
import SectionMap from "../../FormComps/SectionMap";
import TextTitleFullWidth from "../../TitleTextFullWidth";
import NameAddress from "../../FormComps/NameAddress";
import DataProtection from "../../FormComps/DataProtection";
import RadioColumn from "../../FormComps/RadioColumn";
import FeedbackSlider from "../../Project/M5J10/FeedbackSlider";
import OptionComments from "../../FormComps/OptionComments";
import CheckboxTable from "../../FormComps/CheckboxTable";
import RadioButtonTable from "../../FormComps/RadioButtonTable";
import CheckColumn from "../../FormComps/CheckColumn";
import ThankYouMessage from "../../FormComps/ThankYouMessage";
import {mapGetters, mapActions} from "vuex";
import SurveyFeedbackFormatter from "../../../services/formatData";
import API from "../../../services/index";

export default {
  name: "Questionnaire",
  props: {
    
    route: {
      type: String,
      default: "/en/feedback",
    },
    isThankYouPage: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    SectionMap,
    TextTitleFullWidth,
    NameAddress,
    DataProtection,
    RadioColumn,
    FeedbackSlider,
    OptionComments,
    CheckboxTable,
    RadioButtonTable,
    CheckColumn,
    ThankYouMessage,
  },
  data() {
    return {
      section: 0,
    };
  },
  computed: {
    ...mapGetters(["feedback"]),
    savedFeedbackData() {
      if (this.feedback) {
        return this.feedback;
      } else return {};
    },
    sectionTitleData() {
      if (this.data.sectionTitle && this.data.sectionTitle[this.section]) {
        return this.data.sectionTitle[this.section];
      } else return {title: "", text: ""};
    },
    section1QData() {
      if (this.data.section1Questions) {
        return this.data.section1Questions;
      } else return {};
    },
    section2QData() {
      if (this.data.section2Questions) {
        return this.data.section2Questions;
      } else return {};
    },
    section3QData() {
      if (this.data.section3Questions) {
        return this.data.section3Questions;
      } else return {};
    },
    section4QData() {
      if (this.data.section4Questions) {
        return this.data.section4Questions;
      } else return {};
    },
    thankYouMessageData() {
      if (this.data.section5message) {
        return this.data.section5message;
      } else return {};
    },
    feedbackBtn() {
      if (this.data.sectionBtn) {
        return this.data.sectionBtn;
      } else return {};
    },
    submitBtn() {
      if (this.data.submitBtn) {
        this.scrollToTop();

        return this.data.submitBtn;
      } else return {};
    },
  },
  methods: {
    ...mapActions(["saveFeedbackData"]),
    switchThankYouPageOn() {
      this.isThankYouPage = true;
    },
    scrollToTop() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    resetForm() {
      var self = this; //you need this because *this* will refer to Object.keys below`

      //Iterate through each object field, key is name of the object field`
      Object.keys(this.feedback).forEach(function (key, index) {
        self.feedback[key] = '';
      });
    },
    submitData() {
      let requestStatus;

      this.collectData();

      let Formatter = new SurveyFeedbackFormatter(this.savedFeedbackData);
      let dataReady = Formatter.formatData();
      requestStatus = API.postFeedback(dataReady);

      this.section++;

      this.resetForm();
    },
    sectionSaveNext() {
      this.collectData();
      this.section++;
    },
    sectionUpdate(index) {
      this.collectData();
      this.section = index;
    },
    section1Data(data) {

      let feedbackDataObj = {
        sectionIndex: 0,
        question1: data[2].radioFeedback,
        question2: data[3].feedbackData,
        question3: data[4].tableAnswers,
        question4: data[5].tableAnswers,
      };
      this.saveFeedbackData(feedbackDataObj);
    },
    section2Data(data) {

      let feedbackDataObj = {
        sectionIndex: 1,
        question5: data[7].radioFeedback,
        question6: data[8].feedBackData,
        question7: data[9].radioFeedback,
        question8: data[10].feedBackData,
        question9: data[11].radioFeedback,
        question10: data[12].radioFeedback,
        question11: data[13].feedBackData,
        question12: data[14].radioFeedback,
        question13: data[15].feedBackData,
        question14: data[16].radioFeedback,
        question15: data[17].feedBackData,
      };
      this.saveFeedbackData(feedbackDataObj);
    },
    section3Data(data) {

      let feedbackDataObj = {
        sectionIndex: 2,
        question16: data[19].radioFeedback,
        question17: data[20].feedBackData,
        question18: data[21].feedBackData,
        question19: data[22].feedBackData,
        question20: data[23].feedbackData,
        question21: data[24].feedBackData,
        question22: data[25].radioFeedback,
        question23: data[26].radioFeedback,
        question24: data[27].nameAddressData,
      };
      this.saveFeedbackData(feedbackDataObj);
    },
    section4Data(data) {

      let feedbackDataObj = {
        sectionIndex: 3,
        question25: data[29].nameAddressData,
        question26: data[30].radioFeedback,
        question27: data[31].radioFeedback,
        question28: data[32].radioFeedback,
        question29: data[33].tableAnswers,
        question29_comment: data[33].commentFeedback,
        question30: data[34].radioFeedback,
        question31: data[35].radioFeedback,
        question32: data[36].radioFeedback,
        question33: data[37].radioFeedback,
      };
      this.saveFeedbackData(feedbackDataObj);
    },
    collectData() {
      let feedbackDataArray = [];

      for (let i = 0; i < this.$children.length; i++) {
        feedbackDataArray.push(this.$children[i]._data);
      }

      switch (this.section) {
        case 0:
          this.section1Data(feedbackDataArray);
          break;
        case 1:
          this.section1Data(feedbackDataArray);
          this.section2Data(feedbackDataArray);
          break;
        case 2:
          this.section1Data(feedbackDataArray);
          this.section2Data(feedbackDataArray);
          this.section3Data(feedbackDataArray);
          break;
        case 3:
          this.section1Data(feedbackDataArray);
          this.section2Data(feedbackDataArray);
          this.section3Data(feedbackDataArray);
          this.section4Data(feedbackDataArray);
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss">

@import '/src/Variables';

.questionnaire
{

  h4{
    color: #000000;
  }

  .inputSection {
    display: none;

    &.active {
      display: block;
    }
  }

  button {
    padding: 16px 34px;
  }
}
</style>

