<template>
  <div class="ToggleCenter">
    <label class="switch" for="toggle-button" tabindex="0">
      <input type="checkbox" id="toggle-button" v-model="checkedValue" name="change view" value="change view toggle">
      <span class="slider round" id="slider"></span>
    </label>
    <h2 v-if="Title">{{Title}}</h2>
  </div>
</template>

<script>
export default {
  name: "Switch",
  props: {
    defaultState: {
      type: Boolean,
      default: false
    },
    Title: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      currentState: this.defaultState
    }
  },

  computed: {
    isActive() {
      return this.currentState;
    },

    checkedValue: {
      get() {
        return this.defaultState
      },
      set(newValue)
      {
        if (newValue)
        {
          this.Title = "Switch to grid view"
        }
        else
        {
          this.Title = "Switch to map view"
        }

        this.currentState = newValue;
        this.$emit('change', newValue);

        let vm = this.$parent
        while(vm)
        {
          vm.$emit('change', newValue)
          vm = vm.$parent
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import "../Variables";

.ToggleCenter
{
  width: 100%;
  align-self: center;
  text-align: center;
  @media screen and (min-width: 768px) {
      width: 25%;
  }

   @media screen and (min-width: 1025px) {
      width: 100%;
  }

  h2 {
    color: #ffffff;
    font-size: 16px;
    line-height: 22px;
    margin-top: 16px;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 72px;
  height: 28px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 36px;
  width: 36px;
  left: -2px;
  bottom: -4px;
  -webkit-transition: .4s;
  transition: .4s;
  background: url(../assets/images/Icons/switchIconOff.png) #FDC600 10px 11px no-repeat;
}

input:checked + .slider {
  background-color: #EAEAEA;
}


input:checked + .slider:before {
  -webkit-transform: translateX(40px);
  -ms-transform: translateX(40px);
  transform: translateX(40px);
  background: url(../assets/images/Icons/switchIconOn.png) #FDC600 11px 10px  no-repeat;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>