<template>
  <div id="app">
    <router-view />
    <footer>
      <cookie-law theme="dark-lime" ref="cookieBanner">

        <div slot-scope="props">
          <p>
            This website uses cookies to remember things like which language you
            have chosen, the order in which you have looked at our web pages or
            whether you have been to the site before.
          </p>
          <p>
            A 'cookie' is a small text file that is written to your computer by
            the web browser. Our cookies do not contain any personal
            information; they’re just markers to remember things selected while
            on the site. There are 2 types of cookies: ‘session’ cookies are
            only used while you’re on the site and are deleted when the browser
            is closed; ‘persistent’ cookies are temporarily stored on your
            computer for a set length of time and can be re-used when you return
            to the site. You can learn more on websites like this one: <a href="www.allaboutcookies.org">www.allaboutcookies.org</a> or
            <a href="www.aboutcookies.org">www.aboutcookies.org</a>
          </p>
          <div class="Cookie-buttons">
            <button class="button-primary button-transparent" @click="props.accept"><span>I accept</span></button>
            <button class="button-primary button-transparent" @click="props.close"><span>I decline</span></button>
          </div>
        </div>
      </cookie-law>
    </footer>
  </div>
</template>

<script>
import CookieLaw from "vue-cookie-law";

export default {
  name: "App",
  components: {
    CookieLaw,
  },

  computed: {
    areCookiesAccepted() {
      if(this.$refs.cookieBanner.isAccepted())
      {
        return true
      }
      else
      {
        return false
      }
    }
  },
  mounted() {
    this.areCookiesAccepted;
    //console.log(this.areCookiesAccepted) // true or false
  },
  methods: {
    cookiePolicy() {},
  },
};
</script>

<style lang="sass">
@import 'assets/scss/main.scss'
</style>
