<template>
  <div class="FeedbackSlider-comp">
    <b-row>
      <b-col cols="12">
        <div class="FS-Text">
          <h5 v-if="title">{{ title }}</h5>
          <p v-if="text">{{ text }}</p>
          <button v-if="button && buttonText && link">
            <router-link :to="link">{{ buttonText }}</router-link>
          </button>
        </div>
      </b-col>
      <b-col cols="12">
        <div class="FS-Slider">
          <VueAgile v-if="carousel">
            <div
              v-for="(slide, index) in carousel"
              :key="`${$route.name}_feedbackSlide_${index}`"
              class="slide"
            >
              <img :src="slide.image" />
            </div>
          </VueAgile>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { VueAgile } from "vue-agile";

export default {
  name: "FeedbackSlider",
  props: {
    title: {
      type: String,
      default: "Slider Title",
    },
    text: {
      type: String,
      default: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    button: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: "Button",
    },
    carousel: {
      type: Array,
      default() {
        return [{}];
      },
    },
    link: {
      type: String,
      default: "/",
    },
  },
  components: {
    VueAgile,
  },
};
</script>

<style lang="scss">
.FeedbackSlider-comp {
  .FS-Slider {
    width: 50%;

    @media screen and (max-width: 992px) {
      width: 100%;
    }
    //border-radius: 20px;
    border: solid 4px #f7a525;
    .slide {
      height: 455px;
      width: 100%;
      background: grey;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .agile__actions {
      position: relative;
      top: -250px;

      button {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        background: #f2696d;
        border: none;
        color: white;
        font-size: 30px;
        padding: 0;
        padding-bottom: 5px;

        &.agile__nav-button.agile__nav-button--prev {
          position: absolute;
          left: -33px;

          @media screen and (max-width: 576px) {
            left: 0;
          }
        }

        &.agile__nav-button.agile__nav-button--next {
          position: absolute;
          right: -33px;

          @media screen and (max-width: 576px) {
            right: 0;
          }
        }
      }

      .agile__dots {
        display: none;
      }
    }
  }
  .FS-Text {
    padding-right: 20%;
    height: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    button {
      background-color: #f2696d;
      padding: 14px 47px;
      border-radius: 8px;
      outline: none;
      border: none;
      a {
        color: white;
        font-family: "GothamBold", "Roboto", sans-serif !important;
      }

      @media screen and (max-width: 425px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 992px) {
      margin-bottom: 50px;
      padding-right: 0;
    }
  }
}
</style>