<template>
  <section class="footer-comp">
    <div class="mainContent foot">
      <div class="row">
        <div class="col s12 m6">
          <div class="links-logo">
            <div class="project-logo">
              <div
                  :style="{
                  'background-size': 'contain',
                  'background-repeat': 'no-repeat',
                }"
              >
              <a href="https://www.gloucestershire.gov.uk/highways/major-projects-list/m5-junction-10-improvements-scheme/" target="_blank">
                <img src="@/assets/images/logos/logo.png" style="height: 60px; width: 330px" alt="Gloucestershire County Council logo."/>
              </a>

              </div>
            </div>
          </div>
          <div class="footerNavs">
            <ul>
              <li class="footerNav"><a href="https://www.gloucestershire.gov.uk/council-and-democracy/data-protection/privacy-notices/" target="_blank">Privacy notices</a></li>
              <li class="footerNav"><a href="https://www.gloucestershire.gov.uk/gloucestershire-county-council-news/" target="_blank">Latest news</a></li>
              <li class="footerNav"><a href="https://www.gov.uk/" target="_blank">Gov.uk</a></li>
              <li class="footerNav"><a href="https://www.gloucestershire.gov.uk/sitemap/" target="_blank">Sitemap</a></li>
              <li class="footerNav"><a href="https://www.gloucestershire.gov.uk/accessibility/" target="_blank">Accessibility</a></li>
            </ul>
          </div>
        </div>
        <div class="col s12 m6">
          <div class="social">
            <div class="pcg-footer-contact-table">
              <h3 class="pcg-footer-contact-link">
                <a href="https://www.gloucestershire.gov.uk/contact-us/" target="_blank">Contact us</a>
              </h3>
              <div class="icons">
                <a href="https://www.facebook.com/GloucestershireCountyCouncil/" target="_blank" title="Follow us on Facebook" >
                  <img src="@/assets/images/template/footer/fb.png" alt="Facebook">
                </a>
                <a href="https://twitter.com/GlosCC" target="_blank" title="Follow us on Twitter">
                  <img src="@/assets/images/template/footer/twitter.png" alt="Twitter">
                </a>
                <a href="https://www.linkedin.com/company/gloucestershire-county-council/" target="_blank" title="Follow us on LinkedIn">
                  <img src="@/assets/images/template/footer/linkedIn.png" alt="LinkedIn">
                </a>
                <a href="https://www.instagram.com/gloucestershirecc/?utm_medium=copy_link" target="_blank" title="Follow us on Instagram">
                  <img src="@/assets/images/template/footer/insta.png" alt="Instagram">
                </a>
                <a href="https://www.youtube.com/gloscountycouncil" target="_blank" title="Follow us on Youtube">
                  <img src="@/assets/images/template/footer/yt.png" alt="YouTube">
                </a>
                <a href="https://www.gloucestershire.gov.uk/gloucestershire-county-council-news/rss/" target="_blank" title="News RSS">
                  <img src="@/assets/images/template/footer/rss.png" alt="RSS Feed">
                </a>
              </div>
              <div class="copyright" v-if="copyWrite">{{ copyWrite }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Footer",
  props: {
    footerNavs: {
      type: Array,
      default() {
        return [];
      },
    },
    copyWrite: {
      type: String,
    },
  },
};
</script>

<style lang="scss">

.footer-comp {
  padding: 3rem 0;
  width: 100%;
  height: 25em;
  min-height: 100vh;
   @media screen and (min-width: 1024px) {
      height: 25em;
      min-height: 25em;
    }

  .footerNavs {
    margin-top: 10%;

    .footerNav {
      font-size: 16px;
      line-height: 18px;
      margin-top: 5px;
    }
  }

  .row {
    margin: 0 auto;
  }

  a.footerNav{
    padding: 8px;
  }

  a.footerNav:hover{
    text-decoration: none;
    cursor: pointer;
    padding: 8px;
  }

  @media screen and (max-width: 992px) {
    height: 25em;
    width: 100%;
    padding: 2rem 0;
  }

  .links-logo {
    display: flex;
    flex-direction: row;
    margin-top: 3%;
    margin-left: 4%;

    img {
      display: block;
      width: 80px;
    }
    ul {
      margin: 0 auto;
      padding: 0;
      padding-left: 10px;
    }

    a {
      color: #0054A4;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .social {
    text-align: right;
    margin-top: 15%;

    h4 {
      font-size: 24px;
    }

    a {
      color: #ffffff;
      &:hover {
        text-decoration: underline;
      }
      &:focus {
        outline: 1px solid #ffffff;
      }
    }

    .icons {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-end;
      margin-top: 1.5em;

      a {
        display: block;
        padding: 0 5px;

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
      img {
        width: 52px;
        display: block;
      }
    }
  }

  .copyright{
    text-align: right;
    margin-top: 5%;
    color: #ffffff;
  }
}
</style>