<template>
  <div class="accordions-comp" v-if="items">
      <AccordionCard  v-for="(item, index) in items" :key="`${$route.name}_accordion_${index}`" :item="item" :id="idIndex  + '_' + index"/>
  </div>
</template>

<script>
import AccordionCard from "../components/AccordionCard";

export default {
  name: "Accordions",
  components: {
    AccordionCard,
  },
  props: {
    items: {
      type: Array,
      default() {
        return [{
          }];
      },
    },
    idIndex: Object,
  },
};
</script>

<style lang="scss">
@import "../Variables";
</style>