<template>
  <div class="section-map">
    <div class="sectionPoints">
      <div
        class="mapblock"
        v-for="(section, index) in sections"
        :key="`${$route.name}_secMapIndex_${index}`"
      >
        <div class="sectionIndex" @click="() => changeSection(index)">
          <div :class="currentIndex >= index ? 'active' : ''">
            {{ section.index }}</div>
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
export default {
  name: "SectionMap",
  props: {
    currentIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      sections: [
        { index: 1, title: "General questions" },
        { index: 2, title: "Our proposal" },
        { index: 3, title: "Environment" },
        { index: 4, title: "About you" },
      ],
    };
  },
  methods: {
    changeSection(index) {
      this.$emit("update", index);
    },
  },
};
</script>

<style lang="scss">
@import '../../../src/Variables';

.section-map {
  width: 100%;
  margin-bottom: 50px;

  @media screen and (max-width: 1024px) {
    width: 85%;
  }

  .sectionPoints {
    display: flex;
    flex-direction: row;

    .mapblock {
      flex-basis: 25%;
      position: relative;
      z-index: 30;

      @media screen and (max-width: 768px) {
        flex-basis: 33%;
      }

      .sectionIndex {
        width: 60px;
        height: 60px;
        background: #E7F6FD;
        border: solid $Gcc-Blue 5px;
        border-radius: 50%;
        margin: 0;
        cursor: pointer;




        }

        div {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          color: $Gcc-Blue;
          text-align: center;
          font-size: 32px;
          padding-top: 0px;
          font-weight: 700;

          &.active {
            background: $Gcc-Blue;;
            color: white;

        }
      }

      &:nth-child(2) {
        .sectionIndex {
          margin: 0 0 0 25%;
        }
      }
      &:nth-child(3) {
        .sectionIndex {
          margin: 0 0 0 60%;
        }
      }
      &:nth-child(4) {
        .sectionIndex {
          margin: 0 0 0 90%;
        }
      }
      &:nth-child(5) {
        .sectionIndex {
          float: right;
        }


        @media screen and (max-width: 768px)  {
          display: none;
        }
      }
    }
  }



  hr {
    border-top: 3px dashed $Gcc-Blue;
    width: 100%;
    margin: 0 auto;
    position: relative;
    top: -30px;

    @media screen and (max-width: 576px) {
      display: none;
    }
  }
}
</style>