<template>
  <b-nav-item-dropdown class="px-2" v-if="this.language && this.availableLanguages.length > 1">
    <template #button-content>
      <span>{{ language.code }}</span>
    </template>

    <b-dropdown-item v-for="(lang, index) in this.availableLanguages" :key="`${$route.name}_lang_${index}`" @click="() => toggleLang(lang)">
      {{lang}}
    </b-dropdown-item>

  </b-nav-item-dropdown>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "LanguageSelector",
  computed: {
    ...mapGetters(["language", "availableLanguages", "content"]),
    currentLanguage()
    {
      if(this.language)
      {
        return this.language
      }
      else
      {
        return null
      }
    },
  },
  mounted ()
  {
    let currLang = this.currentLanguage.code.toLowerCase();
    let linkLang = this.$route.params.lang.toLowerCase();
    if(currLang !== linkLang)
    {
      this.toggleLang(linkLang.toUpperCase());
    }
  },
  methods: {
    ...mapActions(["toggleLanguage"]),
    toggleLang(lang)
    {
      this.toggleLanguage(lang);
    },
  }
}
</script>

<style scoped>

</style>