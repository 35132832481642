<template>
  <div class="option-comments-comp">
    <div class="OP-title">{{ textTitle }}</div>
    <div
      class="OP-comment"
      v-for="(option, index) in options"
      :key="`${$route.name}_optioncomments${id}_${index}`"
    >
<!--      <div>{{ option.optionDetail }}</div>-->
      <div>
        <textarea :placeholder="commentBoxPreviewText" v-model="feedBackData[index]" :aria-label="option.optionDetail"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OptionComments",
  props: {
    id: {
      type: String,
      required: true,
    },
    textTitle: {
      type: String,
      default: "Option comment Title",
    },
    options: {
      type: Array,
      default() {
        return [{ optionDetail: "Option details here" }];
      },
    },
    ariaLabelText: {
      type: String,
      default: "",
    },
    commentBoxPreviewText: {
      type: String,
      default: "Type your answer here",
    },
    currentInput: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  beforeMount() {
    this.feedBackData = this.currentInput;
  },
  data() {
    return {
      feedBackData: [],
    };
  },
};
</script>

<style lang="scss">
.option-comments-comp {

  .OP-title {
    color: #000000;
    font-weight: bold;
    margin-right: 47%;
  }

  .OP-comment {
    padding-top: 1%;

    textarea {
      width: 52%;
      //border: #0054A4 solid 1.6px;
      border-radius: 8px;
      outline: none;
      padding: 10px;
      margin-top: 4px;
      background-color: transparent;
      /* GCC/blue */
      border: 1px solid #58595B;
      box-sizing: border-box;
      min-height: 154px;

      @media screen and (max-width: 992px) {
        width: 90%;
        padding: 0;
      }

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(#333333, 0.5);
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(#333333, 0.5);
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(#333333, 0.5);
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(#333333, 0.5);
      }
    }
  }
}
</style>