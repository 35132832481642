<template>
  <div class="SideMenu"  :class="[this.state ? 'menu-white' : '']">

    <a href="https://www.gloucestershire.gov.uk/highways/major-projects-list/m5-junction-10-improvements-scheme/" target="_blank">
      <img class="topLogo" :src="this.state ? getImgUrl('Gcc-Blue') : getImgUrl('logo')" alt="Gloucestershire County Council logo." />
    </a>

    <hr/>

    <div class="SideMenuButtons">

      <button class="button-primary" :class="[this.state ? 'button-blue-on-white' : '']"  @click="GoToPage('/en/feedback')">
        Complete the survey <span class="chevron"><i class="fas fa-chevron-right"></i></span>
      </button>

      <button class="button-primary" :class="[this.state ? 'button-blue-on-white' : '']" @click="GoToPage('/en/events')">
        View our events <span class="chevron"><i class="fas fa-chevron-right"></i></span>
      </button>

      <button class='button-primary'  :class="[this.state ? 'button-blue-on-white' : '']" @click="GoToPage('/')" >
        Back to home <span class="chevron"><i class="fas fa-chevron-right"></i></span>
      </button>

    </div>

    <SwitchToggle  class="SwitchToggle" :Title="this.state ? 'switch to grid view' : 'switch to map view'" :defaultState="false"/>

    <a class="accessibility" href="https://www.gloucestershire.gov.uk/accessibility/" target="_blank">Accessibility statement</a>

    <img class="bottomLogo" :src="this.state ? getImgUrl('atkinsBlue') : getImgUrl('atkinsWhite')" alt="Atkins logo" />
  </div>
</template>

<script>
import mixin from "../../mixins";
import SwitchToggle from "../Switch";

export default {
  name: "PortalSideMenu",
  components:
      {
        SwitchToggle
      },
  props:
      {
        state: Boolean
      },
  mixins:[mixin],
  methods:
      {
        getImgUrl(pet) {
          var images = require.context('../../assets/images/logos/', false, /\.png$/)
          return images('./' + pet + ".png")
        }
      }
}
</script>


<style lang="scss">
@import "../../Variables";

.SideMenu
{

  padding:25px;
  height: 100%;
  background-color: $Gcc-Blue;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (min-width: 1025px) {
    flex-direction: column;
  }

   .SwitchToggle {

    display: none;

    @media screen and (min-width: 961px) {
      display: block;
    }
   }

   .mobile {
     display: none;

    @media screen and (min-width: 1025px) {
      display: block;
    }
   }

  &.menu-white
  {
    background-color: #ffffff;

      .accessibility {
        color: $Gcc-DarkBlue;
        display: none;
        &:focus {
          outline: 1px solid $Gcc-DarkBlue;
        }

        @media screen and (min-width: 1025px) {
          display: block;
        }
      }

    hr {
      background-color: $Gcc-DarkBlue;
       display: none;

      @media screen and (min-width: 1025px) {
        display: block;
      }
    }

    .SwitchToggle {

      display: none;

      @media screen and (min-width: 768px) {
        display: block;
      }

      h2
      {
        color: $Gcc-DarkBlue;
      }
    }
  }

  hr {
    border: none;
    height: 1px;
    background-color: #ccc;
    text-align: left;
    width: 85%;
    margin: 0 auto;
    display: none;

    @media screen and (min-width: 1025px) {
      display: block;
    }
  }

  .accessibility {
    color: #ffffff;
    text-align: center;
    margin: 60px 0 40px;
    text-decoration: underline;
    display: none;
    &:focus {
        outline: 1px solid #ffffff;
    }

    @media screen and (min-width: 1025px) {
      display: block;
    }
  }

  .topLogo {
    max-width: 151px;
    max-height: 28px;
    text-align: center;
    margin: 20px 20px 20px 10px;

    @media screen and (min-width: 786px) {
      margin: 30px 100px 30px 28x;
      max-width: 196px;
      max-height: 36px;
    }

     @media screen and (min-width: 1025px) {
      margin: 60px auto 28px;
    }
  }

  .bottomLogo {
    max-width: 132px;
    max-height: 50px;
    text-align: center;
    margin: 0 auto;
    display: none;

    @media screen and (min-width: 1025px) {
      display: block;
    }
  }

  .SideMenuButtons {
    display: flex;
    width: 100%;

    @media screen and (min-width: 768px) {
      width: 54%;
    }

    @media screen and (min-width: 1025px) {
      flex: 1 1 auto;
      display: block;
      width: 100%;
    }

     button {
      //margin: 0;
      width: 100%;
       margin: 0 15px 0 0;

       @media screen and (min-width: 1025px) {
        margin: 0 0 32px 0;
      }

      &:first-of-type {
        margin: 0 15px 0 0;
          @media screen and (min-width: 1025px) {
            margin: 48px 0 32px;
          }
      }
      &:last-of-type {
      
          @media screen and (min-width: 1025px) {
            margin-bottom: auto;
          }
      }
    }
  }
 
}
</style>