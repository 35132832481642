<template>
  <section class="site-cards" v-if="data">
      <div class="boxRow">
        <div class="site-card-col" tab-index="0" role="button" md="6" lg="4" sm="12" v-for="(items, index) in data" :key="index">       
            <a class="site-card" href="#" target="_blank" @click="trackClick(items.link)">
              <div class="site-card-container">

              <div class="site-card-image">
                <img class="boxImage" :src="items.image" style="width: 100%" :alt="items.ImageAltText"/>
                <p><span>{{ hoverText }}</span></p>
              </div>
              <h2 class="site-card-title white-text">
                {{items.title}}
              </h2>
                <span class="sr-only">Open new window</span>
              </div>
            </a>
        </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    data: Array,
    hoverText: String,
  },
  methods:{
    trackClick(buttonLink){
      window.open(buttonLink,'_blank');
    }
  }
};
</script>

<style lang="scss">

$cardDeepBlue: #536f90;
$cardDeepGrey: #727d93;

.container {
  margin: 0 auto;
  max-width: 1439px;
  width: 100%;
}

.site-cards {
  .boxRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .col {
      margin: 0 0 20px;
      float: none;
    }
  }
}
.site-cards {
  margin: 80px 0 0 0;
}

.site-card-col {
  margin-bottom: 20px;

  @media screen and (max-width: 400px) {
    width: 100%;
  }

  @media screen and (max-width: 910px) and (min-width: 601px) {
    width: 50%;
  }

  @media screen  and (min-width: 911px) {
    width: 33.3%;
  }

}

.site-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 442px;
  margin: 0 20px 40px 20px;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none !important;
    box-shadow: inset 0px 0px 1px 1px rgba(0, 90, 132, 0.05);
    filter: drop-shadow(0px 0px 8px rgba(0, 90, 132, 0.4)) drop-shadow(0px 16px 50px rgba(0, 90, 132, 0.24));    
  }

  .site-card-container {
    &:hover,
    &:focus {
      box-shadow: inset 0px 0px 1px 1px rgba(0, 90, 132, 0.05);
      filter: drop-shadow(0px 0px 8px rgba(0, 90, 132, 0.4)) drop-shadow(0px 16px 50px rgba(0, 90, 132, 0.24));    
    }
  }

  .boxImage{
    max-width: 100%;
    height: 339px;
  }

  .site-card-image {
    position: relative;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    flex: 0 0 auto;
    height: 339px;

    &:after {
      content: "";
      display: block;
      padding-bottom: 75%;
    }
    p {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba($color: #000000, $alpha: 0.5);
      text-align: center;
      opacity: 0;
      margin: 0 auto;
      transition: 0.3s ease opacity;

      span {
        border: solid white;
        border-radius: 50px;
        padding: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 60%;
        color: #fff;
        font-weight: 700;
      }
    }
  }
  .site-card-title {
    background: #0054A4;
    color: #ffffff;
    padding: 0 22px;
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    min-height: 62px;
    display: flex;
    align-items: center;
    font-family: 'Helvetica', 'Roboto', sans-serif !important;
    margin-bottom: 0;

    @media screen and (min-width: 1025px) {
      font-size: 24px;
      line-height: 30px;
      min-height: 94px;
      padding: 0 32px;
    }
  }
  &:hover {
    .site-card-image p {
      opacity: 1;
    }
  }

}

</style>