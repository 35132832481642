<template>
  <div class="check-column-comp">
    <div>
      <p>
        {{ titleText }}
      </p>
    </div>
    <div class="checkmarks">
      <div
        class="checkmark"
        v-for="(question, index) in questions"
        :key="`${$route.name}_${$route.name}_checks_${id}_${index}`"
      >
        <label class="check-input"><span class="sr-only">{{answer}}</span>
          <input
            type="checkbox"
            v-model="checksFeedback[index]"
            :value="question.value"
          />
          <span>{{ question.value }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckColumn",
  props: {
    id: {
      type: String,
      required: true,
    },
    titleText: {
      type: String,
      default: "Radio Title Text",
    },
    questions: {
      type: Array,
      default() {
        return [{ value: "checkmark1 Value" }, { value: "checkmark2 Value" }];
      },
    },
    currentInput: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  beforeMount() {
    this.checksFeedback = this.currentInput;
  },
  data() {
    return {
      checksFeedback: [],
    };
  },
};
</script>

<style lang="scss">
.check-column-comp {
  p {
    color: #27606c;
    font-weight: bold;
  }

  .checkmarks {
    .checkmark {
      margin-bottom: 8px;
      .check-input {
        span {
          margin-left: 15.5px;
        }
      }
      .check-textarea {
        margin-top: 8px;
        textarea {
          margin-left: 28.5px;
          width: 40%;
          border: 2px solid #27606c;
          border-radius: 8px;
          outline: none;
          padding: 16px;
        }
      }
    }
  }
}
</style>

